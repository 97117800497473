import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import appReducer from "./app/appSlice";
import bulkReceiptingReducer from "./bulkreceipting/bulkReceiptingSlice";
import feeReducer from "./fees/feeSlice";
import invoiceReducer from "./invoice/invoiceSlice";
import matterReducer from "./matter/matterSlice";
import submissionReducer from "./submission/submissionSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    bulkReceipting: bulkReceiptingReducer,
    fee: feeReducer,
    invoice: invoiceReducer,
    matter: matterReducer,
    submission: submissionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
