import { IconButton, Button, Typography, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { close } from "../../shared/utils/sdk-utils";
import CustomDialog from "./custom-dialog";
import { IInvoiceDto } from "../../shared/dto/invoice/invoice.dto";
import {
  clearSemaphore,
  deleteInvoice,
} from "../../app/store/invoice/invoiceSlice";
import binGreyIcon from "../../shared/images/bin-grey.png";
import { setWarningAcknowledgment } from "../../app/store/app/appSlice";

interface IInvoiceDeleteProps {
  invoiceDetails?: IInvoiceDto;
  invoiceRef?: IInvoiceDto;
  onDelete?: () => void;
}

const InvoiceDeleteButton: FC<IInvoiceDeleteProps> = ({
  invoiceDetails,
  invoiceRef,
  onDelete,
}) => {
  const dispatch = useAppDispatch();

  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);

  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const isDisabled =
    !!invoiceDetails &&
    !!invoiceDetails.invoiceId &&
    invoiceDetails?.status === 3;
  return (
    <>
      <Tooltip placement="bottom-end" title={isDisabled ? "" : "Delete"}>
        <span style={isDisabled ? { cursor: "not-allowed" } : {}}>
          <IconButton
            disabled={isDisabled}
            disableRipple
            disableTouchRipple
            onClick={() => {
              if (!!onDelete) {
                onDelete();
              } else {
                if (
                  invoiceDetails?.invoiceId &&
                  JSON.stringify(invoiceDetails) === JSON.stringify(invoiceRef)
                ) {
                  setDialogOpen(true);
                } else if (
                  JSON.stringify(invoiceDetails) !== JSON.stringify(invoiceRef)
                ) {
                  setDialogOpen(true);
                } else {
                  dispatch(
                    clearSemaphore(invoiceDetails?.semaphoreId || "")
                  ).then(() => close(sdkApi));
                }
              }
            }}
            style={{ marginLeft: 10, marginRight: 5 }}
          >
            <img alt="delete" src={binGreyIcon} />
          </IconButton>
        </span>
      </Tooltip>
      <CustomDialog
        isOpen={dialogOpen}
        title="Alert"
        onClose={() => closeDialog()}
        showWarningIcon
        actions={
          <>
            <Button
              variant="contained"
              onClick={() => {
                if (invoiceDetails?.invoiceId) {
                  dispatch(deleteInvoice(invoiceDetails))
                    .unwrap()
                    .then((action) => {
                      if (
                        !!action.payload &&
                        !!action.payload.length &&
                        !!action.payload[0].code
                      ) {
                        dispatch(setWarningAcknowledgment(action.payload[0]));
                      } else {
                        dispatch(setWarningAcknowledgment(undefined));
                        close(sdkApi);
                      }
                      close(sdkApi);
                      closeDialog();
                    })
                    .catch((e) => console.error(e));
                } else {
                  dispatch(
                    clearSemaphore(invoiceDetails?.semaphoreId || "")
                  ).then(() => close(sdkApi));
                }
              }}
              disableRipple
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
              color="secondary"
              disableRipple
            >
              No
            </Button>
          </>
        }
        content={<Typography style={{ minWidth: 295 }}>Delete now?</Typography>}
      />
    </>
  );
};

export default InvoiceDeleteButton;
