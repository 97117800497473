import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomDialog from "./custom-dialog";

interface ITimeInputProps {
  value?: string;
  updater: (value: string | undefined) => void;
  decimalScale?: number;
  disabled?: boolean;
  width?: number;
}

const TimeInput: React.FC<ITimeInputProps> = ({
  value,
  updater,
  disabled,
  width,
}) => {
  const initialised = useRef(false);
  const [timeWarning, setTimeWarning] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    undefined
  );

  const regex: RegExp = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  useEffect(() => {
    if (!!value && !initialised.current) {
      initialised.current = true;
      setCurrentValue(value);
    }
  }, [setCurrentValue, value]);

  useEffect(() => {
    if (!value && !!initialised.current) {
      setCurrentValue(undefined);
      updater(undefined);
    }
  }, [setCurrentValue, value, updater]);

  useEffect(() => {
    if (
      (updater && initialised.current) ||
      (!initialised.current && !!currentValue)
    ) {
      initialised.current = true;
      if (!!currentValue) {
        updater(currentValue);
      } else {
        updater(undefined);
      }
    }
    // eslint-disable-next-line
  }, [currentValue]);
  return (
    <>
      <TextField
        variant="outlined"
        style={{
          width: width,
        }}
        sx={{ input: { textAlign: "right" } }}
        placeholder="hh:mm"
        value={currentValue || ""}
        onChange={(e) => {
          const regex = /^[0-9 :]+$/;

          if (e.target.value === "" || regex.test(e.target.value)) {
            setCurrentValue(e.target.value);
          }
        }}
        disabled={disabled}
        onBlur={(e) => {
          let value = e.target.value;
          if (!value.includes(":")) {
            value = value.substring(0, 2) + ":" + value.substring(2);
          }
          setCurrentValue(value);

          if (!regex.test(value?.toString() || "")) {
            setTimeWarning(true);
          } else {
            updater(value);
          }
        }}
      />
      <CustomDialog
        isOpen={timeWarning}
        title="Warning"
        onClose={() => setTimeWarning(false)}
        showWarningIcon
        actions={
          <Button
            variant="outlined"
            onClick={() => {
              setCurrentValue(undefined);
              updater(undefined);
              setTimeWarning(false);
            }}
            color="secondary"
            disableRipple
          >
            Revert
          </Button>
        }
        content={
          <Typography>
            Selected Total running time of the tapes is invalid
          </Typography>
        }
      />
    </>
  );
};

export default TimeInput;
