import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSortModel,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import moment from "moment";

import { close, openMatter } from "../../shared/utils/sdk-utils";
import { environment } from "../../shared/environments";
import { filterYear } from "../submission/filters";
import { FirmBranch } from "../../shared/dto/common-list.dto";
import { formatDateForAPI } from "../../shared/utils/utils";
import {
  getBranchList,
  getBulkReceiptingInit,
  getInvoiceList,
  postBulkReceipting,
  setFilter,
  setInvoiceItems,
} from "../../app/store/bulkreceipting/bulkReceiptingSlice";
import { getTooltip } from "../../shared/utils/grid-utils";
import {
  IBulkReceiptingPostingDto,
  IInvoiceOfficeReceiptingDto,
  IInvoiceReceiptingItemDto,
  invoiceStatusPaid,
  invoiceStatusUnpaid,
  IOfficeReceiptCreateInitialisationDataDto,
  IPaymentTypeDto,
} from "../../shared/dto/bukreceipting/bulkreceipting.dto";

import { listMonthItems } from "../submission/lists";
import { RootState } from "../../app/store";
import { setValidationErrors } from "../../app/store/app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useAppInit } from "../../shared/hooks/use-app-init";
import { validate } from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomDialog from "../components/custom-dialog";
import Footer from "../components/footer";
import LocalDatePicker from "../components/date-picker";
import markAsPostedIcon from "../../shared/images/markAsSubmitted.png";
import NumberDisplay from "../components/number-display";
import postReceiptsIcon from "../../shared/images/createSubmission.png";
import TopBar from "../components/topbar";
import CustomCheckbox from "../components/custom-checkbox";

const BulkReceiptingDetails: FC = () => {
  const dispatch = useAppDispatch();

  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const bulkReceipting = useAppSelector((state) => state.bulkReceipting);

  const isBulkReceiptingInitialised = useRef(false);
  const isCreateReceipt = useRef(false);
  const isOutOfBalance = useRef(false);

  const [filteredData, setFilteredData] = useState<
    IInvoiceReceiptingItemDto[] | undefined
  >();
  const [invoiceOfficeReceipt, setInvoiceOfficeReceipt] =
    useState<IInvoiceOfficeReceiptingDto>({
      autoNumber: true,
      bankAccountId: "",
      receiptDate: moment(new Date()).format(environment().dateFormat),
    } as IInvoiceOfficeReceiptingDto);
  const [invoiceSelectionMessage, setInvoiceSelectionMessage] = useState<
    string[] | undefined
  >(undefined);
  const [invoiceSelectionMessageTitle, setInvoiceSelectionMessageTitle] =
    useState<string>("Alert");
  const [isShowPostBankAndDate, setIsShowPostBankAndDate] =
    useState<boolean>(false);
  const [isShowinvoiceSelectionMessage, setIsShowInvoiceSelectionMessage] =
    useState<boolean>(false);
  const [paymentReceived, setPaymentReceived] = useState<number>(0.0);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
    undefined
  );

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "invoiceId",
      headerName: "ID",
    },
    {
      disableColumnMenu: true,
      field: "matterType",
      flex: 1,
      headerName: "MATTER TYPE",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      align: "center",
      disableColumnMenu: true,
      field: "matterNo",
      headerAlign: "center",
      headerName: "MATTER NO.",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">
              <Typography
                className="hyperLink"
                noWrap
                onClick={() => {
                  if (!!params.row.matterId) {
                    openMatter(params.row.matterId, sdkApi);
                  }
                }}
              >
                {params.formattedValue}
              </Typography>
            </span>
          </Tooltip>
        );
      },
      width: 110,
    },
    {
      disableColumnMenu: true,
      field: "client",
      flex: 1,
      headerName: "CLIENT",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "matterUFN",
      headerName: "UFN",
      width: 90,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "invoiceNo",
      headerAlign: "right",
      headerName: "INV. NO.",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
      width: 90,
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "invoiceTotal",
      headerAlign: "right",
      headerName: "INV. TOTAL",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => <NumberDisplay hideNoValue showPrefix value={params.value} />,
      type: "number",
      width: 120,
    },
    {
      disableColumnMenu: true,
      field: "invoiceReceiptAmount",
      headerAlign: "right",
      headerName: "APPLY",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => <NumberDisplay hideNoValue showPrefix value={params.value} />,
      sortable: false,
      type: "number",
      width: 120,
    },
  ];

  const getData = useCallback(() => {
    if (!isBulkReceiptingInitialised.current) {
      isBulkReceiptingInitialised.current = true;

      dispatch(getBulkReceiptingInit()).then((action: any) => {
        const payload =
          action.payload as IOfficeReceiptCreateInitialisationDataDto;

        dispatch(getBranchList()).then(() =>
          setInvoiceOfficeReceipt({
            ...invoiceOfficeReceipt,
            bankAccountId: payload.preference.officeBankAccountGUID,
            paymentTypeItem:
              payload.paymentTypeList.filter((item: IPaymentTypeDto) =>
                item.nameFull.toLowerCase().includes("bacs")
              )[0] || ({} as IPaymentTypeDto),
            transactionNumber: (payload.lastTranNum + 1).toString(),
          })
        );
      });
    }
  }, [dispatch, invoiceOfficeReceipt]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!isBulkReceiptingInitialised.current) {
      dispatch(getInvoiceList(bulkReceipting.filter));
    }
  }, [dispatch, bulkReceipting.filter]);

  useEffect(() => {
    // Filtering
    //
    let filterItems = bulkReceipting.invoiceItems;

    // Bank account filtering
    //
    if (
      bulkReceipting.filter.status === invoiceStatusPaid &&
      bulkReceipting.filter.bankAccount !== "All"
    ) {
      filterItems = [...filterItems].filter(
        (invoice) =>
          invoice.invoiceBankAccountId === bulkReceipting.filter.bankAccount
      );
    }

    // Branch filtering
    //
    if (bulkReceipting.filter.branch !== "All") {
      filterItems = [...filterItems].filter(
        (invoice) => invoice.branchId === bulkReceipting.filter.branch
      );
    }

    // Status filtering
    //
    filterItems = [...filterItems].filter(
      (invoice) => invoice.invoiceReceipted === bulkReceipting.filter.status
    );

    setFilteredData(filterItems);
  }, [bulkReceipting.filter, bulkReceipting.invoiceItems]);

  const calculateTotal = (rowItemsSelected: GridRowSelectionModel) => {
    let invoiceTotal = 0.0;

    if (rowItemsSelected.length > 0) {
      const items = (filteredData || []).filter(
        (item: IInvoiceReceiptingItemDto) => {
          return (
            rowItemsSelected.findIndex((e: any) => e === item.invoiceId) !== -1
          );
        }
      );

      invoiceTotal =
        items
          .map((item: IInvoiceReceiptingItemDto) => {
            return item.invoiceTotal || 0;
          })
          .reduce((prior: number, next: number) => {
            return prior + next;
          }, 0) || 0;
    } else if (bulkReceipting.filter.status === invoiceStatusPaid) {
      invoiceTotal =
        (filteredData || [])
          .map((item: IInvoiceReceiptingItemDto) => {
            return item.invoiceTotal || 0;
          })
          .reduce((prior: number, next: number) => {
            return prior + next;
          }, 0) || 0;
    }

    invoiceTotal = parseFloat(invoiceTotal.toFixed(2));
    return [
      invoiceTotal,
      bulkReceipting.filter.status === invoiceStatusPaid
        ? 0.0
        : paymentReceived - invoiceTotal,
    ];
  };

  const getInvoiceItemsSelected = () => {
    return (filteredData || [])
      .filter((item: IInvoiceReceiptingItemDto) => {
        return (
          rowSelectionModel.findIndex((e: any) => e === item.invoiceId) !== -1
        );
      })
      .map((item: IInvoiceReceiptingItemDto) => {
        return item;
      });
  };

  const handleOnPostReceipts = (createReceipt: boolean) => {
    const bulkReceiptingPosting: IBulkReceiptingPostingDto = {
      receipting: {
        bankAccountId: invoiceOfficeReceipt.bankAccountId,
        invoiceReceiptingItems: getInvoiceItemsSelected(),
        paymentTypeItem: invoiceOfficeReceipt.paymentTypeItem,
        receiptDate: formatDateForAPI(invoiceOfficeReceipt.receiptDate),
        transactionNumber: invoiceOfficeReceipt.transactionNumber,
        autoNumber: invoiceOfficeReceipt.autoNumber,
        warningAcknowledgments: invoiceOfficeReceipt.warningAcknowledgments,
      } as IInvoiceOfficeReceiptingDto,
      createReceipt: createReceipt,
    };

    console.log("bulkReceiptingPosting: ", bulkReceiptingPosting);

    dispatch(
      postBulkReceipting({
        bulkReceiptingPosting: bulkReceiptingPosting,
        onSuccessfulPostCallback: handleOnSuccessfulPosting,
      })
    ).then((response: any) => {
      if (response.payload && response.payload.status === 200) {
        handleOnSuccessfulPosting();
      }
    });
  };

  const handleOnSuccessfulPosting = () => {
    const posted = rowSelectionModel.length;
    dispatch(getInvoiceList(bulkReceipting.filter)).then(() => {
      setPaymentReceived(0);
      setRowSelectionModel([]);
      setInvoiceSelectionMessage(
        isCreateReceipt.current
          ? [`${posted} Receipt${posted !== 1 ? "s" : ""} posted.`]
          : [`${posted} Invoice${posted !== 1 ? "s" : ""} marked as posted.`]
      );
      setInvoiceSelectionMessageTitle("Post Receipts");
      setIsShowInvoiceSelectionMessage(true);
    });
  };

  const renderFilterBar = () => {
    return (
      <div className="bulkReceipting-filter-bar">
        <div className="flex1" style={{ maxWidth: 300 }}>
          <Select
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...bulkReceipting.filter,
                  bankAccount:
                    e.target.value === invoiceStatusUnpaid
                      ? "All"
                      : bulkReceipting.filter.bankAccount,
                  status: e.target.value,
                })
              );

              if (e.target.value === invoiceStatusPaid) setPaymentReceived(0.0);
            }}
            sx={{
              backgroundColor: "#1e365e",
              color: "#fff",
              fontWeight: 600,
              letterSpacing: 0.5,
              textAlign: "center",
              textTransform: "uppercase",
              width: 120,
              ".MuiSvgIcon-root ": {
                fill: "white",
              },
            }}
            value={bulkReceipting.filter.status}
            variant="outlined"
          >
            <MenuItem key={"status-1"} value={invoiceStatusPaid as any}>
              Paid
            </MenuItem>
            <MenuItem key={"status-0"} value={invoiceStatusUnpaid as any}>
              Unpaid
            </MenuItem>
          </Select>
        </div>
        <div className="flex1" style={{ marginRight: 15, maxWidth: 300 }}>
          <Select
            disabled={bulkReceipting.filter.status === invoiceStatusUnpaid}
            fullWidth
            native
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...bulkReceipting.filter,
                  bankAccount: e.target.value,
                })
              );
            }}
            value={bulkReceipting.filter.bankAccount}
            variant="outlined"
          >
            <option key="bankaccount--1" value="All">
              {"<All>"}
            </option>
            {(bulkReceipting.init.bankAccounts || []).map(
              (item: any, index: number) => (
                <option
                  key={`bankaccount-${index}`}
                  value={item.bankAccountGUID}
                >
                  {item.nameFileAs}
                </option>
              )
            )}
          </Select>
        </div>
        <div className="flex1" style={{ marginRight: 15, maxWidth: 300 }}>
          <Select
            fullWidth
            native
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...bulkReceipting.filter,
                  branch: e.target.value,
                })
              );
            }}
            value={bulkReceipting.filter.branch}
            variant="outlined"
          >
            {bulkReceipting.branchList?.length > 1 && (
              <option key="branch--1" value="All">
                {"<All>"}
              </option>
            )}
            {(bulkReceipting.branchList || []).map(
              (item: FirmBranch, index: number) => (
                <option key={`branch-${index}`} value={item.__id}>
                  {item.displayName}
                </option>
              )
            )}
          </Select>
        </div>
        <div className="flex1" style={{ marginRight: 15, maxWidth: 120 }}>
          <Select
            fullWidth
            native
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...bulkReceipting.filter,
                  month: parseInt(e.target.value),
                })
              );
            }}
            size="small"
            value={bulkReceipting.filter.month}
            variant="outlined"
          >
            {listMonthItems.map((item: any, index: number) => (
              <option key={`filterMonth-${index}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex1" style={{ maxWidth: 80 }}>
          <Select
            fullWidth
            native
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...bulkReceipting.filter,
                  year: parseInt(e.target.value),
                })
              );
            }}
            size="small"
            value={bulkReceipting.filter.year}
            variant="outlined"
          >
            <option key={`filterYear-0`} value={filterYear.Current}>
              {filterYear.Current}
            </option>
            <option key={`filterYear-1`} value={filterYear.LastYear}>
              {filterYear.LastYear}
            </option>
            <option key={`filterYear-2`} value={filterYear.YearBeforeLast}>
              {filterYear.YearBeforeLast}
            </option>
          </Select>
        </div>
      </div>
    );
  };

  const renderDataGrid = () => {
    return (
      <DataGridPro
        checkboxSelection={bulkReceipting.filter.status === invoiceStatusUnpaid}
        columns={columns.map((c) => {
          return {
            ...c,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderHeader: (params: GridColumnHeaderParams) => {
              return (
                <div
                  style={{
                    display: "grid",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      height: 1,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {sortModel?.find(
                      (f: any) =>
                        f.field === params.colDef.field && f.sort === "asc"
                    ) && (
                      <KeyboardArrowUpRoundedIcon className="tableHeaderIcon" />
                    )}
                    {sortModel?.find(
                      (f: any) =>
                        f.field === params.colDef.field && f.sort === "desc"
                    ) && <ExpandMoreRoundedIcon className="tableHeaderIcon" />}
                  </div>
                  <div style={{ display: "block" }}>
                    <Typography
                      style={{ fontWeight: 500 }}
                      textAlign={params.colDef.headerAlign}
                    >
                      {params.colDef.headerName}
                    </Typography>
                  </div>
                </div>
              );
            },
          };
        })}
        columnHeaderHeight={35}
        columnVisibilityModel={{
          invoiceId: false,
          invoiceReceiptAmount:
            bulkReceipting.filter.status === invoiceStatusUnpaid,
        }}
        disableRowSelectionOnClick
        getRowId={(row: any) => row.invoiceId}
        hideFooter
        initialState={{}}
        loading={bulkReceipting.loading}
        localeText={{
          noRowsLabel:
            "No invoices can be found based on your search criteria.",
        }}
        onRowSelectionModelChange={(
          newRowSelectionModel: GridRowSelectionModel
        ) => {
          setRowSelectionModel(newRowSelectionModel);

          const updateItems = (bulkReceipting.invoiceItems || []).map(
            (item) => {
              if (
                newRowSelectionModel.findIndex(
                  (row) => row === item.invoiceId
                ) >= 0
              ) {
                return {
                  ...item,
                  invoiceReceiptAmount: item.invoiceTotal,
                };
              } else {
                return {
                  ...item,
                  invoiceReceiptAmount: 0,
                };
              }
            }
          );

          dispatch(setInvoiceItems(updateItems));
        }}
        onSortModelChange={(model: any) => setSortModel(model)}
        rowSelectionModel={rowSelectionModel}
        rowHeight={30}
        rows={filteredData || []}
        scrollbarSize={15}
        slots={{
          columnSortedAscendingIcon: KeyboardArrowUpRoundedIcon,
          columnSortedDescendingIcon: ExpandMoreRoundedIcon,
        }}
        sx={{
          backgroundColor: "#fff",
          borderBottom: 0,
          borderRadius: 0,
          ".MuiDataGrid-overlay": { color: "#0060bb" },
          ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
        }}
      />
    );
  };

  const renderTotals = () => {
    const totals = calculateTotal(rowSelectionModel);
    isOutOfBalance.current = totals[1] !== 0.0;

    return (
      <div className="bulkReceipting-footer">
        <div className="flex1">
          <div className="displayFlex" style={{ alignItems: "center" }}>
            <div className="flex1" style={{ color: "#db761d" }}>
              SELECTED: {rowSelectionModel.length}
            </div>
            <div className="flex1" style={{ maxWidth: 140 }}>
              <InputLabel>PAYMENT RECEIVED</InputLabel>
            </div>
            <div className="flex1">
              <CurrencyInput
                prefix={environment().currencyPrefix}
                value={paymentReceived || 0}
                updater={(value) => setPaymentReceived(value)}
              />
            </div>
          </div>
        </div>
        <div className="flex1">
          <div className="displayFlex" style={{ padding: 8, paddingRight: 15 }}>
            <div className="bulkReceipting-footer-label">TOTAL</div>
            <div className="bulkReceipting-footer-total-value">
              {`${environment().currencyPrefix}`}
              {totals[0].toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
          <div className="displayFlex bulkReceipting-total">
            <div className="bulkReceipting-footer-label">OUT OF BALANCE</div>
            <div className="bulkReceipting-footer-total-value">
              {`${environment().currencyPrefix}`}
              {totals[1].toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPostBankAndDate = () => {
    return (
      <div
        className="displayFlex"
        key={"postBankAndDateDialog"}
        style={{ marginTop: 15, marginBottom: 8 }}
      >
        <div className="flex1">
          <div className="inputRow">
            <div className="flex1">
              <InputLabel required>Receipt Date</InputLabel>
            </div>
            <div className="flex2">
              <LocalDatePicker
                key={"postReceiptDate"}
                onUpdate={(value) => {
                  if (value !== invoiceOfficeReceipt.receiptDate) {
                    const newValue = value || "";
                    if (newValue !== "Invalid date") {
                      setInvoiceOfficeReceipt({
                        ...invoiceOfficeReceipt,
                        receiptDate: newValue,
                      });
                    }
                  }
                }}
                value={invoiceOfficeReceipt.receiptDate}
              />
            </div>
          </div>
          <div className="inputRow">
            <div className="flex1">
              <InputLabel required>Bank Account</InputLabel>
            </div>
            <div className="flex2">
              <Select
                fullWidth
                key={"postBankAccount"}
                native
                onChange={(e: any) => {
                  setInvoiceOfficeReceipt({
                    ...invoiceOfficeReceipt,
                    bankAccountId: e.target.value,
                  });
                }}
                value={invoiceOfficeReceipt.bankAccountId}
                variant="outlined"
              >
                {(bulkReceipting.init.bankAccounts || []).map(
                  (item: any, index: number) => (
                    <option
                      key={`bankaccount-post-${index}`}
                      value={item.bankAccountGUID}
                    >
                      {item.nameFileAs}
                    </option>
                  )
                )}
              </Select>
            </div>
          </div>
          <div className="inputRow">
            <div className="flex1">
              <InputLabel required>Payment Type</InputLabel>
            </div>
            <div className="flex2">
              <Select
                fullWidth
                key={"postBankAccount"}
                native
                onChange={(e: any) => {
                  setInvoiceOfficeReceipt({
                    ...invoiceOfficeReceipt,
                    paymentTypeItem:
                      bulkReceipting.init.paymentTypeList.find(
                        (item) => item.paymentTypeGUID === e.target.value
                      ) || ({} as IPaymentTypeDto),
                  });
                }}
                value={
                  invoiceOfficeReceipt.paymentTypeItem?.paymentTypeGUID || 0
                }
                variant="outlined"
              >
                <option
                  key={"paymenttype-post-enmpty"}
                  value={undefined}
                ></option>
                {(bulkReceipting.init.paymentTypeList || [])
                  .filter((item: IPaymentTypeDto) => item.paymentTypeID === 0)
                  .sort((a, b) =>
                    a.nameFull.toLowerCase() > b.nameFull.toLowerCase() ? 1 : -1
                  )
                  .map((item: IPaymentTypeDto, index: number) => (
                    <option
                      key={`paymenttype-post-${index}`}
                      value={item.paymentTypeGUID}
                    >
                      {item.nameFull}
                    </option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="inputRow">
            <div className="flex1">
              <InputLabel required>Receipt No.</InputLabel>
            </div>
            <div className="flex1">
              <TextField
                disabled={invoiceOfficeReceipt.autoNumber}
                key={"transactionNumber"}
                onChange={(e) => {
                  setInvoiceOfficeReceipt({
                    ...invoiceOfficeReceipt,
                    transactionNumber: e.target.value,
                  });
                }}
                inputProps={{ maxLength: 30 }}
                style={{ minWidth: 100 }}
                value={invoiceOfficeReceipt.transactionNumber || ""}
                variant="outlined"
              />
            </div>
            <div className="flex1">
              <div style={{ marginLeft: 10 }}>
                <CustomCheckbox
                  disabled={false}
                  label="Auto"
                  onChange={(value) => {
                    if (value) {
                      dispatch(getBulkReceiptingInit()).then((action: any) => {
                        const payload =
                          action.payload as IOfficeReceiptCreateInitialisationDataDto;

                        setInvoiceOfficeReceipt({
                          ...invoiceOfficeReceipt,
                          autoNumber: true,
                          transactionNumber: (
                            payload.lastTranNum + 1
                          ).toString(),
                        });
                      });
                    } else {
                      setInvoiceOfficeReceipt({
                        ...invoiceOfficeReceipt,
                        autoNumber: value,
                        transactionNumber: "",
                      });
                    }
                  }}
                  value={invoiceOfficeReceipt.autoNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          <>
            <Button
              className="bulkReceipting-top-bar-action-button"
              disabled={bulkReceipting.filter.status === invoiceStatusPaid}
              onClick={() => {
                // recall init to get updated next transaction number and set default receipt date to today
                dispatch(getBulkReceiptingInit()).then((action: any) => {
                  const payload =
                    action.payload as IOfficeReceiptCreateInitialisationDataDto;

                  setInvoiceOfficeReceipt({
                    ...invoiceOfficeReceipt,
                    autoNumber: true,
                    bankAccountId: payload.preference.officeBankAccountGUID,
                    paymentTypeItem:
                      payload.paymentTypeList.filter((item: IPaymentTypeDto) =>
                        item.nameFull.toLowerCase().includes("bacs")
                      )[0] || ({} as IPaymentTypeDto),
                    receiptDate: moment(new Date()).format(
                      environment().dateFormat
                    ),
                    transactionNumber: (payload.lastTranNum + 1).toString(),
                  });

                  isCreateReceipt.current = true;
                  if (rowSelectionModel.length === 0) {
                    setInvoiceSelectionMessage([
                      "No invoices have been selected for receipting.",
                    ]);
                    setInvoiceSelectionMessageTitle("Alert");
                    setIsShowInvoiceSelectionMessage(true);
                  } else if (isOutOfBalance.current) {
                    setInvoiceSelectionMessage([
                      "Receipts total is out of balance.",
                    ]);
                    setInvoiceSelectionMessageTitle("Alert");
                    setIsShowInvoiceSelectionMessage(true);
                  } else {
                    setIsShowPostBankAndDate(true);
                  }
                });
              }}
            >
              <img
                alt="post receipts"
                className="bulkReceipting-top-bar-action-button-icon"
                src={postReceiptsIcon}
              />
              Post Receipts
            </Button>
            <Button
              className="bulkReceipting-top-bar-action-button"
              disabled={bulkReceipting.filter.status === invoiceStatusPaid}
              onClick={() => {
                isCreateReceipt.current = false;
                if (rowSelectionModel.length === 0)
                  setInvoiceSelectionMessage([
                    "No invoices have been selected.",
                  ]);
                else {
                  setInvoiceSelectionMessage([
                    `${rowSelectionModel.length} invoice${
                      rowSelectionModel.length !== 1 ? "s" : ""
                    } will be marked as posted.`,
                    "This will not post receipts to the matter ledgers.",
                    "Do you wish to continue?",
                  ]);
                }
                setInvoiceSelectionMessageTitle("Alert");
                setIsShowInvoiceSelectionMessage(true);
              }}
            >
              <img
                alt="mark as posted"
                className="bulkReceipting-top-bar-action-button-icon"
                src={markAsPostedIcon}
              />
              Mark as Posted
            </Button>
          </>
        }
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Bulk-Receipting"
      />
      {renderFilterBar()}
      <div className="bulkReceipting-main">{renderDataGrid()}</div>
      {renderTotals()}
      <Footer
        onClose={() => {
          close(sdkApi);
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setIsShowInvoiceSelectionMessage(false);
                if (
                  rowSelectionModel.length > 0 &&
                  (!isOutOfBalance.current || !isCreateReceipt.current)
                ) {
                  handleOnPostReceipts(isCreateReceipt.current);
                }
              }}
              variant="contained"
            >
              {rowSelectionModel.length > 0 &&
              (!isOutOfBalance.current || !isCreateReceipt.current)
                ? "Yes"
                : "OK"}
            </Button>
            {rowSelectionModel.length > 0 &&
              (!isOutOfBalance.current || !isCreateReceipt.current) && (
                <Button
                  color="secondary"
                  disableRipple
                  onClick={() => setIsShowInvoiceSelectionMessage(false)}
                  variant="outlined"
                >
                  No
                </Button>
              )}
          </>
        }
        content={invoiceSelectionMessage?.map((item) => (
          <Typography>{item}</Typography>
        ))}
        isOpen={isShowinvoiceSelectionMessage}
        minWidth={350}
        onClose={() => setIsShowInvoiceSelectionMessage(false)}
        showInfoIcon={invoiceSelectionMessageTitle !== "Alert"}
        showWarningIcon={invoiceSelectionMessageTitle === "Alert"}
        title={invoiceSelectionMessageTitle}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                if (
                  !validate(
                    [
                      {
                        value: invoiceOfficeReceipt.receiptDate,
                        label: "Receipt Date",
                      },
                      {
                        value: invoiceOfficeReceipt.bankAccountId,
                        label: "Bank Account",
                      },
                      {
                        value:
                          invoiceOfficeReceipt.paymentTypeItem.paymentTypeGUID,
                        label: "Payment Type",
                      },

                      {
                        value: invoiceOfficeReceipt.transactionNumber,
                        label: "Receipt No.",
                      },
                    ],
                    [],
                    (errors) => dispatch(setValidationErrors(errors))
                  )?.length
                ) {
                  setIsShowPostBankAndDate(false);
                  setIsShowInvoiceSelectionMessage(true);
                  setInvoiceSelectionMessage([
                    "This will post a receipt for each invoice selected.",
                    "Do you wish to continue?",
                  ]);
                }
              }}
              variant="contained"
            >
              OK
            </Button>
            <Button
              color="secondary"
              disableRipple
              onClick={() => {
                setIsShowPostBankAndDate(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </>
        }
        content={renderPostBankAndDate()}
        isOpen={isShowPostBankAndDate}
        minWidth={500}
        onClose={() => setIsShowPostBankAndDate(false)}
        title="Post Receipts"
      />
    </>
  );
};

export default BulkReceiptingDetails;
