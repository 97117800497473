import { filterMonth, filterSubmission } from "./filters";

interface IOption {
  label: string;
  value: any;
}

export const listMonthItems: IOption[] = [
  { label: "January", value: filterMonth.Jan },
  { label: "February", value: filterMonth.Feb },
  { label: "March", value: filterMonth.Mar },
  { label: "April", value: filterMonth.Apr },
  { label: "May", value: filterMonth.May },
  { label: "June", value: filterMonth.Jun },
  { label: "July", value: filterMonth.Jul },
  { label: "August", value: filterMonth.Aug },
  { label: "September", value: filterMonth.Sep },
  { label: "October", value: filterMonth.Oct },
  { label: "November", value: filterMonth.Nov },
  { label: "December", value: filterMonth.Dec },
];

export const listSubmissionItems: IOption[] = [
  { label: "Submitted", value: filterSubmission.Submitted },
  { label: "Unsubmitted", value: filterSubmission.Unsubmitted },
];
