import { Typography, Tooltip } from "@mui/material";
import { FC, ReactNode } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../app/store";
import { openUrl } from "../../shared/utils/sdk-utils";

interface ITopBarProps {
  helpUrl: string;
  leftComponents?: ReactNode;
  centerComponents?: ReactNode;
  rightComponents?: ReactNode;
}

const TopBar: FC<ITopBarProps> = ({
  helpUrl,
  leftComponents,
  centerComponents,
  rightComponents,
}) => {
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);

  return (
    <>
      <div
        className="displayFlex"
        style={{
          alignItems: "center",
          backgroundColor: "#fafafa",
          borderBottom: "1px solid #d8d8d8",
          minHeight: 36,
        }}
      >
        {leftComponents && <>{leftComponents}</>}
        <div className="flex1">{centerComponents}</div>
        {rightComponents && <div className="flex-end">{rightComponents}</div>}
        <div>
          <Tooltip placement="bottom-end" title="Help for this Window">
            <Typography
              sx={{
                ":hover": {
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0060AC",
                },
                alignSelf: "center",
                paddingRight: 1,
              }}
              onClick={() => openUrl(true, helpUrl, "", sdkApi)}
            >
              Help
            </Typography>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default TopBar;
