import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getUsers,
  setLoader,
  setValidationErrors,
} from "../../app/store/app/appSlice";
import {
  clearSemaphore,
  createInvoice,
  getAgfsFixedFee,
  getAgfsInvoiceLists,
  getInvoice,
  getItemsForInvoice,
  reverseInvoice,
  setInit,
  setPrintingEnabled,
  updateInvoice,
  uploadInvoiceToCCCD,
} from "../../app/store/invoice/invoiceSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import {
  AdvocateType,
  CaseType,
  IAgfsTotalsDto,
  ICCCDSuccessDto,
  IInvoiceDto,
  IValidationDto,
  OffenceBand,
  OffenceCategory,
  TrialType,
} from "../../shared/dto/invoice/invoice.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import {
  calculateBalanceDue,
  printReport,
} from "../../shared/utils/invoice-utils";
import {
  close,
  getDecodedToken,
  onBeforeClose,
  openReport,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  formatValue,
  newGuid,
} from "../../shared/utils/utils";
import { validate } from "../../shared/utils/validation-utils";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import InvoiceErrorDialog from "../components/invoice-error-dialog";
import NumberDisplay from "../components/number-display";
import NumberInput from "../components/number-input";
import ReversalInfo from "../components/reversal-info";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { listStatusItems } from "./lists";
import InvoiceDeleteButton from "../components/invoice-delete-button";
import ReverseIcon from "../../shared/images/reverse";

const InvoiceAgfsDetails: FC = () => {
  const dispatch = useAppDispatch();

  const invoice = useAppSelector((state) => state.invoice);
  const agfsLists = useAppSelector((state) => state.invoice.agfsLists);

  const isInvoiceDetailsInitialised = useRef(false);
  const isInvoiceInitialised = useRef(false);
  const isMatterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const invoiceRef = useRef<IInvoiceDto | undefined>(undefined);
  const decodedToken = useRef<any>(undefined);

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.invoice.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );
  const userList = useSelector((state: RootState) => state.app.users);

  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<
    IInvoiceDto | undefined
  >(undefined);
  const [notificationMessageIsWarning, setNotificationMessageIsWarning] =
    useState(false);
  const [notificationMessage, setNotificationMessage] =
    useState<any>(undefined);
  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const [toBeReversed, setToBeReversed] = useState(false);

  const [searchParams] = useSearchParams();

  const infoIcon = require("../../shared/images/info.png");
  const printerIcon = require("../../shared/images/printer-grey.png");

  const [defendantsValidator, setDefendantsValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: false,
      isRequired: true,
    });

  const [trialStartValidator, setTrialStartValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: false,
      isRequired: true,
    });

  const [trialEndValidator, setTrialEndValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [estimatedDaysValidator, setEstimatedDaysValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: false,
      isRequired: true,
    });

  const [actualDaysValidator, setActualDaysValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: false,
      isRequired: true,
    });

  const [retrialStartValidator, setRetrialStartValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: true,
      isRequired: false,
    });

  const [retrialEndValidator, setRetrialEndValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: true,
      isRequired: false,
    });

  const [retrialEstimatedDaysValidator, setRetrialEstimatedDaysValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: true,
      isRequired: false,
    });

  const [retrialActualDaysValidator, setRetrialActualDaysValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: true,
      isRequired: false,
    });

  const [caseCrackedValidator, setCaseCrackedValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: true,
      isRequired: false,
    });

  const getOffenceBands = () => {
    let filteredOffenceBandList: OffenceBand[] | undefined = [];
    let offenceCategory = agfsLists?.offenceCategories.find(
      (oc) =>
        oc.offenceCategoryGuid ===
        currentInvoiceDetails?.externalJSON?.crown?.agfsCategoryId
    );
    if (!!offenceCategory) {
      filteredOffenceBandList = agfsLists?.offenceBands.filter(
        (ob) => ob.offenceCategoryID === offenceCategory?.offenceCategoryID
      );
    }
    if (!!filteredOffenceBandList?.length) {
      return filteredOffenceBandList;
    } else {
      return [];
    }
  };

  const isFormDisabled =
    invoice.init.isReversedOrReversal ||
    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
    !!invoice.init.invoiceId;

  const trialStartDisabled = () => {
    return (
      (currentInvoiceDetails?.externalJSON?.crown?.trialType === "P" &&
        !!currentInvoiceDetails?.externalJSON?.crown?.trialType) ||
      isFormDisabled
    );
  };

  const trialEndDisabled = () => {
    return (
      (currentInvoiceDetails?.externalJSON?.crown?.trialType === "P" &&
        !!currentInvoiceDetails?.externalJSON?.crown?.trialType) ||
      isFormDisabled
    );
  };

  const retrialDisabled = () => {
    let caseTypeText = agfsLists?.caseTypes.find(
      (ct) =>
        ct.caseType_GUID ===
        currentInvoiceDetails?.externalJSON?.crown?.agfsCaseTypeId
    );

    return caseTypeText?.caseType_Name !== "Retrial" || isFormDisabled;
  };

  const discontinuedDisabled = () => {
    return (
      (currentInvoiceDetails?.externalJSON?.crown?.trialType !== "F" &&
        currentInvoiceDetails?.externalJSON?.crown?.trialType !== "" &&
        !!currentInvoiceDetails?.externalJSON?.crown?.trialType) ||
      isFormDisabled
    );
  };

  const trialFixedNoticeDateDisabled = () => {
    let caseTypeText = agfsLists?.caseTypes.find(
      (ct) =>
        ct.caseType_GUID ===
        currentInvoiceDetails?.externalJSON?.crown?.agfsCaseTypeId
    );

    return (
      (caseTypeText?.caseType_Name !== "Cracked trial" &&
        caseTypeText?.caseType_Name !== "Cracked before retrial" &&
        caseTypeText?.caseType_Name !== "" &&
        !!caseTypeText?.caseType_Name) ||
      isFormDisabled
    );
  };

  const trialFixedDateDisabled = () => {
    let caseTypeText = agfsLists?.caseTypes.find(
      (ct) =>
        ct.caseType_GUID ===
        currentInvoiceDetails?.externalJSON?.crown?.agfsCaseTypeId
    );

    return (
      (caseTypeText?.caseType_Name !== "Cracked trial" &&
        caseTypeText?.caseType_Name !== "Cracked before retrial" &&
        caseTypeText?.caseType_Name !== "" &&
        !!caseTypeText?.caseType_Name) ||
      isFormDisabled
    );
  };

  const trialCrackedAtThirdDisabled = () => {
    let caseTypeText = agfsLists?.caseTypes.find(
      (ct) =>
        ct.caseType_GUID ===
        currentInvoiceDetails?.externalJSON?.crown?.agfsCaseTypeId
    );
    let trialType = agfsLists?.trialTypes.find(
      (tt) =>
        tt.trialType_Grade ===
        currentInvoiceDetails?.externalJSON?.crown?.trialType
    );

    return (
      (caseTypeText?.caseType_Name !== "Cracked trial" &&
        caseTypeText?.caseType_Name !== "Cracked before retrial" &&
        caseTypeText?.caseType_Name !== "" &&
        !!trialType?.trialType_Grade) ||
      isFormDisabled
    );
  };

  const convertFormModelToInvoice = (tempInvoice: IInvoiceDto): IInvoiceDto => {
    let invoice: IInvoiceDto = {
      invoiceId: tempInvoice.invoiceId,
      matterId: tempInvoice.matterId,
      externalURL: tempInvoice.externalURL,
      invoiceDate: tempInvoice.invoiceDate,
      externalJSON: {
        openType: tempInvoice.externalJSON?.openType,
        width: tempInvoice.externalJSON?.width,
        height: tempInvoice.externalJSON?.height,
        minWidth: tempInvoice.externalJSON?.minWidth,
        minHeight: tempInvoice.externalJSON?.minHeight,
        windowTitle: tempInvoice.externalJSON?.windowTitle,
        closeHandler: tempInvoice.externalJSON?.closeHandler,
        matterTypeId: tempInvoice.externalJSON?.matterTypeId,
        billingStageId: tempInvoice.externalJSON?.billingStageId,
        ffFeeEarnerId: tempInvoice.externalJSON?.ffFeeEarnerId,
        dateConcluded: tempInvoice.externalJSON?.dateConcluded,
        disbExVat: tempInvoice.externalJSON?.disbExVat,
        disbVat: tempInvoice.externalJSON?.disbVat,
        fixedFee: tempInvoice.externalJSON?.fixedFee,
        fixedFeeVat: tempInvoice.externalJSON?.fixedFeeVat,
        crown: {
          typeOfCase: "",
          trialType: tempInvoice.externalJSON?.crown?.trialType,
          trialDays: 0,
          ppe: 0,
          committalForTrial: 0,
          offence: "",
          offenceClass: "",
          defendantsForUplift:
            tempInvoice.externalJSON?.crown?.defendantsForUplift,
          basicTrialFee: tempInvoice.externalJSON?.crown?.basicTrialFee,
          basicTrialFeeVat: tempInvoice.externalJSON?.crown?.basicTrialFeeVat,
          trialLengthFee: 0,
          trialLengthFeeVat: 0,
          ppeFee: 0,
          ppeFeeVat: 0,
          totalCaseFee: tempInvoice.externalJSON?.crown?.totalCaseFee,
          totalCaseFeeVat: tempInvoice.externalJSON?.crown?.totalCaseFeeVat,
          committalFee: 0,
          committalFeeVat: 0,
          defendantUpliftFee:
            tempInvoice.externalJSON?.crown?.defendantUpliftFee,
          defendantUpliftFeeVat:
            tempInvoice.externalJSON?.crown?.defendantUpliftFeeVat,
          actualDays: tempInvoice.externalJSON?.crown?.actualDays,
          additionalCase: tempInvoice.externalJSON?.crown?.additionalCase,
          advocateType: tempInvoice.externalJSON?.crown?.advocateType,
          withinOneMonth: tempInvoice.externalJSON?.crown?.withinOneMonth,
          afterOneMonth: tempInvoice.externalJSON?.crown?.afterOneMonth,
          trialStart: tempInvoice.externalJSON?.crown?.trialStart,
          trialEnd: tempInvoice.externalJSON?.crown?.trialEnd,
          estimatedDays: tempInvoice.externalJSON?.crown?.estimatedDays,
          agfsOffenceId: tempInvoice.externalJSON?.crown?.agfsOffenceId,
          agfsCategoryId: tempInvoice.externalJSON?.crown?.agfsCategoryId,
          agfsCaseTypeId: tempInvoice.externalJSON?.crown?.agfsCaseTypeId,
          additionalFee: tempInvoice.externalJSON?.crown?.additionalFee,
          additionalFeeVat: tempInvoice.externalJSON?.crown?.additionalFeeVat,
          additionalCaseUplift:
            tempInvoice.externalJSON?.crown?.additionalCaseUplift,
          additionalCaseUpliftVat:
            tempInvoice.externalJSON?.crown?.additionalCaseUpliftVat,
          dailyAttendanceFee:
            tempInvoice.externalJSON?.crown?.dailyAttendanceFee,
          dailyAttendanceFeeVat:
            tempInvoice.externalJSON?.crown?.dailyAttendanceFeeVat,
          discontinued: tempInvoice.externalJSON?.crown?.discontinued
            ? tempInvoice.externalJSON?.crown?.discontinued
            : "",
          retrialStart: tempInvoice.externalJSON?.crown?.retrialStart,
          retrialEnd: tempInvoice.externalJSON?.crown?.retrialEnd,
          retrialEstimatedDays:
            tempInvoice.externalJSON?.crown?.retrialEstimatedDays,
          retrialActualDays: tempInvoice.externalJSON?.crown?.retrialActualDays,
          trialCrackedAt: tempInvoice.externalJSON?.crown?.trialCrackedAt,
          trialCrackedAtThird:
            tempInvoice.externalJSON?.crown?.trialCrackedAtThird,
          trialFixedDate: tempInvoice.externalJSON?.crown?.trialFixedDate,
          trialFixedNoticeDate:
            tempInvoice.externalJSON?.crown?.trialFixedNoticeDate,
          mainHearingDate: tempInvoice.externalJSON?.crown?.mainHearingDate,
        },
      },
      invoiceNumber: tempInvoice.invoiceNumber,
      invoiceDesc: tempInvoice.invoiceDesc,
      timeRecordGuids: tempInvoice.timeRecordGuids,
      reversedOrReversal: tempInvoice.reversedOrReversal,
      reversalId: tempInvoice.reversalId,
      semaphoreId: tempInvoice.semaphoreId,
      warningAcknowledgments: tempInvoice.warningAcknowledgments,
      status: tempInvoice.status,
      deleted: tempInvoice.deleted,
      totalAdjustments: tempInvoice.totalAdjustments,
      totalApplied: tempInvoice.totalApplied,
    };
    return invoice;
  };

  const getSetFixedFee = useCallback(
    (invoice: IInvoiceDto) => {
      if (
        invoice &&
        invoice?.externalJSON?.crown?.trialType &&
        invoice?.externalJSON?.crown?.advocateType &&
        invoice?.externalJSON?.crown?.agfsOffenceId
      ) {
        dispatch(
          getAgfsFixedFee({
            matterId: matterDetails?.matterId,
            taxFree: matterDetails?.taxFree,
            advocateType: invoice.externalJSON?.crown?.advocateType,
            trialType: invoice.externalJSON?.crown?.trialType,
            offenceId: invoice.externalJSON?.crown?.agfsOffenceId,
            actualDays:
              (invoice.externalJSON?.crown?.retrialActualDays || 0) > 0
                ? invoice.externalJSON?.crown?.retrialActualDays
                : invoice.externalJSON?.crown?.actualDays,
            withinOneMonth: invoice.externalJSON?.crown?.withinOneMonth,
            afterOneMonth: invoice.externalJSON?.crown?.afterOneMonth,
            noOfDefendants: invoice.externalJSON?.crown?.defendantsForUplift,
            noOfAdditionalCase: invoice.externalJSON?.crown?.additionalCase,
            discontinued: invoice.externalJSON?.crown?.discontinued,
            additionalFee: invoice.externalJSON?.crown?.additionalFee,
            additionalFeeVat: invoice.externalJSON?.crown?.additionalFeeVat,
            schemeNo: matterDetails?.legalAidObj?.crownCourt?.schemeNo,
          })
        ).then((action: any) => {
          const payload = action.payload as IAgfsTotalsDto;
          if (payload)
            setCurrentInvoiceDetails({
              ...invoice,
              externalJSON: {
                ...invoice?.externalJSON,
                crown: {
                  ...invoice?.externalJSON?.crown,
                  basicTrialFee: payload.basicTrialFee,
                  basicTrialFeeVat: payload.basicTrialFeeVat,
                  dailyAttendanceFee: payload.dailyAttendanceFee,
                  dailyAttendanceFeeVat: payload.dailyAttendanceFeeVat,
                  defendantUpliftFee: payload.defendantUpliftsFee,
                  defendantUpliftFeeVat: payload.defendantUpliftsFeeVat,
                  additionalCaseUplift: payload.additionalCaseUplift,
                  additionalCaseUpliftVat: payload.additionalCaseUpliftVat,
                  totalCaseFee: payload.totalCaseFee,
                  totalCaseFeeVat: payload.totalCaseFeeVat,
                },
                fixedFee:
                  payload.totalCaseFee +
                  payload.defendantUpliftsFee +
                  payload.additionalCaseUplift,
                fixedFeeVat:
                  payload.totalCaseFeeVat +
                  payload.defendantUpliftsFeeVat +
                  payload.additionalCaseUpliftVat,
              },
            });
        });
      } else setCurrentInvoiceDetails(invoice);
    },
    [dispatch, matterDetails]
  );

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "id",
      headerName: "ID",
      width: 0,
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      sortable: false,
      renderCell: (params: any) => {
        if (params.value === "Fixed Fee") {
          return (
            <>
              {params.value}
              <Tooltip
                style={{ paddingLeft: 5 }}
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Fixed Fee:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFee,
                        invoice.init.isReversedOrReversal ||
                          Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                          toBeReversed
                      )}
                    <br />
                    Fee Limit:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFeeLimit,
                        invoice.init.isReversedOrReversal ||
                          Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                          toBeReversed
                      )}
                  </div>
                }
              >
                <img alt="warning" src={infoIcon} />
              </Tooltip>
              {currentInvoiceDetails?.externalJSON?.isClaimExceptional &&
                currentInvoiceDetails.externalJSON.fixedFee !== 0 && (
                  <Typography color="grey" marginLeft={6}>
                    (This claim is Exceptional)
                  </Typography>
                )}
            </>
          );
        }

        return params.value;
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "time",
      headerAlign: "center",
      headerName: "TIME",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "amount",
      headerAlign: "center",
      headerName: "AMOUNT",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "tax",
      headerAlign: "center",
      headerName: "TAX",
      sortable: false,
      type: "number",
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
  ];

  const handleClose = useCallback(() => {
    if (invoiceRef.current && invoiceRef.current.semaphoreId) {
      dispatch(clearSemaphore(invoiceRef.current.semaphoreId)).then(() => {
        close(sdkApi);
      });
    } else {
      close(sdkApi);
    }
  }, [dispatch, sdkApi]);

  const getData = useCallback(() => {
    if (!isInvoiceInitialised.current) {
      isInvoiceInitialised.current = true;
      getDecodedToken(sdkApi).then((payload) => {
        decodedToken.current = payload;
      });
      dispatch(
        setInit({
          billingStageId: searchParams.get("billingstageid"),
          invoiceId: searchParams.get("invoiceid"),
          isReversedOrReversal: searchParams.get("reversal")
            ? searchParams.get("reversal")
            : false,
          matterTypeId: appInit?.laMatterTypeId,
          matterTypeName: appInit?.laMatterTypeName,
        })
      );

      dispatch(getMatterDetails(false)).then((matterAction) => {
        if (matterAction.meta.requestStatus !== "rejected") {
          dispatch(getUsers());
        }
      });
    }
  }, [dispatch, appInit, searchParams, sdkApi]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !isMatterDetailsInitialised.current) {
      isMatterDetailsInitialised.current = true;
      dispatch(
        getAgfsInvoiceLists({
          schemeNo: matterDetails.legalAidObj?.crownCourt?.schemeNo,
        })
      ).then(() => {
        if (invoice.init.invoiceId) {
          dispatch(getInvoice(invoice.init.invoiceId)).then((action) => {
            setWindowTitle(
              `${matterDetails?.matterReference || ""} ${
                matterDetails?.matterDescription || ""
              }`,
              sdkApi,
              60,
              action.payload.externalJSON?.windowTitle
                ? `- ${action.payload.externalJSON?.windowTitle}`
                : ""
            );
          });
        } else if (invoice.init.billingStageId) {
          dispatch(
            getItemsForInvoice({
              matterId: matterDetails?.matterId,
              billingStageId: invoice?.init.billingStageId,
              disbursementsOnly: false,
            })
          );
          setWindowTitle(
            `${matterDetails?.matterReference || ""} ${
              matterDetails?.matterDescription || ""
            }`,
            sdkApi,
            60,
            "- New Invoice"
          );
        }
      });
    }
  }, [
    dispatch,
    matterDetails,
    invoice.init,
    isMatterDetailsInitialised,
    sdkApi,
  ]);

  useEffect(() => {
    if (!!invoice.invoice && !isInvoiceDetailsInitialised.current) {
      isInvoiceDetailsInitialised.current = true;
      getSetFixedFee(invoice.invoice);
      setCurrentInvoiceDetails(invoice.invoice);
      invoiceRef.current = invoice.invoice;
    }
  }, [
    currentInvoiceDetails,
    invoice.invoice,
    isInvoiceDetailsInitialised,
    getSetFixedFee,
  ]);

  const getTotals = () => {
    const calculationLabel: string =
      "Total Case Fee + Additional Fees + Defendant Uplift + Additional Case Uplift + Disbursements";
    const totalCost: number =
      (currentInvoiceDetails?.externalJSON?.crown?.totalCaseFee || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.additionalFee || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFee || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.additionalCaseUplift || 0) +
      (currentInvoiceDetails?.externalJSON?.disbExVat || 0);
    const totalVat: number =
      (currentInvoiceDetails?.externalJSON?.crown?.totalCaseFeeVat || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.additionalFeeVat || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFeeVat || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.additionalCaseUpliftVat ||
        0) +
      (currentInvoiceDetails?.externalJSON?.disbVat || 0);

    return {
      totalCost,
      totalVat,
      calculationLabel,
    };
  };

  useEffect(() => {
    invoiceRef.current = currentInvoiceDetails;
  }, [currentInvoiceDetails]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi && !!invoice.invoice) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => {
        if (
          JSON.stringify(invoice.invoice) !== JSON.stringify(invoiceRef.current)
        ) {
          setShowUnsavedData(true);
        } else {
          handleClose();
        }
      }, sdkApi);
    }
  }, [sdkApi, invoice.invoice, handleClose]);

  const getRequiredFields = (data: IInvoiceDto) => {
    let fields = [];

    fields.push({
      value: data?.externalJSON?.crown?.trialType,
      label: "Trial Type",
    });

    fields.push({
      value: data?.externalJSON?.crown?.agfsCaseTypeId,
      label: "Case Type",
    });

    fields.push({
      value: data?.externalJSON?.crown?.advocateType,
      label: "Advocate Type",
    });

    fields.push({
      value: data?.externalJSON?.dateConcluded,
      label: "Date Concluded",
    });

    fields.push({
      value: data?.invoiceDate,
      label: "Date Billed",
    });

    fields.push({
      value: data?.externalJSON?.crown?.agfsCategoryId,
      label: "Offence Category",
    });

    fields.push({
      value: data?.externalJSON?.crown?.agfsOffenceId,
      label: "Offence Band",
    });

    if (defendantsValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.defendantsForUplift,
        label: "Defendants",
      });

    if (trialStartValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.trialStart,
        label: "Trial Start",
      });

    if (trialEndValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.trialEnd,
        label: "Trial End",
      });

    if (estimatedDaysValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.estimatedDays,
        label: "Estimated Days",
      });

    if (actualDaysValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.actualDays,
        label: "Actual Days",
      });

    if (retrialStartValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.retrialStart,
        label: "Retrial Start",
      });

    if (retrialEndValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.retrialEnd,
        label: "Retrial End",
      });

    if (retrialEstimatedDaysValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.retrialEstimatedDays,
        label: "Estimated Days",
      });

    if (retrialActualDaysValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.retrialActualDays,
        label: "Actual Days",
      });

    if (caseCrackedValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crown?.trialCrackedAt,
        label: "Case Cracked",
      });

    return fields;
  };

  const getDataRows = [
    {
      id: newGuid(),
      description: "Basic Fee",
      amount: currentInvoiceDetails?.externalJSON?.crown?.basicTrialFee,
      tax: currentInvoiceDetails?.externalJSON?.crown?.basicTrialFeeVat,
    },
    {
      id: newGuid(),
      description: "Daily Attendance Fee",
      amount: currentInvoiceDetails?.externalJSON?.crown?.dailyAttendanceFee,
      tax: currentInvoiceDetails?.externalJSON?.crown?.dailyAttendanceFeeVat,
    },
    {
      id: newGuid(),
      description: "Total Case Fee",
      amount: currentInvoiceDetails?.externalJSON?.crown?.totalCaseFee,
      tax: currentInvoiceDetails?.externalJSON?.crown?.totalCaseFeeVat,
    },
    {
      id: newGuid(),
      description: "Additional Fees",
      amount: currentInvoiceDetails?.externalJSON?.crown?.additionalFee,
      tax: currentInvoiceDetails?.externalJSON?.crown?.additionalFeeVat,
    },
    {
      id: newGuid(),
      description: "Defendant Uplift",
      amount: currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFee,
      tax: currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFeeVat,
    },
    {
      id: newGuid(),
      description: "Additional Case Uplift",
      amount: currentInvoiceDetails?.externalJSON?.crown?.additionalCaseUplift,
      tax: currentInvoiceDetails?.externalJSON?.crown?.additionalCaseUpliftVat,
    },
    {
      id: newGuid(),
      description: "Disbursements",
      amount: currentInvoiceDetails?.externalJSON?.disbExVat,
      tax: currentInvoiceDetails?.externalJSON?.disbVat,
    },
  ];

  const handleUploadToCCCD = () => {
    if (currentInvoiceDetails?.invoiceId) {
      if (currentInvoiceDetails?.deleted === 1) {
        setNotificationMessageIsWarning(true);
        setNotificationMessage(
          "You cannot upload a claim for a reversed or a reversal."
        );
      } else {
        if (
          currentInvoiceDetails?.reversedOrReversal === 1 ||
          invoice.init.isReversedOrReversal ||
          Boolean(currentInvoiceDetails.reversedOrReversal)
        ) {
          setNotificationMessageIsWarning(true);
          setNotificationMessage(
            "You cannot upload a claim for a reversed or a reversal."
          );
        } else if (invoice.uploadSuccess) {
          setNotificationMessageIsWarning(true);
          setNotificationMessage("Claim already uploaded.");
        } else {
          dispatch(
            uploadInvoiceToCCCD({
              invoice: currentInvoiceDetails,
              invoiceUrl: "agfstocccd",
            })
          ).then((action: any) => {
            const payload = action.payload as ICCCDSuccessDto;
            if (payload.message) {
              setNotificationMessageIsWarning(false);
              setNotificationMessage(payload.message);
            }
          });
        }
      }
    } else {
      setNotificationMessageIsWarning(true);
      setNotificationMessage("Please save the invoice first.");
    }
  };

  const renderTotals = () => {
    const totals = getTotals();

    return (
      <Box
        sx={{
          width: "100%",
          height: "80px",
        }}
      >
        <div className="displayFlex">
          <div className="flex1">
            <Typography
              fontWeight={600}
              marginTop={1}
              sx={{ color: "#0160aa" }}
            >
              Total Breakdown
            </Typography>
            <Typography color="grey" marginLeft={2}>
              <i>{totals.calculationLabel}</i>
            </Typography>
            <div style={{ display: "inline-flex", marginTop: 10 }}>
              <div className="invoice-breakdown-box">
                <NumberDisplay
                  value={currentInvoiceDetails?.totalAdjustments}
                  showPrefix
                  showNegative={
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                    toBeReversed
                  }
                  align="right"
                  color="#0160aa"
                />
                <Typography align="right">ADJUSTMENTS</Typography>
              </div>
              <div className="invoice-breakdown-box">
                <NumberDisplay
                  value={currentInvoiceDetails?.totalApplied}
                  showPrefix
                  showNegative={
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                    toBeReversed
                  }
                  align="right"
                  color="#0160aa"
                />
                <Typography align="right">TOTAL PAID</Typography>
              </div>
            </div>
          </div>
          <div>
            <div
              className="invoice-total-row"
              style={{
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <div style={{ width: 90 }}>
                <Typography style={{ fontWeight: 600 }}>TOTAL</Typography>
              </div>
              <div style={{ width: 106 }}></div>
              <div style={{ width: 100 }}>
                <NumberDisplay
                  value={totals?.totalCost}
                  showPrefix
                  showNegative={
                    (!currentInvoiceDetails?.reversalId &&
                      (invoice.init.isReversedOrReversal ||
                        Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                    toBeReversed
                  }
                  align="right"
                />
              </div>
              <div style={{ width: 100 }}>
                <NumberDisplay
                  value={totals?.totalVat || 0}
                  showPrefix
                  showNegative={
                    (!currentInvoiceDetails?.reversalId &&
                      (invoice.init.isReversedOrReversal ||
                        Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                    toBeReversed
                  }
                  align="right"
                />
              </div>
            </div>
            <div className="invoice-total-row">
              <div style={{ width: 90 }}>
                <Typography style={{ fontWeight: 600 }}>INC. TAX</Typography>
              </div>
              <div style={{ width: 206 }}>
                <NumberDisplay
                  value={totals.totalCost + totals.totalVat}
                  showPrefix
                  showNegative={
                    (!currentInvoiceDetails?.reversalId &&
                      (invoice.init.isReversedOrReversal ||
                        Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                    toBeReversed
                  }
                  align="right"
                />
              </div>
            </div>
            <div className="invoice-total-footer">
              <div style={{ width: 90 }}>
                <Typography style={{ color: "#db761d", fontWeight: 600 }}>
                  BALANCE DUE
                </Typography>
              </div>
              <div style={{ width: 207 }}>
                <NumberDisplay
                  value={calculateBalanceDue(
                    [
                      totals.totalCost,
                      totals.totalVat,
                      currentInvoiceDetails?.totalAdjustments || 0,
                    ],
                    [currentInvoiceDetails?.totalApplied || 0]
                  )}
                  showPrefix
                  showNegative={
                    (!currentInvoiceDetails?.reversalId &&
                      (invoice.init.isReversedOrReversal ||
                        Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                    toBeReversed
                  }
                  align="right"
                  bold
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          <>
            <InvoiceDeleteButton
              invoiceDetails={currentInvoiceDetails}
              invoiceRef={invoiceRef.current}
            />
            <Tooltip placement="bottom-end" title="Reverse Transaction">
              <span>
                <IconButton
                  disabled={
                    !currentInvoiceDetails?.invoiceId ||
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                    toBeReversed
                  }
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    setToBeReversed(true);
                  }}
                >
                  <ReverseIcon
                    disabled={
                      !currentInvoiceDetails?.invoiceId ||
                      invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                      toBeReversed
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
        centerComponents={
          <ReversalInfo
            isReversal={
              invoice.init.isReversedOrReversal ||
              Boolean(currentInvoiceDetails?.reversedOrReversal)
            }
          />
        }
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Crown-Court-Invoicing-a-Crown-Court-AGFS-Fixed-Fee"
        rightComponents={
          <>
            <Tooltip placement="bottom-end" title="Upload To CCCD">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  handleUploadToCCCD();
                }}
                style={{ marginRight: 15 }}
              >
                <ArrowCircleUpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="bottom-end" title="Print">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  printReport(
                    () =>
                      setNotificationMessage(
                        "This transaction will be printed after saving."
                      ),
                    () =>
                      openReport(
                        currentInvoiceDetails?.invoiceId || "",
                        sdkApi
                      ),
                    () => dispatch(setPrintingEnabled(true)),
                    currentInvoiceDetails
                  );
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="print" src={printerIcon} />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <div className="invoice-main">
        <div className="invoice-criteria">
          <div className="input-row-aligned">
            <div className="flex2">
              <InputLabel>Matter</InputLabel>
            </div>
            <div className="flex5" style={{ display: "flex" }}>
              <TextField
                disabled
                value={matterDetails?.matterReference || ""}
                variant="outlined"
                style={{ width: 110 }}
              />
              <Typography
                style={{ marginTop: 3, paddingLeft: 5 }}
                maxWidth={211}
                noWrap
              >
                {matterDetails?.matterDescription}
              </Typography>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>
                Main Hearing Date
              </InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown
                      ?.mainHearingDate || null
                  }
                  disabled={isFormDisabled}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown
                        ?.mainHearingDate
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              mainHearingDate: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex9" />
          </div>
          <div className="input-row-aligned">
            <div className="flex2">
              <InputLabel required>Trial Type</InputLabel>
            </div>
            <div className="flex5">
              <Select
                fullWidth
                native
                disabled={isFormDisabled}
                onChange={(event) => {
                  let tempInvoice = {
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        trialType: event.target.value,
                        advocateType: "",
                        agfsOffenceId: "",
                        agfsCategoryId: "",
                      },
                    },
                  } as IInvoiceDto;

                  const currentType = agfsLists?.trialTypes.find(
                    (t) => t.trialType_Grade === event.target.value
                  );
                  if (currentType?.trialType_Description === "Guilty Plea") {
                    setTrialStartValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: false,
                    });
                    setTrialEndValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: false,
                    });
                    setEstimatedDaysValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: false,
                    });
                    setActualDaysValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: false,
                    });
                  } else {
                    setTrialStartValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                    setTrialEndValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                    setEstimatedDaysValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                    setActualDaysValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                  }

                  if (currentType?.trialType_Description === "Fixed Fee") {
                    setDefendantsValidator({
                      error: "",
                      isDisabled: true,
                      isRequired: false,
                    });
                    tempInvoice = {
                      ...tempInvoice,
                      externalJSON: {
                        ...tempInvoice.externalJSON,
                        crown: {
                          ...tempInvoice.externalJSON?.crown,
                          defendantsForUplift: 1,
                        },
                      },
                    };
                  } else {
                    setDefendantsValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                  }
                  setCurrentInvoiceDetails(tempInvoice);
                }}
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.trialType || ""
                }
                variant="outlined"
              >
                {(!currentInvoiceDetails?.externalJSON?.crown?.trialType ||
                  currentInvoiceDetails?.externalJSON?.crown?.trialType ===
                    "") && <option key={""} value=""></option>}
                {agfsLists?.trialTypes?.map((item: TrialType) => (
                  <option
                    key={item.trialType_Grade}
                    value={item.trialType_Grade}
                  >
                    {item.trialType_Description}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex2">
              <InputLabel
                style={{ marginLeft: 10 }}
                required={trialStartValidator.isRequired}
              >
                Trial Start
              </InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.trialStart ||
                    null
                  }
                  disabled={trialStartDisabled()}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown?.trialStart
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              trialStart: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex2">
              <InputLabel
                style={{ marginLeft: 10 }}
                required={trialEndValidator.isRequired}
              >
                Trial End
              </InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.trialEnd || null
                  }
                  disabled={trialEndDisabled()}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown?.trialEnd
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        getSetFixedFee({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              trialEnd: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Invoice No.</InputLabel>
            </div>
            <div className="flex3">
              <TextField
                disabled
                placeholder="Auto no"
                variant="outlined"
                value={currentInvoiceDetails?.invoiceNumber || ""}
                style={{ width: 108 }}
              />
            </div>
          </div>
          <div className="input-row-aligned">
            <div className="flex2">
              <InputLabel required>Case Type</InputLabel>
            </div>
            <div className="flex5">
              <Select
                fullWidth
                native
                disabled={isFormDisabled}
                onChange={(event) => {
                  setCurrentInvoiceDetails({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        agfsCaseTypeId: event.target.value,
                        //TODO LA2-177
                        // retrialStart: undefined,
                        // retrialEnd: undefined,
                        retrialEstimatedDays: 0,
                        retrialActualDays: 0,
                        trialFixedNoticeDate: "",
                        //TODO LA2-177
                        // trialFixedDate: "",
                        // trialCrackedAt: "",
                        trialCrackedAtThird: "",
                      },
                    },
                  } as IInvoiceDto);
                  const caseType = agfsLists?.caseTypes.find(
                    (c) => c.caseType_GUID === event.target.value
                  );
                  if (caseType?.caseType_Name === "Retrial") {
                    setRetrialStartValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                    setRetrialEndValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                    setRetrialEstimatedDaysValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                    setRetrialActualDaysValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                  } else {
                    setRetrialStartValidator({
                      error: "",
                      isDisabled: true,
                      isRequired: false,
                    });
                    setRetrialEndValidator({
                      error: "",
                      isDisabled: true,
                      isRequired: false,
                    });
                    setRetrialEstimatedDaysValidator({
                      error: "",
                      isDisabled: true,
                      isRequired: false,
                    });
                    setRetrialActualDaysValidator({
                      error: "",
                      isDisabled: true,
                      isRequired: false,
                    });
                  }
                  if (
                    caseType?.caseType_Name === "Cracked before retrial" ||
                    caseType?.caseType_Name === "Cracked trial"
                  ) {
                    setCaseCrackedValidator({
                      error: "",
                      isDisabled: false,
                      isRequired: true,
                    });
                  } else {
                    setCaseCrackedValidator({
                      error: "",
                      isDisabled: true,
                      isRequired: false,
                    });
                  }
                }}
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.agfsCaseTypeId ||
                  ""
                }
                variant="outlined"
              >
                {(!currentInvoiceDetails?.externalJSON?.crown?.agfsCaseTypeId ||
                  currentInvoiceDetails?.externalJSON?.crown?.agfsCaseTypeId ===
                    "") && <option key={""} value=""></option>}
                {agfsLists?.caseTypes.map((item: CaseType) => (
                  <option key={item.caseType_GUID} value={item.caseType_GUID}>
                    {item.caseType_Name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex2">
              <InputLabel
                style={{ marginLeft: 10 }}
                required={estimatedDaysValidator.isRequired}
              >
                Estimated Days
              </InputLabel>
            </div>
            <div className="flex2">
              <NumberInput
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.estimatedDays
                }
                disabled={isFormDisabled}
                decimalScale={0}
                updater={(value) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        estimatedDays: Number(value),
                      },
                    },
                  } as IInvoiceDto);
                }}
                width={84}
              />
            </div>
            <div className="flex2">
              <InputLabel
                style={{ marginLeft: 10 }}
                required={actualDaysValidator.isRequired}
              >
                Actual Days
              </InputLabel>
            </div>
            <div className="flex2">
              <NumberInput
                value={currentInvoiceDetails?.externalJSON?.crown?.actualDays}
                disabled={isFormDisabled}
                decimalScale={0}
                updater={(value) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        actualDays: Number(value),
                      },
                    },
                  } as IInvoiceDto);
                }}
                width={84}
              />
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }} required>
                Date Concluded
              </InputLabel>
            </div>
            <div className="flex3">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  disabled={isFormDisabled}
                  onUpdate={(value: any) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.dateConcluded
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            dateConcluded: newValue,
                          },
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.dateConcluded
                      ? currentInvoiceDetails.externalJSON?.dateConcluded
                      : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="input-row-aligned">
            <div className="flex2">
              <InputLabel required>Advocate Type</InputLabel>
            </div>
            <div className="flex5">
              <Select
                fullWidth
                native
                disabled={isFormDisabled}
                onChange={(event) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        advocateType: event.target.value,
                      },
                    },
                  } as IInvoiceDto);
                }}
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.advocateType || ""
                }
                variant="outlined"
              >
                {(!currentInvoiceDetails?.externalJSON?.crown?.advocateType ||
                  currentInvoiceDetails?.externalJSON?.crown?.advocateType ===
                    "") && <option key={""} value=""></option>}
                {[...(agfsLists?.advocateTypes || [])]
                  .sort((a, b) =>
                    a.advocateType_Grade > b.advocateType_Grade ? 1 : -1
                  )
                  .map((item: AdvocateType) => (
                    <option
                      key={item.advocateType_Grade}
                      value={item.advocateType_Grade}
                    >
                      {item.advocateTypeDesc}
                    </option>
                  ))}
              </Select>
            </div>
            <div className="flex2">
              <InputLabel
                style={{ marginLeft: 10 }}
                required={retrialStartValidator.isRequired}
              >
                Retrial Start
              </InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.retrialStart ||
                    null
                  }
                  disabled={retrialStartValidator.isDisabled}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown?.retrialStart
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        getSetFixedFee({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              retrialStart: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex2">
              <InputLabel
                style={{ marginLeft: 10 }}
                required={retrialEndValidator.isRequired}
              >
                Retrial End
              </InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.retrialEnd ||
                    null
                  }
                  disabled={retrialEndValidator.isDisabled}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown?.retrialEnd
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        getSetFixedFee({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              retrialEnd: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }} required>
                Date Billed
              </InputLabel>
            </div>
            <div className="flex3">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  disabled={isFormDisabled && !toBeReversed}
                  onUpdate={(value: any) => {
                    if (value !== currentInvoiceDetails?.invoiceDate) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          invoiceDate: newValue,
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.invoiceDate
                      ? currentInvoiceDetails.invoiceDate
                      : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="input-row-aligned">
            <div className="flex2">
              <InputLabel required>Offence Category</InputLabel>
            </div>
            <div className="flex5">
              <Select
                disabled={isFormDisabled}
                fullWidth
                native
                onChange={(event) => {
                  setCurrentInvoiceDetails({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        agfsCategoryId: event.target.value,
                        agfsOffenceId: "",
                      },
                    },
                  } as IInvoiceDto);
                }}
                required
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.agfsCategoryId ||
                  ""
                }
                variant="outlined"
              >
                {(!currentInvoiceDetails?.externalJSON?.crown?.agfsCategoryId ||
                  currentInvoiceDetails?.externalJSON?.crown?.agfsCategoryId ===
                    "") && <option key={""} value=""></option>}
                {agfsLists?.offenceCategories?.map((item: OffenceCategory) => (
                  <option
                    key={item.offenceCategoryGuid}
                    value={item.offenceCategoryGuid}
                  >
                    {item.offenceCategoryID} {item.offenceCategoryDesc}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Estimated Days</InputLabel>
            </div>
            <div className="flex2">
              <NumberInput
                value={
                  currentInvoiceDetails?.externalJSON?.crown
                    ?.retrialEstimatedDays
                }
                disabled={retrialEstimatedDaysValidator.isDisabled}
                decimalScale={0}
                updater={(value) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        retrialEstimatedDays: Number(value),
                      },
                    },
                  } as IInvoiceDto);
                }}
                width={84}
              />
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Actual Days</InputLabel>
            </div>
            <div className="flex2">
              <NumberInput
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.retrialActualDays
                }
                disabled={retrialActualDaysValidator.isDisabled}
                decimalScale={0}
                updater={(value) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        retrialActualDays: value,
                      },
                    },
                  } as IInvoiceDto);
                }}
                width={84}
              />
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Status</InputLabel>
            </div>
            <div className="flex3">
              <Select
                fullWidth
                native
                onChange={(e: any) => {
                  setCurrentInvoiceDetails({
                    ...currentInvoiceDetails,
                    status: e.target.value,
                  });
                }}
                value={
                  !!currentInvoiceDetails?.status ||
                  currentInvoiceDetails?.status === 0
                    ? currentInvoiceDetails?.status
                    : ""
                }
                variant="outlined"
                disabled={isFormDisabled && currentInvoiceDetails?.status === 3}
              >
                {listStatusItems.map((item: any, index: number) => (
                  <option key={`invoiceStatus-${index}`} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <div className="input-row-aligned">
            <div className="flex2">
              <InputLabel required>Offence Band</InputLabel>
            </div>
            <div className="flex5">
              <Select
                disabled={isFormDisabled}
                onChange={(event) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        agfsOffenceId: event.target.value,
                      },
                    },
                  } as IInvoiceDto);
                }}
                required
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.agfsOffenceId ||
                  ""
                }
                native
                fullWidth
                variant="outlined"
              >
                {(!currentInvoiceDetails?.externalJSON?.crown?.agfsOffenceId ||
                  currentInvoiceDetails?.externalJSON?.crown?.agfsOffenceId ===
                    "") && <option key={""} value=""></option>}
                {getOffenceBands().map((item: OffenceBand) => (
                  <option key={item.offenceGuid} value={item.offenceGuid}>
                    {`${item.offenceBandName} ${item.offenceBandDesc}`}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Discontinuance</InputLabel>
            </div>
            <div className="flex2">
              <Select
                native
                onChange={(event) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        discontinued: event.target.value,
                      },
                    },
                  } as IInvoiceDto);
                }}
                fullWidth
                disabled={discontinuedDisabled()}
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.discontinued || ""
                }
                variant="outlined"
              >
                <option value=""></option>
                <option value="B">Before papers served</option>
                <option value="U">Before papers served uplift</option>
              </Select>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Retrial</InputLabel>
            </div>
            <div className="flex2">
              <Select
                native
                onChange={(event: any) => {
                  setCurrentInvoiceDetails({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        retrialStart: event.target.value,
                      },
                    },
                  } as IInvoiceDto);
                }}
                disabled={retrialDisabled()}
                fullWidth
                value={
                  currentInvoiceDetails?.externalJSON?.crown?.retrialStart || ""
                }
                variant="outlined"
              >
                <option value=""></option>
                <option value="W">Within 1 month</option>
                <option value="A">After 1 month</option>
              </Select>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>
                Staff
                <Tooltip
                  style={{ paddingLeft: 5 }}
                  title="Defines who will be allocated to the fixed fee. Only relevant if a fixed fee applies."
                >
                  <img alt="warning" src={infoIcon} />
                </Tooltip>
              </InputLabel>
            </div>
            <div className="flex3">
              <Select
                native
                onChange={(e: any) => {
                  setCurrentInvoiceDetails({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      ffFeeEarnerId: e.target.value,
                    },
                  });
                }}
                value={currentInvoiceDetails?.externalJSON?.ffFeeEarnerId || ""}
                variant="outlined"
                disabled={isFormDisabled}
                fullWidth
              >
                {filterInactiveStaff(
                  userList,
                  currentInvoiceDetails?.externalJSON?.ffFeeEarnerId
                )?.map((item: any, index: number) => (
                  <option key={`staff-${index}`} value={item.staffId}>
                    {item.firstName} {item.lastName}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <div className="input-row-aligned">
            <div className="flex2">
              <InputLabel required={defendantsValidator.isRequired}>
                Defendants
              </InputLabel>
            </div>
            <div className="flex5 displayFlex">
              <NumberInput
                value={
                  currentInvoiceDetails?.externalJSON?.crown
                    ?.defendantsForUplift
                }
                disabled={defendantsValidator.isDisabled || isFormDisabled}
                decimalScale={0}
                updater={(value) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        defendantsForUplift: Number(value),
                      },
                    },
                  } as IInvoiceDto);
                }}
                width={84}
              />
              <div className="flex1" />
              <div className="displayFlex" style={{ alignItems: "center" }}>
                <InputLabel style={{ paddingRight: 10 }}>
                  Additional Cases
                </InputLabel>
                <div className="flex1">
                  <NumberInput
                    value={
                      currentInvoiceDetails?.externalJSON?.crown
                        ?.additionalCase || 0
                    }
                    disabled={
                      currentInvoiceDetails?.externalJSON?.crown?.trialType ===
                        "F" || isFormDisabled
                    }
                    decimalScale={0}
                    updater={(value) => {
                      getSetFixedFee({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crown: {
                            ...currentInvoiceDetails?.externalJSON?.crown,
                            additionalCase: Number(value),
                          },
                        },
                      } as IInvoiceDto);
                    }}
                    width={84}
                  />
                </div>
              </div>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Notice Date</InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown
                      ?.trialFixedNoticeDate || null
                  }
                  disabled={trialFixedNoticeDateDisabled()}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown
                        ?.trialFixedNoticeDate
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        getSetFixedFee({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              trialFixedNoticeDate: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>1st Fixed</InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown
                      ?.trialFixedDate || null
                  }
                  disabled={trialFixedDateDisabled()}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown?.trialFixedDate
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        getSetFixedFee({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              trialFixedDate: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex5" />
          </div>
          <div className="input-row-aligned">
            <div className="flex7" />
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Case Cracked</InputLabel>
            </div>
            <div className="flex2">
              <div style={{ paddingLeft: "1px" }}>
                <LocalDatePicker
                  value={
                    currentInvoiceDetails?.externalJSON?.crown
                      ?.trialCrackedAt || null
                  }
                  disabled={caseCrackedValidator.isDisabled}
                  onUpdate={(value) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.crown?.trialCrackedAt
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        getSetFixedFee({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            crown: {
                              ...currentInvoiceDetails?.externalJSON?.crown,
                              trialCrackedAt: value,
                            },
                          },
                        } as IInvoiceDto);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex2">
              <InputLabel style={{ marginLeft: 10 }}>Which 3rd</InputLabel>
            </div>
            <div className="flex2">
              <Select
                native
                onChange={(event) => {
                  getSetFixedFee({
                    ...currentInvoiceDetails,
                    externalJSON: {
                      ...currentInvoiceDetails?.externalJSON,
                      crown: {
                        ...currentInvoiceDetails?.externalJSON?.crown,
                        trialCrackedAtThird: event.target.value,
                      },
                    },
                  } as IInvoiceDto);
                }}
                fullWidth
                disabled={trialCrackedAtThirdDisabled()}
                value={
                  currentInvoiceDetails?.externalJSON?.crown
                    ?.trialCrackedAtThird || ""
                }
                variant="outlined"
              >
                <option value=""></option>
                <option value="first_third">First third</option>
                <option value="second_third">Second third</option>
                <option value="final_third">Final third</option>
              </Select>
            </div>
            <div className="flex5" />
          </div>
        </div>
        <>
          <Box
            sx={{
              width: "100%",
              height: 213,
              backgroundColor: "#fff",
            }}
          >
            <DataGridPro
              columns={columns}
              columnHeaderHeight={30}
              columnVisibilityModel={{
                id: false,
              }}
              disableRowSelectionOnClick
              getRowId={(row: any) => row.id}
              hideFooter
              initialState={{}}
              rowHeight={26}
              rows={getDataRows}
              showCellVerticalBorder
              showColumnVerticalBorder
              sx={{
                backgroundColor: "#fff",
                borderBottom: 0,
                borderLeft: 0,
                borderRight: 0,
                borderRadius: 0,
                ".MuiDataGrid-overlay": { color: "#0060bb" },
                ".MuiDataGrid-row:hover": {
                  backgroundColor: "inherit",
                },
                ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
              }}
            />
          </Box>
          <div className="invoice-total">{renderTotals()}</div>
        </>
      </div>
      <Footer
        onCancel={() => {
          if (
            JSON.stringify(invoice.invoice) !==
            JSON.stringify(currentInvoiceDetails)
          ) {
            setShowUnsavedData(true);
          } else {
            handleClose();
          }
        }}
        onSave={() => {
          if (toBeReversed) {
            if (currentInvoiceDetails?.invoiceId) {
              dispatch(reverseInvoice(currentInvoiceDetails))
                .unwrap()
                .then(() => {
                  dispatch(
                    clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                  )
                    .then(() => close(sdkApi))
                    .catch((e) => console.error(e));
                });
            }
          } else {
            if (
              !!currentInvoiceDetails &&
              !validate(
                getRequiredFields(currentInvoiceDetails),
                [],
                (errors) => dispatch(setValidationErrors(errors))
              )?.length &&
              !saving
            ) {
              const totals = getTotals();
              if (totals.totalCost + totals.totalVat > 0) {
                dispatch(setLoader(true));
                if (currentInvoiceDetails.invoiceId) {
                  if (
                    currentInvoiceDetails.status !== invoice.invoice?.status
                  ) {
                    dispatch(
                      updateInvoice(
                        convertFormModelToInvoice(currentInvoiceDetails)
                      )
                    )
                      .unwrap()
                      .then(() => {
                        dispatch(
                          clearSemaphore(
                            currentInvoiceDetails.semaphoreId || ""
                          )
                        )
                          .then(() => close(sdkApi))
                          .catch((e) => console.error(e));
                      });
                  } else {
                    dispatch(
                      clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                    )
                      .then(() => close(sdkApi))
                      .catch((e) => console.error(e));
                  }
                } else {
                  dispatch(
                    createInvoice(
                      convertFormModelToInvoice(currentInvoiceDetails)
                    )
                  )
                    .unwrap()
                    .then((payload: IInvoiceDto) => {
                      dispatch(
                        clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                      )
                        .then(() => {
                          if (invoice.isPrintingEnabled && payload.invoiceId) {
                            openReport(payload.invoiceId, sdkApi).then(() =>
                              close(sdkApi)
                            );
                          } else close(sdkApi);
                        })
                        .catch((e) => console.error(e));
                    });
                }
              } else
                dispatch(
                  setValidationErrors([
                    "You cannot create an invoice with a value of £0.00",
                  ])
                );
            }
          }
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setNotificationMessageIsWarning(false);
                setNotificationMessage(undefined);
              }}
              variant="contained"
            >
              OK
            </Button>
          </>
        }
        content={<Typography>{notificationMessage}</Typography>}
        isOpen={notificationMessage !== undefined}
        onClose={() => setNotificationMessage(undefined)}
        showWarningIcon={notificationMessageIsWarning ? true : false}
        title={notificationMessageIsWarning ? "Alert" : "Notification"}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => {
          setShowUnsavedData(false);
        }}
        onYesCallback={() => handleClose()}
      />
      <InvoiceErrorDialog />
    </>
  );
};

export default InvoiceAgfsDetails;
