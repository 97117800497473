import { Api } from "@leapdev/leap-host";
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "..";
import { IAppInitDto } from "../../../shared/dto/app-init.dto";
import { IUserDto } from "../../../shared/dto/user.dto";
import { environment } from "../../../shared/environments";
import { defaultHeaders } from "../../../shared/utils/request-utils";
import { close } from "../../../shared/utils/sdk-utils";
import { IWarningAcknowledgmentDto } from "../../../shared/dto/warning-acknowledgment.dto";

export interface AppState {
  appInit: IAppInitDto | undefined;
  users: IUserDto[] | undefined;
  validationErrors: string[] | undefined;
  validationAction: (() => void) | undefined;
  errors: string[] | undefined;
  warningAcknowledgment: IWarningAcknowledgmentDto | undefined;
  errorAction: (() => void) | undefined;
  sdkApi: Api | undefined;
  sdkInitialised: boolean;
  loading: boolean;
}

const initialState: AppState = {
  appInit: undefined,
  users: undefined,
  validationErrors: undefined,
  validationAction: undefined,
  errors: undefined,
  warningAcknowledgment: undefined,
  errorAction: undefined,
  sdkApi: undefined,
  sdkInitialised: false,
  loading: true,
};

export const getAppInit = createAsyncThunk(
  "getAppInit",
  async (params: { billingStageId: string }, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      if (!!sdkApi) {
        const leapContext = sdkApi.leapContext;
        const billingStageParams =
          !!params.billingStageId && params.billingStageId !== null
            ? `&billingstageid=${params.billingStageId}`
            : "";
        const res = await axios.get(
          `${environment().backendUrl}/gateway/init?mattertypeid=${
            leapContext.context.matterTypeId
          }${billingStageParams}`,
          await defaultHeaders(sdkApi)
        );
        return res.data;
      }
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getUsers = createAsyncThunk(
  "getUsers",
  async (_IUserDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/users`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const setValidationErrors = createAction(
  "setValidationErrors",
  (errors: string[] | undefined, errorAction?: () => void) => {
    return {
      payload: { errors: errors, errorAction: errorAction },
    };
  }
);

export const setErrors = createAction(
  "setErrors",
  (errors: string[] | undefined, errorAction?: () => void) => {
    return {
      payload: { errors: errors, errorAction: errorAction },
    };
  }
);

export const setWarningAcknowledgment = createAction(
  "setWarningAcknowledgment",
  (
    warningAcknowledgment: IWarningAcknowledgmentDto | undefined,
    errorAction?: () => void
  ) => {
    return {
      payload: {
        warningAcknowledgment: warningAcknowledgment,
        errorAction: errorAction,
      },
    };
  }
);

export const setSDKApi = createAction(
  "setSDKApi",
  (sdkApi: any | undefined) => {
    return {
      payload: sdkApi,
    };
  }
);

export const setSDKInitialised = createAction(
  "setSDKInitialised",
  (initialised: boolean) => {
    return {
      payload: initialised,
    };
  }
);
export const setLoader = createAction("setLoader", (loading: boolean) => {
  return {
    payload: loading,
  };
});

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppInit.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(getAppInit.fulfilled, (state, action) => {
        state.appInit = action.payload;
        state.loading = false;
      })
      .addCase(getUsers.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(setValidationErrors, (state, action) => {
        state.validationErrors = action.payload.errors;
        state.validationAction = action.payload.errorAction;
      })
      .addCase(setErrors, (state, action) => {
        state.errors = action.payload.errors;
        state.errorAction = action.payload.errorAction;
      })
      .addCase(setWarningAcknowledgment, (state, action) => {
        state.warningAcknowledgment = action.payload.warningAcknowledgment;
        state.errorAction = action.payload.errorAction;
      })
      .addCase(setSDKApi, (state, action) => {
        state.sdkApi = action.payload;
      })
      .addCase(setSDKInitialised, (state, action) => {
        state.sdkInitialised = action.payload;
      })
      .addCase(setLoader, (state, action) => {
        state.loading = action.payload;
      });
  },
});

export const { loading } = appSlice.actions;

export default appSlice.reducer;
