import {
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getBillingStages,
  getFees,
  getImmigrationRates,
  getTaxCodes,
  initFee,
  saveFee,
} from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { FeeLegalAidObj, IFeeDto } from "../../shared/dto/fee.dto";

import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  isNullOrEmptyGuid,
} from "../../shared/utils/utils";
import {
  moreThan30DaysInFuture,
  validate,
} from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomCheckbox from "../components/custom-checkbox";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import NumberInput from "../components/number-input";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { useAppInit } from "../../shared/hooks/use-app-init";

const getImmigrationRequiredFields = (fee: IFeeDto) => {
  return [
    {
      value: fee?.legalAidObj?.billingStageId,
      label: "Billing Stage",
    },
  ];
};

const ImmigrationFee: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const dataInitialised = useRef(false);
  const initialFeeDetails = useRef<IFeeDto | undefined>(undefined);
  const feeRef = useRef<IFeeDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const immRates = useSelector((state: RootState) => state.fee.immRates);
  const billingStages = useSelector(
    (state: RootState) => state.fee.billingStages
  );
  const users = useSelector((state: RootState) => state.app.users);
  const taxCodes = useSelector((state: RootState) => state.fee.taxCodes);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.fee.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<
    IFeeDto | undefined
  >(undefined);

  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const feeId = urlParams.get("feeid");

  const calcTotals = useCallback(
    (feeDetails: IFeeDto) => {
      let interviewAmount =
        (feeDetails.legalAidObj.boltOn?.immigration?.hoInterview || 0) *
        (immRates?.hoAmount || 0);
      let ocmmAmount =
        (feeDetails.legalAidObj.boltOn?.immigration?.ocmm || 0) *
        (immRates?.ocAmount || 0);
      let tcmmAmount =
        (feeDetails.legalAidObj.boltOn?.immigration?.tcmm || 0) *
        (immRates?.tcAmount || 0);
      let subHearingAmount = 0;
      if (feeDetails.legalAidObj.boltOn?.immigration?.subHearing) {
        if (feeDetails.legalAidObj.boltOn?.immigration?.isAsylum) {
          subHearingAmount = immRates?.shaAmount || 0;
        } else {
          subHearingAmount = immRates?.shiAmount || 0;
        }
      }
      let noOfHearingAmount =
        (feeDetails.legalAidObj.boltOn?.immigration?.addHearDays || 0) *
        (immRates?.ahAmount || 0);

      let noOfClientsAmount = 0;
      if (feeDetails?.legalAidObj?.boltOn?.immigration?.onSite) {
        if (feeDetails?.legalAidObj?.boltOn?.immigration?.onSite > 4) {
          noOfClientsAmount = immRates?.os5Amount || 0;
        } else if (
          feeDetails?.legalAidObj?.boltOn?.immigration?.onSite < 5 &&
          feeDetails?.legalAidObj?.boltOn?.immigration?.onSite > 0
        ) {
          noOfClientsAmount = immRates?.os4Amount || 0;
        } else {
          noOfClientsAmount = 0;
        }
      }

      let nrmAmount = 0;
      if (feeDetails?.legalAidObj?.boltOn?.immigration?.nrm) {
        nrmAmount = immRates?.nrmAmount || 0;
      } else {
        nrmAmount = 0;
      }

      if (!feeDetails?.legalAidObj?.boltOn?.immigration?.gfs) {
        interviewAmount = 0;
        ocmmAmount = 0;
        tcmmAmount = 0;
        noOfHearingAmount = 0;
        noOfClientsAmount = 0;
        subHearingAmount = 0;
      }

      const currentTaxCode = taxCodes?.find(
        (t) => t.taxCodeId === feeDetails?.taxCodeId
      );
      let tax = 0;
      let total = 0;
      let tempTotalAmount =
        interviewAmount +
        ocmmAmount +
        tcmmAmount +
        subHearingAmount +
        noOfHearingAmount +
        noOfClientsAmount +
        nrmAmount;

      if (currentTaxCode) {
        tax = (currentTaxCode?.ratePercent || 0) / 100;

        if (tax > 0) {
          tax = (tempTotalAmount || 0) * tax;
        }
        if (!!feeDetails.incTax) {
          tax = (tempTotalAmount || 0) * (1 - 1 / (tax + 1));
        }
        total = (tempTotalAmount || 0) + tax;
      }
      const tempFee = {
        ...feeDetails,
        rate: tempTotalAmount,
        amount: tempTotalAmount,
        legalAidObj: {
          ...feeDetails?.legalAidObj,
          boltOn: {
            ...feeDetails?.legalAidObj.boltOn,
            immigration: {
              ...feeDetails?.legalAidObj.boltOn?.immigration,
              substantiveHearingAmount: subHearingAmount,
              nrmAmount: nrmAmount,
              ocmmAmount: ocmmAmount,
              tcmmAmount: tcmmAmount,
              interviewAmount: interviewAmount,
              hearingAmount: noOfHearingAmount,
              clientsAmount: noOfClientsAmount,
            },
          },
        },
      } as IFeeDto;
      setCurrentFeeDetails(tempFee);
      if (!dataInitialised.current) {
        initialFeeDetails.current = tempFee;
      }
      dataInitialised.current = true;
      setTotalAmount(tempTotalAmount);
      setTotal(total);
      setTax(tax);
    },
    [taxCodes, immRates]
  );

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(feeRef.current) !==
      JSON.stringify(initialFeeDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    feeRef.current = currentFeeDetails;
  }, [currentFeeDetails]);

  // useEffect(() => {
  //   if (!!currentFeeDetails && !!taxCodes) calcTotals(currentFeeDetails);
  // }, [taxCodes, calcTotals, currentFeeDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(false)).then((matterAction) => {
        if (matterAction.meta.requestStatus !== "rejected") {
          if (feeId) {
            dispatch(getFees([feeId || ""])).then(() => {
              dispatch(
                getBillingStages(
                  (matterAction.payload as IMatterDetailsDto).legalAidObj
                    ?.matterTypeId || 0
                )
              );
              dispatch(
                getImmigrationRates({
                  matterTypeId:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.matterTypeId || 0,
                  tableNo:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.tableNo || 0,
                  rate:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.rate || "",
                })
              );
            });
          } else {
            dispatch(
              initFee({
                matterDetails: matterAction.payload as IMatterDetailsDto,
                type: "imm",
              })
            ).then(() => {
              dispatch(
                getBillingStages(
                  (matterAction.payload as IMatterDetailsDto).legalAidObj
                    ?.matterTypeId || 0
                )
              );
              dispatch(
                getImmigrationRates({
                  matterTypeId:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.matterTypeId || 0,
                  tableNo:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.tableNo || 0,
                  rate:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.rate || "",
                })
              );
            });
          }
        }
      });
      dispatch(getTaxCodes());
      dispatch(getUsers());
    }
  }, [dispatch, feeId]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Bolt On Payment"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!!feeDetails && !feeDetailsInitialised.current && !!immRates) {
      feeDetailsInitialised.current = true;
      let currentFee = { ...feeDetails[0] };
      if (isNullOrEmptyGuid(currentFee.legalAidObj?.taskCodeId)) {
        currentFee = {
          ...currentFee,
          legalAidObj: {
            ...currentFee.legalAidObj,
            taskCodeId: "2385cceb-3a92-472d-a9e7-53f88d71adf1",
          },
        };
      }

      initialFeeDetails.current = currentFee;
      calcTotals(currentFee);
    }
  }, [feeDetails, feeDetailsInitialised, immRates, calcTotals]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  const isFormDisabled =
    currentFeeDetails?.billed || currentFeeDetails?.deleted;

  return (
    <>
      <TopBar
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Immigration-Creating-a-New-Time-Entry-Bolt-On-Payments"
        leftComponents={
          <FeeDeleteButton
            fee={currentFeeDetails}
            feeRef={initialFeeDetails.current}
          />
        }
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex5">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography style={{ maxWidth: 350, minWidth: 350 }} noWrap>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>

              <div className="flex3">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Date
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <LocalDatePicker
                      value={
                        currentFeeDetails?.transactionDate
                          ? currentFeeDetails?.transactionDate
                          : null
                      }
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        if (value !== currentFeeDetails?.transactionDate) {
                          const newValue = value || "";

                          if (newValue !== "Invalid date") {
                            setCurrentFeeDetails({
                              ...currentFeeDetails,
                              transactionDate: newValue,
                            } as IFeeDto);
                          }
                        }
                      }}
                      validate={(value) =>
                        moreThan30DaysInFuture(
                          value,
                          currentFeeDetails?.transactionDate
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex3">
                <Select
                  className="select"
                  native
                  fullWidth
                  variant="outlined"
                  value={currentFeeDetails?.legalAidObj.billingStageId || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        billingStageId: e.target.value,
                      } as FeeLegalAidObj,
                    } as IFeeDto);
                  }}
                >
                  {!currentFeeDetails?.legalAidObj.billingStageId && (
                    <option key="" value="" className="emptyMenuItem"></option>
                  )}
                  {[...(billingStages || [])]
                    ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((billingStage) => (
                      <option key={billingStage.id} value={billingStage.id}>
                        {billingStage.description}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Staff
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <Select
                      className="select"
                      fullWidth
                      native
                      variant="outlined"
                      value={currentFeeDetails?.legalAidObj.feeEarnerId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        setCurrentFeeDetails({
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            feeEarnerId: e.target.value,
                          } as FeeLegalAidObj,
                        } as IFeeDto);
                      }}
                    >
                      {!currentFeeDetails?.legalAidObj.feeEarnerId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {filterInactiveStaff(
                        users,
                        currentFeeDetails?.legalAidObj.feeEarnerId
                      )?.map((user) => (
                        <option key={user.staffId} value={user.staffId}>
                          {`${user.firstName} ${user.lastName}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRowTextArea">
              <div style={{ width: 130 }}>
                <InputLabel>Billing Description</InputLabel>
              </div>
              <div className="flex6">
                <TextareaAutosize
                  value={currentFeeDetails?.description || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      description: e.target.value,
                    } as IFeeDto);
                  }}
                  minRows={3}
                  maxRows={3}
                  className="textarea-fee"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div className="flex4">
                <CustomCheckbox
                  label="Graduated Fee Scheme"
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.immigration?.gfs ||
                    false
                  }
                  disabled={isFormDisabled}
                  onChange={(value) => {
                    let tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          immigration: {
                            ...currentFeeDetails?.legalAidObj.boltOn
                              ?.immigration,
                            gfs: value,
                          },
                        },
                      },
                    } as IFeeDto;

                    calcTotals(tempFee);
                  }}
                />
              </div>
              <div className="flex4" />
            </div>
            <div className="inputRow">
              <div className="flex4">
                <div className="displayFlex">
                  <div style={{ display: "grid", alignContent: "center" }}>
                    <CustomCheckbox
                      label="National Referral Mechanism"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.immigration
                          ?.nrm || false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              immigration: {
                                ...currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration,
                                nrm: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                  <div className="flex1" />
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.immigration
                        ?.nrmAmount || 0
                    }
                    prefix="£"
                  />
                </div>
              </div>
              <div className="flex4">
                <div className="displayFlex">
                  <div className="flex1" />
                  <div
                    style={{
                      width: 315,
                      display: "grid",
                      alignContent: "center",
                    }}
                  >
                    <CustomCheckbox
                      label="IRC Surgery"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.immigration
                          ?.isIRCSurgery || false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              immigration: {
                                ...currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration,
                                isIRCSurgery: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div className="flex4">
                <div className="displayFlex">
                  <CustomCheckbox
                    label="Substantive Hearing"
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.immigration
                        ?.subHearing || false
                    }
                    disabled={isFormDisabled}
                    onChange={(value) => {
                      let tempFee = {
                        ...currentFeeDetails,
                        legalAidObj: {
                          ...currentFeeDetails?.legalAidObj,
                          boltOn: {
                            ...currentFeeDetails?.legalAidObj.boltOn,
                            immigration: {
                              ...currentFeeDetails?.legalAidObj.boltOn
                                ?.immigration,
                              subHearing: value,
                            },
                          },
                        },
                      } as IFeeDto;

                      calcTotals(tempFee);
                    }}
                  />
                  <div className="flex1" />
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.immigration
                        ?.substantiveHearingAmount || 0
                    }
                    prefix="£"
                  />
                </div>
              </div>
              <div className="flex4">
                <div className="displayFlex">
                  <div className="flex1" />
                  <div className="flex4">
                    <div className="displayFlex">
                      <div className="flex1" />
                      <div style={{ width: 315 }}>
                        <div className="displayFlex">
                          <div
                            style={{ display: "grid", alignContent: "center" }}
                          >
                            <InputLabel style={{ paddingRight: 10 }}>
                              No. of Clients
                            </InputLabel>
                          </div>
                          <div className="flex1" />
                          <div style={{ paddingRight: 10 }}>
                            <NumberInput
                              value={
                                currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration?.onSite || 0
                              }
                              disabled={
                                isFormDisabled ||
                                !currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration?.isIRCSurgery
                              }
                              decimalScale={0}
                              updater={(value) => {
                                let tempFee = {
                                  ...currentFeeDetails,
                                  legalAidObj: {
                                    ...currentFeeDetails?.legalAidObj,
                                    boltOn: {
                                      ...currentFeeDetails?.legalAidObj.boltOn,
                                      immigration: {
                                        ...currentFeeDetails?.legalAidObj.boltOn
                                          ?.immigration,
                                        onSite: Number(value),
                                      },
                                    },
                                  },
                                } as IFeeDto;
                                calcTotals(tempFee);
                              }}
                              width={65}
                            />
                          </div>
                          <CurrencyInput
                            disabled
                            value={
                              currentFeeDetails?.legalAidObj.boltOn?.immigration
                                ?.clientsAmount || 0
                            }
                            prefix="£"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div className="flex4">
                <div className="displayFlex">
                  <div
                    className="flex1"
                    style={{ display: "grid", alignContent: "center" }}
                  >
                    <InputLabel>Home Office Interviews</InputLabel>
                  </div>
                  <div style={{ paddingRight: 10 }}>
                    <NumberInput
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.immigration
                          ?.hoInterview
                      }
                      disabled={isFormDisabled}
                      decimalScale={0}
                      updater={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              immigration: {
                                ...currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration,
                                hoInterview: Number(value),
                              },
                            },
                          },
                        } as IFeeDto;
                        calcTotals(tempFee);
                      }}
                      width={65}
                    />
                  </div>
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.immigration
                        ?.interviewAmount
                    }
                    prefix="£"
                  />
                </div>
              </div>
              <div className="flex4">
                <div className="displayFlex">
                  <div className="flex1" />
                  <div className="flex4">
                    <div className="displayFlex">
                      <div className="flex1" />
                      <div style={{ width: 315 }}>
                        <div className="displayFlex">
                          <div
                            style={{
                              width: 110,
                              display: "grid",
                              alignContent: "center",
                            }}
                          >
                            <InputLabel style={{ paddingRight: 10 }}>
                              No. of Legal Help
                            </InputLabel>
                          </div>
                          <div className="flex1" />
                          <NumberInput
                            value={
                              currentFeeDetails?.legalAidObj.boltOn?.immigration
                                ?.legalHelpAmount
                            }
                            disabled={
                              isFormDisabled ||
                              !currentFeeDetails?.legalAidObj.boltOn
                                ?.immigration?.isIRCSurgery
                            }
                            decimalScale={0}
                            updater={(value) => {
                              let tempFee = {
                                ...currentFeeDetails,
                                legalAidObj: {
                                  ...currentFeeDetails?.legalAidObj,
                                  boltOn: {
                                    ...currentFeeDetails?.legalAidObj.boltOn,
                                    immigration: {
                                      ...currentFeeDetails?.legalAidObj.boltOn
                                        ?.immigration,
                                      legalHelpAmount: Number(value),
                                    },
                                  },
                                },
                              } as IFeeDto;
                              calcTotals(tempFee);
                            }}
                            width={65}
                          />
                          <div style={{ width: 140 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div className="flex4">
                <div className="displayFlex">
                  <div
                    className="flex1"
                    style={{ display: "grid", alignContent: "center" }}
                  >
                    <InputLabel>Adjourned Hearings</InputLabel>
                  </div>
                  <div style={{ paddingRight: 10 }}>
                    <NumberInput
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.immigration
                          ?.addHearDays
                      }
                      decimalScale={0}
                      disabled={isFormDisabled}
                      updater={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              immigration: {
                                ...currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration,
                                addHearDays: Number(value),
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                      width={65}
                    />
                  </div>
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.immigration
                        ?.hearingAmount
                    }
                    prefix="£"
                  />
                </div>
              </div>
              <div className="flex4">
                <div className="displayFlex">
                  <div className="flex1" />
                  <div className="flex4">
                    <div className="displayFlex">
                      <div className="flex1" />
                      <Typography align="right" className="fee-totals">
                        AMOUNT
                      </Typography>
                      <CurrencyInput disabled value={totalAmount} prefix="£" />
                      <div style={{ width: 140 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div className="flex4">
                <div className="displayFlex">
                  <div className="flex1">
                    <div className="displayFlex">
                      <InputLabel>Case Managment</InputLabel>
                      <div className="flex1" />
                      <div style={{ display: "grid", alignContent: "center" }}>
                        <InputLabel
                          style={{ textAlign: "right", paddingRight: 10 }}
                        >
                          Oral
                        </InputLabel>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingRight: 10 }}>
                    <NumberInput
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.immigration?.ocmm
                      }
                      disabled={isFormDisabled}
                      decimalScale={0}
                      updater={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              immigration: {
                                ...currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration,
                                ocmm: Number(value),
                              },
                            },
                          },
                        } as IFeeDto;
                        calcTotals(tempFee);
                      }}
                      width={65}
                    />
                  </div>
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.immigration
                        ?.ocmmAmount || 0
                    }
                    prefix="£"
                  />
                </div>
              </div>
              <div className="flex4">
                <div className="displayFlex">
                  <div className="flex1" />
                  <div className="flex4">
                    <div className="displayFlex">
                      <div className="flex1" />
                      <Typography align="right" className="fee-totals">
                        TAX
                      </Typography>
                      <CurrencyInput disabled value={tax} prefix="£" />
                      <div style={{ width: 140 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div className="flex4">
                <div className="displayFlex">
                  <div
                    className="flex1"
                    style={{ display: "grid", alignContent: "center" }}
                  >
                    <div className="displayFlex">
                      <div className="flex1" />
                      {/* <div style={{ display: "grid", alignContent: "center" }}> */}
                      <InputLabel
                        style={{ textAlign: "right", paddingRight: 10 }}
                      >
                        Telephone
                      </InputLabel>
                      {/* </div> */}
                    </div>
                  </div>
                  <div style={{ paddingRight: 10 }}>
                    <NumberInput
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.immigration?.tcmm
                      }
                      disabled={isFormDisabled}
                      decimalScale={0}
                      updater={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              immigration: {
                                ...currentFeeDetails?.legalAidObj.boltOn
                                  ?.immigration,
                                tcmm: Number(value),
                              },
                            },
                          },
                        } as IFeeDto;
                        calcTotals(tempFee);
                      }}
                      width={65}
                    />
                  </div>
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.immigration
                        ?.tcmmAmount || 0
                    }
                    prefix="£"
                  />
                </div>
              </div>
              <div className="flex4">
                <div className="displayFlex">
                  <div className="flex1" />
                  <div className="flex4">
                    <div className="displayFlex">
                      <div className="flex1" />
                      <Typography align="right" className="fee-totals">
                        TOTAL
                      </Typography>
                      <CurrencyInput disabled value={total} prefix="£" />
                      <div style={{ width: 140 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentFeeDetails &&
            !validate(
              getImmigrationRequiredFields(currentFeeDetails),
              [],
              (errors) => dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(saveFee(currentFeeDetails))
              .unwrap()
              .then(() => {
                close(sdkApi);
              })
              .catch((e) => console.error(e));
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default ImmigrationFee;
