import moment from "moment";
import { IMatterDetailsDto } from "../dto/matter-details.dto";

export interface IValidationFields {
  value: string | number | undefined | null;
  label: string;
  pattern?: RegExp;
}

export const validate = (
  requiredFields: IValidationFields[],
  patternFields: IValidationFields[],
  setErrors: (errors: string[]) => void,
  hideRequiredText?: boolean
) => {
  let tempValidationWarnings: string[] = [];

  let emptyFields = requiredFields.filter(
    (field) => field.value === "" || !field.value
  );

  emptyFields.forEach((field) => {
    tempValidationWarnings = [...tempValidationWarnings, `  • ${field.label}`];
  });

  patternFields.forEach((field) => {
    if (field.pattern) {
      const regex: RegExp = field.pattern;
      if (
        !!field.value &&
        field.value !== "" &&
        !regex.test(field.value?.toString() || "")
      ) {
        tempValidationWarnings = [
          ...tempValidationWarnings,
          `${field.label} is invalid`,
        ];
      }
    }
  });

  if (!!tempValidationWarnings.length) {
    if (!hideRequiredText) {
      tempValidationWarnings.unshift("The following field(s) are required:");
    }
    setErrors(tempValidationWarnings);
    return tempValidationWarnings;
  }

  return undefined;
};

export const getMatterRequiredFields = (matterDetails: IMatterDetailsDto) => {
  return [
    {
      value: matterDetails?.legalAidObj?.__branchId,
      label: "Branch",
    },
    { value: matterDetails?.legalAidObj?.rate, label: "Rate" },
    {
      value: matterDetails?.legalAidObj?.ufnDate,
      label: "UFN Date",
    },
    {
      value: matterDetails?.legalAidObj?.ufnNumber,
      label: "UFN Number",
    },
  ];
};

export const moreThan30DaysInFuture = (
  value: moment.Moment | null,
  originalValue?: string
) => {
  if (value) {
    const today = moment();
    const dateDiff = value.diff(today, "days");
    if (dateDiff > 30) {
      return {
        keepButton: true,
        message:
          "Selected date is over 30 days more than today's date. Is this correct?",
        originalValue: originalValue,
      };
    }
  }
  return null;
};

export const dateIsInFuture = (
  value: moment.Moment | null,
  originalValue?: string | undefined
) => {
  if (value) {
    const today = moment();
    const dateDiff = value.diff(today, "days");
    if (dateDiff > 0) {
      return {
        keepButton: false,
        message: "Selected date is in the future.",
        originalValue: originalValue,
      };
    }
  }
  return null;
};
