import { GridComparatorFn, GridSortCellParams } from "@mui/x-data-grid-pro";
import moment from "moment";
import { IUserDto } from "../dto/user.dto";

export const dateComparator: GridComparatorFn<Date> = (v1, v2) =>
  moment(v1).isSameOrBefore(moment.utc(v2)) ? -1 : 1;

export const staffComparator = (
  v1: string,
  v2: string,
  cp1: GridSortCellParams<string>,
  cp2: GridSortCellParams<string>,
  staff: IUserDto[]
) => {
  const staff1Initials = staff.find((s) => s.staffId === v1)?.initials || "";
  const staff2Initials = staff.find((s) => s.staffId === v2)?.initials || "";

  return staff1Initials < staff2Initials ? -1 : 1;
};

export const getTooltip = (value: string, maxWidth: number) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  let labelWidth = 0;
  if (context) {
    context.font = getComputedStyle(document.body).font;
    labelWidth = context.measureText(value || "").width;
  }
  return (labelWidth || 0) > (maxWidth || 0) ? value : undefined;
};
