import moment from "moment";
import { IUserDto } from "../dto/user.dto";

export const formatValue = (
  value?: number,
  isReversal: boolean = false,
  isCurrency: boolean = true,
  isShowZero: boolean = false
) => {
  value = value ? value : 0;
  if (value === 0 && !isShowZero) return "";

  let result = value.toLocaleString("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (isReversal) result = `(${result})`;
  return isCurrency ? `£${result}` : result;
};

export function formatDateForAPI(date: string) {
  if (!date) {
    return "";
  }
  let value = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
  return value;
}

export const getFormattedUFN = (ufnNumber?: string, ufnDate?: string) => {
  return `${getFormattedUFNDate(ufnDate)}/${ufnNumber}`;
};

export const getFormattedUFNDate = (ufnDate?: string) => {
  if (!!ufnDate) {
    const val = moment(ufnDate, "DD/MM/YYYY").format("DDMMYY");
    return val;
  } else {
    return "";
  }
};

export const isNullOrEmptyGuid = (guid?: string) => {
  if (!guid || guid === "00000000-0000-0000-0000-000000000000" || guid === "") {
    return true;
  }
  return false;
};

export const newGuid = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const roundAwayFromZero = (number: number, decimalPlaces: number) => {
    var factor = Math.pow(10, decimalPlaces || 0);
    return Math.round(number * factor) * (1 + Number.EPSILON) / factor;
  };

export const filterInactiveStaff = (
  stafflist?: IUserDto[],
  currentId?: string
) => {
  return (stafflist || []).filter(
    (s) => s.staffId === currentId || !s.inactive
  );
};
