import {
  Button,
  Divider,
  InputLabel,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import { setValidationErrors } from "../app/store/app/appSlice";
import { IECRM7Data } from "../shared/ecrm7-form.dto";
import { filterInactiveStaff } from "../shared/utils/utils";
import { dateIsInFuture, validate } from "../shared/utils/validation-utils";
import CurrencyInput from "./components/currency-input";
import CustomCheckbox from "./components/custom-checkbox";
import CustomDialog from "./components/custom-dialog";
import LocalDatePicker from "./components/date-picker";
import NumberInput from "./components/number-input";
import {
  clearSemaphore,
  createCRM7eForm,
} from "../app/store/invoice/invoiceSlice";
import TimeInput from "./components/time-input";
import { close } from "../shared/utils/sdk-utils";

interface IDialogProps {
  open: boolean;
  onClose: () => void;
}

const ECRM7Dialog: FC<IDialogProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();

  const [eCRM7ModalFormIndex, setECRM7ModalFormIndex] = useState(0);
  const [eCRM7Data, setECRM7Data] = useState<IECRM7Data | undefined>(undefined);

  const isDataInitialised = useRef(false);

  const users = useSelector((state: RootState) => state.app.users);
  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const invoice = useSelector((state: RootState) => state.invoice.invoice);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);

  const categorySelectList = [
    { description: "Category 1", value: 1 },
    { description: "Category 2", value: 2 },
  ];

  const category1Values = [
    { description: "Guilty Plea", value: "Guilty Plea" },
    { description: "Bind Over", value: "Bind Over" },
    { description: "Uncontested Breach", value: "Uncontested Breach" },
    {
      description: "Deferred sentence hearing",
      value: "Deferred sentence hearing",
    },
    {
      description: "Discontinuance / withdrawal",
      value: "Discontinuance/withdrawal",
    },
    { description: "Change of solicitor", value: "Change of solicitor" },
    { description: "Warrant of Arrest", value: "Warrant of Arrest" },
  ];

  const category2Values = [
    { description: "Not guilty plea", value: "Not guilty plea" },
    { description: "Contested breach", value: "Contested breach" },
    { description: "Mixed pleas", value: "Mixed pleas" },
    {
      description:
        "Discontinuance/withdrawal/bind over/no evidence offered after case fully prepared",
      value: "Discontinuance",
    },
    { description: "Cracked trial", value: "Cracked trial" },
  ];

  const getEcrm7RequiredFields = (data?: IECRM7Data) => {
    let defaultFields: {
      value: string | number | undefined | null;
      label: string;
    }[] = [
      {
        value: data?.Cd_main_offence,
        label: "Main Offence",
      },
      {
        value: data?.Cd_offence_date,
        label: "Date of Offence",
      },
      {
        value: data?.Cdsp_category,
        label: "Category",
      },
    ];

    if (data?.Cd_wasted_cost_order) {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cd_wasted_costs_order_net,
          label: "Amount of Wasted Costs Order",
        },
      ]);
    }

    if (data?.Cd_wasted_cost_order) {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cd_wasted_costs_order_details,
          label: "Wasted costs order details",
        },
      ]);
    }

    if (data?.Cdsp_category === "1") {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cdsp_category_1_type,
          label: "Category 1 type",
        },
      ]);
    }

    if (data?.Cdsp_category_1_type === "Warrant of Arrest") {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cdsp_category_1_type,
          label: "Warant of Arrest",
        },
      ]);
    }

    if (data?.Cdsp_category === "2") {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cdsp_category_2_type,
          label: "Category 2 type",
        },
      ]);
    }

    if (data?.Cdsp_category_2_type === "Cracked Trial") {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cdsp_category_2_type,
          label: "Cracked Trial",
        },
      ]);
    }

    if (data?.Cld_taped_evidence === "true") {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cld_tape_running_time,
          label: "Total running time of the tapes",
        },
      ]);
    }

    if (data?.Cd_indictable_only) {
      defaultFields = defaultFields.concat([
        {
          value: data?.Cd_date_charge_laid,
          label: "Date of Charge",
        },
      ]);
    }

    if (data?.Nsfc_rep_order_withdrawn) {
      defaultFields = defaultFields.concat([
        {
          value: data?.Nsfc_date_order_withdrawn,
          label: "Representation Order withdrawn on date",
        },
      ]);
    }

    if (data?.Nsfc_other) {
      defaultFields = defaultFields.concat([
        {
          value: data?.Nsfc_order_withdrawn_details,
          label: "Other details",
        },
      ]);
    }

    if (eCRM7Data?.Pow_claiming === "true") {
      defaultFields = defaultFields.concat([
        {
          value: data?.Pow_date,
          label: "ate application was received by the court",
        },
      ]);
    }

    defaultFields = defaultFields.concat([
      {
        value: data?.Relevant_case_info_details,
        label: "Relevant case info",
      },
      {
        value: data?.Certification_sol_name,
        label: "Name",
      },
      {
        value: data?.Solicitor_sign_date,
        label: "Date",
      },
    ]);
    return defaultFields;
  };

  useEffect(() => {
    if (matterDetails && !isDataInitialised.current) {
      isDataInitialised.current = true;
      setECRM7Data({
        ...eCRM7Data,
        Cd_main_offence: matterDetails.legalAidObj?.offence,
      } as IECRM7Data);
    }
  }, [matterDetails, eCRM7Data]);

  return (
    <CustomDialog
      isOpen={open}
      maxWidth="xl"
      maxHeight={550}
      height={550}
      hideOverflow
      onClose={() => {
        setECRM7Data({
          Cd_main_offence: matterDetails?.legalAidObj?.offence || "",
        } as IECRM7Data);
        setECRM7ModalFormIndex(0);
        onClose();
      }}
      title="eCRM7 - Non Standard Fee Contract Work Assessment Form"
      content={
        <div style={{ minHeight: 350, paddingTop: 10 }}>
          {eCRM7ModalFormIndex === 0 && (
            <div className="displayFlex" style={{ width: 850 }}>
              <div className="flex1" style={{ paddingRight: 15 }}>
                <div className="inputRow">
                  <InputLabel>Case Details</InputLabel>
                  <div
                    className="flex1"
                    style={{ paddingTop: 10, paddingLeft: 3 }}
                  >
                    <Divider />
                  </div>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <div className="inputRow">
                    <div style={{ width: 130 }}>
                      <InputLabel required>Main Offence</InputLabel>
                    </div>
                    <div className="flex1">
                      <TextField
                        variant="outlined"
                        value={eCRM7Data?.Cd_main_offence || ""}
                        onChange={(e) =>
                          setECRM7Data({
                            ...eCRM7Data,
                            Cd_main_offence: e.target.value,
                          } as IECRM7Data)
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel required>Date of Offence</InputLabel>
                    </div>
                    <div style={{ paddingRight: 5 }}>
                      <LocalDatePicker
                        value={
                          eCRM7Data?.Cd_offence_date
                            ? eCRM7Data.Cd_offence_date
                            : null
                        }
                        onUpdate={(value) => {
                          if (value !== eCRM7Data?.Cd_offence_date) {
                            const newValue = value || "";
                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Cd_offence_date: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                        validate={(value) =>
                          dateIsInFuture(value, eCRM7Data?.Cd_offence_date)
                        }
                      />
                    </div>
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Serious Fraud Case or are these elements of Serious Fraud?"
                      value={eCRM7Data?.Cd_serious_fraud_case || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cd_serious_fraud_case: value,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Was defendant(s) charged with any indictable only offence?"
                      value={eCRM7Data?.Cd_indictable_only || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cd_indictable_only: value,
                          Cd_date_charge_laid: undefined,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <InputLabel>
                    Date the charge was laid. The indictment must be sent by
                    post/DX.
                  </InputLabel>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel>Date of Charge</InputLabel>
                    </div>
                    <div style={{ paddingRight: 5 }}>
                      <LocalDatePicker
                        value={
                          eCRM7Data?.Cd_date_charge_laid
                            ? eCRM7Data.Cd_date_charge_laid
                            : null
                        }
                        onUpdate={(value) => {
                          if (value !== eCRM7Data?.Cd_date_charge_laid) {
                            const newValue = value || "";

                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Cd_date_charge_laid: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Was a wasted costs order made against your firm/counsel?"
                      value={eCRM7Data?.Cd_wasted_cost_order || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cd_wasted_cost_order: value,
                          Cd_wasted_costs_order_net: undefined,
                          Cd_wasted_costs_order_details: "",
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel>Amount of Wasted Costs Order</InputLabel>
                    </div>
                    <div style={{ width: 130 }}>
                      <CurrencyInput
                        prefix="£"
                        disabled={!eCRM7Data?.Cd_wasted_cost_order}
                        value={eCRM7Data?.Cd_wasted_costs_order_net || 0}
                        updater={(value) => {
                          setECRM7Data({
                            ...eCRM7Data,
                            Cd_wasted_costs_order_net: value,
                          } as IECRM7Data);
                        }}
                      />
                    </div>
                  </div>
                  <div className="inputRow">
                    <div style={{ width: 130 }}>
                      <InputLabel>Details</InputLabel>
                    </div>
                    <div className="flex1">
                      <TextareaAutosize
                        disabled={!eCRM7Data?.Cd_wasted_cost_order}
                        value={eCRM7Data?.Cd_wasted_costs_order_details || ""}
                        onChange={(e) => {
                          setECRM7Data({
                            ...eCRM7Data,
                            Cd_wasted_costs_order_details: e.target.value,
                          } as IECRM7Data);
                        }}
                        minRows={3}
                        maxRows={3}
                        style={{
                          width: "calc(50vw - 240px)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex1">
                <div className="inputRow">
                  <InputLabel>Non-Standard Claim</InputLabel>
                  <div
                    className="flex1"
                    style={{ paddingTop: 10, paddingLeft: 3 }}
                  >
                    <Divider />
                  </div>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <InputLabel>Reason(s) for Non Standard Fee Claim</InputLabel>

                  <InputLabel>
                    Tick the box(es) that apply in this case for a non standard
                    fee
                  </InputLabel>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Core Costs Exceeded Higher Limit"
                      value={eCRM7Data?.Nsfc_core_costs_exceeded || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Nsfc_core_costs_exceeded: value,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Enhanced Rates Claimed"
                      value={eCRM7Data?.Nsfc_enhanced_rates_claimed || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Nsfc_enhanced_rates_claimed: value,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Counsel Assigned"
                      value={eCRM7Data?.Nsfc_counsel_assigned || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Nsfc_counsel_assigned: value,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <CustomCheckbox
                        label="Representation Order withdrawn on"
                        value={eCRM7Data?.Nsfc_rep_order_withdrawn || false}
                        onChange={(value) => {
                          setECRM7Data({
                            ...eCRM7Data,
                            Nsfc_rep_order_withdrawn: value,
                          } as IECRM7Data);
                        }}
                      />
                    </div>
                    <div style={{ width: 108 }}>
                      <LocalDatePicker
                        disabled={!eCRM7Data?.Nsfc_rep_order_withdrawn}
                        value={
                          eCRM7Data?.Nsfc_date_order_withdrawn
                            ? eCRM7Data.Nsfc_date_order_withdrawn
                            : null
                        }
                        onUpdate={(value) => {
                          if (value !== eCRM7Data?.Nsfc_date_order_withdrawn) {
                            const newValue = value || "";

                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Nsfc_date_order_withdrawn: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                        validate={(value) =>
                          dateIsInFuture(
                            value,
                            eCRM7Data?.Nsfc_date_order_withdrawn
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Extradition"
                      value={eCRM7Data?.Nsfc_extradition || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Nsfc_extradition: value,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRowTextArea">
                    <CustomCheckbox
                      label="Other"
                      value={eCRM7Data?.Nsfc_other || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Nsfc_other: value,
                        } as IECRM7Data);
                      }}
                    />
                    <TextareaAutosize
                      value={eCRM7Data?.Nsfc_order_withdrawn_details || ""}
                      onChange={(e) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Nsfc_order_withdrawn_details: e.target.value,
                        } as IECRM7Data);
                      }}
                      disabled={!eCRM7Data?.Nsfc_other}
                      minRows={3}
                      maxRows={3}
                      style={{
                        width: 298,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {eCRM7ModalFormIndex === 1 && (
            <div className="displayFlex" style={{ width: 850 }}>
              <div className="flex1" style={{ paddingRight: 15 }}>
                <div className="inputRow">
                  <InputLabel>Case Disposal</InputLabel>
                  <div
                    className="flex1"
                    style={{ paddingTop: 10, paddingLeft: 3 }}
                  >
                    <Divider />
                  </div>
                </div>

                <div style={{ paddingLeft: 20 }}>
                  <div className="inputRow">
                    <div style={{ width: 290 }}>
                      <InputLabel required>
                        Select the Category your claim falls within
                      </InputLabel>
                    </div>
                    <div className="flex1">
                      <Select
                        className="select"
                        fullWidth
                        native
                        variant="outlined"
                        value={eCRM7Data?.Cdsp_category || ""}
                        onChange={(e) => {
                          setECRM7Data({
                            ...eCRM7Data,
                            Cdsp_category: e.target.value,
                            Cdsp_category_1_type: undefined,
                            Cdsp_warrant_of_arrest_date: null,
                            Cdsp_category_2_type: undefined,
                            Cdsp_guilty_plea_date: null,
                          } as IECRM7Data);
                        }}
                      >
                        {!eCRM7Data?.Cdsp_category && (
                          <option
                            key=""
                            value=""
                            className="emptyMenuItem"
                          ></option>
                        )}
                        {categorySelectList.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.description}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="inputRow">
                    <div style={{ width: 130 }}>
                      <InputLabel>Category 1</InputLabel>
                    </div>
                    <div className="flex1">
                      <Select
                        className="select"
                        fullWidth
                        native
                        variant="outlined"
                        disabled={eCRM7Data?.Cdsp_category !== "1"}
                        value={eCRM7Data?.Cdsp_category_1_type || ""}
                        onChange={(e) => {
                          setECRM7Data({
                            ...eCRM7Data,
                            Cdsp_category_1_type: e.target.value,
                            Cdsp_warrant_of_arrest_date: null,
                          } as IECRM7Data);
                        }}
                      >
                        {!eCRM7Data?.Cdsp_category_1_type && (
                          <option
                            key=""
                            value=""
                            className="emptyMenuItem"
                          ></option>
                        )}
                        {category1Values.map((c) => (
                          <option key={c.value} value={c.value}>
                            {c.description}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel required>Warant of Arrest</InputLabel>
                    </div>
                    <div style={{ width: 107 }}>
                      <LocalDatePicker
                        disabled={
                          !(
                            eCRM7Data?.Cdsp_category === "1" &&
                            eCRM7Data?.Cdsp_category_1_type ===
                              "Warrant of Arrest"
                          )
                        }
                        value={
                          eCRM7Data?.Cdsp_warrant_of_arrest_date
                            ? eCRM7Data.Cdsp_warrant_of_arrest_date
                            : null
                        }
                        onUpdate={(value) => {
                          if (
                            value !== eCRM7Data?.Cdsp_warrant_of_arrest_date
                          ) {
                            const newValue = value || "";

                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Cdsp_warrant_of_arrest_date: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="inputRow">
                    <div style={{ width: 130 }}>
                      <InputLabel>Category 2</InputLabel>
                    </div>
                    <div className="flex1">
                      <Select
                        className="select"
                        fullWidth
                        native
                        variant="outlined"
                        disabled={eCRM7Data?.Cdsp_category !== "2"}
                        value={eCRM7Data?.Cdsp_category_2_type || ""}
                        onChange={(e) => {
                          setECRM7Data({
                            ...eCRM7Data,
                            Cdsp_category_2_type: e.target.value,
                            Cdsp_guilty_plea_date: null,
                          } as IECRM7Data);
                        }}
                      >
                        {!eCRM7Data?.Cdsp_category_2_type && (
                          <option
                            key=""
                            value=""
                            className="emptyMenuItem"
                          ></option>
                        )}
                        {category2Values.map((c) => (
                          <option key={c.value} value={c.value}>
                            {c.description}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel required>Cracked Trial</InputLabel>
                    </div>
                    <div style={{ width: 107 }}>
                      <LocalDatePicker
                        disabled={
                          !(
                            eCRM7Data?.Cdsp_category === "2" &&
                            eCRM7Data?.Cdsp_category_2_type === "Cracked trial"
                          )
                        }
                        value={
                          eCRM7Data?.Cdsp_guilty_plea_date
                            ? eCRM7Data.Cdsp_guilty_plea_date
                            : null
                        }
                        onUpdate={(value) => {
                          if (value !== eCRM7Data?.Cdsp_guilty_plea_date) {
                            const newValue = value || "";

                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Cdsp_guilty_plea_date: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex1">
                <div className="inputRow">
                  <InputLabel>Claim Details</InputLabel>
                  <div
                    className="flex1"
                    style={{ paddingTop: 10, paddingLeft: 3 }}
                  >
                    <Divider />
                  </div>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <InputLabel>
                    Where applicable, give the appropriate number of pages for
                  </InputLabel>
                  <div className="inputRow">
                    <div style={{ width: 130 }} />
                    <div style={{ width: 130 }}>
                      <InputLabel>Prosecution Evidence</InputLabel>
                    </div>
                    <div className="flex1" />
                    <NumberInput
                      value={eCRM7Data?.Cld_prosecution_pages}
                      updater={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cld_prosecution_pages: value,
                        } as IECRM7Data);
                      }}
                      width={65}
                    />
                  </div>
                  <div className="inputRow">
                    <div style={{ width: 130 }} />
                    <div style={{ width: 130 }}>
                      <InputLabel>Defence Statements</InputLabel>
                    </div>
                    <div className="flex1" />
                    <NumberInput
                      value={eCRM7Data?.Cld_defence_pages}
                      updater={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cld_defence_pages: value,
                        } as IECRM7Data);
                      }}
                      width={65}
                    />
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel>Number of Defence Witnesses</InputLabel>
                    </div>
                    <NumberInput
                      value={eCRM7Data?.Cld_no_of_defence_witnesses}
                      updater={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cld_no_of_defence_witnesses: value,
                        } as IECRM7Data);
                      }}
                      width={65}
                    />
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Does this bill represent a supplemental claim?"
                      value={
                        eCRM7Data?.Cld_supplemental_claim === "true" || false
                      }
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cld_supplemental_claim: JSON.stringify(value),
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Preparation time spent watching/listening to taped evidence?"
                      value={eCRM7Data?.Cld_taped_evidence === "true" || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cld_taped_evidence: JSON.stringify(value),
                          Cld_tape_running_time: undefined,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel>
                        Total running time of the tapes (hrs:mins):
                      </InputLabel>
                    </div>
                    <TimeInput
                      width={65}
                      disabled={!eCRM7Data?.Cld_taped_evidence}
                      value={eCRM7Data?.Cld_tape_running_time}
                      updater={(val) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cld_tape_running_time: val,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Case remitted back to Magistrates by the Crown Court?"
                      value={
                        eCRM7Data?.Cld_remitted_to_mags === "true" || false
                      }
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Cld_remitted_to_mags: JSON.stringify(value),
                          Cld_date_remitted_to_mags: null,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel>Date Remitted</InputLabel>
                    </div>
                    <div style={{ paddingRight: 5 }}>
                      <LocalDatePicker
                        disabled={eCRM7Data?.Cld_remitted_to_mags !== "true"}
                        value={
                          eCRM7Data?.Cld_date_remitted_to_mags
                            ? eCRM7Data.Cld_date_remitted_to_mags
                            : null
                        }
                        onUpdate={(value) => {
                          if (value !== eCRM7Data?.Cld_date_remitted_to_mags) {
                            const newValue = value || "";

                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Cld_date_remitted_to_mags: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <InputLabel>Pre Order Work</InputLabel>
                <div style={{ paddingLeft: 20 }}>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="Does any work precede the date of grant shown on the order?"
                      value={eCRM7Data?.Pow_claiming === "true" || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          Pow_claiming: JSON.stringify(value),
                          Pow_date: null,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <div className="flex1">
                      <InputLabel>
                        Date application was received by the court
                      </InputLabel>
                    </div>
                    <div style={{ paddingRight: 5 }}>
                      <LocalDatePicker
                        disabled={eCRM7Data?.Pow_claiming !== "true"}
                        value={eCRM7Data?.Pow_date ? eCRM7Data.Pow_date : null}
                        onUpdate={(value) => {
                          if (value !== eCRM7Data?.Pow_date) {
                            const newValue = value || "";

                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Pow_date: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {eCRM7ModalFormIndex === 2 && (
            <div style={{ width: 850 }}>
              <div className="inputRow">
                <InputLabel>Relevant Case Information</InputLabel>
                <div
                  className="flex1"
                  style={{ paddingTop: 10, paddingLeft: 3 }}
                >
                  <Divider />
                </div>
              </div>
              <div className="displayFlex">
                <div className="flex1" style={{ paddingRight: 15 }}>
                  <div className="inputRowTextArea">
                    <Typography paragraph style={{ marginBottom: 0 }}>
                      Give details of any relevant factors in support of time
                      spent on the case and details in support of a claim for
                      enhanced rates. In addition, where relevant, please record
                      when and why a number of linked cases are billed as one
                      fee, or a number of linked cases are billed as separate
                      fees
                    </Typography>
                  </div>

                  <TextareaAutosize
                    value={eCRM7Data?.Relevant_case_info_details || ""}
                    onChange={(e) => {
                      setECRM7Data({
                        ...eCRM7Data,
                        Relevant_case_info_details: e.target.value,
                      } as IECRM7Data);
                    }}
                    minRows={3}
                    maxRows={3}
                    style={{
                      width: 845,
                    }}
                  />
                  <div className="inputRowTextArea">
                    <Typography paragraph style={{ marginBottom: 0 }}>
                      If this claim is being submitted more than 3 months after
                      the conclusion of the proceedings please provide details
                      of the circumstances
                    </Typography>
                  </div>

                  <TextareaAutosize
                    value={eCRM7Data?.Additional_info || ""}
                    onChange={(e) => {
                      setECRM7Data({
                        ...eCRM7Data,
                        Additional_info: e.target.value,
                      } as IECRM7Data);
                    }}
                    minRows={3}
                    maxRows={3}
                    style={{
                      width: 845,
                    }}
                  />
                </div>
              </div>
              <InputLabel>Solicitors Certification</InputLabel>
              <div className="displayFlex">
                <div className="flex1" style={{ paddingRight: 15 }}>
                  <div className="inputRow">
                    <CustomCheckbox
                      label="I certify, on behalf of the payee, that the information provided is correct. This work has not been and will not be the subject of any other claim for remuneration from the Criminal Legal Aid."
                      value={eCRM7Data?.CertificationClaim || false}
                      onChange={(value) => {
                        setECRM7Data({
                          ...eCRM7Data,
                          CertificationClaim: value,
                        } as IECRM7Data);
                      }}
                    />
                  </div>
                  <div className="inputRow">
                    <div style={{ width: 80 }}>
                      <InputLabel>Name</InputLabel>
                    </div>
                    <div style={{ width: 250 }}>
                      <Select
                        className="select"
                        fullWidth
                        native
                        variant="outlined"
                        disabled={!eCRM7Data?.CertificationClaim}
                        value={eCRM7Data?.Certification_sol_name || ""}
                        onChange={(e) => {
                          setECRM7Data({
                            ...eCRM7Data,
                            Certification_sol_name: e.target.value,
                          } as IECRM7Data);
                        }}
                      >
                        {!eCRM7Data?.Certification_sol_name && (
                          <option
                            key=""
                            value=""
                            className="emptyMenuItem"
                          ></option>
                        )}
                        {filterInactiveStaff(users)?.map((user) => (
                          <option
                            key={user.staffId}
                            value={`${user.firstName} ${user.lastName}`}
                          >
                            {`${user.firstName} ${user.lastName}`}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="inputRow">
                    <div style={{ width: 80 }}>
                      <InputLabel>Date</InputLabel>
                    </div>
                    <div className="flex1">
                      <LocalDatePicker
                        disabled={!eCRM7Data?.CertificationClaim}
                        value={
                          eCRM7Data?.Solicitor_sign_date
                            ? eCRM7Data.Solicitor_sign_date
                            : null
                        }
                        onUpdate={(value) => {
                          if (value !== eCRM7Data?.Solicitor_sign_date) {
                            const newValue = value || "";

                            if (newValue !== "Invalid date") {
                              setECRM7Data({
                                ...eCRM7Data,
                                Solicitor_sign_date: newValue,
                              } as IECRM7Data);
                            }
                          }
                        }}
                        validate={(value) =>
                          dateIsInFuture(value, eCRM7Data?.Solicitor_sign_date)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
      actions={
        <div className="displayFlex" style={{ width: "100%" }}>
          <Button
            variant="contained"
            disableRipple
            disabled={eCRM7ModalFormIndex === 0}
            onClick={() => {
              setECRM7ModalFormIndex(eCRM7ModalFormIndex - 1);
            }}
            style={{ marginRight: 5 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            disableRipple
            disabled={eCRM7ModalFormIndex === 2}
            onClick={() => {
              setECRM7ModalFormIndex(eCRM7ModalFormIndex + 1);
            }}
          >
            Next
          </Button>
          <div className="flex1" />
          {eCRM7ModalFormIndex === 2 && (
            <Button
              variant="contained"
              disableRipple
              onClick={() => {
                if (
                  !validate(getEcrm7RequiredFields(eCRM7Data), [], (errors) =>
                    dispatch(setValidationErrors(errors))
                  )?.length &&
                  !!eCRM7Data
                ) {
                  dispatch(
                    createCRM7eForm({
                      ecrm7Data: eCRM7Data,
                      invoiceId: invoice?.invoiceId || "",
                      matterGuid: matterDetails?.matterId || "",
                    })
                  ).then((action) => {
                    if (action.meta.requestStatus !== "rejected") {
                      setECRM7Data({
                        Cd_main_offence:
                          matterDetails?.legalAidObj?.offence || "",
                      } as IECRM7Data);
                      dispatch(clearSemaphore(invoice?.semaphoreId || "")).then(
                        () => {
                          setECRM7ModalFormIndex(0);
                          close(sdkApi);
                          onClose();
                        }
                      );
                    }
                  });
                }
              }}
              style={{ marginRight: 5 }}
            >
              Save & Close
            </Button>
          )}
          <Button
            color="secondary"
            disableRipple
            onClick={() => {
              setECRM7Data({
                Cd_main_offence: matterDetails?.legalAidObj?.offence || "",
              } as IECRM7Data);
              setECRM7ModalFormIndex(0);
              onClose();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      }
    />
  );
};

export default ECRM7Dialog;
