import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  Button,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getUsers,
  setLoader,
  setValidationErrors,
} from "../../app/store/app/appSlice";
import {
  clearSemaphore,
  createDocument,
  createInvoice,
  getInvoice,
  getItemsForInvoice,
  getLgfsFixedFee,
  getLgfsInvoiceLists,
  getPrecedentList,
  reverseInvoice,
  setInit,
  setPrecedentId,
  setPrintingEnabled,
  updateInvoice,
  uploadInvoiceToCCCD,
} from "../../app/store/invoice/invoiceSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import {
  IDocBuilderModelDto,
  IDocModelDto,
} from "../../shared/dto/document.dto";
import {
  ICCCDSuccessDto,
  IInvoiceDto,
  ILgfsTotalsDto,
  IValidationDto,
} from "../../shared/dto/invoice/invoice.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import wordIcon from "../../shared/images/word.png";
import {
  calculateBalanceDue,
  createInvoiceDocument,
  printReport,
} from "../../shared/utils/invoice-utils";
import {
  close,
  getDecodedToken,
  onBeforeClose,
  openDocument,
  openReport,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  formatValue,
  newGuid,
} from "../../shared/utils/utils";
import { validate } from "../../shared/utils/validation-utils";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import InvoiceErrorDialog from "../components/invoice-error-dialog";
import NumberDisplay from "../components/number-display";
import NumberInput from "../components/number-input";
import ReversalInfo from "../components/reversal-info";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { listStatusItems } from "./lists";
import InvoiceDeleteButton from "../components/invoice-delete-button";
import ReverseIcon from "../../shared/images/reverse";

const InvoiceLgfsDetails: FC = () => {
  const dispatch = useAppDispatch();

  const invoice = useAppSelector((state) => state.invoice);

  const isInvoiceDetailsInitialised = useRef(false);
  const isInvoiceInitialised = useRef(false);
  const isMatterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const invoiceRef = useRef<IInvoiceDto | undefined>(undefined);
  const decodedToken = useRef<any>(undefined);

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.invoice.saving);
  const userList = useSelector((state: RootState) => state.app.users);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<
    IInvoiceDto | undefined
  >(undefined);
  const [notificationMessageIsWarning, setNotificationMessageIsWarning] =
    useState(false);
  const [notificationMessage, setNotificationMessage] =
    useState<any>(undefined);
  const [offenceList, setOffenceList] = useState<string[]>([]);
  const [trialTypeList, setTrialTypeList] = useState<string[]>([]);

  const [precedentMenuAnchorEl, setPrecedentMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const precedentMenuOpen = Boolean(precedentMenuAnchorEl);

  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const [toBeReversed, setToBeReversed] = useState(false);

  const [searchParams] = useSearchParams();

  const infoIcon = require("../../shared/images/info.png");
  const printerIcon = require("../../shared/images/printer-grey.png");

  const [feeEarnerValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: false,
  });

  const [trialDaysValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [ppeValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [defendantsForUpliftValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const isFormDisabled =
    invoice.init.isReversedOrReversal ||
    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
    toBeReversed ||
    !!invoice.init.invoiceId;

  const convertFormModelToInvoice = (tempInvoice: IInvoiceDto): IInvoiceDto => {
    let invoice: IInvoiceDto = {
      invoiceId: tempInvoice.invoiceId,
      matterId: tempInvoice.matterId,
      externalURL: tempInvoice.externalURL,
      invoiceDate: tempInvoice.invoiceDate,
      externalJSON: {
        openType: tempInvoice.externalJSON?.openType,
        width: tempInvoice.externalJSON?.width,
        height: tempInvoice.externalJSON?.height,
        minWidth: tempInvoice.externalJSON?.minWidth,
        minHeight: tempInvoice.externalJSON?.minHeight,
        windowTitle: tempInvoice.externalJSON?.windowTitle,
        closeHandler: tempInvoice.externalJSON?.closeHandler,
        matterTypeId: tempInvoice.externalJSON?.matterTypeId,
        billingStageId: tempInvoice.externalJSON?.billingStageId,
        ffFeeEarnerId: tempInvoice.externalJSON?.ffFeeEarnerId,
        dateConcluded: tempInvoice.externalJSON?.dateConcluded,
        disbExVat: tempInvoice.externalJSON?.disbExVat,
        disbVat: tempInvoice.externalJSON?.disbVat,
        fixedFee: tempInvoice.externalJSON?.fixedFee,
        fixedFeeVat: tempInvoice.externalJSON?.fixedFeeVat,
        crown: {
          typeOfCase: tempInvoice.externalJSON?.crown?.typeOfCase,
          trialType: tempInvoice.externalJSON?.crown?.trialType,
          offence: tempInvoice.externalJSON?.crown?.offence,
          offenceClass: tempInvoice.externalJSON?.crown?.offenceClass,
          trialDays: tempInvoice.externalJSON?.crown?.trialDays,
          ppe: tempInvoice.externalJSON?.crown?.ppe,
          committalForTrial: !!tempInvoice.externalJSON?.crown
            ?.committalForTrial
            ? tempInvoice.externalJSON?.crown?.committalForTrial
            : 0,
          mainHearingDate: tempInvoice.externalJSON?.crown?.mainHearingDate,

          defendantsForUplift:
            tempInvoice.externalJSON?.crown?.defendantsForUplift,
          basicTrialFee: tempInvoice.externalJSON?.crown?.basicTrialFee,
          basicTrialFeeVat: tempInvoice.externalJSON?.crown?.basicTrialFeeVat,
          trialLengthFee: tempInvoice.externalJSON?.crown?.trialLengthFee,
          trialLengthFeeVat: tempInvoice.externalJSON?.crown?.trialLengthFeeVat,
          ppeFee: tempInvoice.externalJSON?.crown?.ppeFee,
          ppeFeeVat: tempInvoice.externalJSON?.crown?.ppeFeeVat,
          totalCaseFee: tempInvoice.externalJSON?.crown?.totalCaseFee,
          totalCaseFeeVat: tempInvoice.externalJSON?.crown?.totalCaseFeeVat,
          committalFee: tempInvoice.externalJSON?.crown?.committalFee,
          committalFeeVat: tempInvoice.externalJSON?.crown?.committalFeeVat,
          defendantUpliftFee:
            tempInvoice.externalJSON?.crown?.defendantUpliftFee,
          defendantUpliftFeeVat:
            tempInvoice.externalJSON?.crown?.defendantUpliftFeeVat,
          actualDays: 0,
          additionalCase: 0,
          advocateType: "",
          afterOneMonth: false,
          withinOneMonth: false,
          estimatedDays: 0,
          agfsCategoryId: "",
          agfsOffenceId: "",
          agfsCaseTypeId: "",
          trialStart: "",
          trialEnd: "",
          additionalFee: tempInvoice.externalJSON?.crown?.additionalFee,
          additionalFeeVat: tempInvoice.externalJSON?.crown?.additionalFeeVat,
          additionalCaseUplift: 0,
          additionalCaseUpliftVat: 0,
          dailyAttendanceFee: 0,
          dailyAttendanceFeeVat: 0,
          discontinued: "",
          retrialActualDays: 0,
          retrialEstimatedDays: 0,
          retrialEnd: "",
          retrialStart: "",
          trialCrackedAt: "",
          trialCrackedAtThird: "",
          trialFixedDate: "",
          trialFixedNoticeDate: "",
        },
      },
      invoiceNumber: tempInvoice.invoiceNumber,
      invoiceDesc: tempInvoice.invoiceDesc,
      timeRecordGuids: tempInvoice.timeRecordGuids,
      reversedOrReversal: tempInvoice.reversedOrReversal,
      reversalId: tempInvoice.reversalId,
      semaphoreId: tempInvoice.semaphoreId,
      warningAcknowledgments: tempInvoice.warningAcknowledgments,
      status: tempInvoice.status,
      deleted: tempInvoice.deleted,
      totalAdjustments: tempInvoice.totalAdjustments,
      totalApplied: tempInvoice.totalApplied,
    };
    return invoice;
  };

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "id",
      headerName: "ID",
      width: 0,
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      sortable: false,
      renderCell: (params: any) => {
        if (params.value === "Fixed Fee") {
          return (
            <>
              {params.value}
              <Tooltip
                style={{ paddingLeft: 5 }}
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Fixed Fee:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFee,
                        invoice.init.isReversedOrReversal
                      )}
                    <br />
                    Fee Limit:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFeeLimit,
                        invoice.init.isReversedOrReversal
                      )}
                  </div>
                }
              >
                <img alt="warning" src={infoIcon} />
              </Tooltip>
              {currentInvoiceDetails?.externalJSON?.isClaimExceptional &&
                currentInvoiceDetails.externalJSON.fixedFee !== 0 && (
                  <Typography color="grey" marginLeft={6}>
                    (This claim is Exceptional)
                  </Typography>
                )}
            </>
          );
        }

        return params.value;
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "time",
      headerAlign: "center",
      headerName: "TIME",
      sortable: false,
      valueFormatter: (_params: GridValueFormatterParams<number>) => {
        return "";
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "amount",
      headerAlign: "center",
      headerName: "AMOUNT",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "tax",
      headerAlign: "center",
      headerName: "TAX",
      sortable: false,
      type: "number",
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
  ];

  const handleClose = useCallback(() => {
    if (invoiceRef.current && invoiceRef.current.semaphoreId) {
      dispatch(clearSemaphore(invoiceRef.current.semaphoreId)).then(() => {
        close(sdkApi);
      });
    } else {
      close(sdkApi);
    }
  }, [dispatch, sdkApi]);

  const getData = useCallback(() => {
    if (!isInvoiceInitialised.current) {
      isInvoiceInitialised.current = true;
      getDecodedToken(sdkApi).then((payload) => {
        decodedToken.current = payload;
      });
      dispatch(
        setInit({
          billingStageId: searchParams.get("billingstageid"),
          invoiceId: searchParams.get("invoiceid"),
          isReversedOrReversal: searchParams.get("reversal")
            ? searchParams.get("reversal")
            : false,
          matterTypeId: appInit?.laMatterTypeId,
          matterTypeName: appInit?.laMatterTypeName,
        })
      );

      dispatch(getMatterDetails(false)).then(() => {
        dispatch(getUsers());
      });
    }
  }, [dispatch, appInit, searchParams, sdkApi]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !isMatterDetailsInitialised.current) {
      isMatterDetailsInitialised.current = true;
      dispatch(
        getLgfsInvoiceLists({
          matterId: matterDetails?.matterId,
          billingStageId: invoice?.init?.billingStageId,
          tableNo: matterDetails?.legalAidObj?.tableNo,
          rate: matterDetails?.legalAidObj?.rate,
        })
      )
        .then(() => {
          dispatch(
            getPrecedentList({
              matterTypeId: matterDetails?.legalAidObj?.matterTypeId,
              billingStageId: invoice?.init?.billingStageId,
            })
          );
        })
        .then(() => {
          if (invoice.init.invoiceId) {
            dispatch(getInvoice(invoice.init.invoiceId)).then((action) => {
              setWindowTitle(
                `${matterDetails?.matterReference || ""} ${
                  matterDetails?.matterDescription || ""
                }`,
                sdkApi,
                60,
                action.payload.externalJSON?.windowTitle
                  ? `- ${action.payload.externalJSON?.windowTitle}`
                  : ""
              );
            });
          } else if (invoice.init.billingStageId) {
            dispatch(
              getItemsForInvoice({
                matterId: matterDetails?.matterId,
                billingStageId: invoice?.init.billingStageId,
                disbursementsOnly: false,
              })
            );
            setWindowTitle(
              `${matterDetails?.matterReference || ""} ${
                matterDetails?.matterDescription || ""
              }`,
              sdkApi,
              60,
              "- New Invoice"
            );
          }
        });
    }
  }, [
    dispatch,
    matterDetails,
    invoice.init,
    isMatterDetailsInitialised,
    sdkApi,
  ]);

  useEffect(() => {
    invoiceRef.current = currentInvoiceDetails;
  }, [currentInvoiceDetails]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi && !!invoice.invoice) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => {
        if (
          JSON.stringify(invoice.invoice) !== JSON.stringify(invoiceRef.current)
        ) {
          setShowUnsavedData(true);
        } else {
          handleClose();
        }
      }, sdkApi);
    }
  }, [sdkApi, invoice.invoice, handleClose]);

  const refreshLists = useCallback(
    (incoiceDetails: IInvoiceDto) => {
      const trialType = (invoice.lgfsLists?.typeOfCases || []).filter(
        (item) =>
          item.typeOfCase === incoiceDetails?.externalJSON?.crown?.typeOfCase
      );

      if (trialType[0]) {
        setTrialTypeList(trialType[0].trialTypesList || []);
      }
      const offence = (invoice.lgfsLists?.offences || []).filter(
        (item) =>
          item.offenceClass ===
          incoiceDetails?.externalJSON?.crown?.offenceClass
      );
      if (offence[0]) {
        setOffenceList(offence[0].offencesDescList || []);
      }
    },
    [invoice]
  );

  const getRequiredFields = useCallback(
    (data: IInvoiceDto) => {
      let fields = [];

      fields.push({
        value: data?.externalJSON?.dateConcluded,
        label: "Date Concluded",
      });

      fields.push({
        value: data?.invoiceDate,
        label: "Date Billed",
      });

      if (feeEarnerValidator.isRequired)
        fields.push({
          value: data?.externalJSON?.ffFeeEarnerId,
          label: "Fee Earner",
        });

      if (trialDaysValidator.isRequired)
        fields.push({
          value: data?.externalJSON?.crown?.trialDays,
          label: "No. of Days",
        });

      if (ppeValidator.isRequired)
        fields.push({
          value: data?.externalJSON?.crown?.ppe,
          label: "PPE",
        });

      if (defendantsForUpliftValidator.isRequired)
        fields.push({
          value: data?.externalJSON?.crown?.defendantsForUplift,
          label: "Defendants",
        });

      return fields;
    },
    [
      feeEarnerValidator,
      trialDaysValidator,
      ppeValidator,
      defendantsForUpliftValidator,
    ]
  );

  const getSetFixedFee = useCallback(
    (invoice: IInvoiceDto) => {
      if (
        invoice &&
        !validate(getRequiredFields(invoice), [], () => {})?.length &&
        invoice.externalJSON?.crown?.typeOfCase !== null &&
        invoice.externalJSON?.crown?.trialType !== null &&
        invoice.externalJSON?.crown?.offenceClass !== null
      ) {
        dispatch(
          getLgfsFixedFee({
            matterId: matterDetails?.matterId,
            taxFree: matterDetails?.taxFree,
            typeOfCase: invoice.externalJSON?.crown?.typeOfCase,
            trialType: invoice.externalJSON?.crown?.trialType,
            offenceClass: invoice.externalJSON?.crown?.offenceClass,
            trialDays: invoice.externalJSON?.crown?.trialDays || 0,
            ppe: invoice.externalJSON?.crown?.ppe || 0,
            noOfDefendants:
              invoice.externalJSON?.crown?.defendantsForUplift || 0,
            noOfCommittal: invoice.externalJSON?.crown?.committalForTrial || 0,
            additionalFee: invoice.externalJSON?.crown?.additionalFee || 0,
            additionalFeeVat:
              invoice.externalJSON?.crown?.additionalFeeVat || 0,
            tableNo: matterDetails?.legalAidObj?.tableNoRepOrder,
          })
        ).then((action: any) => {
          const payload = action.payload as ILgfsTotalsDto;
          if (payload) {
            const tempInvoice = {
              ...invoice,
              externalJSON: {
                ...invoice?.externalJSON,
                crown: {
                  ...invoice?.externalJSON?.crown,
                  basicTrialFee: payload.basicTrialFee,
                  basicTrialFeeVat: payload.basicTrialFeeVat,
                  trialLengthFee: payload.trialLengthProxyFee,
                  trialLengthFeeVat: payload.trialLengthProxyFeeVat,
                  ppeFee: payload.ppeProxyFee,
                  ppeFeeVat: payload.ppeProxyFeeVat,
                  committalFee: payload.committalForTrialFee,
                  committalFeeVat: payload.committalForTrialFeeVat,
                  defendantUpliftFee: payload.defendantUpliftsFee,
                  defendantUpliftFeeVat: payload.defendantUpliftsFeeVat,
                  totalCaseFee: payload.totalCaseFee,
                  totalCaseFeeVat: payload.totalCaseFeeVat,
                },
                fixedFee:
                  payload.totalCaseFee +
                  payload.committalForTrialFee +
                  payload.defendantUpliftsFee,
                fixedFeeVat:
                  payload.totalCaseFeeVat +
                  payload.committalForTrialFeeVat +
                  payload.defendantUpliftsFeeVat,
              },
            };
            setCurrentInvoiceDetails(tempInvoice);
          }
        });
      } else {
        setCurrentInvoiceDetails(invoice);
      }
    },
    [dispatch, getRequiredFields, matterDetails]
  );

  useEffect(() => {
    if (!!invoice.invoice && !isInvoiceDetailsInitialised.current) {
      isInvoiceDetailsInitialised.current = true;
      setCurrentInvoiceDetails(invoice.invoice);
      invoiceRef.current = invoice.invoice;
      refreshLists(invoice.invoice);
      getSetFixedFee(invoice.invoice);
    }
  }, [
    currentInvoiceDetails,
    invoice.invoice,
    isInvoiceDetailsInitialised,
    refreshLists,
    getSetFixedFee,
  ]);

  const getTotals = () => {
    const calculationLabel: string =
      "Total Case Fee + Additional Fees + Committal for Trial + Defendant Uplift + Disbursements";
    const totalCost: number =
      (currentInvoiceDetails?.externalJSON?.crown?.totalCaseFee || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.additionalFee || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.committalFee || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFee || 0) +
      (currentInvoiceDetails?.externalJSON?.disbExVat || 0);
    const totalVat: number =
      (currentInvoiceDetails?.externalJSON?.crown?.totalCaseFeeVat || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.additionalFeeVat || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.committalFeeVat || 0) +
      (currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFeeVat || 0) +
      (currentInvoiceDetails?.externalJSON?.disbVat || 0);

    return {
      totalCost,
      totalVat,
      calculationLabel,
    };
  };

  const getDataRows = () => {
    let dataRows = [
      {
        id: newGuid(),
        description: "Basic Trial Fee",
        amount: currentInvoiceDetails?.externalJSON?.crown?.basicTrialFee,
        tax: currentInvoiceDetails?.externalJSON?.crown?.basicTrialFeeVat,
      },
      {
        id: newGuid(),
        description: "Trial Length Proxy",
        amount: currentInvoiceDetails?.externalJSON?.crown?.trialLengthFee,
        tax: currentInvoiceDetails?.externalJSON?.crown?.trialLengthFeeVat,
      },
      {
        id: newGuid(),
        description: "PPE Proxy",
        amount: currentInvoiceDetails?.externalJSON?.crown?.ppeFee,
        tax: currentInvoiceDetails?.externalJSON?.crown?.ppeFeeVat,
      },
      {
        id: newGuid(),
        description: "Total Case Fee",
        amount: currentInvoiceDetails?.externalJSON?.crown?.totalCaseFee,
        tax: currentInvoiceDetails?.externalJSON?.crown?.totalCaseFeeVat,
      },
      {
        id: newGuid(),
        description: "Additional Fees",
        amount: currentInvoiceDetails?.externalJSON?.crown?.additionalFee,
        tax: currentInvoiceDetails?.externalJSON?.crown?.additionalFeeVat,
      },
      {
        id: newGuid(),
        description: "Committal for Trial",
        amount: currentInvoiceDetails?.externalJSON?.crown?.committalFee,
        tax: currentInvoiceDetails?.externalJSON?.crown?.committalFeeVat,
      },
      {
        id: newGuid(),
        description: "Defendant Uplift",
        amount: currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFee,
        tax: currentInvoiceDetails?.externalJSON?.crown?.defendantUpliftFeeVat,
      },
      {
        id: newGuid(),
        description: "Disbursements",
        amount: currentInvoiceDetails?.externalJSON?.disbExVat,
        tax: currentInvoiceDetails?.externalJSON?.disbVat,
      },
    ];

    return dataRows;
  };

  const renderCriteria = () => {
    return (
      <>
        <div className="displayFlex">
          <div className="flex2">
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex1" style={{ display: "inline-flex" }}>
                <TextField
                  disabled
                  value={matterDetails?.matterReference || ""}
                  variant="outlined"
                  style={{ width: 110 }}
                />
                <Typography
                  maxWidth={120}
                  minWidth={120}
                  noWrap
                  style={{ marginTop: 3, marginLeft: 5 }}
                >
                  {matterDetails?.matterDescription}
                </Typography>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Type of Case</InputLabel>
              </div>
              <div className="flex1">
                <Select
                  disabled={isFormDisabled}
                  onChange={(e: any) => {
                    const tempInvoice = {
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        crown: {
                          ...currentInvoiceDetails?.externalJSON?.crown,
                          typeOfCase: e.target.value,
                          trialType: "",
                        },
                      },
                    };
                    getSetFixedFee(tempInvoice);
                    refreshLists(tempInvoice);
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.typeOfCase || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {!currentInvoiceDetails?.externalJSON?.crown?.typeOfCase && (
                    <option value=""></option>
                  )}
                  {invoice.lgfsLists?.typeOfCases.map(
                    (item: any, index: number) => (
                      <option
                        key={`invoiceTypeOfCases-${index}`}
                        value={item.typeOfCase}
                      >
                        {item.typeOfCase}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Trial Type</InputLabel>
              </div>
              <div className="flex1">
                <Select
                  disabled={isFormDisabled}
                  onChange={(e: any) => {
                    getSetFixedFee({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        crown: {
                          ...currentInvoiceDetails?.externalJSON?.crown,
                          trialType: e.target.value,
                        },
                      },
                    });
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.trialType || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {!currentInvoiceDetails?.externalJSON?.crown?.trialType && (
                    <option value=""></option>
                  )}
                  {trialTypeList.map((item: any, index: number) => (
                    <option key={`invoiceTrialType-${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Offence Class</InputLabel>
              </div>
              <div className="flex1">
                <Select
                  disabled={isFormDisabled}
                  onChange={(e: any) => {
                    const offence = (invoice.lgfsLists?.offences || []).filter(
                      (item) => item.offenceClass === e.target.value
                    );
                    setOffenceList(offence[0].offencesDescList || []);
                    getSetFixedFee({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        crown: {
                          ...currentInvoiceDetails?.externalJSON?.crown,
                          offenceClass: e.target.value,
                          offence: "",
                        },
                      },
                    });
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.offenceClass ||
                    ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {!currentInvoiceDetails?.externalJSON?.crown
                    ?.offenceClass && <option value=""></option>}
                  {invoice.lgfsLists?.offences.map(
                    (item: any, index: number) => (
                      <option
                        key={`invoiceOffenceClass-${index}`}
                        value={item.offenceClass}
                      >
                        {item.offenceClass}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Offence</InputLabel>
              </div>
              <div className="flex1">
                <Select
                  disabled={isFormDisabled}
                  onChange={(e: any) => {
                    getSetFixedFee({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        crown: {
                          ...currentInvoiceDetails?.externalJSON?.crown,
                          offence: e.target.value,
                        },
                      },
                    });
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.crown?.offence || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {!currentInvoiceDetails?.externalJSON?.crown?.offence && (
                    <option value=""></option>
                  )}
                  {offenceList.map((item: any, index: number) => (
                    <option key={`invoiceOffence-${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex2">
            <div style={{ paddingLeft: 25 }}>
              <div className="input-row-aligned">
                <div style={{ width: 140 }}>
                  <InputLabel>Main Hearing Date</InputLabel>
                </div>
                <div className="flex1">
                  <div style={{ paddingLeft: "0.5px" }}>
                    <LocalDatePicker
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        if (
                          value !==
                          currentInvoiceDetails?.externalJSON?.crown
                            ?.mainHearingDate
                        ) {
                          const newValue = value || "";
                          if (newValue !== "Invalid date") {
                            setCurrentInvoiceDetails({
                              ...currentInvoiceDetails,
                              externalJSON: {
                                ...currentInvoiceDetails?.externalJSON,
                                crown: {
                                  ...currentInvoiceDetails?.externalJSON?.crown,
                                  mainHearingDate: value,
                                },
                              },
                            } as IInvoiceDto);
                          }
                        }
                      }}
                      value={
                        currentInvoiceDetails?.externalJSON?.crown
                          ?.mainHearingDate || null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="input-row-aligned">
                <div style={{ width: 140 }}>
                  <InputLabel required={trialDaysValidator.isRequired}>
                    No. of Days
                  </InputLabel>
                </div>
                <div className="flex1">
                  <NumberInput
                    disabled={isFormDisabled}
                    updater={(value) => {
                      getSetFixedFee({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crown: {
                            ...currentInvoiceDetails?.externalJSON?.crown,
                            trialDays: value,
                          },
                        },
                      });
                    }}
                    value={
                      currentInvoiceDetails?.externalJSON?.crown?.trialDays
                    }
                    decimalScale={0}
                    width={84}
                  />
                </div>
              </div>
              <div className="input-row-aligned">
                <div style={{ width: 140 }}>
                  <InputLabel required={ppeValidator.isRequired}>
                    PPE
                  </InputLabel>
                </div>
                <div className="flex1">
                  <NumberInput
                    disabled={isFormDisabled}
                    updater={(value) => {
                      getSetFixedFee({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crown: {
                            ...currentInvoiceDetails?.externalJSON?.crown,
                            ppe: value,
                          },
                        },
                      });
                    }}
                    decimalScale={0}
                    value={currentInvoiceDetails?.externalJSON?.crown?.ppe}
                    width={84}
                  />
                </div>
              </div>
              <div className="input-row-aligned">
                <div style={{ width: 140 }}>
                  <InputLabel
                    required={defendantsForUpliftValidator.isRequired}
                  >
                    Defendants
                  </InputLabel>
                </div>
                <div className="flex1">
                  <NumberInput
                    disabled={isFormDisabled}
                    updater={(value) => {
                      getSetFixedFee({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crown: {
                            ...currentInvoiceDetails?.externalJSON?.crown,
                            defendantsForUplift: Number(value),
                          },
                        },
                      });
                    }}
                    decimalScale={0}
                    value={
                      currentInvoiceDetails?.externalJSON?.crown
                        ?.defendantsForUplift || 0
                    }
                    width={84}
                  />
                </div>
              </div>
              <div className="input-row-aligned">
                <div style={{ width: 140 }}>
                  <InputLabel>Committal</InputLabel>
                </div>
                <div className="flex1">
                  <NumberInput
                    disabled={isFormDisabled}
                    updater={(value) => {
                      getSetFixedFee({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crown: {
                            ...currentInvoiceDetails?.externalJSON?.crown,
                            committalForTrial: value,
                          },
                        },
                      });
                    }}
                    decimalScale={0}
                    value={
                      currentInvoiceDetails?.externalJSON?.crown
                        ?.committalForTrial
                    }
                    width={84}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: 260, paddingLeft: 25 }}>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Invoice No.</InputLabel>
              </div>
              <div className="flex1">
                <TextField
                  disabled
                  placeholder="Auto no"
                  variant="outlined"
                  value={currentInvoiceDetails?.invoiceNumber || ""}
                  style={{ width: 108 }}
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required>Date Concluded</InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled}
                  onUpdate={(value: any) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.dateConcluded
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            dateConcluded: newValue,
                          },
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.dateConcluded
                      ? currentInvoiceDetails.externalJSON?.dateConcluded
                      : null
                  }
                ></LocalDatePicker>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required>Date Billed</InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled && !toBeReversed}
                  onUpdate={(value: any) => {
                    if (value !== currentInvoiceDetails?.invoiceDate) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          invoiceDate: newValue,
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.invoiceDate
                      ? currentInvoiceDetails.invoiceDate
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Status</InputLabel>
              </div>
              <div className="flex4">
                <Select
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      status: e.target.value,
                    });
                  }}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  disabled={
                    isFormDisabled && currentInvoiceDetails?.status === 3
                  }
                  value={
                    !!currentInvoiceDetails?.status ||
                    currentInvoiceDetails?.status === 0
                      ? currentInvoiceDetails?.status
                      : ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {listStatusItems.map((item: any, index: number) => (
                    <option key={`invoiceStatus-${index}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required={feeEarnerValidator.isRequired}>
                  Staff
                  <Tooltip
                    style={{ paddingLeft: 5 }}
                    title="Defines who will be allocated to the fixed fee. Only relevant if a fixed fee applies."
                  >
                    <img alt="warning" src={infoIcon} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className="flex4">
                <Select
                  disabled={feeEarnerValidator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        ffFeeEarnerId: e.target.value,
                      },
                    });
                  }}
                  required={feeEarnerValidator.isRequired}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {filterInactiveStaff(
                    userList,
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId
                  )?.map((item: any, index: number) => (
                    <option key={`staff-${index}`} value={item.staffId}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderBreakdown = () => {
    return (
      <DataGridPro
        columns={columns}
        columnHeaderHeight={30}
        columnVisibilityModel={{
          id: false,
        }}
        disableRowSelectionOnClick
        getRowId={(row: any) => row.id}
        hideFooter
        initialState={{}}
        rowHeight={26}
        rows={getDataRows()}
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          backgroundColor: "#fff",
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
          ".MuiDataGrid-overlay": { color: "#0060bb" },
          ".MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
        }}
      />
    );
  };

  const handleUploadToCCCD = () => {
    if (currentInvoiceDetails?.invoiceId) {
      if (currentInvoiceDetails?.deleted === 1) {
        setNotificationMessageIsWarning(true);
        setNotificationMessage(
          "You cannot upload a claim for a reversed or a reversal."
        );
      } else {
        if (
          currentInvoiceDetails?.reversedOrReversal === 1 ||
          invoice.init.isReversedOrReversal
        ) {
          setNotificationMessageIsWarning(true);
          setNotificationMessage(
            "You cannot upload a claim for a reversed or a reversal."
          );
        } else if (invoice.uploadSuccess) {
          setNotificationMessageIsWarning(true);
          setNotificationMessage("Claim already uploaded.");
        } else {
          dispatch(
            uploadInvoiceToCCCD({
              invoice: currentInvoiceDetails,
              invoiceUrl: "lgfstocccd",
            })
          ).then((action: any) => {
            const payload = action.payload as ICCCDSuccessDto;
            if (payload.message) {
              setNotificationMessageIsWarning(false);
              setNotificationMessage(payload.message);
            }
          });
        }
      }
    } else {
      setNotificationMessageIsWarning(true);
      setNotificationMessage("Please save the invoice first.");
    }
  };

  const renderTotals = () => {
    const totals = getTotals();

    return (
      <div className="displayFlex">
        <div className="flex1">
          <Typography fontWeight={600} marginTop={1} sx={{ color: "#0160aa" }}>
            Total Breakdown
          </Typography>
          <Typography color="grey" marginLeft={2}>
            <i>{totals.calculationLabel}</i>
          </Typography>
          <div style={{ display: "inline-flex", marginTop: 10 }}>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalAdjustments}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">ADJUSTMENTS</Typography>
            </div>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalApplied}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">TOTAL PAID</Typography>
            </div>
          </div>
        </div>
        <div>
          <div
            className="invoice-total-row invoice-total-row-proceeds"
            style={{
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>TOTAL</Typography>
            </div>
            <div style={{ width: 92 }}></div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalCost}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
              />
            </div>
          </div>
          <div className="invoice-total-row invoice-total-row-proceeds">
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>INC. TAX</Typography>
            </div>
            <div style={{ width: 192 }}>
              <NumberDisplay
                value={totals.totalCost + totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
              />
            </div>
          </div>
          <div className="invoice-total-footer" style={{ marginLeft: 8 }}>
            <div style={{ width: 90 }}>
              <Typography style={{ color: "#db761d", fontWeight: 600 }}>
                BALANCE DUE
              </Typography>
            </div>
            <div style={{ width: 193 }}>
              <NumberDisplay
                value={calculateBalanceDue(
                  [
                    totals.totalCost,
                    totals.totalVat,
                    currentInvoiceDetails?.totalAdjustments || 0,
                  ],
                  [currentInvoiceDetails?.totalApplied || 0]
                )}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                bold
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          <>
            <InvoiceDeleteButton
              invoiceDetails={currentInvoiceDetails}
              invoiceRef={invoiceRef.current}
            />
            <Tooltip placement="bottom-end" title="Reverse Transaction">
              <span>
                <IconButton
                  disabled={
                    !currentInvoiceDetails?.invoiceId ||
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                    toBeReversed
                  }
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    setToBeReversed(true);
                  }}
                >
                  <ReverseIcon
                    disabled={
                      !currentInvoiceDetails?.invoiceId ||
                      invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                      toBeReversed
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
        centerComponents={
          <ReversalInfo
            isReversal={
              invoice.init.isReversedOrReversal ||
              Boolean(currentInvoiceDetails?.reversedOrReversal)
            }
          />
        }
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Crown-Court-Invoicing-a-Crown-Court-LGFS-Fixed-Fee"
        rightComponents={
          <>
            <Tooltip placement="bottom-end" title="Send to Word">
              <IconButton
                aria-controls="precedent-menu"
                disableRipple
                disableTouchRipple
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setPrecedentMenuAnchorEl(event.currentTarget);
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="precedent options" src={wordIcon} />
              </IconButton>
            </Tooltip>
            <Menu
              id="precedent-menu"
              anchorEl={precedentMenuAnchorEl}
              open={precedentMenuOpen}
              onClose={() => {
                setPrecedentMenuAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {(invoice.precedentList || []).map((item: any, index: number) => (
                <MenuItem
                  data-precedent-value={item.guid}
                  key={`precedent-${index}`}
                  onClick={(e) => {
                    createInvoiceDocument(
                      invoice.init.isReversedOrReversal ||
                        Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                        toBeReversed,
                      (message) => setNotificationMessage(message),
                      (id: string) => {
                        const staffId = !!decodedToken.current
                          ? decodedToken.current.staffId
                          : "";
                        dispatch(
                          createDocument({
                            precedentId: item.guid,
                            matterId: matterDetails?.matterId,
                            documentName: item.description,
                            staffInitials: userList?.find(
                              (s) => s.staffId === staffId
                            )?.initials,
                            isCustomPrecedent: false,
                            parameters: {
                              invoiceId: currentInvoiceDetails?.invoiceId || "",
                              staffId: staffId,
                            },
                          } as IDocBuilderModelDto)
                        ).then((action: any) => {
                          const docModel = action.payload as IDocModelDto;
                          openDocument(
                            {
                              documentId: docModel.documentId,
                              matterId: matterDetails?.matterId,
                            },
                            sdkApi
                          );
                        });
                      },
                      (id) => dispatch(setPrecedentId(id)),
                      currentInvoiceDetails,
                      item.guid
                    );
                    setPrecedentMenuAnchorEl(null);
                  }}
                  value={item.guid}
                >
                  {item.code} - {item.description}
                </MenuItem>
              ))}
            </Menu>
            <Tooltip placement="bottom-end" title="Upload To CCCD">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  handleUploadToCCCD();
                }}
                style={{ marginRight: 15 }}
              >
                <ArrowCircleUpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="bottom-end" title="Print">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  printReport(
                    () =>
                      setNotificationMessage(
                        "This transaction will be printed after saving."
                      ),
                    () =>
                      openReport(
                        currentInvoiceDetails?.invoiceId || "",
                        sdkApi
                      ),
                    () => dispatch(setPrintingEnabled(true)),
                    currentInvoiceDetails
                  );
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="print" src={printerIcon} />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <div className="invoice-main">
        <>
          <div className="invoice-criteria">{renderCriteria()}</div>
          <div className="invoice-breakdown invoice-breakdown-lgfs">
            {renderBreakdown()}
          </div>
          <div className="invoice-total">{renderTotals()}</div>
        </>
      </div>
      <Footer
        onCancel={() => {
          if (
            JSON.stringify(invoice.invoice) !==
            JSON.stringify(currentInvoiceDetails)
          ) {
            setShowUnsavedData(true);
          } else {
            handleClose();
          }
        }}
        onSave={() => {
          if (toBeReversed) {
            if (currentInvoiceDetails?.invoiceId) {
              dispatch(reverseInvoice(currentInvoiceDetails))
                .unwrap()
                .then(() => {
                  dispatch(
                    clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                  )
                    .then(() => close(sdkApi))
                    .catch((e) => console.error(e));
                });
            }
          } else {
            if (
              !!currentInvoiceDetails &&
              !validate(
                getRequiredFields(currentInvoiceDetails),
                [],
                (errors) => dispatch(setValidationErrors(errors))
              )?.length &&
              !saving
            ) {
              const totals = getTotals();
              if (totals.totalCost + totals.totalVat > 0) {
                dispatch(setLoader(true));
                if (currentInvoiceDetails.invoiceId) {
                  if (
                    currentInvoiceDetails.status !== invoice.invoice?.status
                  ) {
                    dispatch(
                      updateInvoice(
                        convertFormModelToInvoice(currentInvoiceDetails)
                      )
                    )
                      .unwrap()
                      .then(() => {
                        dispatch(
                          clearSemaphore(
                            currentInvoiceDetails.semaphoreId || ""
                          )
                        )
                          .then(() => close(sdkApi))
                          .catch((e) => console.error(e));
                      });
                  } else {
                    dispatch(
                      clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                    )
                      .then(() => close(sdkApi))
                      .catch((e) => console.error(e));
                  }
                } else {
                  dispatch(
                    createInvoice(
                      convertFormModelToInvoice(currentInvoiceDetails)
                    )
                  )
                    .unwrap()
                    .then((payload: IInvoiceDto) => {
                      dispatch(
                        clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                      ).then(() => {
                        if (invoice.precedentId) {
                          const staffId = !!decodedToken.current
                            ? decodedToken.current.staffId
                            : "";
                          dispatch(
                            createDocument({
                              precedentId: invoice.precedentId,
                              matterId: payload.matterId,
                              documentName: invoice.precedentList?.find(
                                (item) => item.guid === invoice.precedentId
                              )?.description,
                              staffInitials: userList?.find(
                                (s) => s.staffId === staffId
                              )?.initials,
                              parameters: {
                                invoiceId: payload.invoiceId,
                                staffId: staffId,
                              },
                            } as IDocBuilderModelDto)
                          ).then((action: any) => {
                            const docModel = action.payload as IDocModelDto;
                            openDocument(
                              {
                                documentId: docModel.documentId,
                                matterId: payload.matterId,
                              },
                              sdkApi
                            );
                            if (
                              invoice.isPrintingEnabled &&
                              docModel.documentId &&
                              payload.invoiceId
                            ) {
                              openReport(payload.invoiceId, sdkApi).then(() =>
                                close(sdkApi)
                              );
                            } else {
                              close(sdkApi);
                            }
                          });
                        } else if (
                          invoice.isPrintingEnabled &&
                          payload.invoiceId
                        ) {
                          openReport(payload.invoiceId, sdkApi).then(() =>
                            close(sdkApi)
                          );
                        } else close(sdkApi);
                      });
                    })
                    .catch((e) => console.error(e));
                }
              } else
                dispatch(
                  setValidationErrors([
                    "You cannot create an invoice with a value of £0.00",
                  ])
                );
            }
          }
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setNotificationMessageIsWarning(false);
                setNotificationMessage(undefined);
              }}
              variant="contained"
            >
              OK
            </Button>
          </>
        }
        content={<Typography>{notificationMessage}</Typography>}
        isOpen={notificationMessage !== undefined}
        onClose={() => setNotificationMessage(undefined)}
        showWarningIcon={notificationMessageIsWarning ? true : false}
        title={notificationMessageIsWarning ? "Alert" : "Notification"}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => {
          setShowUnsavedData(false);
        }}
        onYesCallback={() => handleClose()}
      />
      <InvoiceErrorDialog />
    </>
  );
};

export default InvoiceLgfsDetails;
