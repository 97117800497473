import { Api } from "@leapdev/leap-host";

export const defaultHeaders = async (sdkApi?: Api) => {
  if (!!sdkApi) {
    const authToken = await sdkApi.getRefreshedAccessToken();
    return {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
  }
};
