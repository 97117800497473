import {
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getFees,
  getPflrsGroups,
  getTaxCodes,
  initFee,
  saveFee,
} from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { FeeLegalAidObj, IFeeDto } from "../../shared/dto/fee.dto";

import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  moreThan30DaysInFuture,
  validate,
} from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomCheckbox from "../components/custom-checkbox";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import TopBar from "../components/topbar";
import {
  filterInactiveStaff,
  isNullOrEmptyGuid,
} from "../../shared/utils/utils";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { useAppInit } from "../../shared/hooks/use-app-init";

const getPflrsRequiredFields = (fee: IFeeDto) => {
  return [
    {
      value: fee?.legalAidObj?.boltOn?.pflrs?.category,
      label: "Type of Law",
    },
  ];
};

const PflrsFee: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const dataInitialised = useRef(false);
  const initialFeeDetails = useRef<IFeeDto | undefined>(undefined);
  const feeRef = useRef<IFeeDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const pflrsGroups = useSelector((state: RootState) => state.fee.pflrsGroups);
  const users = useSelector((state: RootState) => state.app.users);
  const taxCodes = useSelector((state: RootState) => state.fee.taxCodes);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.fee.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<
    IFeeDto | undefined
  >(undefined);

  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const feeId = urlParams.get("feeid");

  const calcTotals = useCallback(
    (feeDetails: IFeeDto) => {
      let amount = 0;

      let type = pflrsGroups?.find(
        (g) => g.category === feeDetails.legalAidObj.boltOn?.pflrs?.category
      );

      if (!!type) {
        if (feeDetails.legalAidObj.boltOn?.pflrs?.highCourt) {
          amount = amount + type.l3HighCourtAmount;

          if (feeDetails.legalAidObj.boltOn?.pflrs?.level3Settlement) {
            amount = amount + type.settlementHighCourtAmount;
          }
          if (feeDetails.legalAidObj.boltOn?.pflrs?.level4) {
            amount = amount + type.l4HighCourtAmount;
          }
          if (feeDetails.legalAidObj.boltOn?.pflrs?.enforcement) {
            amount = amount + type.enforcementHighCourtAmount;
          }
        } else {
          amount = amount + type.l3CountyCourtAmount;

          if (feeDetails.legalAidObj.boltOn?.pflrs?.level3Settlement) {
            amount = amount + type.settlementCountyCourtAmount;
          }
          if (feeDetails.legalAidObj.boltOn?.pflrs?.level4) {
            amount = amount + type.l4CountyCourtAmount;
          }
          if (feeDetails.legalAidObj.boltOn?.pflrs?.enforcement) {
            amount = amount + type.enforcementCountyCourtAmount;
          }
        }

        if (feeDetails.legalAidObj.boltOn?.pflrs?.halfFee) {
          amount = amount / 2;
        }

        const currentTaxCode = taxCodes?.find(
          (t) => t.taxCodeId === feeDetails?.taxCodeId
        );
        let tax = 0;
        let total = 0;
        if (currentTaxCode) {
          tax = (currentTaxCode?.ratePercent || 0) / 100;
          if (tax > 0) {
            tax = amount * tax;
          }
          if (!!feeDetails.incTax) {
            tax = amount * (1 - 1 / (tax + 1));
          }
          total = amount + tax;
        }
        const tempFee = {
          ...feeDetails,
          amount: amount,
          rate: amount,
        } as IFeeDto;
        setCurrentFeeDetails(tempFee);
        if (!dataInitialised.current) {
          initialFeeDetails.current = tempFee;
        }
        dataInitialised.current = true;
        setTotal(total);
        setTax(tax);
      } else {
        setCurrentFeeDetails(feeDetails);
      }
    },
    [pflrsGroups, taxCodes]
  );

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(feeRef.current) !==
      JSON.stringify(initialFeeDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    feeRef.current = currentFeeDetails;
  }, [currentFeeDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;

      dispatch(getMatterDetails(false)).then((action) => {
        if (action.meta.requestStatus !== "rejected") {
          if (feeId) {
            dispatch(getFees([feeId || ""]));
          } else {
            dispatch(
              initFee({
                matterDetails: action.payload as IMatterDetailsDto,
                type: "pflrs",
              })
            );
          }
          dispatch(
            getPflrsGroups({
              tableNo:
                (action.payload as IMatterDetailsDto)?.legalAidObj?.tableNo ||
                0,
              rate:
                (action.payload as IMatterDetailsDto)?.legalAidObj?.rate || "",
            })
          );
        }
      });
      dispatch(getTaxCodes());
      dispatch(getUsers());
    }
  }, [dispatch, feeId]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- PFLRS Fee"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!!feeDetails && !feeDetailsInitialised.current && !!pflrsGroups) {
      feeDetailsInitialised.current = true;
      let currentFee = { ...feeDetails[0] };
      if (isNullOrEmptyGuid(currentFee.legalAidObj?.taskCodeId)) {
        currentFee = {
          ...currentFee,
          legalAidObj: {
            ...currentFee.legalAidObj,
            taskCodeId: "5C2D0A82-2073-4B55-BFCE-CFFBC19120C1",
          },
        };
      }

      initialFeeDetails.current = currentFee;
      calcTotals(currentFee);
    }
  }, [feeDetails, feeDetailsInitialised, calcTotals, pflrsGroups]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  const isFormDisabled =
    currentFeeDetails?.billed || currentFeeDetails?.deleted;

  return (
    <>
      <TopBar
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Family-Care-Recording-a-Solicitor-s-Fixed-Fee-Licensed-Work"
        leftComponents={
          <FeeDeleteButton
            fee={currentFeeDetails}
            feeRef={initialFeeDetails.current}
          />
        }
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex5">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography style={{ maxWidth: 335, minWidth: 335 }} noWrap>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>

              <div className="flex3">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Date
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <LocalDatePicker
                      value={
                        currentFeeDetails?.transactionDate
                          ? currentFeeDetails?.transactionDate
                          : null
                      }
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        if (value !== currentFeeDetails?.transactionDate) {
                          const newValue = value || "";

                          if (newValue !== "Invalid date") {
                            setCurrentFeeDetails({
                              ...currentFeeDetails,
                              transactionDate: newValue,
                            } as IFeeDto);
                          }
                        }
                      }}
                      validate={(value) =>
                        moreThan30DaysInFuture(
                          value,
                          currentFeeDetails?.transactionDate
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex3">
                <TextField
                  variant="outlined"
                  value="Licensed Work"
                  disabled
                  fullWidth
                />
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Staff
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <Select
                      className="select"
                      fullWidth
                      native
                      variant="outlined"
                      value={currentFeeDetails?.legalAidObj.feeEarnerId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        setCurrentFeeDetails({
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            feeEarnerId: e.target.value,
                          } as FeeLegalAidObj,
                        } as IFeeDto);
                      }}
                    >
                      {!currentFeeDetails?.legalAidObj.feeEarnerId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {filterInactiveStaff(
                        users,
                        currentFeeDetails?.legalAidObj.feeEarnerId
                      )?.map((user) => (
                        <option key={user.staffId} value={user.staffId}>
                          {`${user.firstName} ${user.lastName}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRowTextArea">
              <div style={{ width: 130 }}>
                <InputLabel>Billing Description</InputLabel>
              </div>
              <div className="flex6">
                <TextareaAutosize
                  value={currentFeeDetails?.description || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      description: e.target.value,
                    } as IFeeDto);
                  }}
                  minRows={3}
                  maxRows={3}
                  className="textarea-fee"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Type of Law</InputLabel>
              </div>
              <div className="flex2">
                <div className="displayFlex">
                  <Select
                    variant="outlined"
                    native
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.pflrs?.category ||
                      ""
                    }
                    disabled={isFormDisabled}
                    onChange={(e) => {
                      let tempFee = {
                        ...currentFeeDetails,
                        legalAidObj: {
                          ...currentFeeDetails?.legalAidObj,
                          boltOn: {
                            ...currentFeeDetails?.legalAidObj.boltOn,
                            pflrs: {
                              ...currentFeeDetails?.legalAidObj.boltOn?.pflrs,
                              category: e.target.value,
                            },
                          },
                        },
                      } as IFeeDto;
                      calcTotals(tempFee);
                    }}
                    fullWidth
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {pflrsGroups?.map((group) => (
                      <option key={group.category} value={group.category}>
                        {group.category}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex7" />
              <div style={{ width: 130 }} />
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Claiming</InputLabel>
              </div>
              <div className="flex4">
                <div className="displayFlex">
                  <div style={{ width: 105 }}>
                    <CustomCheckbox
                      label="High Court"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.pflrs
                          ?.highCourt || false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              pflrs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.pflrs,
                                highCourt: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                  <div>
                    <CustomCheckbox
                      label="1/2 Fee Applies"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.pflrs?.halfFee ||
                        false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              pflrs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.pflrs,
                                halfFee: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex5" style={{ paddingRight: 10 }}>
                <div className="displayFlex">
                  <div className="flex1">
                    <Typography align="right" className="fee-totals">
                      AMOUNT
                    </Typography>
                  </div>
                  <CurrencyInput
                    disabled
                    value={currentFeeDetails?.amount || 0}
                    prefix="£"
                  />
                </div>
              </div>
              <div style={{ width: 130 }} />
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }} />
              <div className="flex4">
                <div className="displayFlex">
                  <div style={{ width: 105 }}>
                    <CustomCheckbox
                      label="Level 3"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.pflrs?.level3 ||
                        false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              pflrs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.pflrs,
                                level3: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                  <div>
                    <CustomCheckbox
                      label="Settlement"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.pflrs
                          ?.level3Settlement || false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              pflrs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.pflrs,
                                level3Settlement: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex5" style={{ paddingRight: 10 }}>
                <div className="displayFlex">
                  <div className="flex1">
                    <Typography align="right" className="fee-totals">
                      TAX
                    </Typography>
                  </div>
                  <CurrencyInput disabled value={tax} prefix="£" />
                </div>
              </div>
              <div style={{ width: 130 }}>
                <Select
                  variant="outlined"
                  native
                  value={currentFeeDetails?.taxCodeId || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    let tempFee = {
                      ...currentFeeDetails,
                      taxCodeId: e.target.value,
                    } as IFeeDto;

                    calcTotals(tempFee);
                  }}
                  fullWidth
                >
                  {!currentFeeDetails?.taxCodeId && (
                    <option key="" value="" className="emptyMenuItem"></option>
                  )}
                  {taxCodes?.map((taxCode) => (
                    <option key={taxCode.taxCodeId} value={taxCode.taxCodeId}>
                      {taxCode.taxCode}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }} />
              <div className="flex4">
                <div className="displayFlex">
                  <div style={{ width: 105 }}>
                    <CustomCheckbox
                      label="Level 4"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.pflrs?.level4 ||
                        false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              pflrs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.pflrs,
                                level4: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                  <div>
                    <CustomCheckbox
                      label="Enforcement"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.pflrs
                          ?.enforcement || false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              pflrs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.pflrs,
                                enforcement: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex5" style={{ paddingRight: 10 }}>
                <div className="displayFlex">
                  <div className="flex1">
                    <Typography align="right" className="fee-totals">
                      TOTAL
                    </Typography>
                  </div>
                  <CurrencyInput disabled value={total} prefix="£" />
                </div>
              </div>
              <div style={{ width: 130 }} />
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentFeeDetails &&
            !validate(getPflrsRequiredFields(currentFeeDetails), [], (errors) =>
              dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(saveFee(currentFeeDetails))
              .unwrap()
              .then(() => {
                close(sdkApi);
              })
              .catch((e) => console.error(e));
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default PflrsFee;
