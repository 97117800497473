import { IInvoiceDto } from "../dto/invoice/invoice.dto";

export const createInvoiceDocument = (
  isReversal: boolean,
  notificationAction: (message: string) => void,
  createDocumentAction: (id: string) => void,
  createAfterSaveAction: (id: string) => void,
  invoice?: IInvoiceDto,
  precedentId?: string
) => {
  if (precedentId && invoice) {
    if (!!invoice.invoiceId) {
      if (invoice.deleted) {
        notificationAction(
          "Documents cannot be created for a deleted invoice."
        );
      } else if (isReversal) {
        notificationAction(
          "Documents cannot be created for a reversed or a reversal."
        );
      } else {
        createDocumentAction(precedentId);
      }
    } else {
      notificationAction(
        "Document will be created after the invoice has been saved."
      );
      createAfterSaveAction(precedentId);
    }
  }
};

export const printReport = (
  notificationAction: (message: string) => void,
  printAction: () => void,
  printAfterSaveAction: () => void,
  invoice?: IInvoiceDto
) => {
  if (invoice) {
    if (!!invoice.invoiceId) {
      printAction();
    } else {
      notificationAction("This transaction will be printed after saving.");
      printAfterSaveAction();
    }
  }
};

export const calculateBalanceDue = (add: number[], subtract: number[]) => {
  const balanceDue = subtract.reduce(
    (sum, current) => sum - current,
    add.reduce((sum, current) => sum + current, 0)
  );
  return balanceDue < 0 ? 0 : balanceDue;
};
