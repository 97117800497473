import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setValidationErrors } from "../../app/store/app/appSlice";
import CustomDialog from "./custom-dialog";

const ValidationDialog: FC = () => {
  const dispatch = useAppDispatch();

  const validationErrors = useAppSelector(
    (state: RootState) => state.app.validationErrors
  );
  const validationAction = useAppSelector(
    (state: RootState) => state.app.validationAction
  );

  const onClose = () => {
    if (!!validationAction) {
      validationAction();
    }
    dispatch(setValidationErrors(undefined));
  };

  return (
    <CustomDialog
      isOpen={!!validationErrors}
      closeIcon
      title="Alert"
      onClose={onClose}
      showWarningIcon
      actions={
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          disableRipple
        >
          OK
        </Button>
      }
      content={validationErrors?.map((warning) => (
        <Typography whiteSpace="break-spaces" key={warning}>
          {warning}
        </Typography>
      ))}
    />
  );
};

export default ValidationDialog;
