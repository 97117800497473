import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getActivities,
  getFees,
  getTaskCodes,
  getTaxCodes,
  initFee,
  saveFees,
} from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { IFeeDto } from "../../shared/dto/fee.dto";
import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import { Activity, BillingStageGroup } from "../../shared/dto/rates.dto";
import { IWarningAcknowledgmentDto } from "../../shared/dto/warning-acknowledgment.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  isNullOrEmptyGuid,
  roundAwayFromZero,
} from "../../shared/utils/utils";
import {
  moreThan30DaysInFuture,
  validate,
} from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomCheckbox from "../components/custom-checkbox";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import NumberInput from "../components/number-input";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";

const getCommonRequiredFields = (fee: IFeeDto) => {
  return [
    {
      value: fee?.legalAidObj.feeEarnerId,
      label: "Staff",
    },
    {
      value: fee?.legalAidObj.billingStageId,
      label: "Billing Stage",
    },
    {
      value: fee?.legalAidObj?.category,
      label: "Activity Rate",
    },
    {
      value: fee?.transactionDate,
      label: "Date",
    },
  ];
};

//TODO LA2-28 WHEN text changes to enum
const isFeeOrCostRecovery = (activityType: string) => {
  if (activityType === "fee") {
    return 1;
  }
  return 2;
};

const MultiFee: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const initialFeeDetails = useRef<IFeeDto[] | undefined>(undefined);
  const feeRef = useRef<IFeeDto[] | undefined>(undefined);

  const dispatch = useAppDispatch();

  const users = useSelector((state: RootState) => state.app.users);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.fee.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const taxCodes = useSelector((state: RootState) => state.fee.taxCodes);
  const taskCodes = useSelector((state: RootState) => state.fee.taskCodes);
  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const billingItemRate = useSelector(
    (state: RootState) => state.fee.billingItemRate
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFees, setCurrentFees] = useState<IFeeDto[] | undefined>(
    undefined
  );
  const [group, setGroup] = useState<BillingStageGroup | undefined>(undefined);
  const [activities, setActivities] = useState<Activity[] | undefined>(
    undefined
  );
  const [warningAcknowledgment, setWarningAcknowledgment] = useState<
    IWarningAcknowledgmentDto | undefined
  >(undefined);
  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  const urlParams = new URLSearchParams(window.location.search);
  const feeid = urlParams.get("feeid");
  const feesid = urlParams.get("feesid");
  const multi = urlParams.get("multi");
  const timeelapsed = Number(urlParams.get("timeelapsed"));

  let multiFeeId = feeid;
  if (!!feesid) {
    const ids: string[] = JSON.parse(atob(feesid));
    if (ids) {
      multiFeeId = ids[0];
      // feeGuids = ids;
    }
  }

  const getRequiredFields = (fee: IFeeDto, index: number) => {
    let reqFields: {
      value: string | number | undefined | null;
      label: string;
    }[] = [
      {
        value:
          fee?.legalAidObj?.taskCodeId ===
          "00000000-0000-0000-0000-000000000000"
            ? ""
            : fee?.legalAidObj?.taskCodeId,
        label: `Activity on Row ${index + 1}`,
      },
    ];
    if (
      matterDetails?.legalAidObj?.matterTypeId === 2 &&
      isExpensesVisible(fee) &&
      isExpensesEnabled(fee)
    ) {
      reqFields = reqFields.concat([
        {
          value: fee?.legalAidObj?.ccExpenseReasonId,
          label: `Second Activity on Row ${index + 1}`,
        },
      ]);
    }
    if (
      matterDetails?.legalAidObj?.matterTypeId === 2 &&
      isDisbursmentsEnabled(fee)
    ) {
      reqFields = reqFields.concat([
        {
          value: fee?.legalAidObj?.ccDisbursementTypeId,
          label: `Second Activity on Row ${index + 1}`,
        },
      ]);
    }

    if (
      matterDetails?.legalAidObj?.matterTypeId === 2 &&
      isDestinationEnabled(fee)
    ) {
      reqFields = reqFields.concat([
        {
          value: fee?.legalAidObj?.ccDestination,
          label: `Second Billing Description on Row ${index + 1}`,
        },
      ]);
    }

    return reqFields;
  };

  const refreshLists = useCallback(
    (feeDetails: IFeeDto) => {
      const selectedGroup = billingItemRate?.billingStageGroups.find(
        (g) => g.billingStageId === feeDetails?.legalAidObj?.billingStageId
      );
      if (selectedGroup) {
        setGroup(selectedGroup);
      }
      const activityGroup = selectedGroup?.activityGroups?.find(
        (a) => a.code === feeDetails?.legalAidObj.category
      );
      if (activityGroup) {
        setActivities(activityGroup.activities);
      } else {
        setActivities(undefined);
      }
    },
    [billingItemRate]
  );

  const addItem = () => {
    let newItem = {
      matterId: currentFees?.length ? currentFees[0].matterId : "",
      taxCodeId: currentFees?.length ? currentFees[0].taxCodeId : "",
      rate: 0,
      unit: 0,
      amount: 0,
      incTax: false,
      transactionDate: currentFees?.length
        ? currentFees[0].transactionDate
        : "",
      memo: currentFees?.length ? currentFees[0].memo : "",
      warningAcknowledgments: currentFees?.length
        ? currentFees[0].warningAcknowledgments
        : [],
      legalAidObj: {
        billingStageId: currentFees?.length
          ? currentFees[0].legalAidObj.billingStageId
          : 0,
        feeEarnerId: currentFees?.length
          ? currentFees[0].legalAidObj.feeEarnerId
          : "",
        category: currentFees?.length
          ? currentFees[0].legalAidObj.category
          : "",
        hearingType: currentFees?.length
          ? currentFees[0].legalAidObj.hearingType
          : "",
        attendanceOn: currentFees?.length
          ? currentFees[0].legalAidObj.attendanceOn
          : "",
        uplift: currentFees?.length ? currentFees[0].legalAidObj.uplift : "",
        enhanced: currentFees?.length
          ? currentFees[0].legalAidObj.enhanced
          : false,
        openType: currentFees?.length
          ? currentFees[0].legalAidObj.openType
          : "1",
        width: currentFees?.length ? currentFees[0].legalAidObj.width : 0,
        height: currentFees?.length ? currentFees[0].legalAidObj.height : 0,
        minWidth: currentFees?.length ? currentFees[0].legalAidObj.minWidth : 0,
        minHeight: currentFees?.length
          ? currentFees[0].legalAidObj.minHeight
          : 0,
        windowTitle: currentFees?.length
          ? currentFees[0].legalAidObj.windowTitle
          : 0,
      },
    } as IFeeDto;
    setCurrentFees([...(currentFees || []), newItem]);
  };

  const getCurrentTask = (fee: IFeeDto) => {
    const task = taskCodes?.find(
      (t) => t.taskCodeId === fee.legalAidObj.taskCodeId
    );
    return task;
  };

  const calcAmount = (fee: IFeeDto) => {
    let tempFee = { ...fee };
    const selectedGroup = billingItemRate?.billingStageGroups.find(
      (g) => g.billingStageId === fee?.legalAidObj?.billingStageId
    );
    const currentActivityGroup = selectedGroup?.activityGroups?.find(
      (a) => a.code === fee?.legalAidObj.category
    );

    const task = taskCodes?.find(
      (t) => t.taskCodeId === tempFee.legalAidObj.taskCodeId
    );

    const activity = currentActivityGroup?.activities?.find(
      (a) => a.taskCodeId === tempFee.legalAidObj.taskCodeId
    );

    if (!!activity && activity.nameFileAs === "OTH") {
      tempFee = { ...tempFee, unit: 1, rate: tempFee.amount };
    } else {
      tempFee = {
        ...tempFee,
        legalAidObj: { ...tempFee.legalAidObj, ccDisbursementTypeId: 0 },
      };
    }

    if (!!activity && activity?.nameFileAs !== "OTH") {
      tempFee = { ...tempFee, rate: activity.rate };
    }

    if (!!billingItemRate?.expenseGroups) {
      const tempExpenseGroup = billingItemRate?.expenseGroups.find(
        (e) => e.code === activity?.nameFileAs
      );
      if (!!tempExpenseGroup && !!tempExpenseGroup.expenseReasons) {
        tempFee = {
          ...tempFee,
          legalAidObj: {
            ...tempFee.legalAidObj,
            ccExpenseTypeId: tempExpenseGroup.expenseTypeId,
          },
        };
        const currentExpense = tempExpenseGroup.expenseReasons.find(
          (e) => e.expensesReasonId === fee.legalAidObj.ccExpenseReasonId
        );
        if (!currentExpense) {
          tempFee = {
            ...tempFee,
            legalAidObj: { ...tempFee.legalAidObj, ccExpenseReasonId: 0 },
          };
        }
      }
    }

    //!! CALCULATIONS
    if (
      task?.allowUplift &&
      (!task.requireEnhancedForUplift ||
        (task.requireEnhancedForUplift && tempFee.legalAidObj.enhanced))
    ) {
      tempFee = {
        ...tempFee,
        rate: roundAwayFromZero(
          (tempFee?.rate || 0) *
            (1 + (tempFee?.legalAidObj?.uplift || 0) / 100),
          2
        ),
      };
    }

    if (!task?.hideUnits) {
      let unitsPerRate = 1;
      if (!!task && task?.unitsPerRate && (task?.unitsPerRate || 0) > 1) {
        unitsPerRate = task?.unitsPerRate;
      }
      tempFee = {
        ...tempFee,
        amount: roundAwayFromZero(
          ((tempFee.rate || 0) / unitsPerRate) * (fee?.unit || 0),
          2
        ),
      };
    } else {
      if (task && task.unitsPerRate === 1) {
        tempFee = {
          ...tempFee,
          unit: 1,
          rate: roundAwayFromZero(tempFee.amount || 0, 2),
        };
      }
    }

    //? NOT SURE
    // if (tempFee.taxCodeId) {
    //   let taxCode = taxCodes?.find((t) => t.taxCodeId === tempFee.taxCodeId);
    //   let tempTax = 0;
    //   if (!!taxCode) {
    //     tempTax = (taxCode.ratePercent || 0) / 100;
    //   }
    //   if (!!tempFee.incTax) {
    //     tempTax = 0;
    //   }
    // }

    // let tempFees = [...(currentFees || [])];
    // tempFees = tempFees.map((f, i, array) => (index === i ? tempFee : f));
    // calcAmount(tempFee, index)
    return tempFee;
  };

  const setFeeByIndex = (fee: IFeeDto, index: number) => {
    let tempFees = [...(currentFees || [])];
    tempFees = tempFees.map((f, i) => (index === i ? fee : f));
    setCurrentFees(tempFees);
  };

  const calcTotal = () => {
    let total = (currentFees || [])
      .map((f) => f.amount)
      .reduce((a, b) => (a || 0) + (b || 0), 0);
    return total;
  };

  const isExpensesEnabled = (fee?: IFeeDto) => {
    const selectedGroup = billingItemRate?.billingStageGroups.find(
      (g) => g.billingStageId === fee?.legalAidObj?.billingStageId
    );
    const currentActivityGroup = selectedGroup?.activityGroups?.find(
      (a) => a.code === fee?.legalAidObj.category
    );

    const activity = currentActivityGroup?.activities?.find(
      (a) => a.taskCodeId === fee?.legalAidObj.taskCodeId
    );
    const tempExpenseGroup = billingItemRate?.expenseGroups.find(
      (e) => e.code === activity?.nameFileAs
    );
    if (
      matterDetails?.legalAidObj?.matterTypeId === 2 &&
      !isNullOrEmptyGuid(fee?.legalAidObj.taskCodeId) &&
      activity?.nameFileAs !== "OTH" &&
      !!tempExpenseGroup?.expenseReasons &&
      !!tempExpenseGroup?.expenseReasons.length
    ) {
      return true;
    }

    return false;
  };

  const isExpensesVisible = (fee?: IFeeDto) => {
    let currentActivity = activities?.find(
      (a) => a.taskCodeId === fee?.legalAidObj.taskCodeId
    );
    const selectedGroup = billingItemRate?.billingStageGroups.find(
      (g) => g.billingStageId === fee?.legalAidObj?.billingStageId
    );

    if (
      (matterDetails?.legalAidObj?.matterTypeId === 2 &&
        !isNullOrEmptyGuid(fee?.legalAidObj.taskCodeId) &&
        currentActivity?.nameFileAs !== "OTH" &&
        selectedGroup) ||
      (matterDetails?.legalAidObj?.matterTypeId === 2 &&
        isNullOrEmptyGuid(fee?.legalAidObj.taskCodeId))
    ) {
      return true;
    }

    return false;
  };

  const isDisbursmentsEnabled = (fee?: IFeeDto) => {
    const currentActivity = activities?.find(
      (a) => a.taskCodeId === fee?.legalAidObj.taskCodeId
    );
    if (
      matterDetails?.legalAidObj?.matterTypeId === 2 &&
      !isNullOrEmptyGuid(fee?.legalAidObj.taskCodeId) &&
      currentActivity?.nameFileAs === "OTH"
    ) {
      return true;
    }

    return false;
  };

  const isDestinationEnabled = (fee?: IFeeDto) => {
    const selectedGroup = billingItemRate?.billingStageGroups.find(
      (g) => g.billingStageId === fee?.legalAidObj?.billingStageId
    );
    const currentActivityGroup = selectedGroup?.activityGroups?.find(
      (a) => a.code === fee?.legalAidObj.category
    );

    const activity = currentActivityGroup?.activities?.find(
      (a) => a.taskCodeId === fee?.legalAidObj.taskCodeId
    );
    const tempExpenseGroup = billingItemRate?.expenseGroups.find(
      (e) => e.code === activity?.nameFileAs
    );
    const currentReason = tempExpenseGroup?.expenseReasons.find(
      (r) => r.expensesReasonId === fee?.legalAidObj.ccExpenseReasonId
    );
    if (currentReason?.allowExplText) {
      return true;
    }
    return false;
  };

  const getExpenseReasons = (fee?: IFeeDto) => {
    const selectedGroup = billingItemRate?.billingStageGroups.find(
      (g) => g.billingStageId === fee?.legalAidObj?.billingStageId
    );
    const currentActivityGroup = selectedGroup?.activityGroups?.find(
      (a) => a.code === fee?.legalAidObj.category
    );

    const activity = currentActivityGroup?.activities?.find(
      (a) => a.taskCodeId === fee?.legalAidObj.taskCodeId
    );
    const tempExpenseGroup = billingItemRate?.expenseGroups.find(
      (e) => e.code === activity?.nameFileAs
    );

    return [...(tempExpenseGroup?.expenseReasons || [])].sort(
      (a, b) => (a.expensesReasonId > b.expensesReasonId && 1) || -1
    );
  };

  const deleteFee = (index: number) => {
    const tempFees = currentFees?.filter((f, i) => {
      return index !== i;
    });

    setCurrentFees(tempFees);
    refreshLists((tempFees || [])[0]);
  };

  const getActivityList = (fee: IFeeDto) => {
    return (activities || [])
      .filter(
        (a) =>
          (!!fee.itemId && isFeeOrCostRecovery(a.type) === fee.type) ||
          !fee.itemId
      )
      .sort((a, b) => (a.orderBy > b.orderBy ? 1 : -1));
  };

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(feeRef.current) !==
      JSON.stringify(initialFeeDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    feeRef.current = currentFees;
  }, [currentFees]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(false)).then((action) => {
        if (action.meta.requestStatus !== "rejected") {
          if (feeid) {
            dispatch(getFees([feeid]));
            dispatch(getActivities(action.payload as IMatterDetailsDto));
          } else if (feesid) {
            const ids: string[] = JSON.parse(atob(feesid));
            dispatch(getFees(ids || []));
            dispatch(getActivities(action.payload as IMatterDetailsDto));
          } else {
            dispatch(
              initFee({
                matterDetails: action.payload as IMatterDetailsDto,
                type: "fee",
                timeElapsed: timeelapsed,
              })
            );
            dispatch(getActivities(action.payload as IMatterDetailsDto));
          }
        }
      });
      dispatch(getTaxCodes());
      dispatch(getTaskCodes());
      dispatch(getUsers());
    }
  }, [dispatch, feeid, feesid, timeelapsed]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- New Billable Item"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (
      !!feeDetails &&
      !feeDetailsInitialised.current &&
      billingItemRate?.billingStageGroups
    ) {
      feeDetailsInitialised.current = true;

      let tempFees: IFeeDto[] = [];
      [...feeDetails]?.forEach((fee) => {
        tempFees = tempFees.concat(calcAmount(fee));
      });

      setCurrentFees(tempFees);
      initialFeeDetails.current = tempFees;
      refreshLists(tempFees[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feeDetails,
    feeDetailsInitialised,
    refreshLists,
    billingItemRate?.billingStageGroups,
  ]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => {
        onCancel();
      }, sdkApi);
    }
  }, [sdkApi, onCancel]);

  //TODO CHECK disabled
  const isFormDisabled = false;
  // currentFeeDetails?.billed || currentFeeDetails?.deleted;

  const getHelpUrl = () => {
    switch (matterDetails?.legalAidObj?.matterTypeId) {
      case 12:
        return "https://community.leap.co.uk/s/article/Legal-Aid-Civil-Immigration-Creating-a-New-Time-Entry-Bolt-On-Payments";
      case 11:
        return "https://community.leap.co.uk/s/article/Legal-Aid-Civil-Mental-Health-Creating-a-New-Time-Entry-Bolt-On-Payments";
      case 5:
        return "https://community.leap.co.uk/s/article/Legal-Aid-Civil-Mediation-Recording-a-New-Time-Entry";
      case 2:
        return "https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Crown-Court-Creating-a-New-Time-Entry";
      case 1:
        return "https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Creating-a-New-Time-Entry";
      default:
        return "https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Creating-a-New-Time-Entry";
    }
  };

  return (
    <>
      <TopBar
        helpUrl={getHelpUrl()}
        leftComponents={
          <FeeDeleteButton
            feeId={multiFeeId || undefined}
            multi
            multiFee={currentFees}
            multiFeeRef={initialFeeDetails.current}
          />
        }
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex2">
            <div className="inputRow">
              <div className="flex1">
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex7">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>
            </div>
            {!multi && (
              <div className="inputRow">
                <div className="flex1">
                  <InputLabel required>Staff</InputLabel>
                </div>
                <div className="flex2">
                  <Select
                    sx={{ width: 200 }}
                    variant="outlined"
                    native
                    value={
                      currentFees ? currentFees[0]?.legalAidObj.feeEarnerId : ""
                    }
                    disabled={isFormDisabled}
                    onChange={(e) => {
                      let tempFees = currentFees?.map((fee) => {
                        return {
                          ...fee,
                          legalAidObj: {
                            ...fee?.legalAidObj,
                            feeEarnerId: e.target.value,
                          },
                        };
                      });
                      setCurrentFees(tempFees);
                    }}
                  >
                    {(!(currentFees || [])[0]?.legalAidObj.feeEarnerId ||
                      (currentFees || [])[0]?.legalAidObj.feeEarnerId ===
                        "") && (
                      <option
                        key=""
                        value=""
                        className="emptyMenuItem"
                      ></option>
                    )}
                    {filterInactiveStaff(
                      users,
                      !!currentFees
                        ? currentFees[0]?.legalAidObj.feeEarnerId
                        : undefined
                    )?.map((user) => (
                      <option key={user.staffId} value={user.staffId}>
                        {`${user.firstName} ${user.lastName}`}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex3"></div>
                <div className="flex1">
                  <InputLabel required>Date</InputLabel>
                </div>
                <div className="flex1">
                  <LocalDatePicker
                    value={
                      currentFees && currentFees[0]?.transactionDate
                        ? currentFees[0]?.transactionDate
                        : null
                    }
                    disabled={isFormDisabled}
                    onUpdate={(value) => {
                      if (
                        currentFees &&
                        value !== currentFees[0]?.transactionDate
                      ) {
                        const newValue = value || "";
                        let tempFees = currentFees?.map((fee) => {
                          return {
                            ...fee,
                            transactionDate: newValue,
                          };
                        });
                        setCurrentFees(tempFees);
                      }
                    }}
                    validate={(value) =>
                      moreThan30DaysInFuture(
                        value,
                        currentFees
                          ? currentFees[0]?.transactionDate
                          : undefined
                      )
                    }
                  />
                </div>
              </div>
            )}
            <div className="inputRow">
              <div className="flex1">
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex2">
                <Select
                  sx={{ width: 200 }}
                  fullWidth
                  native
                  variant="outlined"
                  value={
                    currentFees
                      ? currentFees[0]?.legalAidObj.billingStageId
                      : ""
                  }
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    let tempFees = currentFees?.map((fee) => {
                      return {
                        ...fee,
                        legalAidObj: {
                          ...fee?.legalAidObj,
                          billingStageId: Number(e.target.value),
                        },
                      } as IFeeDto;
                    });
                    if (tempFees) {
                      refreshLists(tempFees[0]);
                    }
                    setCurrentFees(tempFees);
                  }}
                >
                  {!(currentFees || [])[0]?.legalAidObj.billingStageId && (
                    <option key="" value="" className="emptyMenuItem"></option>
                  )}
                  {[...(billingItemRate?.billingStageGroups || [])]
                    ?.sort((a, b) =>
                      a.billingStageId > b.billingStageId ? 1 : -1
                    )
                    .map((billingStage) => (
                      <option
                        key={billingStage.billingStageId}
                        value={billingStage.billingStageId}
                      >
                        {billingStage.billingStageDesc}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="flex1">
                <InputLabel>Hearing Type</InputLabel>
              </div>
              <div className="flex2">
                <Select
                  sx={{ width: 165 }}
                  fullWidth
                  native
                  variant="outlined"
                  value={
                    !!currentFees?.length &&
                    !!currentFees[0].legalAidObj?.hearingType
                      ? currentFees[0].legalAidObj.hearingType
                      : ""
                  }
                  disabled={
                    isFormDisabled ||
                    !(
                      !!currentFees &&
                      !!currentFees.length &&
                      currentFees[0].legalAidObj.billingStageId === 2 &&
                      (matterDetails?.legalAidObj?.matterTypeId === 1 ||
                        matterDetails?.legalAidObj?.matterTypeId === 3)
                    )
                  }
                  onChange={(e) => {
                    let tempFees = currentFees?.map((fee) => {
                      return {
                        ...fee,
                        legalAidObj: {
                          ...fee?.legalAidObj,
                          hearingType: e.target.value,
                        },
                      };
                    });
                    let updatedFees: IFeeDto[] = [];
                    tempFees?.forEach((fee) => {
                      updatedFees = updatedFees.concat(calcAmount(fee));
                    });
                    setCurrentFees(updatedFees);
                  }}
                >
                  <option key="" value="" className="emptyMenuItem"></option>
                  {billingItemRate?.hearingType?.map((hearingType) => (
                    <option key={hearingType.code} value={hearingType.code}>
                      {hearingType.description}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="flex1">
                <InputLabel>Uplift %</InputLabel>
              </div>
              <div className="flex1">
                <NumberInput
                  value={
                    !!currentFees?.length
                      ? currentFees[0].legalAidObj.uplift
                      : 0
                  }
                  disabled={isFormDisabled}
                  updater={(value) => {
                    let tempFees = currentFees?.map((fee) => {
                      return {
                        ...fee,
                        legalAidObj: {
                          ...fee?.legalAidObj,
                          uplift: Number(value),
                        },
                      };
                    });
                    let updatedFees: IFeeDto[] = [];
                    tempFees?.forEach((fee) => {
                      updatedFees = updatedFees.concat(calcAmount(fee));
                    });
                    setCurrentFees(updatedFees);
                  }}
                  width={65}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div className="flex1">
                <InputLabel required>Activity Rate</InputLabel>
              </div>
              <div className="flex2">
                <div className="displayFlex">
                  <Select
                    sx={{ width: 200 }}
                    variant="outlined"
                    native
                    value={
                      !!currentFees?.length
                        ? currentFees[0].legalAidObj?.category
                        : ""
                    }
                    disabled={isFormDisabled}
                    onChange={(e) => {
                      let tempFees = currentFees?.map((fee) => {
                        return {
                          ...fee,
                          legalAidObj: {
                            ...fee?.legalAidObj,
                            category: e.target.value,
                          },
                        };
                      });
                      if (tempFees) {
                        refreshLists(tempFees[0]);
                      }
                      let updatedFees: IFeeDto[] = [];
                      tempFees?.forEach((fee) => {
                        updatedFees = updatedFees.concat(calcAmount(fee));
                      });
                      setCurrentFees(updatedFees);
                    }}
                    fullWidth
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {[...(group?.activityGroups || [])]
                      .sort(
                        (a, b) => (a.description > b.description && 1) || -1
                      )
                      .map((activity) => (
                        <option key={activity.code} value={activity.code}>
                          {activity.description}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="flex1">
                <InputLabel>Attended On</InputLabel>
              </div>
              <div className="flex2">
                <Select
                  sx={{ width: 165 }}
                  fullWidth
                  native
                  variant="outlined"
                  value={
                    currentFees &&
                    currentFees[0] &&
                    currentFees[0].legalAidObj.attendanceOn
                      ? currentFees[0].legalAidObj.attendanceOn
                      : ""
                  }
                  disabled={
                    isFormDisabled ||
                    !(
                      !!currentFees &&
                      !!currentFees.length &&
                      currentFees[0].legalAidObj.billingStageId === 2 &&
                      (matterDetails?.legalAidObj?.matterTypeId === 1 ||
                        matterDetails?.legalAidObj?.matterTypeId === 3)
                    )
                  }
                  onChange={(e) => {
                    let tempFees = currentFees?.map((fee) => {
                      return {
                        ...fee,
                        legalAidObj: {
                          ...fee?.legalAidObj,
                          attendanceOn: e.target.value,
                        },
                      };
                    });
                    setCurrentFees(tempFees);
                  }}
                >
                  <option key="" value="" className="emptyMenuItem"></option>
                  {billingItemRate?.attendanceOn?.map((attendance) => (
                    <option key={attendance.code} value={attendance.code}>
                      {attendance.description}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="flex2">
                <CustomCheckbox
                  label="Enhanced"
                  value={
                    currentFees ? currentFees[0]?.legalAidObj?.enhanced : false
                  }
                  disabled={isFormDisabled}
                  onChange={(value) => {
                    let tempFees = currentFees?.map((fee) => {
                      return {
                        ...fee,
                        legalAidObj: {
                          ...fee?.legalAidObj,
                          enhanced: value,
                        },
                      };
                    });
                    let updatedFees: IFeeDto[] = [];
                    tempFees?.forEach((fee) => {
                      updatedFees = updatedFees.concat(calcAmount(fee));
                    });
                    setCurrentFees(updatedFees);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "white" }}>
          <div className="multi-table">
            <div className="multi-cell" style={{ width: 150 }}>
              <Typography className="bold-text">ACTIVITY</Typography>
            </div>
            <div className="multi-cell flex1">
              <Typography className="bold-text">BILLING DESCRIPTION</Typography>
            </div>
            <div className="multi-cell" style={{ width: 120 }}>
              <Typography className="bold-text">TAX</Typography>
            </div>
            <div className="multi-cell" style={{ width: 50 }}>
              <Typography className="bold-text">RATE</Typography>
            </div>
            <div className="multi-cell" style={{ width: 65 }}>
              <Typography className="bold-text">UNITS</Typography>
            </div>
            <div className="multi-cell" style={{ width: 130 }}>
              <Typography className="bold-text">AMOUNT</Typography>
            </div>
            <div className="multi-cell" style={{ width: 65 }}>
              <Typography className="bold-text">INC. TAX</Typography>
            </div>
            <div className="multi-cell" style={{ width: 25 }}></div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              height: "calc(100vh - 315px)",
              overflowY: "auto",
            }}
          >
            {currentFees?.map((fee, index) => (
              <div key={fee.itemId}>
                <div className="displayFlex">
                  <div className="multi-cell" style={{ width: 150 }}>
                    <Select
                      fullWidth
                      native
                      variant="outlined"
                      value={
                        !!fee?.legalAidObj?.taskCodeId &&
                        fee?.legalAidObj?.taskCodeId !==
                          "00000000-0000-0000-0000-000000000000"
                          ? fee?.legalAidObj?.taskCodeId
                          : ""
                      }
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        let tempFee = {
                          ...fee,
                          legalAidObj: {
                            ...fee?.legalAidObj,
                            taskCodeId: e.target.value,
                            ccDestination: undefined,
                            ccExpenseReasonId: undefined,
                            ccDisbursementTypeId: undefined,
                            unit: null,
                            amount: undefined,
                          },
                        } as IFeeDto;

                        setFeeByIndex(calcAmount(tempFee), index);
                      }}
                    >
                      {(!fee?.legalAidObj?.taskCodeId ||
                        fee?.legalAidObj?.taskCodeId ===
                          "00000000-0000-0000-0000-000000000000") && (
                        <option
                          key="00000000-0000-0000-0000-000000000000"
                          value="00000000-0000-0000-0000-000000000000"
                          className="emptyMenuItem"
                        ></option>
                      )}

                      {getActivityList(fee).map((activity) => (
                        <option
                          key={activity.taskCodeId}
                          value={activity.taskCodeId}
                        >
                          {activity.billingDescription}
                        </option>
                      ))}
                    </Select>
                    {matterDetails?.legalAidObj?.matterTypeId === 2 &&
                      isExpensesVisible(fee) && (
                        <Select
                          sx={{ marginTop: "5px" }}
                          fullWidth
                          native
                          variant="outlined"
                          value={
                            !!fee?.legalAidObj?.ccExpenseReasonId
                              ? fee?.legalAidObj?.ccExpenseReasonId
                              : ""
                          }
                          disabled={isFormDisabled || !isExpensesEnabled(fee)}
                          onChange={(e) => {
                            let tempFee = {
                              ...fee,
                              legalAidObj: {
                                ...fee?.legalAidObj,
                                ccExpenseReasonId: Number(e.target.value),
                              },
                            } as IFeeDto;
                            setFeeByIndex(calcAmount(tempFee), index);
                          }}
                        >
                          <option
                            key={0}
                            value={0}
                            className="emptyMenuItem"
                          ></option>
                          {getExpenseReasons(fee).map((expenseReason) => (
                            <option
                              key={expenseReason.expensesReasonId}
                              value={expenseReason.expensesReasonId}
                            >
                              {expenseReason.reason}
                            </option>
                          ))}
                        </Select>
                      )}
                    {matterDetails?.legalAidObj?.matterTypeId === 2 &&
                      isDisbursmentsEnabled(fee) && (
                        <>
                          <Select
                            sx={{ marginTop: "5px" }}
                            fullWidth
                            native
                            variant="outlined"
                            value={
                              !!fee?.legalAidObj?.ccDisbursementTypeId
                                ? fee?.legalAidObj?.ccDisbursementTypeId
                                : ""
                            }
                            disabled={
                              isFormDisabled || !isDisbursmentsEnabled(fee)
                            }
                            onChange={(e) => {
                              let tempFee = {
                                ...fee,
                                legalAidObj: {
                                  ...fee?.legalAidObj,
                                  ccDisbursementTypeId: Number(e.target.value),
                                },
                              } as IFeeDto;
                              setFeeByIndex(calcAmount(tempFee), index);
                            }}
                          >
                            <option
                              key={0}
                              value={0}
                              className="emptyMenuItem"
                            ></option>
                            {[...(billingItemRate?.disbursementTypes || [])]
                              ?.sort(
                                (a, b) =>
                                  (a.disbursementTypeId >
                                    b.disbursementTypeId &&
                                    1) ||
                                  -1
                              )
                              .map((disbursementTypes) => (
                                <option
                                  key={disbursementTypes.disbursementTypeId}
                                  value={disbursementTypes.disbursementTypeId}
                                >
                                  {disbursementTypes.disbursementName}
                                </option>
                              ))}
                          </Select>
                        </>
                      )}
                  </div>
                  <div className="multi-cell flex1">
                    <ItemTextarea
                      value={fee?.description || ""}
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        let tempFee = {
                          ...fee,
                          description: value,
                        } as IFeeDto;

                        setFeeByIndex(calcAmount(tempFee), index);
                      }}
                    />
                    {matterDetails?.legalAidObj?.matterTypeId === 2 && (
                      <div style={{ marginTop: "5px" }}>
                        <ItemTextarea
                          value={fee?.legalAidObj.ccDestination || ""}
                          disabled={
                            isFormDisabled || !isDestinationEnabled(fee)
                          }
                          onUpdate={(value) => {
                            let tempFee = {
                              ...fee,
                              legalAidObj: {
                                ...fee.legalAidObj,
                                ccDestination: value,
                              },
                            } as IFeeDto;

                            setFeeByIndex(calcAmount(tempFee), index);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="multi-cell" style={{ width: 120 }}>
                    <Select
                      variant="outlined"
                      native
                      value={fee?.taxCodeId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        let tempFee = {
                          ...fee,
                          taxCodeId: e.target.value,
                        } as IFeeDto;

                        setFeeByIndex(calcAmount(tempFee), index);
                      }}
                      fullWidth
                    >
                      <option
                        key=""
                        value=""
                        className="emptyMenuItem"
                      ></option>
                      {taxCodes?.map((taxCode) => (
                        <option
                          key={taxCode.taxCodeId}
                          value={taxCode.taxCodeId}
                        >
                          {taxCode.taxCode}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="multi-cell" style={{ width: 50 }}>
                    {!getCurrentTask(fee)?.hideUnits && (
                      <Typography align="right" style={{ paddingTop: 4 }}>
                        {!!fee?.legalAidObj?.taskCodeId &&
                        fee?.legalAidObj?.taskCodeId !==
                          "00000000-0000-0000-0000-000000000000"
                          ? `£${(fee?.rate || 0).toFixed(2)}`
                          : ""}
                      </Typography>
                    )}
                  </div>
                  <div className="multi-cell" style={{ width: 65 }}>
                    {!getCurrentTask(fee)?.hideUnits && (
                      <NumberInput
                        value={fee?.unit}
                        disabled={isFormDisabled}
                        decimalScale={0}
                        updater={(value) => {
                          let tempFee = {
                            ...fee,
                            unit: value,
                          } as IFeeDto;
                          setFeeByIndex(calcAmount(tempFee), index);
                        }}
                        width={65}
                      />
                    )}
                  </div>
                  <div className="multi-cell" style={{ width: 130 }}>
                    <CurrencyInput
                      disabled={!getCurrentTask(fee)?.hideUnits}
                      value={fee?.amount || 0}
                      updater={(value) => {
                        let tempFee = {
                          ...fee,
                          amount: Number(value),
                        } as IFeeDto;
                        setFeeByIndex(calcAmount(tempFee), index);
                      }}
                      prefix="£"
                    />
                  </div>
                  <div className="multi-cell" style={{ width: 65 }}>
                    <div style={{ margin: "0px 25px" }}>
                      <CustomCheckbox
                        value={fee?.incTax || false}
                        disabled={isFormDisabled}
                        onChange={(value) => {
                          let tempFee = {
                            ...fee,
                            incTax: value,
                          } as IFeeDto;
                          setFeeByIndex(calcAmount(tempFee), index);
                        }}
                      />
                    </div>
                  </div>
                  <div className="multi-cell" style={{ width: 25 }}>
                    <Tooltip title={currentFees?.length === 1 ? "" : "Delete"}>
                      <span>
                        <IconButton
                          aria-label="delete"
                          disabled={currentFees?.length === 1}
                          onClick={() => {
                            setCurrentIndex(index);
                          }}
                          disableFocusRipple
                          disableRipple
                          sx={{
                            padding: 0,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              className="flex1"
              style={{
                borderTop: "1px solid #bbbfc6",
              }}
            >
              {!multi && (
                <Button
                  style={{ margin: "5px 15px" }}
                  variant="outlined"
                  onClick={() => addItem()}
                  disabled={multi === "true"}
                  color="secondary"
                  disableRipple
                >
                  New Item
                </Button>
              )}
            </div>
            <div
              className="flex1"
              style={{
                borderTop: "2px solid #f69139",
                backgroundColor: "#fef8f2",
                padding: "5px 15px",
              }}
            >
              <div className="displayFlex" style={{ paddingTop: 5 }}>
                <Typography
                  className="bold-text"
                  style={{ fontSize: "13.5px" }}
                >
                  TOTAL
                </Typography>
                <div className="flex1" />
                <Typography
                  className="bold-text"
                  style={{ fontSize: "13.5px" }}
                >
                  £{(calcTotal() || 0).toFixed(2)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (!!currentFees?.length && !saving) {
            let errors: string[] = ["The following field(s) are required:"];

            validate(
              getCommonRequiredFields(currentFees[0]),
              [],
              (currentFeeErrors) => (errors = errors.concat(currentFeeErrors)),
              true
            );
            currentFees.forEach((fee, index) => {
              validate(
                getRequiredFields(fee, index),
                [],
                (currentFeeErrors) =>
                  (errors = errors.concat(currentFeeErrors)),
                true
              );
            });
            if (
              !errors.filter(
                (e) => e !== "The following field(s) are required:"
              ).length
            ) {
              dispatch(
                saveFees({
                  fees: currentFees,
                  feeId: multiFeeId || undefined,
                  multi: multi === "true" ? true : false,
                })
              )
                .unwrap()
                .then(() => close(sdkApi))
                .catch((e) => console.warn(e));
            } else {
              dispatch(setValidationErrors(errors));
            }
          }
        }}
      />
      <CustomDialog
        isOpen={!!warningAcknowledgment}
        title={warningAcknowledgment?.messageHeader || ""}
        onClose={() => setWarningAcknowledgment(undefined)}
        showWarningIcon
        actions={
          warningAcknowledgment && warningAcknowledgment?.code >= 1000 ? (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  const tempFees = (currentFees || [])?.map((f) => {
                    return {
                      ...f,
                      warningAcknowledgments: [
                        ...(f?.warningAcknowledgments || []),
                        warningAcknowledgment?.code || "",
                      ],
                    } as IFeeDto;
                  });
                  dispatch(
                    saveFees({
                      fees: tempFees,
                      feeId: multiFeeId || undefined,
                      multi: multi === "true" ? true : false,
                    })
                  ).then((action) => {
                    if (!!action.payload.length && !!action.payload[0].code) {
                      setWarningAcknowledgment(action.payload[0]);
                    } else {
                      setWarningAcknowledgment(undefined);
                      close(sdkApi);
                    }
                  });
                }}
                style={{ marginRight: 5 }}
                disableRipple
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setWarningAcknowledgment(undefined);
                }}
                color="secondary"
                disableRipple
              >
                No
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              onClick={() => {
                setWarningAcknowledgment(undefined);
              }}
              color="secondary"
              disableRipple
            >
              OK
            </Button>
          )
        }
        content={<Typography>{warningAcknowledgment?.message}</Typography>}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
      <CustomDialog
        isOpen={currentIndex !== undefined}
        title="Alert"
        onClose={() => setCurrentIndex(undefined)}
        showWarningIcon
        actions={
          <>
            <Button
              variant="contained"
              onClick={() => {
                if (currentIndex !== undefined) {
                  deleteFee(currentIndex);
                  setCurrentIndex(undefined);
                }
              }}
              disableRipple
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setCurrentIndex(undefined);
              }}
              color="secondary"
              disableRipple
            >
              No
            </Button>
          </>
        }
        content={<Typography style={{ minWidth: 295 }}>Delete now?</Typography>}
      />
    </>
  );
};

interface IItemTextareaProps {
  value?: string;
  onUpdate: (value: string) => void;
  disabled: boolean;
}

const ItemTextarea: FC<IItemTextareaProps> = ({
  value,
  onUpdate,
  disabled,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const initialised = useRef(false);

  useEffect(() => {
    if (!initialised.current && value) {
      setCurrentValue(value);
    }
  }, [value, initialised]);

  return (
    <TextField
      variant="outlined"
      value={currentValue}
      className="item-desc"
      disabled={disabled}
      onBlur={(e) => {
        onUpdate(e.target.value);
      }}
      onChange={(e) => {
        setCurrentValue(e.target.value);
      }}
      rows={1}
      fullWidth
      multiline
    />
  );
};

export default MultiFee;
