import { FC } from "react";
import { Button, Typography } from "@mui/material";
import { RootState } from "../../app/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CustomDialog from "./custom-dialog";
import { setWarningAcknowledgment } from "../../app/store/app/appSlice";

const WarningAcknowledgmentDialog: FC = () => {
  const dispatch = useAppDispatch();

  const warningAcknowledgment = useAppSelector(
    (state: RootState) => state.app.warningAcknowledgment
  );
  const errorAction = useAppSelector(
    (state: RootState) => state.app.errorAction
  );

  return (
    <CustomDialog
      isOpen={!!warningAcknowledgment}
      title="Confirm"
      onClose={() => dispatch(setWarningAcknowledgment(undefined))}
      showWarningIcon
      actions={
        warningAcknowledgment && warningAcknowledgment?.code >= 1000 ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                if (errorAction) {
                  errorAction();
                }
              }}
              style={{ marginRight: 5 }}
              disableRipple
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(setWarningAcknowledgment(undefined));
              }}
              color="secondary"
              disableRipple
            >
              No
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(setWarningAcknowledgment(undefined));
            }}
            color="secondary"
            disableRipple
          >
            OK
          </Button>
        )
      }
      content={
        <>
          <div className="displayFlex">
            <Typography>{warningAcknowledgment?.messageHeader}</Typography>
          </div>
          <div className="displayFlex">
            <Typography>{warningAcknowledgment?.message}</Typography>
          </div>
        </>
      }
    />
  );
};

export default WarningAcknowledgmentDialog;
