import { Api } from "@leapdev/leap-host";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../app/store";
import { useAppInit } from "../shared/hooks/use-app-init";
import Loader from "./components/loader";

const RedirectComponent: FC = () => {
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkInitialisedState = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const target = searchParams.get("target");
  const billingstageid = searchParams.get("billingstageid");
  const isWeb = searchParams.get("isWeb");
  const timeelapsed = Number(searchParams.get("timeElapsed"));

  const env = searchParams.get("env");
  const region = searchParams.get("region");
  const appSessionId = searchParams.get("appSessionId");
  const trustedApp = searchParams.get("trustedApp");

  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);

  useAppInit(() => {});

  const constructSetAsLegalAidUrl = (
    additionalParams: string,
    sdkApi?: Api
  ) => {
    if (!!sdkApi) {
      const leapContext = sdkApi.leapContext;
      const matterId = leapContext.context.matterId;
      let feeTransactions: any[];

      let ids = [];
      let transactions = JSON.stringify(leapContext.context.transactions);
      feeTransactions = JSON.parse(transactions);

      if (feeTransactions.length > 1) {
        for (let trans of feeTransactions) {
          if (typeof trans.timeFeeId !== "undefined") {
            ids.push(trans.timeFeeId);
          } else if (typeof trans.costRecoveryId !== "undefined") {
            ids.push(trans.costRecoveryId);
          }
        }

        let idsAsJson = JSON.stringify(ids);
        let feeId = btoa(idsAsJson);

        return `/fees/multi?matterid=${matterId}&feesid=${feeId}&multi=true&${additionalParams}`;
      } else {
        let feeId;
        if (typeof feeTransactions[0].timeFeeId !== "undefined") {
          feeId = feeTransactions[0].timeFeeId;
        } else if (typeof feeTransactions[0].costRecoveryId !== "undefined") {
          feeId = feeTransactions[0].costRecoveryId;
        }

        return `/fees/multi?matterid=${matterId}&feeid=${feeId}&${additionalParams}`;
      }
    }
  };

  useEffect(() => {
    if (
      sdkApi &&
      sdkApi.leapContext.context &&
      !!appInit &&
      !!sdkInitialisedState
    ) {
      const leapContext = sdkApi.leapContext;
      const matterId = leapContext.context.matterId;
      const laMatterTypeName = appInit.laMatterTypeName;
      const laMatterTypeId = appInit.laMatterTypeId;

      let url = "";
      let isWebParam = isWeb || false;
      const additionalParams = `isWeb=${isWebParam}&env=${env}&region=${region}&appSessionId=${appSessionId}&trustedApp=${trustedApp}`;

      switch (target) {
        case "bulkreceipting": {
          url = `/bulkreceipting?${additionalParams}`;
          break;
        }
        case "poa": {
          url = `/poa?billingstageid=${billingstageid}&matterid=${matterId}&isWeb=${isWebParam}&env=${env}&region=${region}&appSessionId=${appSessionId}`;
          break;
        }
        case "matter": {
          const billingStageParams = !!billingstageid
            ? `&billingstageid=${billingstageid}`
            : "";
          url = `/matter/${laMatterTypeName}?matterid=${matterId}${billingStageParams}&${additionalParams}`;
          break;
        }
        case "newbillable": {
          url = `/fees/multi?matterid=${matterId}&timeelapsed=${timeelapsed}&${additionalParams}`;
          break;
        }
        case "counsel": {
          url = `/fees/counsel?matterid=${matterId}&${additionalParams}`;
          break;
        }
        case "expert": {
          url = `/fees/expert?matterid=${matterId}&${additionalParams}`;
          break;
        }
        case "bolton": {
          url = `/fees/bolton/${laMatterTypeId}?matterid=${matterId}&${additionalParams}`;
          break;
        }
        case "fas": {
          url = `/fees/fas?matterid=${matterId}&${additionalParams}`;
          break;
        }
        case "cpgfs": {
          url = `/fees/cpgfs?matterid=${matterId}&${additionalParams}`;
          break;
        }
        case "pflrs": {
          url = `/fees/pflrs?matterid=${matterId}&${additionalParams}`;
          break;
        }
        case "submission": {
          url = `/submission?${additionalParams}`;
          break;
        }
        case "invoice": {
          url = `/invoice/${laMatterTypeName}?billingstageid=${billingstageid}&matterid=${matterId}&${additionalParams}`;
          break;
        }
        case "setaslegalaid": {
          url = constructSetAsLegalAidUrl(additionalParams, sdkApi) || "";
          break;
        }
        default: {
          //statements;
          break;
        }
      }
      console.warn("url", url);
      navigate(url);
    }
  });

  return <Loader />;
};

export default RedirectComponent;
