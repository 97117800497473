export enum filterMonth {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
}

export enum filterSubmission {
  Submitted = 1,
  Unsubmitted = 2,
}

export enum filterYear {
  Current = new Date().getFullYear(),
  LastYear = new Date().getFullYear() - 1,
  YearBeforeLast = new Date().getFullYear() - 2,
}

export const getCurrentMonthFilter = () => {
  switch (new Date().getMonth() - 1) {
    case 0:
      return filterMonth.Jan;
    case 1:
      return filterMonth.Feb;
    case 2:
      return filterMonth.Mar;
    case 3:
      return filterMonth.Apr;
    case 4:
      return filterMonth.May;
    case 5:
      return filterMonth.Jun;
    case 6:
      return filterMonth.Jul;
    case 7:
      return filterMonth.Aug;
    case 8:
      return filterMonth.Sep;
    case 9:
      return filterMonth.Oct;
    case 10:
      return filterMonth.Nov;
    case 11:
      return filterMonth.Dec;
    default:
      return filterMonth.Jan;
  }
};

export function wasLastYearsInvoice(
  billedYear: number,
  selectYearValue: filterYear
): boolean {
  let include = false;
  const thisYear = new Date().getFullYear();

  if (selectYearValue === filterYear.Current) {
    include = billedYear < thisYear;
  } else if (selectYearValue === filterYear.LastYear) {
    include = billedYear < thisYear - 1;
  } else if (selectYearValue === filterYear.YearBeforeLast) {
    include = billedYear < thisYear - 2;
  } else {
    include = billedYear < thisYear - 3;
  }

  return include;
}
