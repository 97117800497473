import { createTheme } from "@mui/material/styles";

const lineHeight = 1.4285;
const fontSize = 13;
const fontWeight = 400;
const mainColor = "#1e365e";
const hoverColor = "#d7ecf6";

export const theme = createTheme({
  palette: { primary: { main: mainColor }, secondary: { main: "#ffffff" } },
  typography: {
    fontFamily: "Segoe UI",
    fontSize: fontSize,
    body1: { color: "#000000" },
  },
  components: {
    MuiTypography: {
      variants: [
        { props: { variant: "body1" }, style: { fontSize: fontSize } },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { color: "secondary" },
          style: {
            backgroundColor: "#ffffff",
            color: "#0060aa",
            borderColor: mainColor,
            ":hover": {
              borderColor: mainColor,
              backgroundColor: hoverColor,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: "none",
          minWidth: 130,
          padding: 7,
          borderRadius: 0,
          textTransform: "none",
          fontWeight: fontWeight,
          fontSize: fontSize,
          lineHeight: lineHeight,
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiIconButton: { styleOverrides: { root: { padding: 4 } } },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: fontSize,
          "& .MuiInputLabel-asterisk": { color: "#0060aa" },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: { root: { fontSize: fontSize, padding: "0px 4px" } },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          //  maxHeight: "350px !important"
        },
      },
    },
    MuiList: { styleOverrides: { root: { padding: 0 } } },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: 0 },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: { multiline: true },
          style: { padding: "0px !important" },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: fontSize,
          color: "#000",
          backgroundColor: "#ffffff",
          "& .Mui-disabled": {
            backgroundColor: "#f3f3f3",
            color: "#000",
            WebkitTextFillColor: "#000 !important",
          },
        },
        input: { padding: "4px !important" },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            fontSize: fontSize,
            // paddingRight: "15px !important",
            "&.MuiInputBase-root.Mui-disabled": {
              backgroundColor: "#f3f3f3",
            },
            "& .MuiNativeSelect-select": {
              paddingRight: "25px !important",
            },
          },
        },
      ],
      styleOverrides: {
        // select: { padding: 4, lineHeight: lineHeight },
      },
    },
    MuiDialog: {
      variants: [
        {
          props: { maxWidth: "sm" },
          style: { ".MuiPaper-root ": { maxWidth: 400 } },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: { padding: 5, fontSize: fontSize },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: { padding: 5 },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: { borderTop: "1px solid #E6E6E6", backgroundColor: "#f0f1f2" },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          "&.Mui-focusVisible": {
            "& .MuiSvgIcon-root": { backgroundColor: "#cde6f7 !important" },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#ffffff",
          border: "1px solid #000000",
          color: "#000000",
          borderRadius: 0,
        },
      },
    },
  },
});
