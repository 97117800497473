import { InputAdornment, OutlinedInput, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
  disabled?: boolean;
}

const NumberFormatCustom = forwardRef((props: NumberFormatCustomProps, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      allowNegative={false}
      prefix={(props as any).value < 0 ? "(" : undefined}
      suffix={(props as any).value < 0 ? ")" : undefined}
      thousandSeparator
      fixedDecimalScale
    />
  );
});

interface ICurrencyInputProps {
  value?: number;
  updater?: (value: any) => void;
  decimalScale?: number;
  prefix?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
}

const CurrencyInput: React.FC<ICurrencyInputProps> = ({
  value,
  prefix,
  updater,
  decimalScale,
  disabled,
  placeholder,
  autoFocus,
}) => {
  return (
    <OutlinedInput
      value={value}
      inputComponent={NumberFormatCustom as any}
      disabled={disabled}
      autoFocus={autoFocus}
      onBlur={(event) => {
        if (!!updater) {
          updater(Number(event.target.value.replaceAll(",", "")));
        }
      }}
      placeholder={placeholder}
      sx={{
        width: 130,
        "& .MuiInputBase-input": {
          color: "#000",
          textAlign: "end",
          borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
        },
        "& .MuiOutlinedInput-notchedOutline ": {
          border: "1px solid rgba(0, 0, 0, 0.23)",
        },
        "&.Mui-disabled  .MuiOutlinedInput-notchedOutline ": {
          border: "1px solid rgba(0, 0, 0, 0.23)",
        },
        "&.MuiOutlinedInput-root": {
          paddingLeft: "10px",
        },
      }}
      inputProps={{ decimalScale: !!decimalScale ? decimalScale : 2 }}
      startAdornment={
        <InputAdornment position="start">
          <Typography>{prefix ?? ""}</Typography>
        </InputAdornment>
      }
    />
  );
};

export default CurrencyInput;
