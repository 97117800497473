import { SvgIcon } from "@mui/material";

function CheckedCheckboxIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        backgroundColor: "white",
        height: 13,
        width: 13,
      }}
    >
      <path d="M11 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597zm11-15v20h-20v-20h20zm2-2h-24v24h24v-24z" />
    </SvgIcon>
  );
}

export default CheckedCheckboxIcon;
