import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FC } from "react";
import LeapIcon from "../../shared/images/leap-icon";

const infoIcon = require("../../shared/images/info-blue.png");
const warningIcon = require("../../shared/images/warning.png");

interface IDialogProps {
  title: string;
  isOpen: boolean;
  closeIcon?: boolean;
  showInfoIcon?: boolean;
  showWarningIcon?: boolean;
  content: React.ReactNode;
  actions: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  minWidth?: number;
  maxHeight?: number;
  height?: number;
  hideOverflow?: boolean;
  noPadding?: boolean;
  onClose?: () => void;
}

const CustomDialog: FC<IDialogProps> = ({
  title,
  isOpen,
  closeIcon,
  showInfoIcon,
  showWarningIcon,
  content,
  actions,
  maxWidth,
  minWidth,
  maxHeight,
  height,
  hideOverflow,
  noPadding,
  onClose,
}) => {
  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={maxWidth ? maxWidth : "sm"}
        PaperProps={{
          style: {
            height: height ? `${height}px !important` : undefined,
            minWidth: minWidth ? minWidth : undefined,
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: "#f0f1f2" }}>
          <div className="displayFlex">
            <div
              style={{
                display: "grid",
                verticalAlign: "center",
                paddingRight: 5,
              }}
            >
              <LeapIcon />
            </div>
            <Typography style={{ color: "#acacac" }}>{title}</Typography>
            {closeIcon && (
              <IconButton
                aria-label="close"
                onClick={!!onClose ? onClose : () => {}}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  borderRadius: 0,
                  color: (theme) => theme.palette.grey[500],
                  ":hover": {
                    color: "#fff",
                    backgroundColor: "#c42b1c",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            overflowY: !hideOverflow ? "auto" : "hidden",
          }}
          sx={!!noPadding ? { "&.MuiDialogContent-root": { padding: 0 } } : {}}
        >
          <div className="displayFlex" style={{ minHeight: 100 }}>
            {!!showWarningIcon ? (
              <div
                style={{
                  paddingLeft: 15,
                  paddingRight: 20,
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <img alt="warning" src={warningIcon} />
              </div>
            ) : (
              !!showInfoIcon && (
                <div
                  style={{
                    paddingLeft: 15,
                    paddingRight: 20,
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <img alt="information" src={infoIcon} />
                </div>
              )
            )}
            <div
              className="flex1"
              style={
                !!noPadding
                  ? {}
                  : {
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: 15,
                      marginRight: 15,
                    }
              }
            >
              {content}
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid rgb(169, 169, 169)" }}>
          {actions}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialog;
