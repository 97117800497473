import { FC, Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AgfsFee from "../features/fees/fee-agfs";
import BulkReceiptingDetails from "../features/bulkreceipting/bulkreceipting";
import CivilMatterDetails from "../features/matter-details/matter-civil";
import CounselFee from "../features/fees/fee-counsel";
import CpgfsFee from "../features/fees/fee-cpgfs";
import CriminalMatterDetails from "../features/matter-details/matter-criminal";
import CrownMatterDetails from "../features/matter-details/matter-crown";
import DebtMatterDetails from "../features/matter-details/matter-debt";
import ExpertFee from "../features/fees/fee-expert";
import FamilyMatterDetails from "../features/matter-details/matter-family";
import FasFee from "../features/fees/fee-fas";
import FixedFee from "../features/fees/fee-fixed";
import HousingMatterDetails from "../features/matter-details/matter-housing";
import ImmigrationFee from "../features/fees/fee-immigration";
import ImmigrationMatterDetails from "../features/matter-details/matter-immigration";
import InvoiceAgfsDetails from "../features/invoice/invoice-agfs";
import InvoiceCivilDetails from "../features/invoice/invoice-civil";
import InvoiceCrimeDetails from "../features/invoice/invoice-crime";
import InvoiceCrownDetails from "../features/invoice/invoice-crown";
import InvoiceLgfsDetails from "../features/invoice/invoice-lgfs";
import MediationMatterDetails from "../features/matter-details/matter-mediation";
import MentalHealthFee from "../features/fees/fee-mental-health";
import MentalHealthMatterDetails from "../features/matter-details/matter-mh";
import MultiFee from "../features/fees/fee-multi";
import PflrsFee from "../features/fees/fee-pflrs";
import RedirectComponent from "../features/redirect";
import SubmissionDetails from "../features/submission/submission";
import InvoicePOADetails from "../features/invoice/invoice-poa";
import PoaFixedFee from "../features/fees/fee-poa-fixed";

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/fees">
            <Route path="multi" element={<MultiFee />} />
            <Route path="fas" element={<FasFee />} />
            <Route path="cpgfs" element={<CpgfsFee />} />
            <Route path="pflrs" element={<PflrsFee />} />
            <Route path="bolton/12" element={<ImmigrationFee />} />
            <Route path="bolton/11" element={<MentalHealthFee />} />
            <Route path="bolton/2" element={<AgfsFee />} />
            <Route path="imm-bolt-on" element={<ImmigrationFee />} />
            <Route path="mh-bolt-on" element={<MentalHealthFee />} />
            <Route path="agfs-bolt-on" element={<AgfsFee />} />
            <Route path="counsel" element={<CounselFee />} />
            <Route path="expert" element={<ExpertFee />} />
            <Route path="invoice-fixed-fee" element={<FixedFee />} />
            <Route path="poa-fixed-fee" element={<PoaFixedFee />} />
          </Route>
          <Route path="/invoice">
            <Route path="agfs" element={<InvoiceAgfsDetails />} />
            <Route path="civil" element={<InvoiceCivilDetails />} />
            <Route path="crime" element={<InvoiceCrimeDetails />} />
            <Route path="crown" element={<InvoiceCrownDetails />} />
            <Route path="family" element={<InvoiceCivilDetails />} />
            <Route path="imm" element={<InvoiceCivilDetails />} />
            <Route path="lf1" element={<InvoiceLgfsDetails />} />
            <Route path="mh" element={<InvoiceCivilDetails />} />
          </Route>
          <Route path="/matter">
            <Route path="civil" element={<CivilMatterDetails />} />
            <Route path="crime" element={<CriminalMatterDetails />} />
            <Route path="crown" element={<CrownMatterDetails />} />
            <Route path="debt" element={<DebtMatterDetails />} />
            <Route path="family" element={<FamilyMatterDetails />} />
            <Route path="housing" element={<HousingMatterDetails />} />
            <Route path="imm" element={<ImmigrationMatterDetails />} />
            <Route path="mediation" element={<MediationMatterDetails />} />
            <Route path="mh" element={<MentalHealthMatterDetails />} />
          </Route>
          <Route path="/redirect" element={<RedirectComponent />} />
          <Route path="/submission" element={<SubmissionDetails />} />
          <Route path="/bulkreceipting" element={<BulkReceiptingDetails />} />
          <Route path="/poa" element={<InvoicePOADetails />} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
};
export default AppRouter;
