import { Typography } from "@mui/material";
import { FC } from "react";

interface IReversalInfoProps {
  isReversal?: boolean;
}

const ReversalInfo: FC<IReversalInfoProps> = ({ isReversal }) => {
  const infoSmallIcon = require("../../shared/images/info-small.png");

  return (
    <>
      {isReversal && (
        <div
          className="displayFlex"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <img alt="warning" src={infoSmallIcon} height={12} width={12} />
          <Typography style={{ paddingLeft: 3 }}>
            This transaction has been reversed or is a reversal.
          </Typography>
        </div>
      )}
    </>
  );
};

export default ReversalInfo;
