import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { close } from "../../../shared/utils/sdk-utils";
import { defaultHeaders } from "../../../shared/utils/request-utils";
import { environment } from "../../../shared/environments";
import {
  filterYear,
  getCurrentMonthFilter,
} from "../../../features/submission/filters";
import {
  IBulkReceiptingFilterDto,
  IBulkReceiptingPostingDto,
  IInvoiceReceiptingItemDto,
  invoiceStatusUnpaid,
  IOfficeReceiptCreateInitialisationDataDto,
} from "../../../shared/dto/bukreceipting/bulkreceipting.dto";
import { FirmBranch } from "../../../shared/dto/common-list.dto";
import { RootState } from "..";
import {
  setErrors,
  setLoader,
  setValidationErrors,
  setWarningAcknowledgment,
} from "../app/appSlice";

export interface BulkReceiptingState {
  branchList: FirmBranch[];
  filter: IBulkReceiptingFilterDto;
  init: IOfficeReceiptCreateInitialisationDataDto;
  invoiceItems: IInvoiceReceiptingItemDto[];
  loading: boolean;
}

const initialState: BulkReceiptingState = {
  branchList: [],
  filter: {
    bankAccount: "All",
    branch: "All",
    month: getCurrentMonthFilter(),
    status: invoiceStatusUnpaid,
    year: filterYear.Current,
  },
  init: {
    bankAccounts: [],
    lastTranNum: 0,
    preference: {
      officeBankAccountGUID: "",
      payTypeDetailsEnforce: false,
      rapidPayVerificationStatus: 0,
    },
    paymentTypeList: [],
  },
  invoiceItems: [],
  loading: false,
};

export const getBranchList = createAsyncThunk(
  "getBranchList",
  async (_, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/branches`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getBulkReceiptingInit = createAsyncThunk(
  "getBulkReceiptingInit",
  async (_, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/bulkreceipting/init`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getInvoiceList = createAsyncThunk(
  "getInvoiceList",
  async (filter: IBulkReceiptingFilterDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/bulkreceipting?month=${
          filter.month
        }&year=${filter.year}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const postBulkReceipting = createAsyncThunk(
  "postBulkReceipting",
  async (
    data: {
      bulkReceiptingPosting: IBulkReceiptingPostingDto;
      onSuccessfulPostCallback: any;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.post(
        `${environment().backendUrl}/bulkreceipting?createReceipt=${
          data.bulkReceiptingPosting.createReceipt
        }`,
        data.bulkReceiptingPosting.receipting,
        await defaultHeaders(sdkApi)
      );
      return res;
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));

      if (errorResponse.response.data && errorResponse.response.data.code) {
        thunkApi.dispatch(
          setWarningAcknowledgment(errorResponse.response.data, () => {
            let tempBulkReceiptingPosting = data.bulkReceiptingPosting;
            thunkApi.dispatch(setWarningAcknowledgment(undefined));
            thunkApi
              .dispatch(
                postBulkReceipting({
                  bulkReceiptingPosting: {
                    ...tempBulkReceiptingPosting,
                    receipting: {
                      ...tempBulkReceiptingPosting.receipting,
                      warningAcknowledgments: [
                        ...(tempBulkReceiptingPosting.receipting
                          ?.warningAcknowledgments || []),
                        errorResponse.response.data.code || "",
                      ],
                    },
                  },
                  onSuccessfulPostCallback: data.onSuccessfulPostCallback,
                })
              )
              .then((response: any) => {
                if (response.payload && response.payload.status === 200) {
                  data.onSuccessfulPostCallback();
                }
              });
          })
        );
      } else {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc])
        );
      }

      return thunkApi.rejectWithValue(errorResponse.response.data);
    }
  }
);

export const bulkReceiptingSlice = createSlice({
  name: "bulkReceipting",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setInvoiceItems: (state, action) => {
      state.invoiceItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranchList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBranchList.fulfilled, (state, action) => {
        state.branchList = action.payload;

        if (state.branchList?.length === 1) {
          let firmId = state.branchList[0].__id;
          state.filter = {
            ...state.filter,
            branch: firmId,
          };
        }

        state.loading = false;
      })
      .addCase(getBranchList.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBulkReceiptingInit.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBulkReceiptingInit.fulfilled, (state, action) => {
        state.init = action.payload;
        state.loading = false;
      })
      .addCase(getBulkReceiptingInit.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getInvoiceList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceList.fulfilled, (state, action) => {
        state.invoiceItems = action.payload;
        state.loading = false;
      })
      .addCase(getInvoiceList.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(postBulkReceipting.pending, (state) => {
        state.loading = true;
      })
      .addCase(postBulkReceipting.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postBulkReceipting.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { loading, setFilter, setInvoiceItems } =
  bulkReceiptingSlice.actions;

export default bulkReceiptingSlice.reducer;
