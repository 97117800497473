import {
  Button,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  checkDSCC,
  getCrimeLists,
  getMatterDetails,
  putDefinables,
} from "../../app/store/matter/matterSlice";
import {
  IMatterDetailsDto,
  MatterLegalAidObj,
} from "../../shared/dto/matter-details.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  getMatterRequiredFields,
  validate,
} from "../../shared/utils/validation-utils";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import MatterComponent from "../components/matter-component";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";

const CriminalMatterDetails: FC = () => {
  const checkDSCCNumber = useRef<string>("");
  const closeHandlerRegistered = useRef(false);
  const initialMatterDetails = useRef<IMatterDetailsDto | undefined>(undefined);
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const matterRef = useRef<IMatterDetailsDto | undefined>(undefined);
  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const matterLists = useSelector(
    (state: RootState) => state.matter.matterLists
  );
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.matter.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [dsccWarning, setDsccWarning] = useState<string | undefined>(undefined);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(matterRef.current) !==
      JSON.stringify(initialMatterDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    matterRef.current = currentMatterDetails;
  }, [currentMatterDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(true))
        .unwrap()
        .then(() => {
          dispatch(getCrimeLists());
        })
        .catch(() => {});
      dispatch(getUsers());
    }
  }, [dispatch]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData]);

  useEffect(() => {
    if (!!matterDetails && !!matterLists && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      initialMatterDetails.current = matterDetails;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Legal Aid Details"
      );
    }
  }, [matterDetails, matterLists, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  return (
    <>
      <TopBar helpUrl="https://community.leap.co.uk/s/article/Creating-a-Legal-Aid-Billing-Matter-Criminal" />
      <div className="main">
        <div className="mainsection">
          <div className="halfWidth">
            <MatterComponent
              matterDetails={currentMatterDetails}
              onUpdate={(matterDetails) =>
                setCurrentMatterDetails(matterDetails)
              }
              getLists={(tableNo) => {
                dispatch(getCrimeLists(tableNo));
              }}
              branchList={matterLists?.branchList}
              rateList={matterLists?.rateList}
            />

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Offence</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.offence || ""}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        offence: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>DSCC</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.criminal?.dsccNumber ||
                    ""
                  }
                  placeholder="YYMMNNNNNA"
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        criminal: {
                          ...currentMatterDetails?.legalAidObj?.criminal,
                          dsccNumber: e.target.value,
                        },
                      } as MatterLegalAidObj,
                    });
                  }}
                  onBlur={(e) => {
                    if (
                      e.target.value &&
                      e.target.value !== checkDSCCNumber.current
                    )
                      dispatch(
                        checkDSCC({
                          dscc: e.target.value || "",
                        })
                      ).then((payloadAction) => {
                        if (payloadAction.type === "checkDSCC/rejected")
                          setDsccWarning(
                            (payloadAction.payload as any).errorDesc
                          );
                        else checkDSCCNumber.current = e.target.value;
                      });
                  }}
                  onFocus={(e) => {
                    if (e.target.value)
                      checkDSCCNumber.current = e.target.value;
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Custody No.</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.criminal
                      ?.custodyNumber || ""
                  }
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        criminal: {
                          ...currentMatterDetails?.legalAidObj?.criminal,
                          custodyNumber: e.target.value,
                        },
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>USN</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.criminal?.usn || ""}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        criminal: {
                          ...currentMatterDetails?.legalAidObj?.criminal,
                          usn: e.target.value,
                        },
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>
          </div>
          <div className="halfWidthWithPadding">
            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Police Station</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    value={
                      currentMatterDetails?.legalAidObj?.criminal
                        ?.policeStation || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          criminal: {
                            ...currentMatterDetails?.legalAidObj?.criminal,
                            policeStation: e.target.value,
                            policeStationSchemeId:
                              matterLists?.policeStationList?.find(
                                (p) =>
                                  `${p.description} [${p.code}]` ===
                                  e.target.value
                              )?.schemeId,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(matterLists?.policeStationList || []).map((station) => (
                      <option
                        key={station.code}
                        value={`${station.description} [${station.code}]`}
                      >
                        {`${station.description} [${station.code}]`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Court</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    fullWidth
                    native
                    variant="outlined"
                    value={currentMatterDetails?.legalAidObj?.court || ""}
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          court: e.target.value,
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(matterLists?.courtList || []).map((court) => (
                      <option
                        key={court.code}
                        value={`${court.description} [${court.code}]`}
                      >
                        {`${court.description} [${court.code}]`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>CPS URN</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.criminal?.cpsUrn || ""
                  }
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        criminal: {
                          ...currentMatterDetails?.legalAidObj?.criminal,
                          cpsUrn: e.target.value,
                        },
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Prison</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    fullWidth
                    native
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.criminal?.prison || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          criminal: {
                            ...currentMatterDetails?.legalAidObj?.criminal,
                            prison: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {matterLists?.prisonList?.map((prison) => (
                      <option
                        key={`${prison.description} [${prison.code}]`}
                        value={`${prison.description} [${prison.code}]`}
                      >
                        {`${prison.description} [${prison.code}]`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Prison Law Auth No.</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.authorityNumber || ""
                  }
                  placeholder="NNNNNNNNNN"
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        authorityNumber: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Rep Order No.</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.laOrderNumber || ""}
                  placeholder="NNNNNNNNNN"
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        laOrderNumber: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Rep Order Date</InputLabel>
              </div>
              <div className="flex7">
                <LocalDatePicker
                  value={
                    currentMatterDetails?.legalAidObj?.laOrderDate
                      ? currentMatterDetails?.legalAidObj?.laOrderDate
                      : null
                  }
                  onUpdate={(value) => {
                    if (
                      value !== currentMatterDetails?.legalAidObj?.laOrderDate
                    ) {
                      const newValue = value;

                      if (newValue !== "Invalid date") {
                        setCurrentMatterDetails({
                          ...currentMatterDetails,
                          legalAidObj: {
                            ...currentMatterDetails?.legalAidObj,
                            laOrderDate: newValue,
                          } as MatterLegalAidObj,
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          const patternFields = [
            {
              value: currentMatterDetails?.legalAidObj?.criminal?.dsccNumber,
              label: "DSCC",
              pattern: /\d{9}[A-Za-z]/,
            },
            {
              value: currentMatterDetails?.legalAidObj?.authorityNumber,
              label: "Prison Law Auth No.",
              pattern: /^\d{10}$/,
            },
            {
              value: currentMatterDetails?.legalAidObj?.laOrderNumber,
              label: "Rep Order No.",
              pattern:
                /^(.{0}|.{1}|.{2}|.{3}|.{4}|.{5}|.{6}|.{7}|.{8}|.{9}|.{10})$/,
            },
          ];
          if (
            !!currentMatterDetails &&
            !validate(
              getMatterRequiredFields(currentMatterDetails),
              patternFields,
              (errors) => dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(putDefinables(currentMatterDetails)).then((action) => {
              if (action.meta.requestStatus !== "rejected") {
                close(sdkApi);
              }
            });
          }
        }}
      />
      <CustomDialog
        isOpen={!!dsccWarning}
        title="Warning"
        onClose={() => setDsccWarning(undefined)}
        showInfoIcon={true}
        actions={
          <Button
            variant="outlined"
            onClick={() => {
              setDsccWarning(undefined);
            }}
            color="secondary"
            disableRipple
          >
            OK
          </Button>
        }
        content={<Typography>{dsccWarning}</Typography>}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default CriminalMatterDetails;
