import { InputLabel, Select, TextField } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getCrownLists,
  getMatterDetails,
  putDefinables,
} from "../../app/store/matter/matterSlice";
import {
  IMatterDetailsDto,
  MatterLegalAidObj,
} from "../../shared/dto/matter-details.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  getMatterRequiredFields,
  validate,
} from "../../shared/utils/validation-utils";
import Footer from "../components/footer";
import MatterComponent from "../components/matter-component";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";

const CrownMatterDetails: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const initialMatterDetails = useRef<IMatterDetailsDto | undefined>(undefined);
  const matterRef = useRef<IMatterDetailsDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const matterLists = useSelector(
    (state: RootState) => state.matter.matterLists
  );
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.matter.saving);
  const users = useSelector((state: RootState) => state.app.users);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(matterRef.current) !==
      JSON.stringify(initialMatterDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    matterRef.current = currentMatterDetails;
  }, [currentMatterDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(true))
        .unwrap()
        .then(() => {
          dispatch(getCrownLists());
        })
        .catch(() => {});
      dispatch(getUsers());
    }
  }, [dispatch]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !!matterLists && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      initialMatterDetails.current = matterDetails;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Legal Aid Details"
      );
    }
  }, [matterDetails, matterLists, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  return (
    <>
      <TopBar helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Crown-Court-Creating-a-New-Matter" />
      <div className="main">
        <div className="mainsection">
          <div className="halfWidth">
            <MatterComponent
              matterDetails={currentMatterDetails}
              onUpdate={(matterDetails) =>
                setCurrentMatterDetails(matterDetails)
              }
              getLists={(tableNo) => {
                dispatch(getCrownLists(tableNo));
              }}
              branchList={matterLists?.branchList}
              rateList={matterLists?.rateList}
            />

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Offence</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.crownCourt
                      ?.lgfsOffence || ""
                  }
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        crownCourt: {
                          ...currentMatterDetails?.legalAidObj?.crownCourt,
                          lgfsOffence: e.target.value,
                        },
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>
          </div>
          <div className="halfWidthWithPadding">
            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Court</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    fullWidth
                    native
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.crownCourt
                        ?.crownCourt || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          crownCourt: {
                            ...currentMatterDetails?.legalAidObj?.crownCourt,
                            crownCourt: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(matterLists?.courtList || []).map((court) => (
                      <option
                        key={court.code}
                        value={`${court.description} [${court.code}]`}
                      >
                        {`${court.description} [${court.code}]`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Case No.</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.caseNumber || ""}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        caseNumber: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Court (Transfer)</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    fullWidth
                    native
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.crownCourt
                        ?.courtTransferredFrom || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          crownCourt: {
                            ...currentMatterDetails?.legalAidObj?.crownCourt,
                            courtTransferredFrom: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(matterLists?.courtList || []).map((court) => (
                      <option
                        key={court.code}
                        value={`${court.description} [${court.code}]`}
                      >
                        {`${court.description} [${court.code}]`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Litigators Name</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.crownCourt
                        ?.litigatorsName || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          crownCourt: {
                            ...currentMatterDetails?.legalAidObj?.crownCourt,
                            litigatorsName: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {users?.map((user) => (
                      <option key={user.staffId} value={user.staffId}>
                        {`${user.firstName} ${user.lastName}`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentMatterDetails &&
            !validate(
              getMatterRequiredFields(currentMatterDetails),
              [],
              (errors) => dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(putDefinables(currentMatterDetails)).then((action) => {
              if (action.meta.requestStatus !== "rejected") {
                close(sdkApi);
              }
            });
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default CrownMatterDetails;
