import { SvgIcon } from "@mui/material";

function LeapIcon() {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      aria-hidden="true"
      height="16"
      width="16"
      style={{ width: 16, height: 16 }}
    >
      <path fill="#1E365E" d="M0 0h16v16H0z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.118 7.71l-4.43-.5 1.306-3.147 4.444 2.098-.785 5.245-.535-3.696zM6.421 8l.262-.781 2.098.52-.786 1.824-5.245 2.343L6.421 8z"
        fill="#F69139"
      ></path>
    </SvgIcon>
  );
}

export default LeapIcon;
