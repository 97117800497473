import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import axios from "axios";
import { RootState } from "..";
import {
  filterSubmission,
  filterYear,
  getCurrentMonthFilter,
} from "../../../features/submission/filters";
import { FirmBranch } from "../../../shared/dto/common-list.dto";
import { IDocModelDto } from "../../../shared/dto/document.dto";
import {
  IInvoiceSubItemDto,
  ISubmissionFilterDto,
} from "../../../shared/dto/submission/submission.dto";
import { environment } from "../../../shared/environments";
import { defaultHeaders } from "../../../shared/utils/request-utils";
import { close } from "../../../shared/utils/sdk-utils";
import { setErrors, setValidationErrors } from "../app/appSlice";

export interface SubmissionState {
  branchList: FirmBranch[];
  docModel: IDocModelDto;
  errors: string[] | undefined;
  invoiceSubmissionItems: IInvoiceSubItemDto[];
  loading: boolean;
  submissionFilter: ISubmissionFilterDto;
}

const initialState: SubmissionState = {
  branchList: [],
  docModel: { documentId: "" },
  errors: undefined,
  invoiceSubmissionItems: [],
  loading: false,
  submissionFilter: {
    submissionType: filterSubmission.Unsubmitted,
    branch: "All",
    month: getCurrentMonthFilter(),
    year: filterYear.Current,
  },
};

export const createSubmission = createAsyncThunk(
  "createSubmission",
  async (
    data: {
      invoiceSubItems: IInvoiceSubItemDto[];
      month: number;
      year: number;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.post(
        `${environment().backendUrl}/submission?month=${data.month}&year=${
          data.year
        }`,
        data.invoiceSubItems,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getBranchList = createAsyncThunk(
  "getBranchList",
  async (_, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/branches`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getInvoiceSubmissionList = createAsyncThunk(
  "getInvoiceSubmissionList",
  async (_IInvoiceSubItemDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/submission`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const markSubmission = createAsyncThunk(
  "markSubmission",
  async (
    data: {
      invoiceSubItems: IInvoiceSubItemDto[];
      month: number;
      year: number;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.put(
        `${environment().backendUrl}/submission/submit?month=${
          data.month
        }&year=${data.year}`,
        data.invoiceSubItems,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const unmarkSubmission = createAsyncThunk(
  "unmarkSubmission",
  async (invoiceSubItems: IInvoiceSubItemDto[], thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.put(
        `${environment().backendUrl}/submission/unsubmit`,
        invoiceSubItems,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const submissionSlice = createSlice({
  name: "submission",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.submissionFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSubmission.pending, (state) => {
        state.docModel = { documentId: "", matterId: "" };
        state.loading = true;
      })
      .addCase(createSubmission.fulfilled, (state, action) => {
        state.docModel = action.payload;
        state.loading = false;
      })
      .addCase(createSubmission.rejected, (state, action) => {
        state.docModel = { documentId: "", matterId: "" };
        state.loading = false;
      })
      .addCase(getBranchList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBranchList.fulfilled, (state, action) => {
        state.branchList = action.payload;

        if (state.branchList?.length === 1) {
          let firmId = state.branchList[0].__id;
          state.submissionFilter = {
            ...state.submissionFilter,
            branch: firmId,
          };
        }

        state.loading = false;
      })
      .addCase(getBranchList.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getInvoiceSubmissionList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceSubmissionList.fulfilled, (state, action) => {
        state.invoiceSubmissionItems = action.payload;
        state.loading = false;
      })
      .addCase(getInvoiceSubmissionList.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(markSubmission.pending, (state) => {
        state.loading = true;
      })
      .addCase(markSubmission.fulfilled, (state, action) => {
        let updateItems = state.invoiceSubmissionItems.map((item) => {
          let idx = action.payload.findIndex(
            (pi: any) => pi.invoiceGuid === item.invoiceGuid
          );
          if (idx >= 0) {
            item = action.payload[idx];
          }
          return item;
        });

        state.invoiceSubmissionItems = updateItems;
        state.loading = false;
      })
      .addCase(markSubmission.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(unmarkSubmission.pending, (state) => {
        state.loading = true;
      })
      .addCase(unmarkSubmission.fulfilled, (state, action) => {
        let updateItems = state.invoiceSubmissionItems.map((item) => {
          let idx = action.payload.findIndex(
            (pi: any) => pi.invoiceGuid === item.invoiceGuid
          );
          if (idx >= 0) {
            item = action.payload[idx];
          }
          return item;
        });

        state.invoiceSubmissionItems = updateItems;
        state.loading = false;
      })
      .addCase(unmarkSubmission.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { loading, setFilter } = submissionSlice.actions;

export default submissionSlice.reducer;
