import {
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getBillingStages,
  getFees,
  getMentalHealthGroups,
  getTaxCodes,
  initFee,
  saveFee,
} from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { FeeLegalAidObj, IFeeDto } from "../../shared/dto/fee.dto";
import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  isNullOrEmptyGuid,
} from "../../shared/utils/utils";
import {
  moreThan30DaysInFuture,
  validate,
} from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomCheckbox from "../components/custom-checkbox";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import NumberInput from "../components/number-input";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { useAppInit } from "../../shared/hooks/use-app-init";
import { Tooltip } from "@mui/material";

const infoIcon = require("../../shared/images/info.png");

const getExpertRequiredFields = (fee: IFeeDto) => {
  return [
    {
      value: fee?.legalAidObj?.billingStageId,
      label: "Billing Stage",
    },
  ];
};

const MentalHealthFee: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const dataInitialised = useRef(false);
  const initialFeeDetails = useRef<IFeeDto | undefined>(undefined);
  const feeRef = useRef<IFeeDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const mentalHealthRate = useSelector(
    (state: RootState) => state.fee.mentalHealthRate
  );
  const billingStages = useSelector(
    (state: RootState) => state.fee.billingStages
  );
  const users = useSelector((state: RootState) => state.app.users);
  const taxCodes = useSelector((state: RootState) => state.fee.taxCodes);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.fee.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<
    IFeeDto | undefined
  >(undefined);

  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [travelAmount, setTravelAmount] = useState(0);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const feeId = urlParams.get("feeid");

  const calcTotals = useCallback(
    (feeDetails: IFeeDto) => {
      let hearingAmount =
        (feeDetails.legalAidObj.boltOn?.mentalHealth?.adjournedHearings || 0) *
        (mentalHealthRate?.noOfHearingAmount || 0);

      const currentTaxCode = taxCodes?.find(
        (t) => t.taxCodeId === feeDetails?.taxCodeId
      );
      let tax = 0;
      let total = 0;

      let tempTravel =
        feeDetails?.legalAidObj.boltOn?.mentalHealth?.remoteTravel?.toString() ===
        "1"
          ? mentalHealthRate?.remoteTravelAmount || 0
          : 0;
      if (currentTaxCode) {
        tax = (currentTaxCode?.ratePercent || 0) / 100;
        if (tax > 0) {
          tax = ((tempTravel || 0) + (hearingAmount || 0)) * tax;
        }
        total = (hearingAmount || 0) + (tempTravel || 0) + tax;
      }
      const tempFee = {
        ...feeDetails,
        amount: (hearingAmount || 0) + (tempTravel || 0),
        rate: (hearingAmount || 0) + (tempTravel || 0),
        legalAidObj: {
          ...feeDetails?.legalAidObj,
          boltOn: {
            ...feeDetails?.legalAidObj.boltOn,
            mentalHealth: {
              ...feeDetails?.legalAidObj.boltOn?.mentalHealth,
              hearingAmount: hearingAmount,
            },
          },
        },
      } as IFeeDto;
      setCurrentFeeDetails(tempFee);
      if (!dataInitialised.current) {
        initialFeeDetails.current = tempFee;
      }
      dataInitialised.current = true;
      setTravelAmount(tempTravel);
      setTotal(total);
      setTax(tax);
    },
    [taxCodes, mentalHealthRate]
  );

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(feeRef.current) !==
      JSON.stringify(initialFeeDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    feeRef.current = currentFeeDetails;
  }, [currentFeeDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(false)).then((matterAction) => {
        if (matterAction.meta.requestStatus !== "rejected") {
          if (feeId) {
            dispatch(getFees([feeId || ""])).then((feeAction) => {
              const currentFee = ((feeAction.payload as IFeeDto[]) || [])[0];
              dispatch(
                getBillingStages(
                  (matterAction.payload as IMatterDetailsDto).legalAidObj
                    ?.matterTypeId || 0
                )
              );
              dispatch(
                getMentalHealthGroups({
                  tableNo:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.tableNo || 0,
                  matterTypeId:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.matterTypeId || 0,
                  caseStageCode: currentFee?.legalAidObj?.caseStageCode || "",
                  rate:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.rate || "",
                })
              );
            });
          } else {
            dispatch(
              initFee({
                matterDetails: matterAction.payload as IMatterDetailsDto,
                type: "mh",
              })
            ).then((feeAction) => {
              const currentFee = ((feeAction.payload as IFeeDto[]) || [])[0];
              dispatch(
                getBillingStages(
                  (matterAction.payload as IMatterDetailsDto).legalAidObj
                    ?.matterTypeId || 0
                )
              );
              dispatch(
                getMentalHealthGroups({
                  tableNo:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.tableNo || 0,
                  matterTypeId:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.matterTypeId || 0,
                  caseStageCode: currentFee?.legalAidObj?.caseStageCode || "",
                  rate:
                    (matterAction.payload as IMatterDetailsDto)?.legalAidObj
                      ?.rate || "",
                })
              );
            });
          }
        }
      });
      dispatch(getTaxCodes());
      dispatch(getUsers());
    }
  }, [dispatch, feeId]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Bolt On Payment"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (
      !!feeDetails &&
      !feeDetailsInitialised.current &&
      !!mentalHealthRate &&
      !!taxCodes
    ) {
      feeDetailsInitialised.current = true;
      let currentFee = { ...feeDetails[0] };
      if (isNullOrEmptyGuid(currentFee.legalAidObj?.taskCodeId)) {
        currentFee = {
          ...currentFee,
          legalAidObj: {
            ...currentFee.legalAidObj,
            taskCodeId: "6f69ad33-bb3a-46f9-8384-36fe17553a4b",
          },
        };
      }

      initialFeeDetails.current = currentFee;
      calcTotals(currentFee);
    }
  }, [
    feeDetails,
    feeDetailsInitialised,
    mentalHealthRate,
    taxCodes,
    calcTotals,
  ]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  const isFormDisabled =
    currentFeeDetails?.billed || currentFeeDetails?.deleted;

  return (
    <>
      <TopBar
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Mental-Health-Creating-a-New-Time-Entry-Bolt-On-Payments"
        leftComponents={
          <FeeDeleteButton
            fee={currentFeeDetails}
            feeRef={initialFeeDetails.current}
          />
        }
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex5">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography style={{ maxWidth: 350, minWidth: 350 }} noWrap>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>

              <div className="flex3">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Date
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <LocalDatePicker
                      value={
                        currentFeeDetails?.transactionDate
                          ? currentFeeDetails?.transactionDate
                          : null
                      }
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        if (value !== currentFeeDetails?.transactionDate) {
                          const newValue = value || "";

                          if (newValue !== "Invalid date") {
                            setCurrentFeeDetails({
                              ...currentFeeDetails,
                              transactionDate: newValue,
                            } as IFeeDto);
                          }
                        }
                      }}
                      validate={(value) =>
                        moreThan30DaysInFuture(
                          value,
                          currentFeeDetails?.transactionDate
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex3">
                <Select
                  className="select"
                  native
                  fullWidth
                  variant="outlined"
                  value={currentFeeDetails?.legalAidObj.billingStageId || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        billingStageId: e.target.value,
                      } as FeeLegalAidObj,
                    } as IFeeDto);
                  }}
                >
                  {!currentFeeDetails?.legalAidObj.billingStageId && (
                    <option key="" value="" className="emptyMenuItem"></option>
                  )}
                  {[...(billingStages || [])]
                    ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((billingStage) => (
                      <option key={billingStage.id} value={billingStage.id}>
                        {billingStage.description}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Staff
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <Select
                      className="select"
                      fullWidth
                      native
                      variant="outlined"
                      value={currentFeeDetails?.legalAidObj.feeEarnerId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        setCurrentFeeDetails({
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            feeEarnerId: e.target.value,
                          } as FeeLegalAidObj,
                        } as IFeeDto);
                      }}
                    >
                      {!currentFeeDetails?.legalAidObj.feeEarnerId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {filterInactiveStaff(
                        users,
                        currentFeeDetails?.legalAidObj.feeEarnerId
                      )?.map((user) => (
                        <option key={user.staffId} value={user.staffId}>
                          {`${user.firstName} ${user.lastName}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRowTextArea">
              <div style={{ width: 130 }}>
                <InputLabel>Billing Description</InputLabel>
              </div>
              <div className="flex1">
                <TextareaAutosize
                  value={currentFeeDetails?.description || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      description: e.target.value,
                    } as IFeeDto);
                  }}
                  minRows={3}
                  maxRows={3}
                  className="textarea-fee"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>
                  Case Stage Level
                  <Tooltip
                    style={{ paddingLeft: 5 }}
                    title="Please select a Case Stage Level within the Matter Details."
                  >
                    <img alt="warning" src={infoIcon} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className="flex3">
                <TextField
                  variant="outlined"
                  value={currentFeeDetails?.legalAidObj.caseStageCode || ""}
                  disabled
                  style={{ width: 130, paddingRight: 5, textAlign: "right" }}
                />
              </div>

              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    HEARING FEE
                  </Typography>
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.mentalHealth
                        ?.hearingAmount || 0
                    }
                    prefix="£"
                  />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Fixed Fee</InputLabel>
              </div>
              <div className="flex3">
                <CurrencyInput
                  disabled
                  value={mentalHealthRate?.fixedFee || 0}
                  prefix="£"
                />
              </div>

              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TRAVEL
                  </Typography>
                  <CurrencyInput disabled value={travelAmount} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Adjourned Hearings</InputLabel>
              </div>
              <div className="flex3">
                <NumberInput
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.mentalHealth
                      ?.adjournedHearings
                  }
                  disabled={isFormDisabled}
                  decimalScale={0}
                  updater={(value) => {
                    let tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          mentalHealth: {
                            ...currentFeeDetails?.legalAidObj.boltOn
                              ?.mentalHealth,
                            adjournedHearings: value,
                          },
                        },
                      },
                    } as IFeeDto;
                    calcTotals(tempFee);
                  }}
                  width={65}
                />
              </div>

              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TAX
                  </Typography>
                  <CurrencyInput disabled value={tax} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }}>
                    <Select
                      variant="outlined"
                      native
                      value={currentFeeDetails?.taxCodeId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          taxCodeId: e.target.value,
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                      fullWidth
                    >
                      {!currentFeeDetails?.taxCodeId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {taxCodes?.map((taxCode) => (
                        <option
                          key={taxCode.taxCodeId}
                          value={taxCode.taxCodeId}
                        >
                          {taxCode.taxCode}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div
                style={{ width: 130, display: "grid", alignContent: "center" }}
              >
                <CustomCheckbox
                  label="Remote Travel"
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.mentalHealth?.remoteTravel?.toString() ===
                      "1" || false
                  }
                  disabled={isFormDisabled}
                  onChange={(value) => {
                    let tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          mentalHealth: {
                            ...currentFeeDetails?.legalAidObj.boltOn
                              ?.mentalHealth,
                            remoteTravel: !!value ? 1 : 0,
                          },
                        },
                      },
                    } as IFeeDto;
                    calcTotals(tempFee);
                  }}
                />
              </div>
              <div className="flex3" />

              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TOTAL
                  </Typography>
                  <CurrencyInput disabled value={total} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentFeeDetails &&
            !validate(
              getExpertRequiredFields(currentFeeDetails),
              [],
              (errors) => dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(saveFee(currentFeeDetails))
              .unwrap()
              .then(() => {
                close(sdkApi);
              })
              .catch((e) => console.error(e));
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default MentalHealthFee;
