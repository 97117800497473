import {
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers } from "../../app/store/app/appSlice";
import { getBillingStages, getFees } from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { IFeeDto } from "../../shared/dto/fee.dto";
import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import { close, setWindowTitle } from "../../shared/utils/sdk-utils";
import CurrencyInput from "../components/currency-input";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import TopBar from "../components/topbar";
import { filterInactiveStaff } from "../../shared/utils/utils";
import { useAppInit } from "../../shared/hooks/use-app-init";

const FixedFee: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const billingStages = useSelector(
    (state: RootState) => state.fee.billingStages
  );
  const users = useSelector((state: RootState) => state.app.users);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<
    IFeeDto | undefined
  >(undefined);

  const urlParams = new URLSearchParams(window.location.search);
  const feeId = urlParams.get("feeid");

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(false)).then((matterAction) => {
        if (matterAction.meta.requestStatus !== "rejected") {
          if (feeId) {
            dispatch(getFees([feeId || ""])).then(() => {
              dispatch(
                getBillingStages(
                  (matterAction.payload as IMatterDetailsDto).legalAidObj
                    ?.matterTypeId || 0
                )
              );
            });
          }
        }
      });
      dispatch(getUsers());
    }
  }, [dispatch, feeId]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Invoice Fixed Fee"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!!feeDetails && !feeDetailsInitialised.current) {
      feeDetailsInitialised.current = true;
      let currentFee = { ...feeDetails[0] };

      setCurrentFeeDetails(currentFee);
    }
  }, [feeDetails, feeDetailsInitialised]);

  return (
    <>
      <TopBar
        helpUrl="https://community.leap.co.uk/s/article/Creating-a-Legal-Aid-Cost-Recovery"
        leftComponents={<FeeDeleteButton fee={currentFeeDetails} />}
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div className="flex1" style={{ minWidth: 130, maxWidth: 130 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex6">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography style={{ maxWidth: 450, minWidth: 450 }} noWrap>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>

              <div className="flex2">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <div className="flex1" />
                  <InputLabel style={{ paddingRight: 10 }} required>
                    Date
                  </InputLabel>
                  <div style={{ width: 108 }}>
                    <LocalDatePicker
                      value={
                        currentFeeDetails?.transactionDate
                          ? currentFeeDetails?.transactionDate
                          : null
                      }
                      disabled
                      onUpdate={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div className="flex1" style={{ minWidth: 130, maxWidth: 130 }}>
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex2">
                <Select
                  className="select"
                  fullWidth
                  variant="outlined"
                  native
                  value={currentFeeDetails?.legalAidObj.billingStageId || ""}
                  disabled
                >
                  <option key="" value="" className="emptyMenuItem"></option>
                  {[...(billingStages || [])]
                    ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((billingStage) => (
                      <option key={billingStage.id} value={billingStage.id}>
                        {billingStage.description}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="flex6" />
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRowTextArea">
              <div className="flex1" style={{ minWidth: 130, maxWidth: 130 }}>
                <InputLabel>Billing Description</InputLabel>
              </div>
              <div className="flex1">
                <TextareaAutosize
                  value={currentFeeDetails?.description || ""}
                  minRows={3}
                  maxRows={3}
                  className="textarea-fee"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="displayFlex" style={{ alignItems: "center" }}>
              <div className="flex1" style={{ minWidth: 130, maxWidth: 130 }}>
                <InputLabel required>Staff</InputLabel>
              </div>
              <div className="flex2">
                <div className="displayFlex">
                  <Select
                    className="select"
                    fullWidth
                    native
                    variant="outlined"
                    value={currentFeeDetails?.legalAidObj.feeEarnerId || ""}
                    disabled
                  >
                    {!currentFeeDetails?.legalAidObj.feeEarnerId && (
                      <option
                        key=""
                        value=""
                        className="emptyMenuItem"
                      ></option>
                    )}
                    {filterInactiveStaff(
                      users,
                      currentFeeDetails?.legalAidObj.feeEarnerId
                    )?.map((user) => (
                      <option key={user.staffId} value={user.staffId}>
                        {`${user.firstName} ${user.lastName}`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex4" />
              <div className="flex2">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <div className="flex1" />
                  <Typography
                    align="right"
                    className="bold-text"
                    style={{ paddingRight: 10 }}
                  >
                    AMOUNT
                  </Typography>
                  <CurrencyInput
                    disabled
                    value={currentFeeDetails?.amount}
                    prefix="£"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => {
          close(sdkApi);
        }}
      />
    </>
  );
};

export default FixedFee;
