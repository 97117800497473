import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { Api, LeapHostSdkFactory } from "@leapdev/leap-host";
import { useLocation } from "react-router";
import { RootState } from "../../app/store";
import {
  getAppInit,
  setSDKApi,
  setSDKInitialised,
} from "../../app/store/app/appSlice";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useAppInit = (callback?: () => void) => {
  const dispatch = useAppDispatch();
  const queryParams = useQuery();
  const requestId = queryParams.get("requestId");
  const env = queryParams.get("env");
  const region = queryParams.get("region");
  const appSessionId = queryParams.get("appSessionId");
  const billingstageid = queryParams.get("billingstageid");

  const sdkInitialisedRef = useRef(false);
  const callbackCalled = useRef(false);
  const sdkApi = useAppSelector((state: RootState) => state.app.sdkApi);
  const sdkInitialised = useAppSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  useEffect(() => {
    const initData = () => {
      if (!!callback) {
        callback();
      }
    };

    if (!sdkInitialisedRef.current && !sdkApi && !sdkInitialised) {
      const sdk = LeapHostSdkFactory.getInstance();
      console.warn("sdk", sdk);
      dispatch(setSDKApi(sdk as Api));
      sdkInitialisedRef.current = true;

      sdk
        .init()
        .then(() => {
          if (!!sdk.getRefreshedAccessToken()) {
            dispatch(setSDKInitialised(true));
            dispatch(getAppInit({ billingStageId: billingstageid || "" })).then(
              () => {
                callbackCalled.current = true;
                initData();
              }
            );
          }
        })
        .catch((e) => {
          console.error(`Error initialising the SDK`, e);
        });
    }
  }, [
    dispatch,
    callback,
    sdkApi,
    appSessionId,
    env,
    region,
    requestId,
    billingstageid,
    sdkInitialised,
  ]);
};
