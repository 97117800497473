import {
  IMatterDetailsDto,
  MatterLegalAidObj,
} from "../dto/matter-details.dto";
import { AccessPoints, ProcurementAreas } from "../dto/common-list.dto";
import moment from "moment";

export const getFilteredProcurementAreas = (
  matterDetails: IMatterDetailsDto,
  setCurrentMatterDetails: (matterDetails: IMatterDetailsDto) => void,
  setProcurementAreaList: (procurementAreas?: ProcurementAreas[]) => void,
  setAccessPoints: (accessPoints: AccessPoints[]) => void,
  date?: string,
  procurementAreaList?: ProcurementAreas[]
) => {
  if (date && date !== "") {
    let tempList = (procurementAreaList || [])
      .map((p) => {
        return {
          ...p,
          accessPoints: p.accessPoints.filter(
            (pa) =>
              (pa.dateFrom === "" ||
                moment(pa.dateFrom, "DD/MM/YYYY").isSameOrBefore(
                  moment(date, "DD/MM/YYYY")
                )) &&
              (pa.dateTo === "" ||
                moment(pa.dateTo, "DD/MM/YYYY").isSameOrAfter(
                  moment(date, "DD/MM/YYYY")
                ))
          ),
        };
      })
      .filter((p) => !!p.accessPoints.length);
    let tempMatterDetails = { ...matterDetails };
    if (
      !!tempMatterDetails &&
      !tempList?.find(
        (pa) => pa.code === tempMatterDetails?.legalAidObj?.procurementArea
      )
    ) {
      tempMatterDetails = {
        ...tempMatterDetails,
        legalAidObj: {
          ...tempMatterDetails?.legalAidObj,
          procurementArea: "",
          accessPoint: "",
        } as MatterLegalAidObj,
      };
    } else {
      const group = tempList.find(
        (a) => a.code === tempMatterDetails.legalAidObj?.procurementArea
      );
      if (group && group.accessPoints) {
        setAccessPoints(group.accessPoints);
      }
      if (
        !group?.accessPoints.find(
          (a) => a.code === tempMatterDetails.legalAidObj?.accessPoint
        )
      ) {
        tempMatterDetails = {
          ...tempMatterDetails,
          legalAidObj: {
            ...tempMatterDetails?.legalAidObj,
            accessPoint: "",
          } as MatterLegalAidObj,
        };
      }
    }
    setCurrentMatterDetails(tempMatterDetails);
    setProcurementAreaList(tempList);
  }
};
