import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FC, useState } from "react";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useEffect, useRef } from "react";
import { Button, InputBaseComponentProps, Typography } from "@mui/material";
import { environment } from "../../shared/environments";
import CustomDialog from "./custom-dialog";
import CalendarIcon from "../../shared/images/calendar-icon";

interface IValidationError {
  message: string;
  keepButton: boolean;
  originalValue?: string;
}

interface IDatePickerProps {
  value: string | null;
  onUpdate: (value: string | null) => void;
  disabled?: boolean;
  validate?: (value: moment.Moment | null) => IValidationError | null;
}
const LocalDatePicker: FC<IDatePickerProps> = ({
  value,
  onUpdate,
  disabled,
  validate,
}) => {
  const initialised = useRef(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentValue, setValue] = useState<moment.Moment | null>(null);
  const [validationError, setValidationError] =
    useState<IValidationError | null>(null);

  const dateFormat = environment().dateFormat;
  const getInputProps = (inputProps: InputBaseComponentProps | undefined) => {
    const updatedProps = {
      ...inputProps,
      type: undefined,
      placeholder: "dd/mm/yyyy",
    };
    return updatedProps;
  };

  useEffect(() => {
    if (initialised.current) {
      setValue(moment(value, dateFormat));
    } else {
      if (!value) {
        setValue(moment(value, dateFormat));
      }
    }
  }, [value, dateFormat]);

  useEffect(() => {
    if (!!value && !initialised.current) {
      initialised.current = true;
      setValue(moment(value, dateFormat));
    }
  }, [setValue, value, dateFormat]);

  moment.locale("en", {
    week: {
      dow: 1,
    },
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={currentValue}
          disabled={disabled}
          inputFormat={dateFormat}
          PopperProps={{
            placement: "auto",
            anchorEl: anchorEl,
          }}
          views={["year", "month", "day"]}
          InputAdornmentProps={{
            style: { margin: 0 },
            className: "date-adornment",
            onClick: (e) => setAnchorEl(e.currentTarget),
          }}
          components={{ OpenPickerIcon: CalendarIcon }}
          onChange={(newValue, keyboardVal) => {}}
          onAccept={(newValue) => {
            if (!!validate && newValue !== currentValue) {
              const tempValError = validate(newValue || null);
              if (!!tempValError) {
                setValidationError(tempValError);
              }
            }
            setValue(newValue);
            onUpdate(newValue ? newValue.format(dateFormat) : newValue);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ":focus-visible": { borderRadius: 0 },
                ":visited": { borderRadius: 0 },
                transform: "none",
              }}
            >
              <input
                ref={inputRef}
                {...getInputProps(inputProps)}
                onBlur={(e) => {
                  let keyValue = e.target.value;
                  if (keyValue !== value) {
                    if (keyValue === "") {
                      setValue(null);
                      onUpdate(null);
                    } else {
                      let retrunedDate = moment();

                      let values = keyValue.split("/").filter((v) => v !== "");

                      if (values.length === 1) {
                        retrunedDate = retrunedDate.set(
                          "D",
                          Number(keyValue.replace("/", ""))
                        );
                      }
                      if (values.length === 2) {
                        let values = keyValue
                          .split("/")
                          .filter((v) => v !== "");
                        retrunedDate = retrunedDate.set(
                          "M",
                          Number(values[1]) - 1
                        );
                        retrunedDate = retrunedDate.set("D", Number(values[0]));
                      }
                      if (values.length === 3) {
                        const yearVal = values[2];
                        const currentYear = retrunedDate.year().toString();

                        let retYear =
                          yearVal.length < 4
                            ? Number(
                                currentYear
                                  .substring(
                                    0,
                                    currentYear.length - yearVal.length
                                  )
                                  .concat(yearVal.toString())
                              )
                            : Number(yearVal);
                        retrunedDate = retrunedDate.set(
                          "M",
                          Number(values[1]) - 1
                        );
                        retrunedDate = retrunedDate.set("y", retYear);
                        retrunedDate = retrunedDate.set("D", Number(values[0]));
                      }
                      if (validate) {
                        const tempValError = validate(retrunedDate || null);
                        if (
                          !!tempValError &&
                          retrunedDate.format(dateFormat) !==
                            (currentValue
                              ? moment(currentValue, dateFormat).format(
                                  dateFormat
                                )
                              : "")
                        ) {
                          setValidationError(tempValError);
                        }
                      }
                      setValue(retrunedDate);
                      onUpdate(
                        retrunedDate
                          ? retrunedDate.format(dateFormat)
                          : retrunedDate
                      );
                    }
                  }
                }}
                className="date-picker-input"
              />
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
      <CustomDialog
        isOpen={!!validationError}
        title="Warning"
        onClose={() => setValidationError(null)}
        showWarningIcon
        actions={
          <>
            {validationError?.keepButton && (
              <Button
                variant="contained"
                onClick={() => {
                  onUpdate(
                    currentValue ? currentValue?.format(dateFormat) : null
                  );
                  setValidationError(null);
                }}
                style={{ marginRight: 5 }}
                disableRipple
              >
                Keep Value
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => {
                onUpdate(
                  validationError?.originalValue
                    ? validationError?.originalValue
                    : null
                );
                setValue(
                  !!validationError?.originalValue
                    ? moment(
                        validationError?.originalValue,
                        environment().dateFormat
                      )
                    : null
                );
                setValidationError(null);
              }}
              color="secondary"
              disableRipple
            >
              Revert
            </Button>
          </>
        }
        content={<Typography>{validationError?.message}</Typography>}
      />
    </>
  );
};
export default LocalDatePicker;
