import {
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getCpgfsGroups,
  getFees,
  getTaxCodes,
  initFee,
  saveFee,
} from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { FeeLegalAidObj, IFeeDto } from "../../shared/dto/fee.dto";

import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import { CpgfsGroup } from "../../shared/dto/rates.dto";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  isNullOrEmptyGuid,
} from "../../shared/utils/utils";
import {
  moreThan30DaysInFuture,
  validate,
} from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomCheckbox from "../components/custom-checkbox";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { useAppInit } from "../../shared/hooks/use-app-init";

const getCpgfsRequiredFields = (fee: IFeeDto) => {
  return [
    {
      value: fee?.legalAidObj?.boltOn?.cpgfs?.clientType,
      label: "Client Type",
    },
    {
      value: fee?.legalAidObj?.boltOn?.cpgfs?.region,
      label: "Region",
    },
  ];
};

const CpgfsFee: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const dataInitialised = useRef(false);
  const initialFeeDetails = useRef<IFeeDto | undefined>(undefined);
  const feeRef = useRef<IFeeDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const cpgfsGroups = useSelector((state: RootState) => state.fee.cpgfsGroups);
  const users = useSelector((state: RootState) => state.app.users);
  const taxCodes = useSelector((state: RootState) => state.fee.taxCodes);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.fee.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<
    IFeeDto | undefined
  >(undefined);

  const [group, setGroup] = useState<CpgfsGroup | undefined>(undefined);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const feeId = urlParams.get("feeid");

  const calcTotals = useCallback(
    (feeDetails: IFeeDto, currentGroup?: CpgfsGroup) => {
      const rateItem = currentGroup?.cpgfsRates.find(
        (e) =>
          e.clientType === feeDetails.legalAidObj.boltOn?.cpgfs?.clientType &&
          e.isTwoPlusClient ===
            feeDetails.legalAidObj.boltOn?.cpgfs?.client2Plus
      );

      let amount = 0;
      if (!!rateItem) {
        if (feeDetails.legalAidObj.boltOn?.cpgfs?.highCourt) {
          amount = rateItem.highCourtAmount;
        } else {
          amount = rateItem.countyCourtAmount;
        }

        if (feeDetails.legalAidObj.boltOn?.cpgfs?.halfFee) {
          amount = amount / 2;
        }
      }

      const currentTaxCode = taxCodes?.find(
        (t) => t.taxCodeId === feeDetails?.taxCodeId
      );

      let tax = 0;
      let total = 0;
      if (currentTaxCode) {
        tax = (currentTaxCode?.ratePercent || 0) / 100;

        if (tax > 0) {
          tax = amount * tax;
        }

        if (!!feeDetails.incTax) {
          tax = amount * (1 - 1 / (tax + 1));
        }
        total = amount + tax;
      }
      const tempFee = {
        ...feeDetails,
        amount: amount,
        rate: amount,
      } as IFeeDto;
      setCurrentFeeDetails(tempFee);
      if (!dataInitialised.current) {
        initialFeeDetails.current = tempFee;
      }
      dataInitialised.current = true;
      setTotal(total);
      setTax(tax);
    },
    [taxCodes]
  );

  const refreshLists = useCallback(
    (feeDetails: IFeeDto) => {
      const selectedGroup = cpgfsGroups?.find(
        (g) => g.region === feeDetails?.legalAidObj?.boltOn?.cpgfs?.region
      );
      if (selectedGroup) {
        setGroup(selectedGroup);
      }
      calcTotals(feeDetails, selectedGroup);
    },
    [cpgfsGroups, calcTotals]
  );

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(feeRef.current) !==
      JSON.stringify(initialFeeDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    feeRef.current = currentFeeDetails;
  }, [currentFeeDetails]);

  // useEffect(() => {
  //   if (!!currentFeeDetails && !!taxCodes) calcTotals(currentFeeDetails);
  // }, [taxCodes, currentFeeDetails, calcTotals]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(false)).then((action) => {
        if (action.meta.requestStatus !== "rejected") {
          if (feeId) {
            dispatch(getFees([feeId || ""]));
          } else {
            dispatch(
              initFee({
                matterDetails: action.payload as IMatterDetailsDto,
                type: "cpgfs",
              })
            );
          }
          dispatch(
            getCpgfsGroups(
              (action.payload as IMatterDetailsDto)?.legalAidObj?.tableNo || 0
            )
          );
        }
      });
      dispatch(getTaxCodes());
      dispatch(getUsers());
    }
  }, [dispatch, feeId]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- CPGFS Fee"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!!feeDetails && !feeDetailsInitialised.current) {
      feeDetailsInitialised.current = true;
      let currentFee = { ...feeDetails[0] };
      if (isNullOrEmptyGuid(currentFee.legalAidObj?.taskCodeId)) {
        currentFee = {
          ...currentFee,
          legalAidObj: {
            ...currentFee.legalAidObj,
            taskCodeId: "A9A1F853-7353-4C6D-8A42-1EFA9789F413",
          },
        };
      }

      initialFeeDetails.current = currentFee;
      setCurrentFeeDetails(currentFee);
      refreshLists(currentFee);
    }
  }, [feeDetails, feeDetailsInitialised, refreshLists]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  const isFormDisabled =
    currentFeeDetails?.billed || currentFeeDetails?.deleted;

  return (
    <>
      <TopBar
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Family-Care-Recording-a-Solicitor-s-Fixed-Fee-Licensed-Work"
        leftComponents={
          <FeeDeleteButton
            fee={currentFeeDetails}
            feeRef={initialFeeDetails.current}
          />
        }
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex5">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography style={{ maxWidth: 350, minWidth: 350 }} noWrap>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>

              <div className="flex3">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Date
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <LocalDatePicker
                      value={
                        currentFeeDetails?.transactionDate
                          ? currentFeeDetails?.transactionDate
                          : null
                      }
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        if (value !== currentFeeDetails?.transactionDate) {
                          const newValue = value || "";

                          if (newValue !== "Invalid date") {
                            setCurrentFeeDetails({
                              ...currentFeeDetails,
                              transactionDate: newValue,
                            } as IFeeDto);
                          }
                        }
                      }}
                      validate={(value) =>
                        moreThan30DaysInFuture(
                          value,
                          currentFeeDetails?.transactionDate
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex3">
                <TextField
                  variant="outlined"
                  value="Licensed Work"
                  disabled
                  fullWidth
                />
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Staff
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <Select
                      className="select"
                      fullWidth
                      native
                      variant="outlined"
                      value={currentFeeDetails?.legalAidObj.feeEarnerId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        setCurrentFeeDetails({
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            feeEarnerId: e.target.value,
                          } as FeeLegalAidObj,
                        } as IFeeDto);
                      }}
                    >
                      {!currentFeeDetails?.legalAidObj.feeEarnerId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {filterInactiveStaff(
                        users,
                        currentFeeDetails?.legalAidObj.feeEarnerId
                      )?.map((user) => (
                        <option key={user.staffId} value={user.staffId}>
                          {`${user.firstName} ${user.lastName}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRowTextArea">
              <div style={{ width: 130 }}>
                <InputLabel>Billing Description</InputLabel>
              </div>
              <div className="flex1">
                <TextareaAutosize
                  value={currentFeeDetails?.description || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      description: e.target.value,
                    } as IFeeDto);
                  }}
                  minRows={3}
                  maxRows={3}
                  className="textarea-fee"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Client Type</InputLabel>
              </div>
              <div className="flex2">
                <Select
                  variant="outlined"
                  style={{ width: 110 }}
                  native
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.cpgfs?.clientType ||
                    ""
                  }
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    let tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          cpgfs: {
                            ...currentFeeDetails?.legalAidObj.boltOn?.cpgfs,
                            clientType: e.target.value,
                          },
                        },
                      },
                    } as IFeeDto;

                    refreshLists(tempFee);
                  }}
                  fullWidth
                >
                  {!currentFeeDetails?.legalAidObj.boltOn?.cpgfs
                    ?.clientType && (
                    <option key="" value="" className="emptyMenuItem"></option>
                  )}
                  {group?.cpgfsRates
                    .filter(
                      (rate) =>
                        rate.isTwoPlusClient ===
                        currentFeeDetails?.legalAidObj.boltOn?.cpgfs
                          ?.client2Plus
                    )
                    .sort((a, b) =>
                      a.clientTypeDesc > b.clientTypeDesc ? 1 : -1
                    )
                    ?.map((rate) => (
                      <option key={rate.clientTypeDesc} value={rate.clientType}>
                        {rate.clientTypeDesc}
                      </option>
                    ))}
                </Select>
              </div>

              <div className="flex5" />
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Region</InputLabel>
              </div>
              <div className="flex2">
                <Select
                  variant="outlined"
                  style={{ width: 110 }}
                  native
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.cpgfs?.region || ""
                  }
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    let tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          cpgfs: {
                            ...currentFeeDetails?.legalAidObj.boltOn?.cpgfs,
                            region: e.target.value,
                          },
                        },
                      },
                    } as IFeeDto;

                    refreshLists(tempFee);
                  }}
                  fullWidth
                >
                  {!currentFeeDetails?.legalAidObj.boltOn?.cpgfs?.region && (
                    <option key="" value="" className="emptyMenuItem"></option>
                  )}
                  {cpgfsGroups?.map((group) => (
                    <option key={group.region} value={group.region}>
                      {group.region}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    AMOUNT
                  </Typography>
                  <CurrencyInput
                    disabled
                    value={currentFeeDetails?.amount || 0}
                    prefix="£"
                  />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div
                style={{
                  width: 130,
                  display: "grid",
                  alignContent: "center",
                  height: "inherit",
                }}
              >
                <InputLabel>Claiming</InputLabel>
              </div>
              <div className="flex3">
                <div className="displayFlex">
                  <div style={{ width: 105 }}>
                    <CustomCheckbox
                      label="2+ Clients"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.cpgfs
                          ?.client2Plus || false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              cpgfs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.cpgfs,
                                client2Plus: value,
                              },
                            },
                          } as FeeLegalAidObj,
                        } as IFeeDto;

                        calcTotals(tempFee, group);
                      }}
                    />
                  </div>
                  <div>
                    <CustomCheckbox
                      label="1/2 Fee Applies"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.cpgfs?.halfFee ||
                        false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              cpgfs: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.cpgfs,
                                halfFee: value,
                              },
                            },
                          } as FeeLegalAidObj,
                        } as IFeeDto;

                        calcTotals(tempFee, group);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TAX
                  </Typography>
                  <CurrencyInput disabled value={tax} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }}>
                    <Select
                      variant="outlined"
                      native
                      value={currentFeeDetails?.taxCodeId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          taxCodeId: e.target.value,
                        } as IFeeDto;

                        calcTotals(tempFee, group);
                      }}
                      fullWidth
                    >
                      {!currentFeeDetails?.taxCodeId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {taxCodes?.map((taxCode) => (
                        <option
                          key={taxCode.taxCodeId}
                          value={taxCode.taxCodeId}
                        >
                          {taxCode.taxCode}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}></div>
              <div className="flex3">
                <CustomCheckbox
                  label="High Court"
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.cpgfs?.highCourt ||
                    false
                  }
                  disabled={isFormDisabled}
                  onChange={(value) => {
                    let tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          cpgfs: {
                            ...currentFeeDetails?.legalAidObj.boltOn?.cpgfs,
                            highCourt: value,
                          },
                        },
                      } as FeeLegalAidObj,
                    } as IFeeDto;

                    calcTotals(tempFee, group);
                  }}
                />
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TOTAL
                  </Typography>
                  <CurrencyInput disabled value={total} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentFeeDetails &&
            !validate(getCpgfsRequiredFields(currentFeeDetails), [], (errors) =>
              dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(saveFee(currentFeeDetails))
              .unwrap()
              .then(() => {
                close(sdkApi);
              })
              .catch((e) => console.error(e));
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default CpgfsFee;
