import React from "react";
import { Backdrop } from "@mui/material";
import "../../shared/styles/loader.css";

interface IProps {
  overlay?: boolean;
}
const Loader: React.FC<IProps> = ({ overlay }) => (
  <>
    <Backdrop open={true} invisible={!overlay} style={{ zIndex: 1350 }}>
      <div style={{ width: "100%" }}>
        <div
          style={{
            position: "relative",
            width: "100%",
          }}
          data-title=".dot-flashing"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              padding: "2rem 0",
              margin: "0 -5%",
              overflow: "hidden",
            }}
          >
            <div className="dot-flashing"></div>
          </div>
        </div>
      </div>
    </Backdrop>
  </>
);

export default Loader;
