import { FC } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "@mui/material/styles";

import "./App.css";
import { RootState } from "./app/store";
import { theme } from "./shared/theme";
import { useAppSelector } from "./app/hooks";
import AppRouter from "./app/router";
import Loader from "./features/components/loader";
import ValidationDialog from "./features/components/validation-dialog";
import ErrorDialog from "./features/components/error-dialog";
import WarningAcknowledgmentDialog from "./features/components/warning-acknowledgment-dialog";

LicenseInfo.setLicenseKey(
  "72f57cb3cde44c9e57ec88c753b6087eTz05MjQyMSxFPTE3NDk5MDg0ODgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const App: FC = () => {
  const appLoading = useAppSelector((state: RootState) => state.app.loading);
  const matterLoading = useAppSelector(
    (state: RootState) => state.matter.loading
  );

  return (
    <ThemeProvider theme={theme}>
      {(appLoading || matterLoading) && <Loader overlay />}
      <AppRouter />
      <ValidationDialog />
      <ErrorDialog />
      <WarningAcknowledgmentDialog />
    </ThemeProvider>
  );
};

export default App;
