interface IOfficeReceiptPreferenceDto {
  officeBankAccountGUID: string;
  payTypeDetailsEnforce: boolean;
  rapidPayVerificationStatus: number;
}

interface IOfficeReceiptBankAccountDto {
  bankAccountGUID: string;
  accountName: string;
  accountNumber: string;
  bSB: string;
  nameFileAs: string;
  accountUsage: number;
  deleted: boolean;
  rowVersion: number;
  drawer: string;
  chequeSVGLayoutGUID: string;
  chequeLayoutId: number;
  chequeMarginV: number;
  chequeMarginH: number;
  chequeNumber: number;
  receiptNumber: number;
  bankDepositNumber: number;
  journalNumber: number;
  eFTNumber: number;
  bankRecStatementDate?: string; // DateTime
  bankRecStatementBalance?: number;
  bankCurrentDate: string;
  rapidPayVerificationStatus: number;
}

export interface IBulkReceiptingFilterDto {
  bankAccount: string;
  branch: string;
  month: number;
  status: boolean;
  year: number;
}

export interface IInvoiceOfficeReceiptingDto {
  receiptDate: string; // DateTime
  bankAccountId: string;
  paymentTypeItem: IPaymentTypeDto;
  transactionNumber: string;
  autoNumber: boolean;
  warningAcknowledgments: number[];
  invoiceReceiptingItems: IInvoiceReceiptingItemDto[];
}

export interface IInvoiceReceiptingItemDto {
  matterId: string;
  invoiceId: string;
  branchId: string;
  matterType: string;
  matterUFN: string;
  client: string;
  matterNo: string;
  description: string;
  invoiceNo: string;
  invoiceTotal: number;
  invoiceBankAccountId: string;
  invoiceReceiptAmount?: number;
  invoiceReceiptId: string;
  invoiceReceipted: boolean;
}

export interface IOfficeReceiptCreateInitialisationDataDto {
  lastTranNum: number;
  preference: IOfficeReceiptPreferenceDto;
  bankAccounts: IOfficeReceiptBankAccountDto[];
  paymentTypeList: IPaymentTypeDto[];
}

export interface IPaymentTypeDto {
  paymentTypeGUID: string;
  paymentTypeID: number;
  subPaymentTypeID: number;
  nameFull: string;
  autoChequeNumber: boolean;
  needsBanking: boolean;
  clearanceDays?: number;
  releted: boolean;
  rowVersion: number;
  autoEFTNumber: boolean;
}

export interface IBulkReceiptingPostingDto {
  receipting: IInvoiceOfficeReceiptingDto;
  createReceipt: boolean;
}

export const invoiceStatusPaid = true;
export const invoiceStatusUnpaid = false;
