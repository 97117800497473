import { SvgIcon } from "@mui/material";

function CheckboxIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{
        backgroundColor: "white",
        height: 13,
        width: 13,
      }}
      viewBox="0 0 24 24"
    >
      <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z" />
    </SvgIcon>
  );
}

export default CheckboxIcon;
