import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "..";
import { IMatterListsDto } from "../../../shared/dto/common-list.dto";
import { IMatterDetailsDto } from "../../../shared/dto/matter-details.dto";
import { environment } from "../../../shared/environments";
import { defaultHeaders } from "../../../shared/utils/request-utils";
import { close } from "../../../shared/utils/sdk-utils";
import { setErrors } from "../app/appSlice";

export interface MatterState {
  matterDetails: IMatterDetailsDto | undefined;
  matterLists: IMatterListsDto | undefined;
  saving: boolean;
  loading: boolean;
}

const initialState: MatterState = {
  matterDetails: undefined,
  matterLists: undefined,
  saving: false,
  loading: false,
};

export const getMatterDetails = createAsyncThunk(
  "getMatterDetails",
  async (showLoader: boolean, thunkApi) => {
    const urlParams = new URLSearchParams(window.location.search);
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    if (!!sdkApi) {
      try {
        const res = await axios.get(
          `${environment().backendUrl}/matter/${
            urlParams.get("matterid") || ""
          }`,
          await defaultHeaders(sdkApi)
        );
        return res.data;
      } catch (errorResponse: any) {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc], () => {
            close(sdkApi);
          })
        );
        return thunkApi.rejectWithValue({
          errorResponse: errorResponse.response.data,
        });
      }
    }
  }
);

export const getCrownLists = createAsyncThunk(
  "getCrownLists",
  async (tableNo: number | undefined, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/crowndata?tableNo=${currentTableNo}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getCrimeLists = createAsyncThunk(
  "getCrimeLists",
  async (tableNo: number | undefined, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/crimedata?tableNo=${currentTableNo}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getMediationLists = createAsyncThunk(
  "getMediationLists",
  async (tableNo: number | undefined, thunkApi) => {
    const appInit = (thunkApi.getState() as RootState).app.appInit;
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/mediationdata?tableNo=${currentTableNo}&matterTypeId=${
          appInit?.laMatterTypeId || ""
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getMentalHealthLists = createAsyncThunk(
  "getMentalHealthLists",
  async (tableNo: number | undefined, thunkApi) => {
    const appInit = (thunkApi.getState() as RootState).app.appInit;
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/mentalhealthdata?tableNo=${currentTableNo}&matterTypeId=${
          appInit?.laMatterTypeId
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getFamilyLists = createAsyncThunk(
  "getFamilyLists",
  async (tableNo: number | undefined, thunkApi) => {
    const appInit = (thunkApi.getState() as RootState).app.appInit;
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/familydata?tableNo=${currentTableNo}&matterTypeId=${
          appInit?.laMatterTypeId
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getDebtLists = createAsyncThunk(
  "getDebtLists",
  async (tableNo: number | undefined, thunkApi) => {
    const appInit = (thunkApi.getState() as RootState).app.appInit;
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/debtdata?tableNo=${currentTableNo}&matterTypeId=${
          appInit?.laMatterTypeId
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getImmigrationLists = createAsyncThunk(
  "getImmigrationLists",
  async (tableNo: number | undefined, thunkApi) => {
    const appInit = (thunkApi.getState() as RootState).app.appInit;
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/immigrationdata?tableNo=${currentTableNo}&matterTypeId=${
          appInit?.laMatterTypeId
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getHousingLists = createAsyncThunk(
  "getHousingLists",
  async (tableNo: number | undefined, thunkApi) => {
    const appInit = (thunkApi.getState() as RootState).app.appInit;
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/housingdata?tableNo=${currentTableNo}&matterTypeId=${
          appInit?.laMatterTypeId
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getCivilLists = createAsyncThunk(
  "getCivilLists",
  async (tableNo: number | undefined, thunkApi) => {
    const appInit = (thunkApi.getState() as RootState).app.appInit;
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    const currentTableNo = !!tableNo
      ? tableNo
      : matterDetails?.legalAidObj?.tableNo || 0;
    try {
      const res = await axios.get(
        `${
          environment().backendUrl
        }/legalaiddata/civildata?tableNo=${currentTableNo}&matterTypeId=${
          appInit?.laMatterTypeId
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const checkUFN = createAsyncThunk(
  "checkUFN",
  async (data: { ufnDate: string; ufnNumber: string }, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/matter/${
          matterDetails?.matterId || ""
        }/checkufn?ufndate=${data.ufnDate}&ufnnumber=${data.ufnNumber}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const checkDSCC = createAsyncThunk(
  "checkDSCC",
  async (data: { dscc: string }, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/matter/${
          matterDetails?.matterId || ""
        }/checkdscc?dscc=${data.dscc}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getTableNo = createAsyncThunk(
  "getTableNo",
  async (date: string, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    const matterDetails = (thunkApi.getState() as RootState).matter
      .matterDetails;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/matter/tableno?matterTypeId=${
          matterDetails?.legalAidObj?.matterTypeId || 0
        }&date=${date}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const putDefinables = createAsyncThunk(
  "putDefinables",
  async (matterDetails: IMatterDetailsDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.put(
        `${environment().backendUrl}/matter/${
          matterDetails.matterId
        }/definables`,
        matterDetails,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc || ""])
      );
      return thunkApi.rejectWithValue(errorResponse.response);
    }
  }
);

export const matterSlice = createSlice({
  name: "matter",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMatterDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMatterDetails.fulfilled, (state, action) => {
        state.matterDetails = action.payload;
        if (!action.meta.arg) {
          state.loading = false;
        }
      })
      .addCase(getMatterDetails.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCrownLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCrownLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(getMediationLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMediationLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(getMentalHealthLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMentalHealthLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(getFamilyLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFamilyLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(getImmigrationLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImmigrationLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(getHousingLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHousingLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(getCivilLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCivilLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(checkUFN.pending, (state) => {
        state.loading = false;
      })
      .addCase(checkUFN.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkDSCC.pending, (state) => {
        state.loading = false;
      })
      .addCase(checkDSCC.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkDSCC.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCrimeLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCrimeLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(getDebtLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDebtLists.fulfilled, (state, action) => {
        state.matterLists = action.payload;
        state.loading = false;
      })
      .addCase(putDefinables.pending, (state) => {
        state.loading = true;
        state.saving = true;
      })
      .addCase(putDefinables.fulfilled, (state) => {
        state.loading = false;
        state.saving = false;
      })
      .addCase(putDefinables.rejected, (state, action) => {
        state.loading = false;
        state.saving = false;
      });
  },
});

export const { loading } = matterSlice.actions;

export default matterSlice.reducer;
