import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "..";
import { IBillingStageDto } from "../../../shared/dto/billing-stage.dto";
import { IFeeDto } from "../../../shared/dto/fee.dto";
import { IMatterDetailsDto } from "../../../shared/dto/matter-details.dto";
import {
  AgfsGroup,
  BillingItemRate,
  CpgfsGroup,
  ExpertGroup,
  FasGroup,
  ImmType,
  MHRate,
  PflrsGroup,
} from "../../../shared/dto/rates.dto";
import { ITaskCodeDto } from "../../../shared/dto/task-code.dto";
import { ITaxCodeDto } from "../../../shared/dto/tax-code.dto";
import { environment } from "../../../shared/environments";
import { defaultHeaders } from "../../../shared/utils/request-utils";
import { close } from "../../../shared/utils/sdk-utils";
import {
  setErrors,
  setLoader,
  setValidationErrors,
  setWarningAcknowledgment,
} from "../app/appSlice";

export interface FeeState {
  fee: IFeeDto[] | undefined;
  billingStages: IBillingStageDto[] | undefined;
  fasGroups: FasGroup[] | undefined;
  cpgfsGroups: CpgfsGroup[] | undefined;
  pflrsGroups: PflrsGroup[] | undefined;
  expertGroups: ExpertGroup[] | undefined;
  mentalHealthRate: MHRate | undefined;
  agfsGroups: AgfsGroup[] | undefined;
  immRates: ImmType | undefined;
  billingItemRate: BillingItemRate | undefined;
  taxCodes: ITaxCodeDto[] | undefined;
  taskCodes: ITaskCodeDto[] | undefined;
  saving: boolean;
  loading: boolean;
}

const initialState: FeeState = {
  fee: undefined,
  billingStages: undefined,
  fasGroups: undefined,
  cpgfsGroups: undefined,
  pflrsGroups: undefined,
  expertGroups: undefined,
  mentalHealthRate: undefined,
  agfsGroups: undefined,
  immRates: undefined,
  billingItemRate: undefined,
  taxCodes: undefined,
  taskCodes: undefined,
  saving: false,
  loading: false,
};

export const initFee = createAsyncThunk(
  "initFee",
  async (
    params: {
      matterDetails: IMatterDetailsDto;
      type: string;
      timeElapsed?: number;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/init?matterId=${
          params.matterDetails.matterId || ""
        }&taxFree=${params.matterDetails.taxFree || false}&type=${
          params.type
        }&timeelapsed=${params.timeElapsed || 0}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getBillingStages = createAsyncThunk(
  "getBillingStages",
  async (matterTypeId: number, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/billingstage/${matterTypeId}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getFees = createAsyncThunk(
  "getFees",
  async (feeIds: string[], thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.post(
        `${environment().backendUrl}/fee/getfees`,
        feeIds,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getFasGroups = createAsyncThunk(
  "getFasGroups",
  async (tableNo: number, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/fas?tableNo=${tableNo}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getCpgfsGroups = createAsyncThunk(
  "getCpgfsGroups",
  async (tableNo: number, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/cpgfs?tableNo=${tableNo}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getPflrsGroups = createAsyncThunk(
  "getPflrsGroups",
  async (data: { tableNo: number; rate: string }, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/pflrs?tableNo=${data.tableNo}&rate=${
          data.rate
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getExpertGroups = createAsyncThunk(
  "getExpertGroups",
  async (
    data: {
      tableNo: number;
      billingStageId: number;
      tableNoRepOrder: number;
      matterTypeId: number;
      rate: string;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/expertrates?matterTypeId=${
          data.matterTypeId
        }&tableNo=${data.tableNo}&rate=${data.rate}&billingStageId=${
          data.billingStageId
        }&tableNoRepOrder=${data.tableNoRepOrder}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getMentalHealthGroups = createAsyncThunk(
  "getMentalHealthGroups",
  async (
    data: {
      tableNo: number;
      matterTypeId: number;
      caseStageCode: string;
      rate: string;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/mhrates?matterTypeId=${
          data.matterTypeId
        }&tableNo=${data.tableNo}&rate=${data.rate}&caseStageCode=${
          data.caseStageCode
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getAgfsGroups = createAsyncThunk(
  "getAgfsGroups",
  async (
    data: {
      schemeNo: number;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/agfsrates?schemeNo=${data.schemeNo}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getImmigrationRates = createAsyncThunk(
  "getImmigrationRates",
  async (
    data: {
      matterTypeId: number;
      tableNo: number;
      rate: string;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/immrates?matterTypeId=${
          data.matterTypeId
        }&tableNo=${data.tableNo}&rate=${data.rate}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getActivities = createAsyncThunk(
  "getActivities",
  async (matter: IMatterDetailsDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/fee/activities?matterTypeId=${
          matter.legalAidObj?.matterTypeId || 0
        }&tableNo=${matter.legalAidObj?.tableNo || 0}&rate=${
          matter.legalAidObj?.rate || ""
        }&tableNoRepOrder=${matter.legalAidObj?.tableNoRepOrder || 0}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc], () => {
          close(sdkApi);
        })
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getTaxCodes = createAsyncThunk(
  "getTaxCodes",
  async (_, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/taxcodes`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getTaskCodes = createAsyncThunk(
  "getTaskCodes",
  async (_, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.get(
        `${environment().backendUrl}/taskcodes`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const saveFee = createAsyncThunk(
  "saveFee",
  async (fee: IFeeDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      if (!fee.itemId) {
        const res = await axios.post(
          `${environment().backendUrl}/fee`,
          fee,
          await defaultHeaders(sdkApi)
        );
        return res.data;
      } else {
        const res = await axios.put(
          `${environment().backendUrl}/fee/${fee.itemId}`,
          fee,
          await defaultHeaders(sdkApi)
        );
        return res.data;
      }
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      if (
        errorResponse.response.data.length &&
        errorResponse.response.data[0].code
      ) {
        thunkApi.dispatch(
          setWarningAcknowledgment(errorResponse.response.data[0], () => {
            thunkApi
              .dispatch(
                saveFee({
                  ...fee,
                  warningAcknowledgments: [
                    ...(fee?.warningAcknowledgments || []),
                    errorResponse.response.data[0]?.code || "",
                  ],
                })
              )
              .then(() => close(sdkApi));
          })
        );
      } else {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc])
        );
      }

      return thunkApi.rejectWithValue(errorResponse.response);
    }
  }
);

export const saveFees = createAsyncThunk(
  "saveFees",
  async (
    data: { fees: IFeeDto[]; feeId?: string; multi: boolean },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      let tempFees: IFeeDto[] = JSON.parse(JSON.stringify(data.fees));
      tempFees.forEach((fee) => {
        fee.warningAcknowledgments = [1001, 1002, 1006, 1099];
      });

      const feeIdParam = !!data.feeId ? `&feeId=${data.feeId}` : ``;
      const res = await axios.post(
        `${environment().backendUrl}/fee/multiple?multi=${
          data.multi
        }${feeIdParam}`,
        tempFees,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      thunkApi.dispatch(
        setValidationErrors([
          (errorResponse as any).response.data.error.errorDesc as string,
        ])
      );
      return thunkApi.rejectWithValue((errorResponse as any).error.errorDesc);
    }
  }
);

export const deleteFee = createAsyncThunk(
  "deleteFee",
  async (feeId: string, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.delete(
        `${environment().backendUrl}/fee/${feeId}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      if (
        errorResponse.response.data.length &&
        errorResponse.response.data[0].code
      ) {
        thunkApi.dispatch(
          setWarningAcknowledgment(errorResponse.response.data[0], () => {
            thunkApi.dispatch(deleteFee(feeId)).then(() => close(sdkApi));
          })
        );
      } else {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc])
        );
      }

      return thunkApi.rejectWithValue(errorResponse.response);
    }
  }
);

export const deleteFees = createAsyncThunk(
  "deleteFees",
  async (feeId: string, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const res = await axios.delete(
        `${environment().backendUrl}/fee/multiple?feeId=${feeId}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      // thunkApi.dispatch(
      //   setWarningAcknowledgment(errorResponse.response.data, () => {
      //     thunkApi.dispatch(deleteFee(feeId)).then(() => close(sdkApi));
      //   })
      // );
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue(errorResponse.response);
    }
  }
);

export const feeSlice = createSlice({
  name: "fee",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaxCodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaxCodes.fulfilled, (state, action) => {
        state.taxCodes = action.payload;
        state.loading = false;
      })
      .addCase(getTaskCodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaskCodes.fulfilled, (state, action) => {
        state.taskCodes = action.payload;
        state.loading = false;
      })
      .addCase(initFee.pending, (state) => {
        state.loading = true;
      })
      .addCase(initFee.fulfilled, (state, action) => {
        state.fee = action.payload;
        state.loading = false;
      })
      .addCase(initFee.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFees.fulfilled, (state, action) => {
        state.fee = action.payload;
        state.loading = false;
      })
      .addCase(getBillingStages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingStages.fulfilled, (state, action) => {
        state.billingStages = action.payload;
        state.loading = false;
      })
      .addCase(getFasGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFasGroups.fulfilled, (state, action) => {
        state.fasGroups = action.payload;
        state.loading = false;
      })
      .addCase(getCpgfsGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCpgfsGroups.fulfilled, (state, action) => {
        state.cpgfsGroups = action.payload;
        state.loading = false;
      })
      .addCase(getPflrsGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPflrsGroups.fulfilled, (state, action) => {
        state.pflrsGroups = action.payload;
        state.loading = false;
      })
      .addCase(getExpertGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpertGroups.fulfilled, (state, action) => {
        state.expertGroups = action.payload;
        state.loading = false;
      })
      .addCase(getMentalHealthGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMentalHealthGroups.fulfilled, (state, action) => {
        state.mentalHealthRate = action.payload;
        state.loading = false;
      })
      .addCase(getAgfsGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgfsGroups.fulfilled, (state, action) => {
        state.agfsGroups = action.payload;
        state.loading = false;
      })
      .addCase(getImmigrationRates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImmigrationRates.fulfilled, (state, action) => {
        state.immRates = action.payload;
        state.loading = false;
      })
      .addCase(getActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.billingItemRate = action.payload;
        state.loading = false;
      })
      .addCase(deleteFee.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFee.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteFees.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFees.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveFee.pending, (state) => {
        state.loading = true;
        state.saving = true;
      })
      .addCase(saveFee.fulfilled, (state, action) => {
        state.loading = false;
        state.saving = false;
      })
      .addCase(saveFee.rejected, (state, action) => {
        state.loading = false;
        state.saving = false;
      })
      .addCase(saveFees.pending, (state) => {
        state.loading = true;
        state.saving = true;
      })
      .addCase(saveFees.fulfilled, (state, action) => {
        state.loading = false;
        state.saving = false;
      })
      .addCase(saveFees.rejected, (state, action) => {
        state.loading = false;
        state.saving = false;
      });
  },
});

export const { loading } = feeSlice.actions;

export default feeSlice.reducer;
