import { Avatar, Tooltip } from "@mui/material";
import { FC } from "react";
import { IUserDto } from "../../shared/dto/user.dto";

interface IStaffAvatarProps {
  staffInitials?: string;
  hideMargin?: boolean;
  staff?: IUserDto;
}

const StaffAvatar: FC<IStaffAvatarProps> = ({
  staffInitials,
  hideMargin,
  staff,
}) => {
  return (
    <>
      {!!staff ? (
        <Tooltip title={`${staff?.firstName} ${staff.lastName}` || ""}>
          <Avatar
            sx={{
              height: 28,
              width: 28,
              marginLeft: hideMargin ? 0 : "auto",
              marginRight: hideMargin ? 0 : "auto",
              fontSize: 12,
              backgroundColor: "#a7a7a7",
            }}
          >
            {staff?.initials || "?"}
          </Avatar>
        </Tooltip>
      ) : (
        <Avatar
          sx={{
            height: 28,
            width: 28,
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: 12,
            backgroundColor: "#a7a7a7",
          }}
        >
          {staffInitials || "?"}
        </Avatar>
      )}
    </>
  );
};

export default StaffAvatar;
