import { FC } from "react";
import { Button, Typography } from "@mui/material";

import { close } from "../../shared/utils/sdk-utils";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import CustomDialog from "./custom-dialog";

interface UnsavedDataProps {
  isOpen?: boolean;
  onClose: () => void;
  onYesCallback?: () => void;
}

const UnsavedDataDialog: FC<UnsavedDataProps> = ({
  isOpen,
  onClose,
  onYesCallback,
}) => {
  const sdkApi = useAppSelector((state: RootState) => state.app.sdkApi);

  return (
    <CustomDialog
      isOpen={!!isOpen}
      title="Unsaved data"
      onClose={() => onClose()}
      showWarningIcon
      actions={
        <>
          <Button
            variant="contained"
            onClick={() => {
              if (onYesCallback) onYesCallback();
              else close(sdkApi);
            }}
            style={{ marginRight: 5 }}
            disableRipple
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            color="secondary"
            disableRipple
          >
            No
          </Button>
        </>
      }
      content={<Typography>Are you sure you want to cancel?</Typography>}
    />
  );
};

export default UnsavedDataDialog;
