import { Button } from "@mui/material";
import { FC } from "react";

const footertStyle = {
  display: "flex",
  flexFlow: "row-reverse",
  borderTop: "1px solid #a9a9a9",
  padding: "12px 15px",
  backgroundColor: "#f0f1f2",
};

interface IFooterProps {
  onSave?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

const Footer: FC<IFooterProps> = ({ onSave, onCancel, onClose }) => {
  return (
    <>
      <div style={footertStyle}>
        {onCancel && (
          <Button
            variant="outlined"
            onClick={onCancel}
            color="secondary"
            disableRipple
          >
            Cancel
          </Button>
        )}
        {onSave && (
          <Button
            variant="contained"
            onClick={onSave}
            style={{ marginRight: 5 }}
            disableRipple
          >
            Save & Close
          </Button>
        )}
        {onClose && (
          <Button
            variant="outlined"
            onClick={onClose}
            color="secondary"
            disableRipple
          >
            Close
          </Button>
        )}
      </div>
    </>
  );
};

export default Footer;
