import { environment as environmentTest } from "./uk-test";
import { environment as environmentLive } from "./uk-live";
import { environment as environmentLocal } from "./local";

export const environment = () => {
  switch (process.env.REACT_APP_ENV) {
    case "uk-test":
      return environmentTest;
    case "uk-live":
      return environmentLive;
    case "local":
      return environmentLocal;
  }
  return environmentTest;
};