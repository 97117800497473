import { InputLabel, Select, TextField } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getFamilyLists,
  getMatterDetails,
  putDefinables,
} from "../../app/store/matter/matterSlice";
import {
  ListCodeDesc,
  ProcurementAreas,
} from "../../shared/dto/common-list.dto";
import {
  IMatterDetailsDto,
  MatterLegalAidObj,
} from "../../shared/dto/matter-details.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  getMatterRequiredFields,
  validate,
} from "../../shared/utils/validation-utils";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import MatterComponent from "../components/matter-component";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { getFilteredProcurementAreas } from "../../shared/utils/matter-utils";

const FamilyMatterDetails: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const initialMatterDetails = useRef<IMatterDetailsDto | undefined>(undefined);
  const matterRef = useRef<IMatterDetailsDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const matterLists = useSelector(
    (state: RootState) => state.matter.matterLists
  );
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.matter.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);

  const [procurementAreaList, setProcurementAreaList] = useState<
    ProcurementAreas[] | undefined
  >(undefined);
  const [accessPoints, setAccessPoints] = useState<ListCodeDesc[] | undefined>(
    undefined
  );
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(matterRef.current) !==
      JSON.stringify(initialMatterDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    matterRef.current = currentMatterDetails;
  }, [currentMatterDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(true))
        .unwrap()
        .then(() => {
          dispatch(getFamilyLists());
        })
        .catch(() => {});
      dispatch(getUsers());
    }
  }, [dispatch]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !!matterLists && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      initialMatterDetails.current = matterDetails;
      const group = matterLists?.procurementAreaList.find(
        (a) => a.code === matterDetails.legalAidObj?.procurementArea
      );
      if (group && group.accessPoints) {
        setAccessPoints(group.accessPoints);
      }
      getFilteredProcurementAreas(
        matterDetails,
        setCurrentMatterDetails,
        setProcurementAreaList,
        setAccessPoints,
        matterDetails.legalAidObj?.ufnDate,
        matterLists?.procurementAreaList
      );
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Legal Aid Details"
      );
    }
  }, [matterDetails, matterLists, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  return (
    <>
      <TopBar helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Family-Care-Creating-a-New-Legal-Aid-Matter" />
      <div className="main">
        <div className="mainsection">
          <div className="halfWidth">
            <MatterComponent
              matterDetails={currentMatterDetails}
              onUpdate={(tempMatterDetails) => {
                getFilteredProcurementAreas(
                  tempMatterDetails,
                  (matterDetails) => setCurrentMatterDetails(matterDetails),
                  (procurementAreaList) =>
                    setProcurementAreaList(procurementAreaList),
                  (accessPoints) => setAccessPoints(accessPoints),
                  tempMatterDetails?.legalAidObj?.ufnDate,
                  matterLists?.procurementAreaList
                );
              }}
              getLists={(tableNo) => {
                dispatch(getFamilyLists(tableNo));
              }}
              branchList={matterLists?.branchList}
              rateList={matterLists?.rateList}
            />

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>LA Order No.</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.laOrderNumber || ""}
                  placeholder="NNNNNNNNNNNN"
                  inputProps={{ maxLength: 12 }}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        laOrderNumber: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>LA Order Date</InputLabel>
              </div>
              <div className="flex7">
                <LocalDatePicker
                  value={
                    currentMatterDetails?.legalAidObj?.laOrderDate
                      ? currentMatterDetails?.legalAidObj?.laOrderDate
                      : null
                  }
                  onUpdate={(value) => {
                    if (
                      value !== currentMatterDetails?.legalAidObj?.laOrderDate
                    ) {
                      const newValue = value;

                      if (newValue !== "Invalid date") {
                        setCurrentMatterDetails({
                          ...currentMatterDetails,
                          legalAidObj: {
                            ...currentMatterDetails?.legalAidObj,
                            laOrderDate: newValue,
                          } as MatterLegalAidObj,
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Court</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.court || ""}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        court: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Case No.</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.caseNumber || ""}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        caseNumber: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Type of Law</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.family?.typeOfLaw || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          family: {
                            ...currentMatterDetails?.legalAidObj?.family,
                            typeOfLaw: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(matterLists?.typeOfLawList || [])?.map((typeOfLaw) => (
                      <option key={typeOfLaw.value} value={typeOfLaw.value}>
                        {typeOfLaw.value}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Court/Level of Judge</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.family
                        ?.courtLevelOfJudge || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          family: {
                            ...currentMatterDetails?.legalAidObj?.family,
                            courtLevelOfJudge: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(matterLists?.courtJudgeList || [])?.map((courtJudge) => (
                      <option key={courtJudge.value} value={courtJudge.value}>
                        {courtJudge.value}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="halfWidthWithPadding">
            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Procurement Area</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.procurementArea || ""
                    }
                    onChange={(e) => {
                      const tempMatterDetails = {
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          procurementArea: e.target.value,
                          accessPoint: "",
                        } as MatterLegalAidObj,
                      };
                      getFilteredProcurementAreas(
                        tempMatterDetails,
                        (matterDetails) =>
                          setCurrentMatterDetails(matterDetails),
                        (procurementAreaList) =>
                          setProcurementAreaList(procurementAreaList),
                        (accessPoints) => setAccessPoints(accessPoints),
                        tempMatterDetails?.legalAidObj?.ufnDate,
                        matterLists?.procurementAreaList
                      );
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(procurementAreaList || []).map((procurementArea) => (
                      <option
                        key={procurementArea.code}
                        value={procurementArea.code}
                      >
                        {`${procurementArea.code} ${procurementArea.description}`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Access Point</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    variant="outlined"
                    value={currentMatterDetails?.legalAidObj?.accessPoint || ""}
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          accessPoint: e.target.value,
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {accessPoints?.map((accessPoint) => (
                      <option
                        key={`${accessPoint.description} [${accessPoint.code}]`}
                        value={accessPoint.code}
                      >
                        {`${accessPoint.code} ${accessPoint.description}`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Exemption Criteria</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    fullWidth
                    variant="outlined"
                    native
                    value={
                      currentMatterDetails?.legalAidObj?.exemptionCriteria || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          exemptionCriteria: e.target.value,
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {matterLists?.exemptionCriteriaList?.map(
                      (exemptionCriteria) => (
                        <option
                          key={`${exemptionCriteria.description} [${exemptionCriteria.code}]`}
                          value={exemptionCriteria.code}
                        >
                          {`${exemptionCriteria.code} ${exemptionCriteria.description}`}
                        </option>
                      )
                    )}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>ECF Reference</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={currentMatterDetails?.legalAidObj?.ecfReference || ""}
                  placeholder="NNNNNNNAA"
                  inputProps={{ maxLength: 9 }}
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        ecfReference: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Case Stage Level</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.caseStageCode || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          caseStageCode: e.target.value,
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {matterLists?.caseStageList?.map((caseStage) => (
                      <option
                        key={`${caseStage.description} [${caseStage.code}]`}
                        value={caseStage.code}
                      >
                        {`${caseStage.code} ${caseStage.description}`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Local Authority No.</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.authorityNumber || ""
                  }
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        authorityNumber: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>
            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Client Type</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.family?.clientType ||
                      ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          family: {
                            ...currentMatterDetails?.legalAidObj?.family,
                            clientType: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {matterLists?.clientTypeList?.map((clientType) => (
                      <option key={clientType.value} value={clientType.value}>
                        {clientType.value}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Schedule Reference</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.scheduleReference || ""
                  }
                  placeholder="NANNNA/AAA/NN"
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        scheduleReference: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Transfer Date</InputLabel>
              </div>
              <div className="flex7">
                <LocalDatePicker
                  value={
                    currentMatterDetails?.legalAidObj?.transferDate
                      ? currentMatterDetails?.legalAidObj?.transferDate
                      : null
                  }
                  onUpdate={(value) => {
                    if (
                      value !== currentMatterDetails?.legalAidObj?.transferDate
                    ) {
                      const newValue = value;

                      if (newValue !== "Invalid date") {
                        setCurrentMatterDetails({
                          ...currentMatterDetails,
                          legalAidObj: {
                            ...currentMatterDetails?.legalAidObj,
                            transferDate: newValue,
                          } as MatterLegalAidObj,
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentMatterDetails &&
            !validate(
              getMatterRequiredFields(currentMatterDetails),
              [
                {
                  value: currentMatterDetails?.legalAidObj?.laOrderNumber,
                  label: "LA Order No.",
                  pattern: /^(.{10}|.{12})$/,
                },
              ],
              (errors) => dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(putDefinables(currentMatterDetails)).then((action) => {
              if (action.meta.requestStatus !== "rejected") {
                close(sdkApi);
              }
            });
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default FamilyMatterDetails;
