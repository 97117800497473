import {
  Button,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getUsers,
  setLoader,
  setValidationErrors,
} from "../../app/store/app/appSlice";
import {
  clearSemaphore,
  createDocument,
  createInvoice,
  getCrownInvoiceLists,
  getInvoice,
  getItemsForInvoice,
  getPrecedentList,
  reverseInvoice,
  setInit,
  setPrecedentId,
  setPrintingEnabled,
  updateInvoice,
} from "../../app/store/invoice/invoiceSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import {
  IDocBuilderModelDto,
  IDocModelDto,
} from "../../shared/dto/document.dto";
import { IInvoiceDto } from "../../shared/dto/invoice/invoice.dto";
import wordIcon from "../../shared/images/word.png";
import {
  calculateBalanceDue,
  createInvoiceDocument,
  printReport,
} from "../../shared/utils/invoice-utils";
import {
  close,
  getDecodedToken,
  onBeforeClose,
  openDocument,
  openReport,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import { formatValue, newGuid } from "../../shared/utils/utils";
import { validate } from "../../shared/utils/validation-utils";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import InvoiceErrorDialog from "../components/invoice-error-dialog";
import NumberDisplay from "../components/number-display";
import ReversalInfo from "../components/reversal-info";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { listStatusItems } from "./lists";
import { useAppInit } from "../../shared/hooks/use-app-init";
import InvoiceDeleteButton from "../components/invoice-delete-button";
import ReverseIcon from "../../shared/images/reverse";

const InvoiceCrownDetails: FC = () => {
  const dispatch = useAppDispatch();

  const invoice = useAppSelector((state) => state.invoice);

  const isInvoiceDetailsInitialised = useRef(false);
  const isInvoiceInitialised = useRef(false);
  const isMatterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const invoiceRef = useRef<IInvoiceDto | undefined>(undefined);
  const decodedToken = useRef<any>(undefined);

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.invoice.saving);
  const userList = useSelector((state: RootState) => state.app.users);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<
    IInvoiceDto | undefined
  >(undefined);
  const [notificationMessage, setNotificationMessage] = useState<
    string | undefined
  >(undefined);
  const [precedentMenuAnchorEl, setPrecedentMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const precedentMenuOpen = Boolean(precedentMenuAnchorEl);
  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const [toBeReversed, setToBeReversed] = useState(false);

  const [searchParams] = useSearchParams();

  const infoIcon = require("../../shared/images/info.png");
  const printerIcon = require("../../shared/images/printer-grey.png");

  const isFormDisabled =
    invoice.init.isReversedOrReversal ||
    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
    toBeReversed ||
    !!invoice.init.invoiceId;

  const convertFormModelToInvoice = (tempInvoice: IInvoiceDto): IInvoiceDto => {
    let invoice: IInvoiceDto = {
      invoiceId: tempInvoice.invoiceId,
      matterId: tempInvoice.matterId,
      externalURL: tempInvoice.externalURL,
      externalJSON: {
        openType: tempInvoice.externalJSON?.openType,
        width: tempInvoice.externalJSON?.width,
        height: tempInvoice.externalJSON?.height,
        minWidth: tempInvoice.externalJSON?.minWidth,
        minHeight: tempInvoice.externalJSON?.minHeight,
        windowTitle: tempInvoice.externalJSON?.windowTitle,
        closeHandler: tempInvoice.externalJSON?.closeHandler,
        matterTypeId: tempInvoice.externalJSON?.matterTypeId,
        billingStageId: tempInvoice.externalJSON?.billingStageId,
        dateConcluded: tempInvoice.externalJSON?.dateConcluded,
        profitCostsVat: tempInvoice.externalJSON?.profitCostsVat,
        travelVat: tempInvoice.externalJSON?.travelVat,
        waitingVat: tempInvoice.externalJSON?.waitingVat,
        profitCosts: tempInvoice.externalJSON?.profitCosts,
        travelCosts: tempInvoice.externalJSON?.travelCosts,
        waitingCosts: tempInvoice.externalJSON?.waitingCosts,
        disbExVat: tempInvoice.externalJSON?.disbExVat,
        disbVat: tempInvoice.externalJSON?.disbVat,
        adviceTime: tempInvoice.externalJSON?.adviceTime,
        travelTime: tempInvoice.externalJSON?.travelTime,
        waitingTime: tempInvoice.externalJSON?.waitingTime,
      },
      invoiceDate: tempInvoice.invoiceDate,
      invoiceNumber: tempInvoice.invoiceNumber,
      invoiceDesc: tempInvoice.invoiceDesc,
      timeRecordGuids: tempInvoice.timeRecordGuids,
      reversalId: tempInvoice.reversalId,
      reversedOrReversal: tempInvoice.reversedOrReversal,
      semaphoreId: tempInvoice.semaphoreId,
      warningAcknowledgments: tempInvoice.warningAcknowledgments,
      status: tempInvoice.status,
      deleted: tempInvoice.deleted,
      totalAdjustments: tempInvoice.totalAdjustments,
      totalApplied: tempInvoice.totalApplied,
    };
    return invoice;
  };

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "id",
      headerName: "ID",
      width: 0,
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      sortable: false,
      renderCell: (params: any) => {
        if (params.value === "Fixed Fee") {
          return (
            <>
              {params.value}
              <Tooltip
                style={{ paddingLeft: 5 }}
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Fixed Fee:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFeeValue,
                        invoice.init.isReversedOrReversal
                      )}
                    <br />
                    Fee Limit:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFeeLimit,
                        invoice.init.isReversedOrReversal
                      )}
                  </div>
                }
              >
                <img alt="warning" src={infoIcon} />
              </Tooltip>
              {currentInvoiceDetails?.externalJSON?.isClaimExceptional &&
                currentInvoiceDetails.externalJSON.fixedFeeValue !== 0 && (
                  <Typography color="grey" marginLeft={6}>
                    (This claim is Exceptional)
                  </Typography>
                )}
            </>
          );
        }

        return params.value;
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "time",
      headerAlign: "center",
      headerName: "TIME",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          notCurrency
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "amount",
      headerAlign: "center",
      headerName: "AMOUNT",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "tax",
      headerAlign: "center",
      headerName: "TAX",
      sortable: false,
      type: "number",
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
  ];

  const handleClose = useCallback(() => {
    if (invoiceRef.current && invoiceRef.current.semaphoreId) {
      dispatch(clearSemaphore(invoiceRef.current.semaphoreId)).then(() => {
        close(sdkApi);
      });
    } else {
      close(sdkApi);
    }
  }, [dispatch, sdkApi]);

  const getData = useCallback(() => {
    if (!isInvoiceInitialised.current) {
      isInvoiceInitialised.current = true;
      getDecodedToken(sdkApi).then((payload) => {
        decodedToken.current = payload;
      });
      dispatch(
        setInit({
          billingStageId: searchParams.get("billingstageid"),
          invoiceId: searchParams.get("invoiceid"),
          isReversedOrReversal: searchParams.get("reversal")
            ? searchParams.get("reversal")
            : false,
          matterTypeId: appInit?.laMatterTypeId,
          matterTypeName: appInit?.laMatterTypeName,
        })
      );

      dispatch(getMatterDetails(false)).then(() => {
        dispatch(getUsers());
      });
    }
  }, [dispatch, appInit, searchParams, sdkApi]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !isMatterDetailsInitialised.current) {
      isMatterDetailsInitialised.current = true;
      dispatch(
        getCrownInvoiceLists({
          matterTypeId: matterDetails?.legalAidObj?.matterTypeId,
          billingStageId: invoice?.init?.billingStageId,
          tableNo: matterDetails?.legalAidObj?.tableNo,
          rate: matterDetails?.legalAidObj?.rate,
        })
      )
        .then(() => {
          dispatch(
            getPrecedentList({
              matterTypeId: matterDetails?.legalAidObj?.matterTypeId,
              billingStageId: invoice?.init?.billingStageId,
            })
          );
        })
        .then(() => {
          if (invoice.init.invoiceId) {
            dispatch(getInvoice(invoice.init.invoiceId)).then((action) => {
              setWindowTitle(
                `${matterDetails?.matterReference || ""} ${
                  matterDetails?.matterDescription || ""
                }`,
                sdkApi,
                60,
                action.payload.externalJSON?.windowTitle
                  ? `- ${action.payload.externalJSON?.windowTitle}`
                  : ""
              );
            });
          } else if (invoice.init.billingStageId) {
            dispatch(
              getItemsForInvoice({
                matterId: matterDetails?.matterId,
                billingStageId: invoice?.init.billingStageId,
                disbursementsOnly: false,
              })
            );
            setWindowTitle(
              `${matterDetails?.matterReference || ""} ${
                matterDetails?.matterDescription || ""
              }`,
              sdkApi,
              60,
              "- New Invoice"
            );
          }
        });
    }
  }, [
    dispatch,
    matterDetails,
    invoice.init,
    isMatterDetailsInitialised,
    sdkApi,
  ]);

  useEffect(() => {
    if (!!invoice.invoice && !isInvoiceDetailsInitialised.current) {
      isInvoiceDetailsInitialised.current = true;
      setCurrentInvoiceDetails(invoice.invoice);
      invoiceRef.current = invoice.invoice;
    }
  }, [currentInvoiceDetails, invoice.invoice, isInvoiceDetailsInitialised]);

  const getTotals = () => {
    let calculationLabel: string = "";
    let totalCost: number = 0.0;
    let totalTime =
      (currentInvoiceDetails?.externalJSON?.adviceTime || 0) +
      (currentInvoiceDetails?.externalJSON?.travelTime || 0) +
      (currentInvoiceDetails?.externalJSON?.waitingTime || 0);
    let totalVat: number = 0.0;

    totalVat =
      (currentInvoiceDetails?.externalJSON?.profitCostsVat || 0) +
      (currentInvoiceDetails?.externalJSON?.travelVat || 0) +
      (currentInvoiceDetails?.externalJSON?.waitingVat || 0);
    totalCost =
      (currentInvoiceDetails?.externalJSON?.profitCosts || 0) +
      (currentInvoiceDetails?.externalJSON?.travelCosts || 0) +
      (currentInvoiceDetails?.externalJSON?.waitingCosts || 0);
    calculationLabel = "Profit Cost + Travel + Waiting";

    totalCost += currentInvoiceDetails?.externalJSON?.disbExVat || 0;
    totalVat += currentInvoiceDetails?.externalJSON?.disbVat || 0;
    calculationLabel += " + Disbursements";

    return {
      totalCost,
      totalTime,
      totalVat,
      calculationLabel,
    };
  };

  useEffect(() => {
    invoiceRef.current = currentInvoiceDetails;
  }, [currentInvoiceDetails]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi && !!invoice.invoice) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => {
        if (
          JSON.stringify(invoice.invoice) !== JSON.stringify(invoiceRef.current)
        ) {
          setShowUnsavedData(true);
        } else {
          handleClose();
        }
      }, sdkApi);
    }
  }, [sdkApi, invoice.invoice, handleClose]);

  const getDataRows = () => {
    let dataRows = [
      {
        id: newGuid(),
        description: "Profit Cost",
        time: currentInvoiceDetails?.externalJSON?.adviceTime,
        amount: currentInvoiceDetails?.externalJSON?.profitCosts,
        tax: currentInvoiceDetails?.externalJSON?.profitCostsVat,
      },
      {
        id: newGuid(),
        description: "Travel",
        time: currentInvoiceDetails?.externalJSON?.travelTime,
        amount: currentInvoiceDetails?.externalJSON?.travelCosts,
        tax: currentInvoiceDetails?.externalJSON?.travelVat,
      },
      {
        id: newGuid(),
        description: "Waiting",
        time: currentInvoiceDetails?.externalJSON?.waitingTime,
        amount: currentInvoiceDetails?.externalJSON?.waitingCosts,
        tax: currentInvoiceDetails?.externalJSON?.waitingVat,
      },
      {
        id: newGuid(),
        description: "Disbursements",
        amount: currentInvoiceDetails?.externalJSON?.disbExVat,
        tax: currentInvoiceDetails?.externalJSON?.disbVat,
      },
    ];

    return dataRows;
  };

  const getRequiredFields = (_data: IInvoiceDto) => {
    let fields = [
      {
        value: _data?.invoiceDate,
        label: "Date Billed",
      },
      {
        value: _data?.externalJSON?.dateConcluded,
        label: "Date Concluded",
      },
    ];
    return fields;
  };

  const renderCriteria = () => {
    return (
      <>
        <div className="displayFlex">
          <div className="flex1">
            <div className="input-row-aligned">
              <div className="flex1">
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex6" style={{ display: "inline-flex" }}>
                <TextField
                  disabled
                  value={matterDetails?.matterReference || ""}
                  variant="outlined"
                  style={{ width: 110 }}
                />
                <Typography
                  style={{ marginTop: 3, marginLeft: 5 }}
                  maxWidth={510}
                  minWidth={510}
                  noWrap
                >
                  {matterDetails?.matterDescription}
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ width: 225 }}>
            <div className="input-row-aligned">
              <InputLabel>Invoice No.</InputLabel>
              <div className="flex1" />
              <TextField
                disabled
                placeholder="Auto no"
                variant="outlined"
                value={currentInvoiceDetails?.invoiceNumber || ""}
                style={{ width: 108 }}
              />
            </div>
          </div>
        </div>
        <div className="displayFlex">
          <div className="flex1" />
          <div style={{ width: 225 }}>
            <div className="input-row-aligned">
              <InputLabel required>Date Concluded</InputLabel>
              <div className="flex1" />
              <div style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled}
                  onUpdate={(value: any) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.dateConcluded
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            dateConcluded: newValue,
                          },
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.dateConcluded
                      ? currentInvoiceDetails.externalJSON?.dateConcluded
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required>Date Billed</InputLabel>
              </div>
              <div className="flex1" />
              <div style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled && !toBeReversed}
                  onUpdate={(value: any) => {
                    if (value !== currentInvoiceDetails?.invoiceDate) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          invoiceDate: newValue,
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.invoiceDate
                      ? currentInvoiceDetails.invoiceDate
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <InputLabel>Status</InputLabel>
              <div className="flex1" />

              <Select
                onChange={(e: any) => {
                  setCurrentInvoiceDetails({
                    ...currentInvoiceDetails,
                    status: e.target.value,
                  });
                }}
                sx={{
                  maxWidth: 170,
                  minWidth: 170,
                }}
                disabled={isFormDisabled && currentInvoiceDetails?.status === 3}
                value={
                  !!currentInvoiceDetails?.status ||
                  currentInvoiceDetails?.status === 0
                    ? currentInvoiceDetails?.status
                    : ""
                }
                variant="outlined"
                native
                className="select"
              >
                {listStatusItems.map((item: any, index: number) => (
                  <option key={`invoiceStatus-${index}`} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderBreakdown = () => {
    return (
      <DataGridPro
        columns={columns}
        columnHeaderHeight={30}
        columnVisibilityModel={{
          id: false,
        }}
        disableRowSelectionOnClick
        getRowId={(row: any) => row.id}
        hideFooter
        initialState={{}}
        rowHeight={26}
        rows={getDataRows()}
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          backgroundColor: "#fff",
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
          ".MuiDataGrid-overlay": { color: "#0060bb" },
          ".MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
        }}
      />
    );
  };

  const renderTotals = () => {
    const totals = getTotals();

    return (
      <div className="displayFlex">
        <div className="flex1">
          <Typography fontWeight={600} marginTop={1} sx={{ color: "#0160aa" }}>
            Total Breakdown
          </Typography>
          <Typography color="grey" marginLeft={2}>
            <i>{totals.calculationLabel}</i>
          </Typography>
          <div style={{ display: "inline-flex", marginTop: 10 }}>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalAdjustments}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">ADJUSTMENTS</Typography>
            </div>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalApplied}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">TOTAL PAID</Typography>
            </div>
          </div>
        </div>
        <div>
          <div
            className="invoice-total-row invoice-total-row-proceeds"
            style={{
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>TOTAL</Typography>
            </div>
            <div style={{ width: 92 }}>
              <NumberDisplay
                value={totals.totalTime}
                notCurrency
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalCost}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-row invoice-total-row-proceeds">
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>INC. TAX</Typography>
            </div>
            <div style={{ width: 192 }}>
              <NumberDisplay
                value={totals.totalCost + totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-footer" style={{ marginLeft: 8 }}>
            <div style={{ width: 90 }}>
              <Typography style={{ color: "#db761d", fontWeight: 600 }}>
                BALANCE DUE
              </Typography>
            </div>
            <div style={{ width: 193 }}>
              <NumberDisplay
                value={calculateBalanceDue(
                  [
                    totals.totalCost,
                    totals.totalVat,
                    currentInvoiceDetails?.totalAdjustments || 0,
                  ],
                  [currentInvoiceDetails?.totalApplied || 0]
                )}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
                bold
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          <>
            <InvoiceDeleteButton
              invoiceDetails={currentInvoiceDetails}
              invoiceRef={invoiceRef.current}
            />
            <Tooltip placement="bottom-end" title="Reverse Transaction">
              <span>
                <IconButton
                  disabled={
                    !currentInvoiceDetails?.invoiceId ||
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                    toBeReversed
                  }
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    setToBeReversed(true);
                  }}
                >
                  <ReverseIcon
                    disabled={
                      !currentInvoiceDetails?.invoiceId ||
                      invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                      toBeReversed
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Crown-Court-Fixed-Fee-Invoice"
        centerComponents={
          <ReversalInfo
            isReversal={
              invoice.init.isReversedOrReversal ||
              Boolean(currentInvoiceDetails?.reversedOrReversal)
            }
          />
        }
        rightComponents={
          <>
            <Tooltip placement="bottom-end" title="Send to Word">
              <IconButton
                aria-controls="precedent-menu"
                disableRipple
                disableTouchRipple
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setPrecedentMenuAnchorEl(event.currentTarget);
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="precedent options" src={wordIcon} />
              </IconButton>
            </Tooltip>
            <Menu
              id="precedent-menu"
              anchorEl={precedentMenuAnchorEl}
              onClose={() => {
                setPrecedentMenuAnchorEl(null);
              }}
              open={precedentMenuOpen}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {(invoice.precedentList || []).map((item: any, index: number) => (
                <MenuItem
                  data-precedent-value={item.guid}
                  key={`precedent-${index}`}
                  onClick={(e) => {
                    createInvoiceDocument(
                      invoice.init.isReversedOrReversal ||
                        Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                        toBeReversed,
                      (message) => setNotificationMessage(message),
                      (id: string) => {
                        const staffId = !!decodedToken.current
                          ? decodedToken.current.staffId
                          : "";
                        dispatch(
                          createDocument({
                            precedentId: item.guid,
                            matterId: matterDetails?.matterId,
                            documentName: item.description,
                            staffInitials: userList?.find(
                              (s) => s.staffId === staffId
                            )?.initials,
                            isCustomPrecedent: false,
                            parameters: {
                              invoiceId: currentInvoiceDetails?.invoiceId || "",
                              staffId: staffId,
                            },
                          } as IDocBuilderModelDto)
                        ).then((action: any) => {
                          const docModel = action.payload as IDocModelDto;
                          openDocument(
                            {
                              documentId: docModel.documentId,
                              matterId: matterDetails?.matterId,
                            },
                            sdkApi
                          );
                        });
                      },
                      (id) => dispatch(setPrecedentId(id)),
                      currentInvoiceDetails,
                      item.guid
                    );
                    setPrecedentMenuAnchorEl(null);
                  }}
                  value={item.guid}
                >
                  {item.code} - {item.description}
                </MenuItem>
              ))}
            </Menu>
            <Tooltip placement="bottom-end" title="Print">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  printReport(
                    () =>
                      setNotificationMessage(
                        "This transaction will be printed after saving."
                      ),
                    () =>
                      openReport(
                        currentInvoiceDetails?.invoiceId || "",
                        sdkApi
                      ),
                    () => dispatch(setPrintingEnabled(true)),
                    currentInvoiceDetails
                  );
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="print" src={printerIcon} />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <div className="invoice-main">
        <>
          <div className="invoice-criteria">{renderCriteria()}</div>
          <div className={"invoice-breakdown invoice-breakdown-proceeds"}>
            {renderBreakdown()}
          </div>
          <div className="invoice-total">{renderTotals()}</div>
        </>
      </div>
      <Footer
        onCancel={() => {
          if (
            JSON.stringify(invoice.invoice) !==
            JSON.stringify(currentInvoiceDetails)
          ) {
            setShowUnsavedData(true);
          } else {
            handleClose();
          }
        }}
        onSave={() => {
          if (toBeReversed) {
            if (currentInvoiceDetails?.invoiceId) {
              dispatch(reverseInvoice(currentInvoiceDetails))
                .unwrap()
                .then(() => {
                  dispatch(
                    clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                  )
                    .then(() => close(sdkApi))
                    .catch((e) => console.error(e));
                });
            }
          } else {
            if (
              !!currentInvoiceDetails &&
              !validate(
                getRequiredFields(currentInvoiceDetails),
                [],
                (errors) => dispatch(setValidationErrors(errors))
              )?.length &&
              !saving
            ) {
              const totals = getTotals();
              if (totals.totalCost + totals.totalVat > 0) {
                dispatch(setLoader(true));
                if (currentInvoiceDetails.invoiceId) {
                  if (
                    currentInvoiceDetails.status !== invoice.invoice?.status
                  ) {
                    dispatch(
                      updateInvoice(
                        convertFormModelToInvoice(currentInvoiceDetails)
                      )
                    )
                      .unwrap()
                      .then(() => {
                        dispatch(
                          clearSemaphore(
                            currentInvoiceDetails.semaphoreId || ""
                          )
                        )
                          .then(() => close(sdkApi))
                          .catch((e) => console.error(e));
                      });
                  } else {
                    dispatch(
                      clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                    )
                      .then(() => close(sdkApi))
                      .catch((e) => console.error(e));
                  }
                } else {
                  dispatch(
                    createInvoice(
                      convertFormModelToInvoice(currentInvoiceDetails)
                    )
                  )
                    .unwrap()
                    .then((payload: IInvoiceDto) => {
                      dispatch(
                        clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                      ).then(() => {
                        if (invoice.precedentId) {
                          const staffId = !!decodedToken.current
                            ? decodedToken.current.staffId
                            : "";
                          dispatch(
                            createDocument({
                              precedentId: invoice.precedentId,
                              matterId: payload.matterId,
                              documentName: invoice.precedentList?.find(
                                (item) => item.guid === invoice.precedentId
                              )?.description,
                              staffInitials: userList?.find(
                                (s) => s.staffId === staffId
                              )?.initials,
                              parameters: {
                                invoiceId: payload.invoiceId,
                                staffId: staffId,
                              },
                            } as IDocBuilderModelDto)
                          ).then((action: any) => {
                            const docModel = action.payload as IDocModelDto;
                            openDocument(
                              {
                                documentId: docModel.documentId,
                                matterId: payload.matterId,
                              },
                              sdkApi
                            );
                            if (
                              invoice.isPrintingEnabled &&
                              docModel.documentId &&
                              payload.invoiceId
                            ) {
                              openReport(payload.invoiceId, sdkApi).then(() =>
                                close(sdkApi)
                              );
                            } else {
                              close(sdkApi);
                            }
                          });
                        } else if (
                          invoice.isPrintingEnabled &&
                          payload.invoiceId
                        ) {
                          openReport(payload.invoiceId, sdkApi).then(() =>
                            close(sdkApi)
                          );
                        } else close(sdkApi);
                      });
                    })
                    .catch((e) => console.error(e));
                }
              } else
                dispatch(
                  setValidationErrors([
                    "You cannot create an invoice with a value of £0.00",
                  ])
                );
            }
          }
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setNotificationMessage(undefined);
              }}
              variant="contained"
            >
              OK
            </Button>
          </>
        }
        content={<Typography>{notificationMessage}</Typography>}
        isOpen={notificationMessage !== undefined}
        onClose={() => setNotificationMessage(undefined)}
        title="Notification"
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => {
          setShowUnsavedData(false);
        }}
        onYesCallback={() => handleClose()}
      />
      <InvoiceErrorDialog />
    </>
  );
};

export default InvoiceCrownDetails;
