import {
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getBillingStages,
  getFees,
  getTaxCodes,
  initFee,
  saveFee,
} from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { FeeLegalAidObj, IFeeDto } from "../../shared/dto/fee.dto";

import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  isNullOrEmptyGuid,
} from "../../shared/utils/utils";
import {
  moreThan30DaysInFuture,
  validate,
} from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomCheckbox from "../components/custom-checkbox";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import NumberInput from "../components/number-input";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { useAppInit } from "../../shared/hooks/use-app-init";

const getCounselRequiredFields = (fee: IFeeDto) => {
  return [
    {
      value: fee?.transactionDate,
      label: "Date",
    },
    {
      value: fee?.legalAidObj?.billingStageId,
      label: "Billing Stage",
    },
    {
      value: fee?.unit,
      label: "Quantity",
    },
  ];
};

const CounselFee: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const dataInitialised = useRef(false);
  const initialFeeDetails = useRef<IFeeDto | undefined>(undefined);
  const feeRef = useRef<IFeeDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const billingStages = useSelector(
    (state: RootState) => state.fee.billingStages
  );
  const users = useSelector((state: RootState) => state.app.users);
  const taxCodes = useSelector((state: RootState) => state.fee.taxCodes);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.fee.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<
    IFeeDto | undefined
  >(undefined);

  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const feeId = urlParams.get("feeid");

  const calcTotals = useCallback(
    (feeDetails: IFeeDto) => {
      const currentTaxCode = taxCodes?.find(
        (t) => t.taxCodeId === feeDetails?.taxCodeId
      );
      let tax = 0;
      let totalTax = 0;
      let total = 0;
      let tempTotalAmount = 0;
      if (currentTaxCode) {
        tax = (currentTaxCode?.ratePercent || 0) / 100;
        tempTotalAmount = (feeDetails.unit || 0) * (feeDetails?.rate || 0);
        if (tax > 0) {
          totalTax = (tempTotalAmount || 0) * tax;
        }
        if (!!feeDetails.incTax) {
          totalTax = (tempTotalAmount || 0) * (1 - 1 / (tax + 1));
          tax = 0;
        }
        total = (tempTotalAmount || 0) * (1 + tax);
      }
      const tempFee = {
        ...feeDetails,
        amount: tempTotalAmount,
      } as IFeeDto;
      setCurrentFeeDetails(tempFee);
      if (!dataInitialised.current) {
        initialFeeDetails.current = tempFee;
      }
      dataInitialised.current = true;
      setTotalAmount(tempTotalAmount);
      setTotal(total);
      setTax(totalTax);
    },
    [taxCodes]
  );

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(feeRef.current) !==
      JSON.stringify(initialFeeDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    feeRef.current = currentFeeDetails;
  }, [currentFeeDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(false)).then((matterAction) => {
        if (matterAction.meta.requestStatus !== "rejected") {
          if (feeId) {
            dispatch(getFees([feeId || ""])).then(() => {
              dispatch(
                getBillingStages(
                  (matterAction.payload as IMatterDetailsDto).legalAidObj
                    ?.matterTypeId || 0
                )
              );
            });
          } else {
            dispatch(
              initFee({
                matterDetails: matterAction.payload as IMatterDetailsDto,
                type: "counsel",
              })
            ).then(() => {
              dispatch(
                getBillingStages(
                  (matterAction.payload as IMatterDetailsDto).legalAidObj
                    ?.matterTypeId || 0
                )
              );
            });
          }
        }
      });
      dispatch(getTaxCodes());
      dispatch(getUsers());
    }
  }, [dispatch, feeId]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Counsel Fee"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!!feeDetails && !feeDetailsInitialised.current) {
      feeDetailsInitialised.current = true;
      let currentFee = { ...feeDetails[0] };
      if (isNullOrEmptyGuid(currentFee.legalAidObj?.taskCodeId)) {
        currentFee = {
          ...currentFee,
          legalAidObj: {
            ...currentFee.legalAidObj,
            taskCodeId: "5900F120-6C7F-6F42-B646-A999DE5EABB6",
          },
        };
      }
      calcTotals(currentFee);
      initialFeeDetails.current = currentFee;
      setCurrentFeeDetails(currentFee);
    }
  }, [feeDetails, feeDetailsInitialised, calcTotals]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  const isFormDisabled =
    currentFeeDetails?.billed || currentFeeDetails?.deleted;

  return (
    <>
      <TopBar
        helpUrl="https://community.leap.co.uk/s/article/Creating-a-Legal-Aid-Cost-Recovery"
        leftComponents={
          <FeeDeleteButton
            fee={currentFeeDetails}
            feeRef={initialFeeDetails.current}
          />
        }
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex5">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography style={{ maxWidth: 350, minWidth: 350 }} noWrap>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>

              <div className="flex3">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Date
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <LocalDatePicker
                      value={
                        currentFeeDetails?.transactionDate
                          ? currentFeeDetails?.transactionDate
                          : null
                      }
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        if (value !== currentFeeDetails?.transactionDate) {
                          const newValue = value || "";

                          if (newValue !== "Invalid date") {
                            setCurrentFeeDetails({
                              ...currentFeeDetails,
                              transactionDate: newValue,
                            } as IFeeDto);
                          }
                        }
                      }}
                      validate={(value) =>
                        moreThan30DaysInFuture(
                          value,
                          currentFeeDetails?.transactionDate
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex3">
                <Select
                  className="select"
                  fullWidth
                  native
                  variant="outlined"
                  value={currentFeeDetails?.legalAidObj.billingStageId || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        billingStageId: e.target.value,
                      },
                    } as IFeeDto);
                  }}
                >
                  {!currentFeeDetails?.legalAidObj.billingStageId && (
                    <option key="" value="" className="emptyMenuItem"></option>
                  )}
                  {[...(billingStages || [])]
                    ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                    ?.map((billingStage) => (
                      <option key={billingStage.id} value={billingStage.id}>
                        {billingStage.description}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Staff
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <Select
                      className="select"
                      fullWidth
                      native
                      variant="outlined"
                      value={currentFeeDetails?.legalAidObj.feeEarnerId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        setCurrentFeeDetails({
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            feeEarnerId: e.target.value,
                          } as FeeLegalAidObj,
                        } as IFeeDto);
                      }}
                    >
                      {!currentFeeDetails?.legalAidObj.feeEarnerId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {filterInactiveStaff(
                        users,
                        currentFeeDetails?.legalAidObj.feeEarnerId
                      )?.map((user) => (
                        <option key={user.staffId} value={user.staffId}>
                          {`${user.firstName} ${user.lastName}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRowTextArea">
              <div style={{ width: 130 }}>
                <InputLabel>Billing Description</InputLabel>
              </div>
              <div className="flex1">
                <TextareaAutosize
                  value={currentFeeDetails?.description || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      description: e.target.value,
                    } as IFeeDto);
                  }}
                  minRows={3}
                  maxRows={3}
                  className="textarea-fee"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }} />
              <div className="flex3" />

              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    AMOUNT
                  </Typography>
                  <CurrencyInput disabled value={totalAmount} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Quantity</InputLabel>
              </div>
              <div className="flex3">
                <div style={{ width: 140 }}>
                  <NumberInput
                    value={currentFeeDetails?.unit}
                    disabled={isFormDisabled}
                    decimalScale={0}
                    updater={(value) => {
                      let tempFee = {
                        ...currentFeeDetails,
                        unit: value,
                      } as IFeeDto;
                      calcTotals(tempFee);
                    }}
                    width={65}
                  />
                </div>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TAX
                  </Typography>
                  <CurrencyInput disabled value={tax} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }}>
                    <Select
                      variant="outlined"
                      native
                      value={currentFeeDetails?.taxCodeId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          taxCodeId: e.target.value,
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                      fullWidth
                    >
                      {!currentFeeDetails?.taxCodeId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {taxCodes?.map((taxCode) => (
                        <option
                          key={taxCode.taxCodeId}
                          value={taxCode.taxCodeId}
                        >
                          {taxCode.taxCode}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Amount Each</InputLabel>
              </div>
              <div className="flex3">
                <div className="displayFlex">
                  <div style={{ width: 140 }}>
                    <CurrencyInput
                      prefix="£"
                      value={currentFeeDetails?.rate || 0}
                      updater={(value) => {
                        let tempFee = {
                          ...currentFeeDetails,
                          rate: value,
                        } as IFeeDto;

                        calcTotals(tempFee);
                      }}
                    />
                  </div>
                  <CustomCheckbox
                    label="Inc. Tax"
                    value={currentFeeDetails?.incTax || false}
                    disabled={isFormDisabled}
                    onChange={(value) => {
                      let tempFee = {
                        ...currentFeeDetails,
                        incTax: value,
                      } as IFeeDto;

                      calcTotals(tempFee);
                    }}
                  />
                </div>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TOTAL
                  </Typography>
                  <CurrencyInput disabled value={total} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentFeeDetails &&
            !validate(
              getCounselRequiredFields(currentFeeDetails),
              [],
              (errors) => dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(saveFee(currentFeeDetails))
              .unwrap()
              .then(() => {
                close(sdkApi);
              })
              .catch((e) => console.error(e));
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default CounselFee;
