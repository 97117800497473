import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import CustomDialog from "./custom-dialog";
import { setInvoiceErrors } from "../../app/store/invoice/invoiceSlice";

const InvoiceErrorDialog: FC = () => {
  const dispatch = useAppDispatch();

  const invoiceErrors = useAppSelector(
    (state: RootState) => state.invoice.errors
  );
  const errorAction = useAppSelector(
    (state: RootState) => state.invoice.errorAction
  );

  const onClose = () => {
    if (!!errorAction) {
      errorAction();
    }
    dispatch(setInvoiceErrors(undefined));
  };

  return (
    <CustomDialog
      isOpen={!!invoiceErrors}
      closeIcon
      title="Error"
      onClose={onClose}
      showWarningIcon
      actions={
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          disableRipple
        >
          OK
        </Button>
      }
      content={invoiceErrors?.map((warning) => (
        <Typography key={warning}>{warning}</Typography>
      ))}
    />
  );
};

export default InvoiceErrorDialog;
