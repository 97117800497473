import {
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import Select from "@mui/material/Select/Select";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getFasGroups,
  getFees,
  getTaxCodes,
  initFee,
  saveFee,
} from "../../app/store/fees/feeSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import { FeeLegalAidObj, IFeeDto } from "../../shared/dto/fee.dto";

import { IMatterDetailsDto } from "../../shared/dto/matter-details.dto";
import { CourtBundle, CourtType, FasGroup } from "../../shared/dto/rates.dto";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  isNullOrEmptyGuid,
  roundAwayFromZero,
} from "../../shared/utils/utils";
import {
  moreThan30DaysInFuture,
  validate,
} from "../../shared/utils/validation-utils";
import CurrencyInput from "../components/currency-input";
import CustomCheckbox from "../components/custom-checkbox";
import LocalDatePicker from "../components/date-picker";
import FeeDeleteButton from "../components/fee-delete-button";
import Footer from "../components/footer";
import NumberInput from "../components/number-input";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { useAppInit } from "../../shared/hooks/use-app-init";

const getFasRequiredFields = (fee: IFeeDto) => {
  return [
    {
      value: fee?.legalAidObj?.boltOn?.fas?.category,
      label: "Type of Law",
    },
    {
      value: fee?.legalAidObj?.boltOn?.fas?.hearingType,
      label: "Hearing Type",
    },
    {
      value: fee?.legalAidObj?.boltOn?.fas?.courtLevelJudge,
      label: "Court/Level of Judge",
    },
    {
      value: fee?.unit,
      label: "Units/Days",
    },
  ];
};

const FasFee: FC = () => {
  const dispatch = useAppDispatch();

  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const feeDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const dataInitialised = useRef(false);
  const initialFeeDetails = useRef<IFeeDto | undefined>(undefined);
  const feeRef = useRef<IFeeDto | undefined>(undefined);

  const infoIcon = require("../../shared/images/info.png");

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const feeDetails = useSelector((state: RootState) => state.fee.fee);
  const fasGroups = useSelector((state: RootState) => state.fee.fasGroups);
  const users = useSelector((state: RootState) => state.app.users);
  const taxCodes = useSelector((state: RootState) => state.fee.taxCodes);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.fee.saving);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<
    IFeeDto | undefined
  >(undefined);

  const [group, setGroup] = useState<FasGroup | undefined>(undefined);
  const [courtTypes, setCourtTypes] = useState<CourtType[] | undefined>(
    undefined
  );

  const [bundles, setBundles] = useState<CourtBundle[] | undefined>(undefined);
  const [hearingCost, setHearingCost] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const feeId = urlParams.get("feeid");

  const calcTravel = useCallback(
    (feeDetails: IFeeDto) => {
      let travelCost = 0;
      const type = group?.fasRates?.find(
        (t) =>
          t.hearingType === feeDetails?.legalAidObj.boltOn?.fas?.hearingType
      );
      if (type) {
        let travelRate = 0;
        travelRate = type.travelValue;
        if (travelRate) {
          travelCost =
            travelRate *
            (feeDetails?.legalAidObj.boltOn?.fas?.travelDays || 0) *
            (feeDetails?.legalAidObj?.boltOn?.fas?.travel ? 1 : 0);
        }
      }
      return travelCost;
    },
    [group?.fasRates]
  );

  const calcExtra = (feeDetails: IFeeDto) => {
    let tempVal = 0;

    if (feeDetails?.legalAidObj.boltOn?.fas?.harm) {
      tempVal += 0.25;
    }

    if (feeDetails?.legalAidObj.boltOn?.fas?.understanding) {
      tempVal += 0.25;
    }
    if (feeDetails?.legalAidObj.boltOn?.fas?.expert) {
      tempVal += 0.25;
    }
    return tempVal;
  };

  const calcTotals = useCallback(
    (
      feeDetails: IFeeDto,
      currentBundles?: CourtBundle[],
      currentCourtTypes?: CourtType[]
    ) => {
      const extra = calcExtra(feeDetails);
      const travelCost = calcTravel(feeDetails);

      const bundleValue =
        currentBundles?.find(
          (b) => b.code === feeDetails.legalAidObj.boltOn?.fas?.courtBundle
        )?.amount || 0;

      let tempBoltOn = travelCost + bundleValue;

      let courtAmount =
        currentCourtTypes?.find(
          (c) =>
            c.code === feeDetails?.legalAidObj?.boltOn?.fas?.courtLevelJudge
        )?.amount || 0;

      const hearingCost = roundAwayFromZero(
        courtAmount * (feeDetails?.unit || 0),
        2
      );

      setHearingCost(hearingCost);
      tempBoltOn = tempBoltOn + roundAwayFromZero(hearingCost * extra, 2);

      const currentTaxCode = taxCodes?.find(
        (t) => t.taxCodeId === feeDetails?.taxCodeId
      );
      if (currentTaxCode) {
        let tempTax = (currentTaxCode?.ratePercent || 0) / 100;

        let amount = hearingCost + tempBoltOn;
        if (tempTax > 0) {
          setTax(amount * tempTax);
        } else {
          setTax(0);
          tempTax = 0;
        }
        if (!!feeDetails?.incTax) {
          setTax(amount * (1 - 1 / (tempTax + 1)));
          tempTax = 0;
        }
        setTotal(amount * (1 + tempTax));

        const tempFee = {
          ...feeDetails,
          amount: amount,
          rate: amount / (feeDetails?.unit || 1),
          legalAidObj: {
            ...feeDetails?.legalAidObj,
            boltOn: {
              ...feeDetails?.legalAidObj.boltOn,

              fas: {
                ...feeDetails?.legalAidObj.boltOn?.fas,
                boltOnAmount: tempBoltOn,
              },
            },
          },
        } as IFeeDto;
        setCurrentFeeDetails(tempFee);
        if (!dataInitialised.current) {
          initialFeeDetails.current = tempFee;
        }
        dataInitialised.current = true;
      }
    },
    [calcTravel, taxCodes]
  );

  const refreshLists = useCallback(
    (feeDetails: IFeeDto) => {
      const selectedGroup = fasGroups?.find(
        (g) => g.category === feeDetails?.legalAidObj?.boltOn?.fas?.category
      );
      if (selectedGroup) {
        setGroup(selectedGroup);
      }

      const type = selectedGroup?.fasRates?.find(
        (t) =>
          t.hearingType === feeDetails?.legalAidObj.boltOn?.fas?.hearingType
      );

      if (type) {
        setCourtTypes(type.courtTypes);
        setBundles(type.courtBundles);
        calcTotals(feeDetails, type.courtBundles, type.courtTypes);
      } else {
        setCourtTypes(undefined);
        setBundles(undefined);
        calcTotals(feeDetails);
      }
    },
    [fasGroups, calcTotals]
  );

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(feeRef.current) !==
      JSON.stringify(initialFeeDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    feeRef.current = currentFeeDetails;
  }, [currentFeeDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(false)).then((action) => {
        if (action.meta.requestStatus !== "rejected") {
          if (feeId) {
            dispatch(getFees([feeId || ""]));
          } else {
            dispatch(
              initFee({
                matterDetails: action.payload as IMatterDetailsDto,
                type: "fas",
              })
            );
          }
          dispatch(
            getFasGroups(
              (action.payload as IMatterDetailsDto)?.legalAidObj?.tableNo || 0
            )
          );
        }
      });
      dispatch(getTaxCodes());
      dispatch(getUsers());
    }
  }, [dispatch, feeId]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- FAS Fee"
      );
    }
  }, [matterDetails, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!!feeDetails && !feeDetailsInitialised.current) {
      feeDetailsInitialised.current = true;
      let currentFee = { ...feeDetails[0] };
      if (isNullOrEmptyGuid(currentFee.legalAidObj?.taskCodeId)) {
        currentFee = {
          ...currentFee,
          legalAidObj: {
            ...currentFee.legalAidObj,
            taskCodeId: "7956f0bc-5382-4ae5-9b07-fd8791ddf6f1",
          },
        };
      }

      initialFeeDetails.current = currentFee;
      refreshLists(currentFee);
    }
  }, [feeDetails, feeDetailsInitialised, refreshLists]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  const isFormDisabled =
    currentFeeDetails?.billed || currentFeeDetails?.deleted;

  return (
    <>
      <TopBar
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Family-Care-Recording-a-Family-Advocacy-Scheme-Fee-Licensed-Work"
        leftComponents={
          <FeeDeleteButton
            fee={currentFeeDetails}
            feeRef={initialFeeDetails.current}
          />
        }
      />
      <div className="main">
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex5">
                <div className="displayFlex" style={{ alignItems: "center" }}>
                  <TextField
                    variant="outlined"
                    value={currentMatterDetails?.matterReference || ""}
                    disabled
                    style={{ width: 110, paddingRight: 5 }}
                  />
                  <Typography style={{ maxWidth: 450, minWidth: 450 }} noWrap>
                    {currentMatterDetails?.matterDescription || ""}
                  </Typography>
                </div>
              </div>

              <div className="flex3">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Date
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <LocalDatePicker
                      value={
                        currentFeeDetails?.transactionDate
                          ? currentFeeDetails?.transactionDate
                          : null
                      }
                      disabled={isFormDisabled}
                      onUpdate={(value) => {
                        if (value !== currentFeeDetails?.transactionDate) {
                          const newValue = value || "";

                          if (newValue !== "Invalid date") {
                            setCurrentFeeDetails({
                              ...currentFeeDetails,
                              transactionDate: newValue,
                            } as IFeeDto);
                          }
                        }
                      }}
                      validate={(value) =>
                        moreThan30DaysInFuture(
                          value,
                          currentFeeDetails?.transactionDate
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Billing Stage</InputLabel>
              </div>
              <div className="flex3">
                <TextField
                  variant="outlined"
                  value="Licensed Work"
                  disabled
                  fullWidth
                />
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <InputLabel
                    required
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Staff
                  </InputLabel>
                  <div style={{ width: 200, paddingLeft: 10 }}>
                    <Select
                      className="select"
                      fullWidth
                      native
                      variant="outlined"
                      value={currentFeeDetails?.legalAidObj.feeEarnerId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        setCurrentFeeDetails({
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            feeEarnerId: e.target.value,
                          } as FeeLegalAidObj,
                        } as IFeeDto);
                      }}
                    >
                      {!currentFeeDetails?.legalAidObj.feeEarnerId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {filterInactiveStaff(
                        users,
                        currentFeeDetails?.legalAidObj.feeEarnerId
                      )?.map((user) => (
                        <option key={user.staffId} value={user.staffId}>
                          {`${user.firstName} ${user.lastName}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRowTextArea">
              <div style={{ width: 130 }}>
                <InputLabel>Billing Description</InputLabel>
              </div>
              <div className="flex1">
                <TextareaAutosize
                  value={currentFeeDetails?.description || ""}
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    setCurrentFeeDetails({
                      ...currentFeeDetails,
                      description: e.target.value,
                    } as IFeeDto);
                  }}
                  minRows={3}
                  maxRows={3}
                  className="textarea-fee"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mainsection">
          <div className="flex1">
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Type of Law</InputLabel>
              </div>
              <div className="flex3">
                <Select
                  variant="outlined"
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.fas?.category || ""
                  }
                  fullWidth
                  native
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    const tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          fas: {
                            ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                            category: e.target.value,
                          },
                        },
                      },
                    } as IFeeDto;

                    refreshLists(tempFee);
                  }}
                >
                  <option key="" value="" className="emptyMenuItem"></option>
                  {fasGroups?.map((group) => (
                    <option key={group.category} value={group.category}>
                      {group.category}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="flex5" />
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>
                  Hearing Type
                  <Tooltip
                    style={{ paddingLeft: 5 }}
                    title="An Advocates Attendance form must be submitted with each hearing claimed, unless you are claiming a Hearing Unit 1,
                                 or the hearing took place by video/teleconference. This should be the original form that has been signed by the judge, magistrate or legal adviser."
                  >
                    <img alt="warning" src={infoIcon} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  variant="outlined"
                  native
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.fas?.hearingType ||
                    ""
                  }
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    const tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          fas: {
                            ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                            hearingType: e.target.value,
                            courtLevelJudge: undefined,
                          },
                        },
                      },
                    } as IFeeDto;

                    refreshLists(tempFee);
                  }}
                  fullWidth
                >
                  <option key="" value="" className="emptyMenuItem"></option>
                  {[...(group?.fasRates || [])]
                    ?.sort((a, b) => (a.hearingType > b.hearingType ? 1 : -1))
                    .map((type) => (
                      <option key={type.hearingType} value={type.hearingType}>
                        {type.hearingType}
                      </option>
                    ))}
                </Select>
              </div>

              <div className="flex5" />
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Court/Level of Judge</InputLabel>
              </div>
              <div className="flex3">
                <Select
                  variant="outlined"
                  native
                  value={
                    currentFeeDetails?.legalAidObj.boltOn?.fas
                      ?.courtLevelJudge || ""
                  }
                  disabled={isFormDisabled}
                  onChange={(e) => {
                    const tempFee = {
                      ...currentFeeDetails,
                      legalAidObj: {
                        ...currentFeeDetails?.legalAidObj,
                        boltOn: {
                          ...currentFeeDetails?.legalAidObj.boltOn,
                          fas: {
                            ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                            courtLevelJudge: e.target.value,
                          },
                        },
                      },
                    } as IFeeDto;

                    refreshLists(tempFee);
                  }}
                  fullWidth
                >
                  <option key="" value="" className="emptyMenuItem"></option>
                  {[...(courtTypes || [])]
                    ?.sort((a, b) => (a.code > b.code ? 1 : -1))
                    .map((type) => (
                      <option key={type.code} value={type.code}>
                        {type.code}
                      </option>
                    ))}
                </Select>
              </div>

              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    HEARING FEE
                  </Typography>
                  <CurrencyInput disabled value={hearingCost} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel required>Units/Days</InputLabel>
              </div>
              <div className="flex3">
                <div className="displayFlex">
                  <NumberInput
                    value={currentFeeDetails?.unit}
                    disabled={isFormDisabled}
                    decimalScale={0}
                    updater={(value) => {
                      const tempFee = {
                        ...currentFeeDetails,
                        unit: value,
                        legalAidObj: {
                          ...currentFeeDetails?.legalAidObj,
                          boltOn: {
                            ...currentFeeDetails?.legalAidObj.boltOn,
                            fas: {
                              ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                              unitDays: value,
                            },
                          },
                        } as FeeLegalAidObj,
                      } as IFeeDto;
                      calcTotals(tempFee, bundles, courtTypes);
                    }}
                    width={65}
                  />
                  <div
                    style={{
                      display: "grid",
                      alignContent: "center",
                      paddingRight: 10,
                    }}
                  >
                    <InputLabel style={{ paddingLeft: 40 }}>
                      Court Bundle
                    </InputLabel>
                  </div>

                  <div className="flex2">
                    <Select
                      native
                      variant="outlined"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.fas
                          ?.courtBundle || ""
                      }
                      fullWidth
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        const tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              fas: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                                courtBundle: e.target.value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee, bundles, courtTypes);
                      }}
                    >
                      <option
                        key=""
                        value=""
                        className="emptyMenuItem"
                      ></option>
                      {bundles?.map((bundle) => (
                        <option key={bundle.code} value={bundle.code}>
                          {bundle.code}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    BOLT-ON
                  </Typography>
                  <CurrencyInput
                    disabled
                    value={
                      currentFeeDetails?.legalAidObj.boltOn?.fas
                        ?.boltOnAmount || 0
                    }
                    prefix="£"
                  />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}>
                <InputLabel>
                  Uplifts
                  <Tooltip
                    style={{ paddingLeft: 5 }}
                    title="Where you are claiming any bolt-on you must have been granted authority to do so by the judge, magistrate or legal adviser."
                  >
                    <img alt="warning" src={infoIcon} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className="flex3">
                <div className="displayFlex">
                  <div style={{ width: 105 }}>
                    <CustomCheckbox
                      label="Harm"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.fas?.harm ||
                        false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        const tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              fas: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                                harm: value,
                              },
                            },
                          } as FeeLegalAidObj,
                        } as IFeeDto;

                        calcTotals(tempFee, bundles, courtTypes);
                      }}
                    />
                  </div>
                  <div>
                    <CustomCheckbox
                      label="Understanding"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.fas
                          ?.understanding || false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        const tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              fas: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                                understanding: value,
                              },
                            },
                          } as FeeLegalAidObj,
                        } as IFeeDto;

                        calcTotals(tempFee, bundles, courtTypes);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TAX
                  </Typography>
                  <CurrencyInput disabled value={tax} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }}>
                    <Select
                      variant="outlined"
                      native
                      value={currentFeeDetails?.taxCodeId || ""}
                      disabled={isFormDisabled}
                      onChange={(e) => {
                        const tempFee = {
                          ...currentFeeDetails,
                          taxCodeId: e.target.value,
                        } as IFeeDto;

                        calcTotals(tempFee, bundles, courtTypes);
                      }}
                      fullWidth
                    >
                      {!currentFeeDetails?.taxCodeId && (
                        <option
                          key=""
                          value=""
                          className="emptyMenuItem"
                        ></option>
                      )}
                      {taxCodes?.map((taxCode) => (
                        <option
                          key={taxCode.taxCodeId}
                          value={taxCode.taxCodeId}
                        >
                          {taxCode.taxCode}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputRow">
              <div style={{ width: 130 }}></div>
              <div className="flex3">
                <div className="displayFlex">
                  <div style={{ width: 105 }}>
                    <CustomCheckbox
                      label="Expert"
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.fas?.expert ||
                        false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        const tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              fas: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                                expert: value,
                              },
                            },
                          } as FeeLegalAidObj,
                        } as IFeeDto;

                        calcTotals(tempFee, bundles, courtTypes);
                      }}
                    />
                  </div>
                  <div>
                    <CustomCheckbox
                      label={
                        <InputLabel>
                          Travel
                          <Tooltip
                            style={{ paddingLeft: 5 }}
                            title="Only applicable if travel from your office to court/location of advocates' meeting is more than 25 miles each way."
                          >
                            <img alt="warning" src={infoIcon} />
                          </Tooltip>
                        </InputLabel>
                      }
                      value={
                        currentFeeDetails?.legalAidObj.boltOn?.fas?.travel ||
                        false
                      }
                      disabled={isFormDisabled}
                      onChange={(value) => {
                        const tempFee = {
                          ...currentFeeDetails,
                          legalAidObj: {
                            ...currentFeeDetails?.legalAidObj,
                            boltOn: {
                              ...currentFeeDetails?.legalAidObj.boltOn,
                              fas: {
                                ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                                travel: value,
                              },
                            },
                          },
                        } as IFeeDto;

                        calcTotals(tempFee, bundles, courtTypes);
                      }}
                    />
                  </div>

                  <NumberInput
                    value={
                      currentFeeDetails?.legalAidObj?.boltOn?.fas?.travelDays ||
                      undefined
                    }
                    disabled={isFormDisabled}
                    decimalScale={0}
                    updater={(value) => {
                      const tempFee = {
                        ...currentFeeDetails,
                        legalAidObj: {
                          ...currentFeeDetails?.legalAidObj,
                          boltOn: {
                            ...currentFeeDetails?.legalAidObj.boltOn,
                            fas: {
                              ...currentFeeDetails?.legalAidObj.boltOn?.fas,
                              travelDays: Number(value),
                            },
                          },
                        } as FeeLegalAidObj,
                      } as IFeeDto;

                      calcTotals(tempFee, bundles, courtTypes);
                    }}
                    width={65}
                  />
                </div>
              </div>
              <div className="flex5">
                <div className="displayFlex">
                  <div className="flex1" />
                  <Typography align="right" className="fee-totals">
                    TOTAL
                  </Typography>
                  <CurrencyInput disabled value={total} prefix="£" />
                  <div style={{ width: 140, paddingLeft: 10 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (
            !!currentFeeDetails &&
            !validate(getFasRequiredFields(currentFeeDetails), [], (errors) =>
              dispatch(setValidationErrors(errors))
            )?.length &&
            !saving
          ) {
            dispatch(saveFee(currentFeeDetails))
              .unwrap()
              .then(() => {
                close(sdkApi);
              })
              .catch((e) => console.error(e));
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default FasFee;
