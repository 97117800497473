interface IOption {
  label: string;
  value: any;
}

export const listStatusItems: IOption[] = [
  { label: "Draft - Unapproved", value: 0 },
  { label: "Draft - Approved", value: 1 },
  { label: "Final - Printed/Sent", value: 3 },
];
