import { OutlinedInput } from "@mui/material";
import React, { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  disabled?: boolean;
}

const NumberFormatCustom = forwardRef((props: NumberFormatCustomProps, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
    />
  );
});

interface INumberInputProps {
  value?: number;
  updater?: (event: any) => void;
  decimalScale?: number;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  width?: number;
}

const NumberInput: React.FC<INumberInputProps> = ({
  value,
  updater,
  decimalScale,
  disabled,
  placeholder,
  autoFocus,
  width,
}) => {
  return (
    <>
      <OutlinedInput
        value={value}
        inputComponent={NumberFormatCustom as any}
        disabled={disabled}
        inputProps={{ decimalScale: decimalScale }}
        autoFocus={autoFocus}
        onBlur={(event) => {
          if (!!updater) {
            updater(event.target.value);
          }
        }}
        placeholder={placeholder}
        sx={{
          width: width ? width : 130,
          "& .MuiInputBase-input": { textAlign: "end" },
          "& .MuiOutlinedInput-notchedOutline ": {
            border: "1px solid rgba(0, 0, 0, 0.23)",
          },
          "&.Mui-disabled  .MuiOutlinedInput-notchedOutline ": {
            border: "1px solid rgba(0, 0, 0, 0.23)",
          },
        }}
      />
    </>
  );
};

export default NumberInput;
