import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSortModel,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import RefreshIcon from "@mui/icons-material/Refresh";

import { close, openDocument, openMatter } from "../../shared/utils/sdk-utils";
import {
  createSubmission,
  markSubmission,
  setFilter,
  getInvoiceSubmissionList,
  getBranchList,
  unmarkSubmission,
} from "../../app/store/submission/submissionSlice";
import { environment } from "../../shared/environments";
import {
  filterMonth,
  filterSubmission,
  filterYear,
  wasLastYearsInvoice,
} from "./filters";
import { FirmBranch } from "../../shared/dto/common-list.dto";
import { IInvoiceSubItemDto } from "../../shared/dto/submission/submission.dto";
import { listSubmissionItems, listMonthItems } from "./lists";
import { RootState } from "../../app/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useAppInit } from "../../shared/hooks/use-app-init";
import createSubmissionIcon from "../../shared/images/createSubmission.png";
import CustomDialog from "../components/custom-dialog";
import Footer from "../components/footer";
import markAsSubmittedIcon from "../../shared/images/markAsSubmitted.png";
import markAsUnsubmittedIcon from "../../shared/images/markAsUnsubmitted.png";
import NumberDisplay from "../components/number-display";
import TopBar from "../components/topbar";
import { getTooltip } from "../../shared/utils/grid-utils";
import { setErrors } from "../../app/store/app/appSlice";

const SubmissionDetails: FC = () => {
  const dispatch = useAppDispatch();

  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const submission = useAppSelector((state) => state.submission);

  const isSubmissionInitialised = useRef(false);

  const [filteredData, setFilteredData] = useState<
    IInvoiceSubItemDto[] | undefined
  >();
  const [noInvoicesSelectedWarning, setNoInvoicesSelectedWarning] =
    useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [rowSelectionTotal, setRowselectionTotal] = useState<Number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
    undefined
  );

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "invoiceGuid",
      headerName: "ID",
      width: 0,
    },
    {
      disableColumnMenu: true,
      field: "matterType",
      flex: 1,
      headerName: "MATTER TYPE",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      align: "center",
      disableColumnMenu: true,
      field: "matterNo",
      headerAlign: "center",
      headerName: "MATTER NO.",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">
              <Typography
                className="hyperLink"
                noWrap
                onClick={() => {
                  if (!!params.row.matterGuid) {
                    openMatter(params.row.matterGuid, sdkApi);
                  }
                }}
              >
                {params.formattedValue}
              </Typography>
            </span>
          </Tooltip>
        );
      },
      width: 110,
    },
    {
      disableColumnMenu: true,
      field: "client",
      flex: 1,
      headerName: "CLIENT",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "matterUFN",
      headerName: "UFN",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "invoiceNo",
      headerAlign: "right",
      headerName: "INV. NO.",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const colWidth = params.colDef.computedWidth;
        return (
          <Tooltip
            placement="bottom-end"
            title={getTooltip(params.formattedValue, colWidth)}
          >
            <span className="tableCellTrucate">{params.formattedValue}</span>
          </Tooltip>
        );
      },
      width: 90,
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "invTotal",
      headerAlign: "right",
      headerName: "INV. TOTAL",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => <NumberDisplay hideNoValue showPrefix value={params.value} />,
      type: "number",
    },
  ];

  const getData = useCallback(() => {
    if (!isSubmissionInitialised.current) {
      isSubmissionInitialised.current = true;
      dispatch(getBranchList()).then(() => {
        dispatch(getInvoiceSubmissionList());
      });
    }
  }, [dispatch]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    // Filtering
    //
    let filterItems = [...submission.invoiceSubmissionItems].filter(
      (invoice) =>
        invoice.submitted !==
        (submission.submissionFilter?.submissionType ===
          filterSubmission.Unsubmitted)
    );

    // Branch filtering
    //
    if (submission.submissionFilter?.branch !== "All") {
      filterItems = [...filterItems].filter(
        (invoice) => invoice.branchGuid === submission.submissionFilter?.branch
      );
    }

    // Month filtering
    //
    if (
      submission.submissionFilter?.submissionType ===
      filterSubmission.Unsubmitted
    ) {
      filterItems = [...filterItems].filter(
        (invoice) =>
          wasLastYearsInvoice(
            invoice.billedYear,
            submission.submissionFilter?.year
          ) || invoice.billedMonth <= submission.submissionFilter?.month
      );
    } else {
      filterItems = [...filterItems].filter(
        (invoice) =>
          Number(invoice.submittedMonth) ===
          Number(submission.submissionFilter?.month)
      );
    }

    // Year filtering
    //
    if (
      submission.submissionFilter?.submissionType ===
      filterSubmission.Unsubmitted
    ) {
      filterItems = [...filterItems].filter(
        (invoice) => invoice.billedYear <= submission.submissionFilter?.year
      );
    } else {
      filterItems = [...filterItems].filter(
        (invoice) =>
          Number(invoice.submittedYear) ===
          Number(submission.submissionFilter?.year)
      );
    }

    setFilteredData(filterItems);
  }, [submission.submissionFilter, submission.invoiceSubmissionItems]);

  useEffect(() => {
    if (submission.docModel.documentId) {
      openDocument(submission.docModel, sdkApi);
    }
  }, [submission.docModel, sdkApi]);

  const calculateTotal = (rowItemsSelected: GridRowSelectionModel) => {
    let total = 0.0;

    if (rowItemsSelected.length > 0) {
      total =
        (filteredData || [])
          .filter((item: IInvoiceSubItemDto) => {
            return (
              rowItemsSelected.findIndex((e: any) => e === item.invoiceGuid) !==
              -1
            );
          })
          .map((item: IInvoiceSubItemDto) => {
            return Number(item.invTotal);
          })
          .reduce((prior: number, next: number) => {
            return prior + next;
          }, 0) || 0;
    }

    return total;
  };

  const getInvoiceSubItemsSelected = () => {
    return (filteredData || [])
      .filter((item: IInvoiceSubItemDto) => {
        return (
          rowSelectionModel.findIndex((e: any) => e === item.invoiceGuid) !== -1
        );
      })
      .map((item: IInvoiceSubItemDto) => {
        return item;
      });
  };

  const handleOnCreateSubmission = () => {
    if (rowSelectionModel.length === 0) {
      setNoInvoicesSelectedWarning(true);
    } else {
      dispatch(
        createSubmission({
          invoiceSubItems: getInvoiceSubItemsSelected(),
          month: submission.submissionFilter.month,
          year: submission.submissionFilter.year,
        })
      );
    }
  };

  const handleOnCreateSubmissionNoInvoicesSelected = () => {
    dispatch(
      createSubmission({
        invoiceSubItems: [],
        month: submission.submissionFilter.month,
        year: submission.submissionFilter.year,
      })
    );
  };

  const handleOnMarkSubmission = () =>
    dispatch(
      markSubmission({
        invoiceSubItems: getInvoiceSubItemsSelected(),
        month: submission.submissionFilter.month,
        year: submission.submissionFilter.year,
      })
    );

  const handleOnMarkAsUnsubmitted = () => {
    dispatch(unmarkSubmission(getInvoiceSubItemsSelected()));
  };

  const renderFilterBar = () => {
    return (
      <div className="submission-filter-bar">
        <div className="flex1" style={{ marginRight: 15 }}>
          <Select
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...submission.submissionFilter,
                  submissionType: e.target.value,
                })
              );
            }}
            sx={{
              backgroundColor: "#1e365e",
              color: "#fff",
              fontWeight: 600,
              letterSpacing: 0.5,
              textAlign: "center",
              textTransform: "uppercase",
              width: 158,
              ".MuiSvgIcon-root ": {
                fill: "white",
              },
            }}
            value={
              submission?.submissionFilter?.submissionType ||
              filterSubmission.Unsubmitted
            }
            variant="outlined"
          >
            {listSubmissionItems.map((item: any, index: number) => (
              <MenuItem key={`filterSubmission-${index}`} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex1" style={{ marginRight: 15, maxWidth: 300 }}>
          <Select
            fullWidth
            native
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...submission.submissionFilter,
                  branch: e.target.value,
                })
              );
            }}
            value={submission?.submissionFilter?.branch || "All"}
            variant="outlined"
          >
            {submission.branchList?.length > 1 && (
              <option key="branch--1" value="All">
                {"<All>"}
              </option>
            )}
            {(submission.branchList || []).map(
              (item: FirmBranch, index: number) => (
                <option key={`branch-${index}`} value={item.__id}>
                  {item.displayName}
                </option>
              )
            )}
          </Select>
        </div>
        <div className="flex1" style={{ marginRight: 15, maxWidth: 120 }}>
          <Select
            fullWidth
            native
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...submission.submissionFilter,
                  month: parseInt(e.target.value),
                })
              );
            }}
            value={submission?.submissionFilter?.month || filterMonth.Jan}
            variant="outlined"
          >
            {listMonthItems.map((item: any, index: number) => (
              <option key={`filterMonth-${index}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex1" style={{ maxWidth: 80 }}>
          <Select
            fullWidth
            native
            onChange={(e: any) => {
              dispatch(
                setFilter({
                  ...submission.submissionFilter,
                  year: parseInt(e.target.value),
                })
              );
            }}
            size="small"
            value={submission?.submissionFilter?.year || filterYear.Current}
            variant="outlined"
          >
            <option key={`filterYear-0`} value={filterYear.Current}>
              {filterYear.Current}
            </option>
            <option key={`filterYear-1`} value={filterYear.LastYear}>
              {filterYear.LastYear}
            </option>
            <option key={`filterYear-2`} value={filterYear.YearBeforeLast}>
              {filterYear.YearBeforeLast}
            </option>
          </Select>
        </div>
      </div>
    );
  };

  const renderDataGrid = () => {
    return (
      <DataGridPro
        checkboxSelection
        columns={columns.map((c) => {
          return {
            ...c,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderHeader: (params: GridColumnHeaderParams) => {
              return (
                <div
                  style={{
                    display: "grid",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      marginRight: "auto",
                      marginLeft: "auto",
                      height: 1,
                    }}
                  >
                    {sortModel?.find(
                      (f: any) =>
                        f.field === params.colDef.field && f.sort === "asc"
                    ) && (
                      <KeyboardArrowUpRoundedIcon className="tableHeaderIcon" />
                    )}
                    {sortModel?.find(
                      (f: any) =>
                        f.field === params.colDef.field && f.sort === "desc"
                    ) && <ExpandMoreRoundedIcon className="tableHeaderIcon" />}
                  </div>
                  <div style={{ display: "block" }}>
                    <Typography
                      textAlign={params.colDef.headerAlign}
                      style={{ fontWeight: 500 }}
                    >
                      {params.colDef.headerName}
                    </Typography>
                  </div>
                </div>
              );
            },
          };
        })}
        columnHeaderHeight={35}
        columnVisibilityModel={{
          invoiceGuid: false,
        }}
        disableRowSelectionOnClick
        getRowId={(row: any) => row.invoiceGuid}
        hideFooter
        initialState={{}}
        loading={submission.loading}
        localeText={{
          noRowsLabel:
            "No invoices can be found based on your search criteria.",
        }}
        // onRowClick={(params) => {
        //   let currentList = rowSelectionModel;
        //   if (currentList.includes(params.row.invoiceGuid)) {
        //     currentList = currentList.filter(
        //       (i) => i !== params.row.invoiceGuid
        //     );
        //   } else {
        //     currentList = [...currentList, params.row.invoiceGuid];
        //   }
        //   setRowSelectionModel(currentList);
        //   setRowselectionTotal(calculateTotal(currentList));
        // }}
        onRowSelectionModelChange={(
          newRowSelectionModel: GridRowSelectionModel
        ) => {
          setRowSelectionModel(newRowSelectionModel);
          setRowselectionTotal(calculateTotal(newRowSelectionModel));
        }}
        onSortModelChange={(model: any) => setSortModel(model)}
        rowSelectionModel={rowSelectionModel}
        rowHeight={30}
        rows={filteredData || []}
        scrollbarSize={15}
        slots={{
          columnSortedAscendingIcon: KeyboardArrowUpRoundedIcon,
          columnSortedDescendingIcon: ExpandMoreRoundedIcon,
        }}
        sx={{
          backgroundColor: "#fff",
          borderBottom: 0,
          borderRadius: 0,
          ".MuiDataGrid-overlay": { color: "#0060bb" },
          ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
        }}
      />
    );
  };

  const renderTotals = () => {
    return (
      <div
        className="submission-total"
        style={{
          height: 28,
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        <div className="flex4">SELECTED: {rowSelectionModel.length}</div>
        <div className="flex1" style={{ color: "#000" }}>
          TOTAL
        </div>
        <div
          style={{
            alignItems: "end",
            color: "#000",
            marginLeft: 20,
            marginRight: 10,
            minWidth: 70,
            textAlign: "end",
          }}
        >
          {`${environment().currencyPrefix}${rowSelectionTotal.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
            }
          )}`}
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          submission.submissionFilter.submissionType ===
          filterSubmission.Unsubmitted ? (
            <>
              <Button
                className="submission-top-bar-action-button"
                onClick={handleOnCreateSubmission}
              >
                <img
                  alt="create submission"
                  className="submission-top-bar-action-button-icon"
                  src={createSubmissionIcon}
                />
                Create Submission
              </Button>
              <Button
                className="submission-top-bar-action-button"
                onClick={() => {
                  rowSelectionModel.length && handleOnMarkSubmission();
                }}
              >
                <img
                  alt="mark as submitted"
                  className="submission-top-bar-action-button-icon"
                  src={markAsSubmittedIcon}
                />
                Mark as Submitted
              </Button>
            </>
          ) : (
            <Button
              className="submission-top-bar-action-button"
              onClick={() => {
                rowSelectionModel.length && handleOnMarkAsUnsubmitted();
              }}
            >
              <img
                alt="mark as unsubmitted"
                className="submission-top-bar-action-button-icon"
                src={markAsUnsubmittedIcon}
              />
              Mark as Unsubmitted
            </Button>
          )
        }
        rightComponents={
          <IconButton
            color="primary"
            onClick={() => {
              dispatch(getInvoiceSubmissionList());
            }}
          >
            <RefreshIcon />
          </IconButton>
        }
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Creating-the-CWA-Monthly-Submission"
      />
      {renderFilterBar()}
      <div className="submission-main">{renderDataGrid()}</div>
      {renderTotals()}
      <Footer
        onClose={() => {
          close(sdkApi);
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setNoInvoicesSelectedWarning(false);
                handleOnCreateSubmissionNoInvoicesSelected();
              }}
              variant="contained"
            >
              OK
            </Button>
            <Button
              color="secondary"
              disableRipple
              onClick={() => setNoInvoicesSelectedWarning(false)}
              variant="outlined"
            >
              Cancel
            </Button>
          </>
        }
        content={
          <>
            <Typography>No invoices selected, this will generate</Typography>
            <Typography>New Matter Starts data only.</Typography>
          </>
        }
        isOpen={noInvoicesSelectedWarning}
        onClose={() => setNoInvoicesSelectedWarning(false)}
        showWarningIcon
        title="Confirm"
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => dispatch(setErrors(undefined))}
              variant="contained"
            >
              OK
            </Button>
          </>
        }
        content={<Typography>{submission.errors}</Typography>}
        isOpen={submission.errors ? true : false}
        onClose={() => dispatch(setErrors(undefined))}
        showWarningIcon
        title="Error"
      />
    </>
  );
};

export default SubmissionDetails;
