import { Typography } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { environment } from "../../shared/environments";

interface INumberDisplayProps {
  value?: number;
  showNegative?: boolean;
  showPrefix?: boolean;
  hideNoValue?: boolean;
  bold?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  color?: string;
  notCurrency?: boolean;
  displayAsQty?: boolean;
}

const NumberDisplay: React.FC<INumberDisplayProps> = ({
  value,
  showNegative,
  showPrefix,
  hideNoValue,
  align,
  bold,
  color,
  notCurrency,
  displayAsQty
}) => {
  return (
    <>
      {hideNoValue && !value ? (
        <></>
      ) : (
        <Typography
          align={align}
          fontWeight={bold ? 600 : undefined}
          color={color ? color : undefined}
        >
          {showNegative && value !== 0 && "("}
          <NumericFormat
            value={(value || 0).toFixed(displayAsQty ? 0 : 2)}
            displayType="text"
            thousandSeparator={notCurrency ? false : true}
            prefix={showPrefix ? environment().currencyPrefix : undefined}
            fixedDecimalScale={notCurrency ? false : true}
          />
          {showNegative && value !== 0 && ")"}
        </Typography>
      )}
    </>
  );
};

export default NumberDisplay;
