import { Checkbox, FormControlLabel } from "@mui/material";
import { FC, ReactNode } from "react";
import CheckedCheckboxIcon from "../../shared/images/checkbox-checked-icon";
import CheckboxIcon from "../../shared/images/checkbox-icon";

interface ICheckBoxProps {
  label?: ReactNode;
  value?: boolean;
  disabled?: boolean;
  disableMargin?: boolean;
  onChange?: (value: boolean) => void;
}

const CustomCheckbox: FC<ICheckBoxProps> = ({
  label,
  value,
  disabled,
  disableMargin,
  onChange,
}) => {
  return (
    <FormControlLabel
      sx={{
        "& .MuiTypography-root": {
          paddingLeft: "5px",
          color: "#000",
          WebkitTextFillColor: "#000 !important",
        },
      }}
      style={!disableMargin ? { marginLeft: 0 } : { marginRight: 0 }}
      control={
        <Checkbox
          checked={value || false}
          disabled={disabled}
          onChange={(_e, value) => {
            if (!!onChange) {
              onChange(value);
            }
          }}
          checkedIcon={<CheckedCheckboxIcon />}
          icon={<CheckboxIcon />}
          disableRipple
          disableTouchRipple
          disableFocusRipple
        />
      }
      label={label ? label : null}
    />
  );
};

export default CustomCheckbox;
