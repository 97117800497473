import { InputLabel, Select, TextField } from "@mui/material";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { checkUFN, getTableNo } from "../../app/store/matter/matterSlice";
import { FirmBranch, ListCodeDesc } from "../../shared/dto/common-list.dto";
import {
  Criminal,
  IMatterDetailsDto,
  MatterLegalAidObj,
} from "../../shared/dto/matter-details.dto";
import { IUFNCheckerDto } from "../../shared/dto/unf-check.dto";
import {
  getFormattedUFNDate,
  isNullOrEmptyGuid,
} from "../../shared/utils/utils";
import CustomCheckbox from "./custom-checkbox";
import LocalDatePicker from "./date-picker";

interface MatterProps {
  matterDetails?: IMatterDetailsDto;
  branchList?: FirmBranch[];
  rateList?: ListCodeDesc[];
  onUpdate: (matterDetails: IMatterDetailsDto) => void;
  getLists: (tableNo: number) => void;
}

const MatterComponent: FC<MatterProps> = ({
  matterDetails,
  branchList,
  rateList,
  onUpdate,
  getLists,
}) => {
  const ufnInitialised = useRef(false);
  const dispatch = useAppDispatch();

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);

  useEffect(() => {
    if (!ufnInitialised.current && !!matterDetails) {
      let tempMatterDetails = { ...matterDetails };
      dispatch(
        checkUFN({
          ufnDate: matterDetails?.legalAidObj?.ufnDate || "",
          ufnNumber: matterDetails?.legalAidObj?.ufnNumber || "",
        })
      ).then((action) => {
        if (!(action.payload as any).valid) {
          tempMatterDetails = {
            ...tempMatterDetails,
            legalAidObj: {
              ...tempMatterDetails.legalAidObj,
              ufnNumber: (action.payload as any).validNumber,
            } as MatterLegalAidObj,
          };
        }
        setCurrentMatterDetails(tempMatterDetails);
        onUpdate(tempMatterDetails);
      });
      if (
        (branchList || []).length === 1 &&
        isNullOrEmptyGuid(tempMatterDetails.legalAidObj?.__branchId)
      ) {
        const currentBranch = (branchList || [])[0];
        tempMatterDetails = {
          ...tempMatterDetails,
          legalAidObj: {
            ...tempMatterDetails.legalAidObj,
            __branchId: currentBranch.__id,
            rate: (currentBranch?.legalAidChargeRate || "")[0],
          } as MatterLegalAidObj,
        };
        if (matterDetails?.legalAidObj?.matterTypeId === 1) {
          tempMatterDetails = {
            ...tempMatterDetails,
            legalAidObj: {
              ...tempMatterDetails?.legalAidObj,
              criminal: {
                ...tempMatterDetails?.legalAidObj?.criminal,
                undesignatedArea: currentBranch?.undesignatedAreaLegalAid,
              },
            } as MatterLegalAidObj,
          };
        }
      }
      ufnInitialised.current = true;
    }
  }, [matterDetails, setCurrentMatterDetails, dispatch, onUpdate, branchList]);

  return (
    <>
      <div className="inputRow">
        <div className="flex4">
          <InputLabel>Branch *</InputLabel>
        </div>
        <div className="flex7">
          <Select
            fullWidth
            native
            className="select"
            variant="outlined"
            value={matterDetails?.legalAidObj?.__branchId || ""}
            onChange={(e) => {
              const currentBranch = (branchList || []).find(
                (b) => b.__id === e.target.value
              );
              let returnedDetails = {
                ...matterDetails,
                legalAidObj: {
                  ...matterDetails?.legalAidObj,
                  __branchId: e.target.value,
                  rate: (currentBranch?.legalAidChargeRate || "")[0],
                } as MatterLegalAidObj,
              };
              if (matterDetails?.legalAidObj?.matterTypeId === 1) {
                returnedDetails = {
                  ...returnedDetails,
                  legalAidObj: {
                    ...returnedDetails?.legalAidObj,
                    criminal: {
                      ...returnedDetails?.legalAidObj.criminal,
                      undesignatedArea: currentBranch?.undesignatedAreaLegalAid,
                    },
                  } as MatterLegalAidObj,
                };
              }

              onUpdate(returnedDetails);
            }}
          >
            {isNullOrEmptyGuid(matterDetails?.legalAidObj?.__branchId) && (
              <option key="" value="" className="emptyMenuItem"></option>
            )}
            {(branchList || []).map((branch) => (
              <option key={branch.__id} value={branch.__id}>
                {branch.displayName}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="inputRow">
        <div className="flex4">
          <InputLabel>Date Opened *</InputLabel>
        </div>
        <div className="flex7" style={{ paddingLeft: 1 }}>
          <div className="displayFlex">
            <LocalDatePicker
              value={
                currentMatterDetails?.legalAidObj?.ufnDate
                  ? currentMatterDetails?.legalAidObj?.ufnDate
                  : null
              }
              onUpdate={(value) => {
                if (value !== currentMatterDetails?.legalAidObj?.ufnDate) {
                  const newValue = value;
                  const formattedDate = moment(value, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  );

                  if (newValue !== "Invalid date") {
                    let temp = {
                      ...matterDetails,
                      legalAidObj: {
                        ...matterDetails?.legalAidObj,
                        ufnDate: newValue,
                        ufnNumber: "001",
                      } as MatterLegalAidObj,
                    };
                    setCurrentMatterDetails(temp);
                    onUpdate(temp);

                    if (newValue) {
                      dispatch(
                        checkUFN({
                          ufnDate: newValue,
                          ufnNumber: temp?.legalAidObj?.ufnNumber || "",
                        })
                      ).then((action) => {
                        dispatch(getTableNo(formattedDate)).then(
                          (tableNoAction) => {
                            getLists(Number(tableNoAction.payload));
                          }
                        );
                        if (!(action.payload as IUFNCheckerDto).valid) {
                          temp = {
                            ...temp,
                            legalAidObj: {
                              ...temp?.legalAidObj,
                              ufnNumber: (action.payload as IUFNCheckerDto)
                                .validNumber,
                            } as MatterLegalAidObj,
                          };
                        }
                        setCurrentMatterDetails(temp);
                        onUpdate(temp);
                      });
                    }
                  }
                }
              }}
              validate={(value) => {
                if (value) {
                  const today = moment();
                  const dateDiff = value.diff(today, "days");
                  if (dateDiff > 30) {
                    return {
                      keepButton: true,
                      message:
                        "Selected date is over 30 days more than today's date. Is this correct?",
                      originalValue: currentMatterDetails?.legalAidObj?.ufnDate,
                    };
                  }
                }
                return null;
              }}
            />

            <InputLabel
              style={{
                flex: 1,
                textAlign: "end",
                paddingRight: 5,
                paddingTop: 5,
              }}
            >
              UFN
            </InputLabel>
            <TextField
              variant="outlined"
              disabled
              style={{
                width: 60,
              }}
              sx={{ input: { textAlign: "right" } }}
              value={getFormattedUFNDate(
                currentMatterDetails?.legalAidObj?.ufnDate
              )}
            />
            <InputLabel style={{ padding: "0px 5px", paddingTop: 3 }}>
              /
            </InputLabel>
            <TextField
              variant="outlined"
              style={{ width: 50 }}
              value={currentMatterDetails?.legalAidObj?.ufnNumber || ""}
              inputProps={{ maxLength: 3 }}
              onChange={(e) => {
                setCurrentMatterDetails({
                  ...matterDetails,
                  legalAidObj: {
                    ...matterDetails?.legalAidObj,
                    ufnNumber: e.target.value,
                  } as MatterLegalAidObj,
                });
              }}
              onBlur={(e) => {
                let newValue =
                  e.target.value.length === 3
                    ? e.target.value
                    : e.target.value.padStart(3, "0");

                let temp = {
                  ...currentMatterDetails,
                  legalAidObj: {
                    ...matterDetails?.legalAidObj,
                    ufnNumber: newValue,
                  } as MatterLegalAidObj,
                };
                dispatch(
                  checkUFN({
                    ufnDate: matterDetails?.legalAidObj?.ufnDate || "",
                    ufnNumber: newValue || "",
                  })
                ).then((action) => {
                  if (!(action.payload as IUFNCheckerDto).valid) {
                    temp = {
                      ...temp,
                      legalAidObj: {
                        ...temp?.legalAidObj,
                        ufnNumber: (action.payload as IUFNCheckerDto)
                          .validNumber,
                      } as MatterLegalAidObj,
                    };
                  }
                  setCurrentMatterDetails(temp);
                  onUpdate(temp);
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="inputRow">
        <div className="flex4">
          <InputLabel>Rate *</InputLabel>
        </div>
        <div className="flex7">
          <div className="displayFlex">
            <div
              className="flex2"
              style={
                matterDetails?.legalAidObj?.matterTypeId === 1
                  ? { paddingRight: 10 }
                  : {}
              }
            >
              <Select
                fullWidth
                variant="outlined"
                native
                className="select"
                value={matterDetails?.legalAidObj?.rate || ""}
                onChange={(e) => {
                  onUpdate({
                    ...matterDetails,
                    legalAidObj: {
                      ...matterDetails?.legalAidObj,
                      rate: e.target.value,
                    } as MatterLegalAidObj,
                  });
                }}
              >
                {!matterDetails?.legalAidObj?.rate && (
                  <option key="" value="" className="emptyMenuItem"></option>
                )}
                {(rateList || []).map((rate) => (
                  <option key={rate.code} value={rate.code}>
                    {rate.description}
                  </option>
                ))}
              </Select>
            </div>
            {matterDetails?.legalAidObj?.matterTypeId === 1 && (
              <div className="flex4">
                <CustomCheckbox
                  label="Undesignated Area"
                  value={matterDetails?.legalAidObj?.criminal?.undesignatedArea}
                  onChange={(value) => {
                    onUpdate({
                      ...matterDetails,
                      legalAidObj: {
                        ...matterDetails?.legalAidObj,
                        criminal: {
                          ...matterDetails?.legalAidObj?.criminal,
                          undesignatedArea: value,
                        } as Criminal,
                      } as MatterLegalAidObj,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MatterComponent;
