export const isDisbursementsOnly = (
  claimTypeCode: string,
  matterTypeId: number
) => {
  let isDisbursementsOnly = false;

  if (matterTypeId === 11) {
    //MH
    if (claimTypeCode === "Mental Health Stage Disbursement Claim") {
      isDisbursementsOnly = true;
    }
  } else if (matterTypeId === 12) {
    // Immigration
    if (claimTypeCode === "Immigration Stage Disbursement Claim") {
      isDisbursementsOnly = true;
    }
  }
  return isDisbursementsOnly;
};
