import { InputLabel, Select, TextField } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getUsers, setValidationErrors } from "../../app/store/app/appSlice";
import {
  getMatterDetails,
  getMediationLists,
  putDefinables,
} from "../../app/store/matter/matterSlice";
import {
  IMatterDetailsDto,
  MatterLegalAidObj,
} from "../../shared/dto/matter-details.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import {
  close,
  onBeforeClose,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  getMatterRequiredFields,
  validate,
} from "../../shared/utils/validation-utils";
import CustomCheckbox from "../components/custom-checkbox";
import Footer from "../components/footer";
import MatterComponent from "../components/matter-component";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";

const MediationMatterDetails: FC = () => {
  const listsInitialised = useRef(false);
  const matterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const initialMatterDetails = useRef<IMatterDetailsDto | undefined>(undefined);
  const matterRef = useRef<IMatterDetailsDto | undefined>(undefined);

  const dispatch = useAppDispatch();

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const matterLists = useSelector(
    (state: RootState) => state.matter.matterLists
  );
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const saving = useSelector((state: RootState) => state.matter.saving);

  const [currentMatterDetails, setCurrentMatterDetails] = useState<
    IMatterDetailsDto | undefined
  >(undefined);
  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const onCancel = useCallback(() => {
    if (
      JSON.stringify(matterRef.current) !==
      JSON.stringify(initialMatterDetails.current)
    ) {
      setShowUnsavedData(true);
    } else {
      close(sdkApi);
    }
  }, [sdkApi]);

  useEffect(() => {
    matterRef.current = currentMatterDetails;
  }, [currentMatterDetails]);

  const getData = useCallback(() => {
    if (!listsInitialised.current) {
      listsInitialised.current = true;
      dispatch(getMatterDetails(true))
        .unwrap()
        .then(() => {
          dispatch(getMediationLists());
        })
        .catch(() => {});
      dispatch(getUsers());
    }
  }, [dispatch]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !!matterLists && !matterDetailsInitialised.current) {
      matterDetailsInitialised.current = true;
      initialMatterDetails.current = matterDetails;
      setCurrentMatterDetails(matterDetails);
      setWindowTitle(
        `${matterDetails?.matterReference || ""} ${
          matterDetails?.matterDescription || ""
        }`,
        sdkApi,
        60,
        "- Legal Aid Details"
      );
    }
  }, [matterDetails, matterLists, matterDetailsInitialised, sdkApi]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => onCancel(), sdkApi);
    }
  }, [sdkApi, onCancel]);

  return (
    <>
      <TopBar helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Civil-Mediation-Creating-a-New-Legal-Aid-Matter" />
      <div className="main">
        <div className="mainsection">
          <div className="halfWidth">
            <MatterComponent
              matterDetails={currentMatterDetails}
              onUpdate={(matterDetails) =>
                setCurrentMatterDetails(matterDetails)
              }
              getLists={(tableNo) => {
                dispatch(getMediationLists(tableNo));
              }}
              branchList={matterLists?.branchList}
              rateList={matterLists?.rateList}
            />
          </div>
          <div className="halfWidthWithPadding">
            <div className="inputRow">
              <div className="flex4">
                <InputLabel required>Mediation Type</InputLabel>
              </div>
              <div className="flex7">
                <div style={{ display: "grid" }}>
                  <Select
                    className="select"
                    native
                    fullWidth
                    displayEmpty
                    variant="outlined"
                    value={
                      currentMatterDetails?.legalAidObj?.mediation
                        ?.mediationType || ""
                    }
                    onChange={(e) => {
                      setCurrentMatterDetails({
                        ...currentMatterDetails,
                        legalAidObj: {
                          ...currentMatterDetails?.legalAidObj,
                          mediation: {
                            ...currentMatterDetails?.legalAidObj?.mediation,
                            mediationType: e.target.value,
                          },
                        } as MatterLegalAidObj,
                      });
                    }}
                  >
                    <option key="" value="" className="emptyMenuItem"></option>
                    {(matterLists?.mediationTypesList || []).map((type) => (
                      <option
                        key={type.code}
                        value={`${type.description} [${type.code}]`}
                      >
                        {`${type.description} [${type.code}]`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Eligible</InputLabel>
              </div>
              <div className="flex7">
                <div className="displayFlex" style={{ marginBottom: 2 }}>
                  <div className="flex1" style={{ paddingBottom: 1 }}>
                    <CustomCheckbox
                      label="Client 1"
                      value={currentMatterDetails?.legalAidObj?.eligibleClient}
                      onChange={(value) => {
                        setCurrentMatterDetails({
                          ...currentMatterDetails,
                          legalAidObj: {
                            ...currentMatterDetails?.legalAidObj,
                            eligibleClient: value,
                          } as MatterLegalAidObj,
                        });
                      }}
                    />
                  </div>
                  <div className="flex1" style={{ paddingBottom: 1 }}>
                    <CustomCheckbox
                      label="Client 2"
                      value={
                        currentMatterDetails?.legalAidObj?.mediation
                          ?.eligibleClient2
                      }
                      onChange={(value) => {
                        setCurrentMatterDetails({
                          ...currentMatterDetails,
                          legalAidObj: {
                            ...currentMatterDetails?.legalAidObj,
                            mediation: {
                              ...currentMatterDetails?.legalAidObj?.mediation,
                              eligibleClient2: value,
                            },
                          } as MatterLegalAidObj,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inputRow">
              <div className="flex4">
                <InputLabel>Schedule Reference</InputLabel>
              </div>
              <div className="flex7">
                <TextField
                  variant="outlined"
                  value={
                    currentMatterDetails?.legalAidObj?.scheduleReference || ""
                  }
                  onChange={(e) => {
                    setCurrentMatterDetails({
                      ...currentMatterDetails,
                      legalAidObj: {
                        ...currentMatterDetails?.legalAidObj,
                        scheduleReference: e.target.value,
                      } as MatterLegalAidObj,
                    });
                  }}
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onCancel={() => onCancel()}
        onSave={() => {
          if (!!currentMatterDetails && !saving) {
            const requiredFields = [
              ...getMatterRequiredFields(currentMatterDetails),
              {
                value:
                  currentMatterDetails?.legalAidObj?.mediation?.mediationType,
                label: "Mediation Type",
              },
            ];
            if (
              !validate(requiredFields, [], (errors) =>
                dispatch(setValidationErrors(errors))
              )?.length
            ) {
              dispatch(putDefinables(currentMatterDetails)).then((action) => {
                if (action.meta.requestStatus !== "rejected") {
                  close(sdkApi);
                }
              });
            }
          }
        }}
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => setShowUnsavedData(false)}
      />
    </>
  );
};

export default MediationMatterDetails;
